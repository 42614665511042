import React, { useState } from 'react';
import StandardTable from '../../../modules/StandardTable';
import inputs from './modalInputs';
import Editor from '../../../modules/Editor';
import { connect, useSelector } from 'react-redux';
import { uploadFiles } from '../../../api/ApplicationForm';
import { Button, Modal, Upload } from 'antd';
import { isNumberAndAboveZero } from '../../../utils/validation';
import config from '../../../config';

const tableName = 'pollutants_in_wastewater';

const InformationForDischarges = ({ token }) => {
  const [data] = useState([
    {
      id: '1',
      wastewater_discharge_source: 'Test',
      names_of_issues: 0,
      names_of_substances: 0,
      wastewater_consumption: 0,
      permissible_concentration: 0,
      standards_for_discharges: 0,
      note: 'Test',
    },
  ]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const { orderProductionId } = useSelector(state => ({
    ...state.userStore,
  }));
  const [state, setState] = useState({
    fileList: [],
    uploading: false,
  });

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    uploadFiles({
      token,
      tableName,
      tableType: 'discharge',
      formId: orderProductionId,
      file: state.fileList[0],
    }).then(() => window.location.reload());

    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const transformDataSource = (data, setState) => {
    const newDataSource = [];
    data.forEach(el =>
      newDataSource.push({
        key: el.id,
        ...el,
      })
    );
    setState(newDataSource);
  };

  const columns = rounding => (token, refresh) => [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Источник сброса сточных вод',
      dataIndex: 'wastewater_discharge_source',
      key: 'wastewater_discharge_source',
      width: 250,
    },
    {
      title: 'Наименования (номер) выпусков',
      dataIndex: 'names_of_issues',
      key: 'names_of_issues',
      width: 220,
    },
    {
      title: 'Наименования веществ  (показателя)',
      dataIndex: 'names_of_substances',
      key: 'names_of_substances',
    },
    {
      title: 'Расход сточных вод тыс. м3/год',
      dataIndex: 'wastewater_consumption',
      key: 'wastewater_consumption',
      rules: [
        {
          required: true,
          message: 'Данные обязательны для заполнения',
          validator: isNumberAndAboveZero,
        },
      ],
    },
    {
      title: 'Допустимая концентрация на выпуске, мг/дм3',
      dataIndex: 'permissible_concentration',
      key: 'permissible_concentration',
    },
    {
      title: 'Нормативы сбросов ЗВ тонн/год',
      dataIndex: 'standards_for_discharges',
      key: 'standards_for_discharges',
      rules: [
        {
          required: true,
          message: 'Данные обязательны для заполнения',
          validator: isNumberAndAboveZero,
        },
      ],
    },
    {
      title: 'Примечание',
      dataIndex: 'note',
      key: 'note',
    },
    {
      title: 'Действия',
      key: 'actions',
      width: 120,
      render: (text, record) => (
        <Editor
          addInputs={inputs}
          editInputs={inputs}
          text={text}
          tableName={tableName}
          editTableType={'order_production_tables'}
          record={record}
          refresh={refresh}
        />
      ),
    },
  ];

  const { fileList, uploading } = state;

  const props = {
    onRemove: file => {
      setState(state => {
        const index = state.fileList.indexOf(file);
        const newFileList = state.fileList.slice();
        newFileList.splice(index, 1);
        return {
          fileList: newFileList,
        };
      });
    },
    beforeUpload: file => {
      setState(state => ({
        fileList: [...state.fileList, file],
      }));
      return false;
    },
    fileList,
  };

  return (
    <div>
      <StandardTable
        inputs={inputs}
        tableName={tableName}
        tableType={'order_production_tables'}
        columns={rounding => columns(rounding)}
        transformDataSource={transformDataSource}
        dataSource={data}
      />
      <Button.Group>
        <Button type="primary" onClick={showModal}>
          Загрузить через шаблон
        </Button>
        <Button
          type="primary"
          onClick={() => {
            window.open(`${config.api_url}сбросы.xlsx`);
          }}
        >
          Скачать пример шаблона
        </Button>
      </Button.Group>
      <Modal
        title="Файл"
        visible={isModalVisible}
        okText="Отправить"
        onOk={handleOk}
        cancelText="Закрыть"
        onCancel={handleCancel}
      >
        <Upload {...props}>
          <Button>Выбрать файл</Button>
        </Upload>
      </Modal>
    </div>
  );
};

const mapStateToProps = ({ userStore: { token } }) => ({
  token,
});

export default connect(mapStateToProps)(InformationForDischarges);

import roundUp from '../../../../../utils/roundUp';

export default rounding => [
  {
    title: '№п/п',
    dataIndex: 'number',
    key: 'number',
  },
  {
    title: 'Наименование площадки',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'лимит, тонн/год',
    dataIndex: 'limit_value',
    key: 'limit_value',
    render: value => roundUp(value, rounding),
  },
  {
    title: 'Фактический сброс, тонн/квартал',
    children: [
      {
        title: '1 квартал',
        dataIndex: 'fact_discharges_1',
        key: 'fact_discharges_1',
        render: value => roundUp(value, rounding),
      },
      {
        title: '2 квартал',
        dataIndex: 'fact_discharges_2',
        key: 'fact_discharges_2',
        render: value => roundUp(value, rounding),
      },
      {
        title: '3 квартал',
        dataIndex: 'fact_discharges_3',
        key: 'fact_discharges_3',
        render: value => roundUp(value, rounding),
      },
      {
        title: '4 квартал',
        dataIndex: 'fact_discharges_4',
        key: 'fact_discharges_4',
        render: value => roundUp(value, rounding),
      },
    ],
  },
  {
    title: 'Итого за год',
    dataIndex: 'total',
    key: 'total',
    render: value => roundUp(value, rounding),
  },
];

import {
    Button,
    Col,
    Icon,
    Input,
    Modal,
    Popconfirm,
    Select,
    Table
} from 'antd';
import ButtonGroup from 'antd/lib/button/button-group';
import Axios from 'axios';
import React, { useEffect, useState } from 'react';

const roles = {
  1: 'Админ',
  2: 'Супер-Админ',
  3: 'Менеджер',
  4: 'Пользователь',
};

export const Users = () => {
  const [operation, setOperation] = useState(null);
  const [show, setShowTable] = useState(true);
  const [users, setUser] = useState([]);
  const [username, setUsername] = useState('');
  const [role_id, setRoleId] = useState();
  const [password, setPassword] = useState('');
  const [visible, setVisible] = useState(false);

  const fetchData = () => {
    console.log('M~OtH`er');
    Axios.get('http://185.146.3.215:4000/api/user').then(res => {
      console.log('RES', res);
      setUser(res.data);
    });
  };

  const deleteUser = id => {
    Axios.delete(`http://185.146.3.215:4000/api/user?username=${id}`).then(
      res => {
        console.log('RES', res);
        fetchData();
      }
    );
  };

  const createUser = () => {
    console.log({ username, password });
    Axios.post(`http://185.146.3.215:4000/api/user`, {
      username,
      password,
      role_id
    }).then(res => {
      console.log('RES', res);
      setTimeout(() => fetchData(), 200);
      setVisible(false);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const closePopup = () => {
    setOperation(null);
    setShowTable(false);
    setTimeout(() => setShowTable(true), 100);
  };

  return (
    <div>
      <Col>
        <Button.Group>
          <Button
            style={{ marginBottom: 30 }}
            type="primary"
            onClick={() => setVisible(true)}
          >
            Добавить
            <Icon type="plus" />
          </Button>
        </Button.Group>
      </Col>
      <Table
        dataSource={users}
        columns={[
          { dataIndex: 'username', title: 'Логин' },
          { dataIndex: 'role_id', title: 'Роль', render: id => roles[id] },
          {
            title: 'Действия',
            render(_, record) {
              return (
                <Popconfirm
                  title="Вы уверены ?"
                  onConfirm={() => deleteUser(record.username)}
                >
                  <ButtonGroup>
                    <Button>
                      <Icon type="delete" />
                    </Button>
                  </ButtonGroup>
                </Popconfirm>
              );
            },
          },
        ]}
      />
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={() => createUser()}
        okButtonProps={{
          disabled: username === '' || password === '' || !role_id,
        }}
      >
        <div style={{ marginBottom: 10, marginTop: 20 }}>
          <Input
            placeholder="Логин"
            onChange={val => setUsername(val.target.value)}
          />
        </div>
        <div style={{ marginBottom: 10, marginTop: 10 }}>
          <Input
            placeholder="Пароль"
            type="password"
            onChange={val => setPassword(val.target.value)}
          />
        </div>
        <div style={{ marginBottom: 10, marginTop: 10, width: '100%' }}>
          <Select style={{width:'100%'}} placeholder="Роль" onChange={val=>setRoleId(val)}>
            <Select.Option value={1}>Админ</Select.Option>
            <Select.Option value={2}>Супер-Админ</Select.Option>
            <Select.Option value={3}>Менеджер</Select.Option>
            <Select.Option value={4}>Пользователь</Select.Option>
          </Select>
        </div>
      </Modal>
    </div>
  );
};

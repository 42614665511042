import React from 'react';
import {Input} from 'antd';
import {isNumberAndAboveZero} from '../../../../../utils/validation';

export default [
    {
        title: 'Точки отбора проб ',
        dataIndex: 'sampling_points',
        key: 'sampling_points',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Долгота',
        dataIndex: 'latitude',
        key: 'latitude',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
                validator: isNumberAndAboveZero
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Широта',
        dataIndex: 'longitude',
        key: 'longitude',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
                validator: isNumberAndAboveZero
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Наименование загрязняющих веществ',
        dataIndex: 'name_pollutants',
        key: 'name_pollutants',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Предельно допустимая концентрация (мг/кг)',
        dataIndex: 'maximum_allowable_concentration',
        key: 'buried_volume_of_waste',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
                validator: isNumberAndAboveZero
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Фактическая концентрация (мг/кг)',
        dataIndex: 'actual_concentration',
        key: 'actual_concentration',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
                validator: isNumberAndAboveZero
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Наличие превышения предельно допустимых концентраций, кратность',
        dataIndex: 'existence_exceeding_concentrations',
        key: 'existence_exceeding_concentrations',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Мероприятия по устранению нарушений и улучшению экологической обстановки (с указанием сроков)',
        dataIndex: 'measures_eliminate_violations_improve',
        key: 'measures_eliminate_violations_improve',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
            },
        ],
        component: <Input/>,
    },
];

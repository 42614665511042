import React from 'react';
import { Button, Card, Col, Icon, Modal as BaseModal, Form, Row } from 'antd';

const Modal = ({
  visibleFirst,
  closeFirstModal,
  formItemLayout,
  onSubmit,
  renderInputs,
  inputs,
}) => {
  return (
    <div style={{ marginBottom: 20 }}>
      <BaseModal
        visible={visibleFirst}
        onCancel={closeFirstModal}
        width="80vw"
        footer={false}
      >
        <Form {...formItemLayout} onSubmit={onSubmit}>
          <Row>
            <Col span={23} style={{ marginBottom: 20 }}>
              <Card>
                {renderInputs(inputs)}
                {/*{ methodologyVisible ? <BoilerForm getFieldDecorator={getFieldDecorator} /> : null}*/}
              </Card>
            </Col>
          </Row>
          <Button.Group style={{ display: 'block' }}>
            <Button type="danger" onClick={closeFirstModal}>
              <Icon type="stop" />
              Отмена
            </Button>
            <Button type="primary" htmlType="submit">
              <Icon type="check" />
              Добавить
            </Button>
          </Button.Group>
        </Form>
      </BaseModal>
    </div>
  );
};

export default Modal;

import { Icon, Menu } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import config from '../../config';

const Navigation = () => {
  const { isRvpz } = config;

  return (
    <>
      <div>
        <Menu theme="light" mode="inline">
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            <img
              width={40}
              // src={require('../../assets/img/koktem.png')}
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/ee/Emblem_of_Kazakhstan_latin.svg/1200px-Emblem_of_Kazakhstan_latin.svg.png"
              alt="Логотип Коктем2"
            />
          </div>
          {/* <Menu.Item key="1">
            <Link to="/">
              <Icon type="idcard" />
              <span>Карточка предприятия</span>
            </Link>
          </Menu.Item>*/}
          {/*
          <Menu.Item key="3">
            <Link to="/taxes">
              <Icon type="audit" />
              <span>Налоговые платежи</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="5">
            <Link to="/statistic">
              <Icon type="pie-chart" />
              <span>Статистическая отчетность</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="6">
            <Link to="/nature-management">
              <Icon type="global" />
              <span>Особые условия природопользования</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="7">
            <Link to="/events">
              <Icon type="safety" />
              <span>Природоохранные мероприятия</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="8">
            <Link to="/greenhouse-gases">
              <Icon type="dot-chart" />
              <span>Парниковые газы</span>
            </Link>
          </Menu.Item>
         
          <Menu.Item key="10">
            <Link to="/documents">
              <Icon type="copy" />
              <span>Разрешительная документация</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="11">
            <Link to="/form">
              <Icon type="form" />
              <span>Форма заявления</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="14">
            <Link to="/form-list">
              <Icon type="form" />
              <span>Cписок природопользователей</span>
            </Link>
          </Menu.Item>
          {user.username?.role_id === '2' && (
            <Menu.Item key="12">
              <Link to="/admin">
                <Icon type="team" />
                <span>Админ</span>
              </Link>
            </Menu.Item>
          )} */}

          {isRvpz && (
            <Menu.Item key="1">
              <Link to="/">
                <Icon type="form" />
                <span>ГРВПЗ</span>
              </Link>
            </Menu.Item>
          )}
          {isRvpz || (
            <Menu.Item key="1">
              <Link to="/">
                <Icon type="form" />
                <span>Определение категории</span>
              </Link>
            </Menu.Item>
          )}
          {isRvpz || (
            <Menu.Item key="2">
              <Link to="/pek">
                <Icon type="line-chart" />
                <span>Производственный экологический контроль</span>
              </Link>
            </Menu.Item>
          )}
          {isRvpz || (
            <Menu.Item key="15">
              <Link to="/profile">
                <Icon type="profile" />
                <span>Профиль</span>
              </Link>
            </Menu.Item>
          )}
          {/* <Menu.Item key="9">
            <Link to="/substance">
              <Icon type="experiment" />
              <span>Список веществ</span>
            </Link>
          </Menu.Item> */}
          <Menu.Item key="13">
            <Link
              onClick={() => {
                localStorage.clear();
                window.location.reload();
              }}
              to="/admin"
            >
              <Icon type="logout" />
              <span>Выйти</span>
            </Link>
          </Menu.Item>
        </Menu>
      </div>
    </>
  );
};

export default Navigation;

import axios from 'axios';
import { BaseAPI } from './BaseAPI';

class SuperTableAPI extends BaseAPI {
  getResources = async (token, { name, year, period, promoAreaId }) => {
    const url = `${this.BASE_URL}${this.SUPER_TABLE_PATH}${name}?year=${year}&period=${period}&companyId=${promoAreaId}`;
    console.log('getJoinResources', 'url: ', url);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  getGreenhouse = async (token, { year, period, promoAreaId }) => {
    const url = `${this.BASE_URL}${this.GREENHOUSE_TABLE_PATH}?year=${year}&period=${period}&companyId=${promoAreaId}`;
    console.log('getJoinResources', 'url: ', url);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  getSumResources = async (
    token,
    { year, period, code, sourceName, promoAreaId }
  ) => {
    const url = `${this.BASE_URL}${this.SUPER_TABLE_PATH}calculation_data_sum?year=${year}&period=${period}&code=${code}&sourceName=${sourceName}&companyId=${promoAreaId}`;
    console.log('getJoinResources', 'url: ', url);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  setSource = async ({
    token,
    name,
    year,
    period,
    promoAreaId,
    values,
    dataType,
  }) => {
    const url = `${this.BASE_URL}${this.SUPER_TABLE_PATH}insert/${name}`;
    const info = {
      ...values,
      year,
      period,
      company_card_id: promoAreaId,
      data_type: dataType,
    };
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      return await axios.post(url, info, config);
    } catch (error) {
      console.error(error);
      return error.response.data;
    }
  };

  setSourceGreenhouse = async ({
    token,
    name,
    year,
    period,
    promoAreaId,
    values,
    dataType,
  }) => {
    const url = `${this.BASE_URL}${this.GREENHOUSE_TABLE_PATH}`;
    const info = {
      ...values,
      year,
      period,
      company_card_id: promoAreaId,
    };
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      return await axios.post(url, info, config);
    } catch (error) {
      console.error(error);
      return error.response.data;
    }
  };

  setZV = async ({
    token,
    name,
    year,
    period,
    promoAreaId,
    values,
    dataType,
    itemId,
  }) => {
    const url = `${this.BASE_URL}${this.SUPER_TABLE_PATH}insert/${name}`;
    const info = {
      ...values,
      year,
      period,
      company_card_id: promoAreaId,
      data_type: dataType,
      source_id: itemId,
    };
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      return await axios.post(url, info, config).then(data => data);
    } catch (error) {
      console.error(error.response);
      return error.response;
    }
  };

  setGreenhouseData = async ({
    token,
    name,
    year,
    period,
    promoAreaId,
    values,
    dataType,
    itemId,
    columnName
  }) => {
    const url = `${this.BASE_URL}${this.GREENHOUSE_TABLE_PATH}/data/${columnName}`;
    const info = {
      ...values,
      year,
      period,
      company_card_id: promoAreaId,
      source_id: itemId,
    };
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      return await axios.post(url, info, config).then(data => data);
    } catch (error) {
      console.error(error.response);
      return error.response;
    }
  };

  remove = async (token, { tableName, itemId }) => {
    const url = `${this.BASE_URL}${this.SUPER_TABLE_PATH}delete/${tableName}?id=${itemId}`;
    try {
      const { data } = await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return data;
    } catch (error) {
      console.error('error: ', error);
      return error;
    }
  };

  update = async ({
    token,
    tableName,
    values,
    itemId,
    period,
    year,
    sourceId,
    promoAreaId,
    dataType,
  }) => {
    const url = `${this.BASE_URL}${this.SUPER_TABLE_PATH}update/${tableName}?id=${itemId}`;
    const info = {
      ...values,
      period,
      year,
      source_id: sourceId,
      company_card_id: promoAreaId,
      data_type: dataType,
    };
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const { data } = await axios.put(url, info, config);
      return data;
    } catch (error) {
      console.error('error: ', error);
      return error;
    }
  };

  sendMethodic = async ({
    method,
    year,
    quarter,
    promoAreaId,
    sourceId,
    dataType,
    token,
    values,
  }) => {
    return axios({
      method: 'POST',
      url: `${this.BASE_URL}${this.SUPER_TABLE_PATH}${method}`,
      data: {
        ...values,
        year,
        period: quarter,
        company_card_id: promoAreaId,
        source_id: sourceId,
        data_type: dataType,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  };

  sendCalculationrequest = async ({
    method,
    year,
    quarter,
    promoAreaId,
    sourceId,
    dataType,
    token,
    values,
    type,
  }) => {
    return axios({
      method: 'POST',
      url: `${this.BASE_URL}${this.EMISSION_PATH}${type}`,
      data: {
        ...values,
        year,
        period: quarter,
        company_card_id: promoAreaId,
        source_id: sourceId,
        data_type: dataType,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  };

  getDischargeConstants = async ({ token, period, year, promoAreaId }) => {
    return axios({
      method: 'GET',
      url: `${this.BASE_URL}api/discharge/constants?year=${year}&period=${period}&companyId=${promoAreaId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  setDischargeConstants = async ({
    token,
    year,
    period,
    promoAreaId,
    waterOutcomeCount,
    wasteWaterVolume,
  }) => {
    return axios({
      method: 'PATCH',
      url: `${this.BASE_URL}api/discharge/constants?year=${year}&period=${period}&companyId=${promoAreaId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        water_outcome_count: waterOutcomeCount,
        wastewater_volume: wasteWaterVolume,
      },
    });
  };
}

const {
  getResources,
  getSumResources,
  setSource,
  remove,
  update,
  setSourceGreenhouse,
  setZV,
  setGreenhouseData,
  sendMethodic,
  sendCalculationrequest,
  getDischargeConstants,
  getGreenhouse,
  setDischargeConstants,
} = new SuperTableAPI();

export {
  getResources,
  getSumResources,
  setSource,
  remove,
  setSourceGreenhouse,
  update,
  getGreenhouse,
  setGreenhouseData,
  setZV,
  sendMethodic,
  sendCalculationrequest,
  getDischargeConstants,
  setDischargeConstants,
};

import roundUp from '../../../../utils/roundUp';

export default rounding => [
  {
    title: 'Наименование',
    dataIndex: 'name',
    key: 'name',
    fixed: 'left',
    width: 150,
  },
  {
    title: 'Синонимы',
    dataIndex: 'synonym',
    key: 'synonym',
    fixed: 'left',
    width: 200,
  },
  {
    title: 'Концентрации веществ',
    dataIndex: 'concentration',
    key: 'concentration',
  },
  {
    title: 'Ед. изм.',
    dataIndex: 'unit',
    key: 'unit',
    render: value => roundUp(value, rounding),
  },
  {
    title: 'Показатель вредности',
    dataIndex: 'harm_indicator',
    key: 'harm_indicator',
    render: value => roundUp(value, rounding),
  },
  {
    title: 'Класс опасности',
    dataIndex: 'danger_class',
    key: 'danger_class',
    render: value => roundUp(value, rounding),
  },
];

import React from 'react';
import { Alert, Spin } from 'antd';

const WithLoader = () => {
  return (
    <div
      style={{
        position: ' fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255,255,255,0.6)',
        paddingTop: '48vh',
        paddingLeft: '48vw',
        zIndex: '5',
      }}
    >
      <Spin tip="Loading..." />
    </div>
  );
};
export default WithLoader;

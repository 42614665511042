import React, { useEffect, useState } from 'react';
import { Form, Icon, Input, Button, message, Tooltip } from 'antd';
import { connect } from 'react-redux';
import { Typography, Space } from 'antd';
import { Radio } from 'antd';
import { userSetAction } from '../../redux/actions/userActions';
import RegistrationStepForm from './RegistrationStep';
import { RegistrationApi } from '../../api/RegistrationApi';
import { checkUser, getUser } from '../../api/LoginAPI';
import WithLoader from '../../hoc/withLoader';

const RegistrationPageForm = () => {
  const [stepNames] = useState({
    FIRST_STEP: 'FIRST_STEP',
    SECOND_STEP: 'SECOND_STEP',
    // THIRD_STEP: 'THIRD_STEP',
  });
  const [loading, setLoading] = useState(false);
  const [steps, setStep] = useState({
    current_step: stepNames.FIRST_STEP,
    current_id: 0,
    data: {
      [stepNames.FIRST_STEP]: {
        type: stepNames.FIRST_STEP,
        code: 1,
        text: 'Шаг 1.Регистрационные данные.',
        fields: [
          {
            code: 'name',
            type: 'input',
            message: 'Введите наименование!',
            required: true,
            placeholder: 'Наименование',
            name: 'name',
            value: '',
          },
          {
            code: 'bin',
            type: 'input',
            message: 'Введите форму БИН!',
            required: true,
            placeholder: 'БИН',
            name: 'bin',
            value: '',
          },
          {
            code: 'oked',
            type: 'input',
            message: 'Введите форму ОКЭД!',
            required: true,
            placeholder: 'ОКЭД',
            name: 'oked',
            value: '',
          },
          {
            code: 'email',
            type: 'input',
            message: 'Введите адрес электронной почты!',
            required: true,
            placeholder: 'Адрес электронной почты',
            name: 'email',
            value: '',
            additional: {
              type: 'email',
              message: 'Введен неверный адрес электронной почты!',
            },
          },
          {
            code: 'fio_leader',
            type: 'input',
            message: 'Введите ФИО руководителя',
            required: true,
            placeholder: 'ФИО руководителя',
            name: 'fio_leader',
            value: '',
          },
          {
            code: 'username',
            type: 'input',
            message: 'Введите логин!',
            required: true,
            placeholder: 'Логин',
            name: 'username',
            value: '',
          },
          {
            code: 'password',
            type: 'input',
            message: 'Введите пароль!',
            required: true,
            input_type: 'password',
            placeholder: 'Пароль',
            name: 'password',
            value: '',
          },
          {
            code: 'replacePassword',
            type: 'input',
            message: 'Повторите пароль!',
            required: true,
            input_type: 'password',
            placeholder: 'Повторите пароль',
            name: 'replacePassword',
            value: '',
          },
        ],
      },
      [stepNames.SECOND_STEP]: {
        type: stepNames.SECOND_STEP,
        code: 2,
        text: 'Шаг 2.Адрес.',
        fields: [
          {
            code: 'city_kz',
            type: 'select',
            message: 'Выберите город!',
            required: true,
            placeholder: 'Город Казахстана',
            answers: [
              'Нур-Султан',
              'Алматы',
              'Шымкент',
              'Алматинская область',
              'Акмолинская область',
              'Атырауская область',
              'Актюбинская область',
              'Восточно-Казахстанская область',
              'Жамбылская область',
              'Западно-Казахстанская область',
              'Карагандинская область',
              'Костанайская область',
              'Кызылординская область',
              'Мангистауская область',
              'Павлодарская область',
              'Северо-Казахстанская область',
              'Туркестанская область',
            ],
            name: 'city_kz',
            value: '',
          },
          {
            code: 'postcode',
            type: 'input',
            message: 'Введите почтовый индекс!',
            required: true,
            placeholder: 'Почтовый индекс',
            name: 'postcode',
            value: '',
          },
          {
            code: 'street_name_ru',
            type: 'input',
            message: 'Введите название улицы!',
            required: true,
            placeholder: 'Название улицы',
            name: 'street_name_ru',
            value: '',
          },
          {
            code: 'home_num',
            type: 'input',
            message: 'Введите номер дома/здания!',
            required: true,
            placeholder: 'Номер дома/здания',
            name: 'home_num',
            value: '',
          },
          {
            code: 'kato',
            type: 'input',
            message: 'Введите КАТО!',
            required: true,
            placeholder: 'КАТО',
            name: 'kato',
            value: '',
            additional: {
              len: 9,
              message: 'КАТО должен состоять из 9 цифр',
            },
          },
          {
            code: 'phone_num',
            type: 'input',
            message: 'Введите номера телефона',
            required: true,
            input_type: 'number',
            placeholder: 'Номер телефона',
            name: 'phone_num',
            value: '',
          },
          {
            code: 'fax_num',
            type: 'input',
            message: 'Введите номер факса',
            required: true,
            input_type: 'number',
            placeholder: 'Номер факса',
            name: 'fax_num',
            value: '',
          },
          {
            code: 'phone_leader',
            type: 'input',
            message: 'Введите телефон руководителя',
            required: true,
            placeholder: 'Телефон руководителя',
            name: 'phone_leader',
            value: '',
          },
        ],
      },
      // [stepNames.THIRD_STEP]: {
      //     type: stepNames.THIRD_STEP,
      //     code: 3,
      //     text: 'Шаг 3.Создать заявление.',
      //     fields: [
      //         {
      //             code: 'department_id',
      //             type: 'select',
      //             message: 'Введите лизенциара!',
      //             placeholder: 'Выбрать лизенциара',
      //             required: true,
      //             answers: [],
      //             name: 'department_id',
      //             value: '',
      //         },
      //     ],
      // },
    },
  });

  const handleChangeStep = () => {
    const copy = { ...steps };
    let isError = false;
    console.log(steps.data);
    for (let i in steps.data[steps.current_step].fields) {
      if (
        steps.data[steps.current_step].fields[i].code === 'kato' &&
        (steps.data[steps.current_step].fields[i].value.length !== 9 ||
          !steps.data[steps.current_step].fields[i].value.match(/^\d+/))
      ) {
        isError = true;
        message.error('Неправильный КАТО');
      }
      if (steps.data[steps.current_step].fields[i].value === '') {
        console.log('first');
        isError = true;
        break;
      }
    }
    if (!isError) {
      if (copy.data.FIRST_STEP.fields[5].value.length < 6) {
        message.error(
          'Логин слишком короткий, минимальное количество символом 6'
        );
        return;
      }
      if (copy.data.FIRST_STEP.fields[6].value.length < 6) {
        message.error(
          'Пароль слишком короткий, минимальное количество символом 6'
        );
        return;
      }

      const pattern = new RegExp(/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/); //unacceptable chars
      if (pattern.test(copy.data.FIRST_STEP.fields[5].value)) {
        message.error(
          'Логин может содержать только буквы и цифры, повторите логин'
        );

        return false;
      }

      let valueLogin = copy.data.FIRST_STEP.fields[5].value.split('');
      let haveSpaceLogin = false;
      valueLogin.map(value => {
        if (value === ' ') {
          haveSpaceLogin = true;
        }
      });

      if (haveSpaceLogin) {
        message.error('В вашем логине есть пробел повторите логин ');
        return;
      }

      let valuePassword = copy.data.FIRST_STEP.fields[6].value.split('');
      let haveSpaceInPassword = false;
      valuePassword.map(value => {
        if (value === ' ') {
          haveSpaceInPassword = true;
        }
      });

      if (haveSpaceInPassword) {
        message.error('В вашем пароле есть пробел повторите пароль ');
        return;
      }

      if (
        copy.data.FIRST_STEP.fields[6].value !==
        copy.data.FIRST_STEP.fields[7].value
      ) {
        message.error('Пароли не совпадают');
        return;
      }
    }
    if (!isError) {
      console.log(copy.data.FIRST_STEP.fields[5].value);
      checkUser({ username: copy.data.FIRST_STEP.fields[5].value })
        .then(res => {
          console.log(res);
          message.error('Пользователь с таким логином уже существует');
          return;
        })
        .catch(err => {
          copy.current_id++;
          copy.current_step = Object.keys(stepNames)[copy.current_id];
          console.log(copy);
          setStep(copy);
        });
    }
  };

  const handleChange = (event, step, index) => {
    const copy = { ...steps };
    copy.data = { ...steps.data };
    console.log(copy.data[step]);
    copy.data[step].fields[index].value =
      typeof event.target.value === 'string'
        ? event.target.value.trim()
        : event.target.value;
    console.log(step);
    setStep(copy);
  };

  const handleSubmit = () => {
    const payload = { role_id: 1 };
    let isError = false;
    for (let i in steps.data) {
      for (let j in steps.data[i].fields) {
        if (steps.data[i].fields[j].value === '') {
          isError = true;
          break;
        } else {
          payload[steps.data[i].fields[j].name] = steps.data[i].fields[j].value;
        }
      }
    }
    message.loading('Подождите !');
    if (!isError) {
      setLoading(true);
      const response = new RegistrationApi()
        .register(payload)
        .then(() => {
          message.success('Успешно');
          setTimeout(() => window.location.replace('/login'), 1000);
        })
        .catch(() => {
          message.error('Ошибка');
          setLoading(false);
        });
    }
  };

  return (
    <div className="login-container">
      {loading && <WithLoader />}
      <RegistrationStepForm
        handleChange={handleChange}
        handleChangeStep={handleChangeStep}
        handleSubmit={handleSubmit}
        isLast={steps.current_step === stepNames.SECOND_STEP}
        data={steps.data[steps.current_step]}
      />
      <div
        style={{
          float: 'right',
          marginTop: -28,
        }}
      >
        <Tooltip title="Руководство">
          <div
            onClick={() => {
              window.open('http://185.146.3.215:4000/help.pdf');
            }}
            style={{
              borderRadius: 2000,
              border: '1px solid black',
              width: 30,
              height: 30,
              display: 'flex',
              float: 'right',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Icon type="question" />
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default RegistrationPageForm;

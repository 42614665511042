import { Input } from 'antd';
import React from 'react';
import isNumeric from 'validator/es/lib/isNumeric';

export default [
  {
    title: 'Код и наименование ЗВ',
    dataIndex: 'code',
    key: 'code',
    rules: [
      {
        required: true,
        message: 'Данное поле должно содержать 4 цифры',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Установленный норматив по ПДВ',
    children: [
      {
        title: 'г/с',
        dataIndex: 'setup_standard_g_sec',
        key: 'setup_standard_g_sec',
        component: <Input />,
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
          },
        ]
      },
      {
        title: 'тонн/год',
        dataIndex: 'setup_standard_t_year',
        key: 'setup_standard_t_year',
        component: <Input />,
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
          },
        ]
      }
    ]
  },
  {
    title: 'Фактический объем выбросов ЗВ за квартал/с начала года, тн.',
    key: 'gross_emission',
    children: [
      {
        title: 'тонн/квартал',
        dataIndex: 'fact_result_t_year',
        key: 'fact_result_t_year',
        component: <Input />,
      },
      {
        title: 'тонн/год',
        dataIndex: 'gross_emission_t_y',
        key: 'gross_emission_t_y',
        component: <Input />,
      },
    ],
  },
  // {
  //   title: 'Фактический объем выбросов ЗВ за квартал/с начала года, тн.',
  //   dataIndex: 'gross_emission',
  //   key: 'gross_emission',
  //   children: [
  //     {
  //       title: 'тонн/кв',
  //       dataIndex: 'fact_result_t_year',
  //       key: 'fact_result_t_year',
  //       component: <Input />,
  //       rules: [
  //         {
  //           required: true,
  //           message: 'Данные обязательны для заполнения',
  //           validator: isNumberAndAboveZero,
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    title: 'Эффективность очистки, %',
    dataIndex: 'cleaning_efficiency',
    key: 'cleaning_efficiency',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: async (rule, value, callback) => {
          try {
            if (
              isNumeric(value, { no_symbols: false }) &&
              +value >= 0 &&
              +value <= 100
            ) {
              rule.message = 'Данные обязательны для заполнения';
              return true;
            }

            if (+value < 0 || +value > 100) {
              rule.message = 'Значение должно быть от 0 до 100 включительно!';
            }

            callback(rule.message);
          } catch (err) {
            callback(err);
          }
        },
      },
    ],
    component: <Input />,
  },
  {
    title: 'Объем выбросов в атмосферный воздух без очистки, тн',
    dataIndex: 'fact_result_t_year',
    key: 'fact_result_t_year',
    component: <Input disabled />,
    displayNone: true,
  },
  {
    title: 'Объем уловленных ЗВ, тн',
    dataIndex: 'captured_pollutants',
    key: 'captured_pollutants',
    component: <Input disabled />,
    displayNone: true,
  },
  {
    title: 'Сверхнормативные выбросы,  тн.',
    dataIndex: 'excess',
    key: 'excess',
    component: <Input disabled />,
    displayNone: true,
  },
  {
    title: 'Увеличение или снижение выбросов ЗВ в сравнении  разрешенными,   %',
    dataIndex: 'increase_or_decrease',
    key: 'increase_or_decrease',
    component: <Input disabled />,
    displayNone: true,
  },
  {
    title: 'Причины снижения/увеличения',
    dataIndex: 'reasons',
    key: 'reasons',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'грамм в секунду',
    dataIndex: 'setup_standard_g_sec',
    key: 'setup_standard_g_sec',
    component: <Input />,
    displayNone: true,
  },
  {
    title: 'грамм в секунду',
    dataIndex: 'fact_result_g_sec',
    key: 'fact_result_g_sec',
    component: <Input />,
    displayNone: true,
  },
  {
    title: 'грамм в секунду',
    dataIndex: 'excess_g_sec',
    key: 'excess_g_sec',
    component: <Input />,
    displayNone: true,
  },
  {
    title: 'тонн в год',
    dataIndex: 'excess_t_year',
    key: 'excess_t_year',
    component: <Input />,
    displayNone: true,
  },
  {
    title: 'Мероприятия по устранению нарушения',
    dataIndex: 'events',
    key: 'events',
    component: <Input />,
    displayNone: true,
  },
];

import moment from 'moment';
import roundUp from '../../../../utils/roundUp';

export default rounding => [
  {
    title: 'Код строки',
    dataIndex: 'code_string',
    key: 'code_string',
    fixed: 'left',
    width: 120,
    height: 40,
  },
  {
    title: ' Наименование групп загрязняющих веществ',
    dataIndex: 'full_code_name',
    key: 'full_code_name',
    fixed: 'left',
    width: 300,
  },
  {
    title: 'Код ЗВ',
    dataIndex: 'codeTwoTp',
    key: 'codeTwoTp',
  },
  {
    title: 'Объем выброшенных без очистки',
    key: 'limit',
    children: [
      {
        title: 'всего',
        dataIndex: 'emissionsAll',
        key: 'emissionsAll',
        render: value => roundUp(value, rounding),
      },
      {
        title: 'из них от организованных источников загрязнения',
        dataIndex: 'emissionsOrganized',
        key: 'emissionsOrganized',
        render: value => roundUp(value, rounding),
      },
    ],
  },
  {
    title: 'Объем поступивших на очистные сооружения загрязняющих веществ',
    dataIndex: 'volumeOfCapturedEmissions',
    key: 'volumeOfCapturedEmissions',
    render: value => roundUp(value, rounding),
  },
  {
    title: 'из них уловленных и обезвреженных',
    key: 'balance',
    children: [
      {
        title: 'всего',
        dataIndex: 'capturedPollutants',
        key: 'capturedPollutants',
        render: value => roundUp(value, rounding),
      },
      {
        title: 'из них утилизированных',
        dataIndex: 'received_disposed',
        key: 'received_disposed',
        render: value => roundUp(value, rounding),
      },
    ],
  },
  {
    title: 'Объем выбросов загрязняющих веществ в атмосферу за отчетный период',
    dataIndex: 'emissionVolumeForPeriod',
    key: 'emissionVolumeForPeriod',
    render: value => roundUp(value, rounding),
  },
  {
    title:
      'Установленный предельно-допустимый выброс специфических веществ на отчетный период',
    dataIndex: 'setupStandart',
    key: 'setupStandart',
    render: value => roundUp(value, rounding),
  },
  {
    title: 'Создан',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (text, record) =>
      record.code_string === 'A' ? text : moment(text).format('YYYY-MM-DD'),
  },
];

import { BaseAPI } from './BaseAPI';
import axios from 'axios';

class LoginAPI extends BaseAPI {
  login = async ({ values }) =>
    axios({
      method: 'POST',
      url: `${this.BASE_URL}api/user/login`,
      data: values,
      headers: {
        'Content-Type': 'application/json',
      },
    });

  updateUser = async ({ values, id, token }) =>
    axios({
      method: 'PUT',
      url: `${this.BASE_URL}api/user`,
      data: values,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

  updateUserPassword = async ({ values, iv, content }) =>
    axios({
      method: 'PUT',
      url: `${this.BASE_URL}api/recovery/reset-password/${iv}/${content}`,
      data: values,
      headers: {
        'Content-Type': 'application/json',
      },
    });

  getUser = async ({ token }) =>
    axios({
      method: 'GET',
      url: `${this.BASE_URL}api/user/index`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

  checkUser = async ({ username }) =>
    axios({
      method: 'GET',
      url: `${this.BASE_URL}api/user/exist/${encodeURIComponent(username)}`,
      headers: {
        'Content-Type': 'application/json',
      },
    });

  defineCategory = async ({ username, token }) =>
    axios({
      method: 'POST',
      url: `${this.BASE_URL}api/user/category/${encodeURIComponent(username)}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
}

const {
  login,
  updateUser,
  getUser,
  checkUser,
  defineCategory,
  updateUserPassword,
} = new LoginAPI();

export {
  login,
  updateUser,
  getUser,
  checkUser,
  defineCategory,
  updateUserPassword,
};

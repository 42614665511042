import React from 'react';
import { Form, Input, InputNumber, Select } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';

import {
  pekYearChange,
  pekQuarterChange,
} from '../../../redux/actions/pekDateActions';

const { Option } = Select;

const Configurator = ({
  year,
  quarter,
  pekYearChange,
  pekQuarterChange,
  onlyYear = false,
}) => {
  return (
    <div style={{ display: 'flex' }}>
      <Input.Group compact style={{ width: 200, marginRight: 10 }}>
        <Input disabled value="Год" style={{ width: '30%' }} />
        <InputNumber
          min={2000}
          max={+moment().format('YYYY')}
          defaultValue={year}
          style={{ width: '70%' }}
          onChange={value => pekYearChange(value)}
        />
      </Input.Group>
      {onlyYear || (
        <Input.Group compact style={{ width: 210 }}>
          <Input disabled value="Квартал" style={{ width: '40%' }} />
          <Select
            placeholder="Квартал"
            style={{ width: '60%' }}
            onSelect={pekQuarterChange}
            defaultValue={quarter}
          >
            <Option value={1}>1</Option>
            <Option value={2}>2</Option>
            <Option value={3}>3</Option>
            <Option value={4}>4</Option>
          </Select>
        </Input.Group>
      )}
    </div>
  );
};

const mapStateToProps = ({ pekDateStore: { year, quarter } }) => ({
  year,
  quarter,
});

const mapDispatchToProps = {
  pekYearChange,
  pekQuarterChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(Configurator);

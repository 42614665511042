import React from 'react';
import { Button, Card, Col, Form, Modal, Row, Select } from 'antd';
const { Option } = Select;

const CopyForm = ({
  visible,
  setVisible,
  onSubmit,
  getFieldDecorator,
  handleCancel,
}) => {
  return (
    <div>
      <Button
        type="primary"
        onClick={() => setVisible(true)}
        style={{ marginLeft: 15 }}
      >
        Создать копию
      </Button>
      <Modal visible={visible} onCancel={handleCancel} footer={false}>
        <Row>
          <Col span={23}>
            <Card>
              <Form style={{ width: '100%' }} onSubmit={onSubmit}>
                <Form.Item>
                  {getFieldDecorator('fromPeriod', {
                    rules: [
                      {
                        required: true,
                        message: 'Please input your username!',
                      },
                    ],
                  })(
                    <Select
                      placeholder="Действующий квартал:"
                      style={{ width: '100%' }}
                    >
                      <Option value={1}>1</Option>
                      <Option value={2}>2</Option>
                      <Option value={3}>3</Option>
                      <Option value={4}>4</Option>
                    </Select>
                  )}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator('toPeriod', {
                    rules: [
                      {
                        required: true,
                        message: 'Please input your Password!',
                      },
                    ],
                  })(
                    <Select placeholder="Куда" style={{ width: '100%' }}>
                      <Option value={1}>1</Option>
                      <Option value={2}>2</Option>
                      <Option value={3}>3</Option>
                      <Option value={4}>4</Option>
                    </Select>
                  )}
                </Form.Item>
                <Button.Group>
                  <Button htmlType="submit" type="primary">
                    Копировать
                  </Button>
                  <Button onClick={handleCancel} type="danger">
                    Отмена
                  </Button>
                </Button.Group>
              </Form>
            </Card>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default CopyForm;

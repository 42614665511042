import { message, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Configurator from '../../modules/DateConfigurator/components/Configurator';

import Info from './Info';
import PekHistory from './PekHistory';
import PekWaterGeneralInformation from './PekWater/PekWaterGeneralInformation';
import PekWaterHistory from './PekWater/PekWaterHistory';
import PromAreas from './PromAreas';

const { TabPane } = Tabs;

const PEK = () => {
  const [activeTab, setActiveTab] = useState('1');

  const isPekWater = activeTab === '4';

  return (
    <div>
      <Configurator onlyYear={isPekWater} />

      <Tabs activeKey={activeTab} onChange={setActiveTab}>
        <TabPane tab="Общие сведения" key="1">
          <Info />
        </TabPane>
        <TabPane tab="Производственные объекты площадки" key="2">
          <PromAreas />
        </TabPane>
        <TabPane tab="История отчета" key="3">
          <PekHistory />
        </TabPane>
        <TabPane tab="ПЭК море" key="4">
          <Tabs>
            <TabPane
              tab="Сведения по производственному мониторингу на море"
              key="1"
            >
              <PekWaterGeneralInformation />
            </TabPane>
            <TabPane tab="История отчета ПЭК море" key="2">
              <PekWaterHistory />
            </TabPane>
          </Tabs>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default PEK;

import React from 'react';
import { Input } from 'antd';
import { isNumberAndAboveZero } from '../../../../utils/validation';

export default [
  {
    title: 'Код ЗВ',
    dataIndex: 'code',
    key: 'code',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Наименование вещества',
    dataIndex: 'name',
    key: 'name',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Класс опасности',
    dataIndex: 'danger_class',
    key: 'danger_class',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'ПДКм,р,',
    dataIndex: 'pdkm_r',
    key: 'pdkm_r',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'ПДКс,с ,',
    dataIndex: 'pdkc_c',
    key: 'pdkc_c',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'ОБУВ',
    dataIndex: 'obuv',
    key: 'obuv',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Агрегатное состояние',
    dataIndex: 'aggregation_state',
    key: 'aggregation_state',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Код по 2-ТП',
    dataIndex: 'code_2tp',
    key: 'code_2tp',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
];

import { Form, message } from 'antd';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { setSource as setSourceAtmosphere } from '../../../api/AtmosphereAPI';
import { setSource as setSourceDischarge } from '../../../api/DischargeAPI';
import { setSource as setSourceEmission, setSourceGreenhouse } from '../../../api/SuperTableAPI';
import compose from '../../../hoc/compose';
import ModalContainer from '../containers/Modal';


const dataSender = async ({
  tableType,
  token,
  values,
  year,
  quarter,
  promoAreaId,
  dataType,
}) => {
  switch (tableType) {
    case 'atmosphere':
      return await setSourceAtmosphere({
        token,
        values,
        period: quarter,
        year,
        promoAreaId,
      });
    case 'discharge':
      return await setSourceDischarge({
        token,
        name: 'discharge_sources',
        values,
        year,
        period: quarter,
        promoAreaId,
      });
    case 'emissions':
      return await setSourceEmission({
        token,
        name: 'calculation_source',
        values,
        year,
        period: quarter,
        promoAreaId,
        dataType,
      });
    case 'greenhouse':
      return await setSourceGreenhouse({
        token,
        name: 'greenhouse_gas_source',
        values,
        year,
        period: quarter,
        promoAreaId,
        dataType,
      });
    default:
      return;
  }
};

const Modal = ({
  tableType,
  token,
  promoAreaId,
  year,
  quarter,
  inputs,
  dataType,
  form,
  formItemLayout,
  modalClose,
  modalOpen,
  visible,
  refresh,
}) => {
  const { getFieldDecorator, validateFields } = form;
  const [visibleFirst, visibleSecond] = visible;
  const [closeFirstModal, closeSecondModal] = modalClose;
  const [openSecondModal] = modalOpen;
  const [sourceId, setSourceId] = useState(undefined);
  const [methodologyVisible, setMethodologyVisible] = useState(false);

  console.log('PROMO AREA ID', promoAreaId);

  const renderChildrenInputs = (inputs, firstTitle = '') => {
    const items = inputs.map(
      ({ title, dataIndex, children, component, rules = [] }) => {
        if (children) return renderChildrenInputs(children, title);

        let text = title
          .toLowerCase()
          .trim()
          .replace(/\W{1}/, str => str.toUpperCase());

        return (
          <Form.Item key={dataIndex} label={text}>
            {getFieldDecorator(dataIndex, {
              rules,
            })(component)}
          </Form.Item>
        );
      }
    );

    return (
      <div>
        {firstTitle && <p key={firstTitle}>{firstTitle}</p>}
        {items}
      </div>
    );
  };

  const renderInputs = (inputs, firstTitle = '') => {
    return inputs.map(
      ({ title, dataIndex, children, component, rules = [] }) => {
        if (children) return renderChildrenInputs(children, title);

        let text = title
          .toLowerCase()
          .trim()
          .replace(/\W{1}/, str => str.toUpperCase());

        return (
          <div key={dataIndex}>
            <Form.Item label={text}>
              {getFieldDecorator(dataIndex, {
                rules,
              })(component)}
            </Form.Item>
          </div>
        );
      }
    );
  };

  const onSubmit = evt => {
    evt.preventDefault();
    validateFields(async (err, values) => {
      if (err) {
        console.error(err);
        message.error('Форма заполнена не правильно!');
      }
      if (!err && token) {
        // if (values.code) values.code = `{${values.code.join()}}`;
        try {
          const res = await dataSender({
            tableType,
            token,
            promoAreaId,
            values,
            year,
            quarter,
            dataType,
          });

          setSourceId(res.data.rows[0].id);

          await refresh();
          await closeFirstModal();
          message.success('Данные отправлены успешно!');
        } catch (error) {
          message.error('Что-то пошло не так!');
          console.error(error);
        }
      }
    });
  };

  const props = {
    closeFirstModal,
    closeSecondModal,
    openSecondModal,
    formItemLayout,
    onSubmit,
    renderInputs,
    inputs,
    visibleFirst,
    visibleSecond,
    methodologyVisible,
    setMethodologyVisible,
    sourceId,
    getFieldDecorator,
  };

  return <ModalContainer {...props} />;
};

const mapStateToProps = ({
  userStore: { token },
  companyStore: { companyId, promoAreaId },
  pekDateStore: { year, quarter },
}) => ({ token, companyId, year, quarter, promoAreaId });

export default compose(connect(mapStateToProps), Form.create())(Modal);

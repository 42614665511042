import React from 'react';
import { Button } from 'antd';

import withInteractivity from '../../../hoc/withInteractivity';
import WaterConsumption from './Tables/WaterConsumption';
import WaterDisposal from './Tables/WaterDisposal';

const WaterManagement = ({
  state: { waterConsumption, waterDisposal },
  showTableHandler,
}) => {
  return (
    <div>
      <Button.Group style={{ marginBottom: 15 }}>
        <Button
          type="primary"
          onClick={() => showTableHandler('waterConsumption')}
        >
          Водопотребление
        </Button>
        <Button
          type="primary"
          onClick={() => showTableHandler('waterDisposal')}
        >
          Водоотведение
        </Button>
      </Button.Group>
      {waterConsumption && <WaterConsumption />}
      {waterDisposal && <WaterDisposal />}
    </div>
  );
};

export default withInteractivity({
  waterConsumption: false,
  waterDisposal: false,
})(WaterManagement);

import React from 'react';
import { Input } from 'antd';
import { isNumberAndAboveZero } from '../../../../utils/validation';

export default [
  {
    title: 'Код и наименование ЗВ',
    dataIndex: 'code',
    key: 'code',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Установленный норматив (грамм в секунду/ тонна в год)',
    key: 'Установленный норматив (грамм в секунду/ тонна в год)',
    children: [
      {
        title: 'грамм в секунду',
        dataIndex: 'setup_standard_g_sec',
        key: 'setup_standard_g_sec',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
      {
        title: 'тонн в год',
        dataIndex: 'setup_standard_t_year',
        key: 'setup_standard_t_year',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
    ],
  },
  {
    title: 'Фактический результат мониторинга (грамм в секунду/  тонн в год)',
    key: 'Фактический результат мониторинга',
    children: [
      {
        title: 'грамм в секунду',
        dataIndex: 'fact_result_g_sec',
        key: 'fact_result_g_sec',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
      {
        title: 'тонн в год',
        dataIndex: 'fact_result_t_year',
        key: 'fact_result_t_year',
        component: <Input />,
        displayNone: true,
      },
    ],
  },
  {
    title: 'Превышение нормативов (ПДВ) предельно допустимых выбросов',
    key: 'excess',
    children: [
      {
        title: 'грамм в секунду',
        dataIndex: 'excess_g_sec',
        key: 'excess_g_sec',
        component: <Input />,
        displayNone: true,
      },
      {
        title: 'тонн в год',
        dataIndex: 'excess_t_year',
        key: 'excess_t_year',
        component: <Input />,
        displayNone: true,
      },
    ],
  },
  {
    title: 'Мероприятия по устранению нарушения',
    dataIndex: 'events',
    key: 'events',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'тонн/год',
    dataIndex: 'gross_emission_t_y',
    key: 'gross_emission_t_y',
    component: <Input disabled />,
    displayNone: true,
  },
  {
    title: 'Эффективность очистки, %',
    dataIndex: 'cleaning_efficiency',
    key: 'cleaning_efficiency',
    component: <Input />,
    displayNone: true,
  },
  {
    title: 'Объем уловленных ЗВ, тн',
    dataIndex: 'captured_pollutants',
    key: 'captured_pollutants',
    component: <Input disabled />,
    displayNone: true,
  },
  {
    title: 'Сверхнормативные выбросы,  тн.',
    dataIndex: 'excess',
    key: 'excess',
    component: <Input disabled />,
    displayNone: true,
  },
  {
    title: 'Увеличение или снижение выбросов ЗВ в сравнении  разрешенными,   %',
    dataIndex: 'increase_or_decrease',
    key: 'increase_or_decrease',
    component: <Input disabled />,
    displayNone: true,
  },
  {
    title: 'Причины снижения/увеличения',
    dataIndex: 'reasons',
    key: 'reasons',
    component: <Input />,
    displayNone: true,
  },
];

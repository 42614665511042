import React, { useState } from 'react';
import columns from './columns';

// import columns from "./columns";
import Editor from '../../../modules/Editor';
import inputs from '../InformationOfLimits/modalInputs';
import StandardTable from '../../../modules/StandardTable';
import { connect, useSelector } from 'react-redux';
import { uploadFiles } from '../../../api/ApplicationForm';
import { Table, Button, Modal, Upload } from 'antd';
import config from '../../../config';

const tableName = 'information_on_the_limits';

const InformationOfLimits = ({ token }) => {
  const [data, setData] = useState([]);
  const [rounding, setRounding] = useState(false);
  const { orderProductionId } = useSelector(state => ({
    ...state.userStore,
  }));
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [state, setState] = useState({
    fileList: [],
    uploading: false,
  });

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    uploadFiles({
      token,
      tableName,
      tableType: 'waste',
      formId: orderProductionId,
      file: state.fileList[0],
    }).then(() => window.location.reload());
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns = rounding => refresh => [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Перечень отходов* ',
      dataIndex: 'list_of_waste',
      key: 'list_of_waste',
    },
    {
      title: 'Код',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Лимиты накопление отходов (тонн/год)**',
      dataIndex: 'waste_accumulation_limits',
      key: 'waste_accumulation_limits',
    },
    {
      title: 'Лимиты захоронения отходов (тонн/год)**',
      dataIndex: 'waste_disposal_limits',
      key: 'waste_disposal_limits',
    },
    {
      title: 'Примечание',
      dataIndex: 'note',
      key: 'note',
    },
    {
      title: 'Действия',
      key: 'actions',
      width: 120,
      render: (text, record) => (
        <Editor
          addInputs={inputs}
          editInputs={inputs}
          text={text}
          tableName={tableName}
          editTableType={'order_production_tables'}
          record={record}
          refresh={refresh}
        />
      ),
    },
  ];

  const transformDataSource = (data, setState) => {
    const newDataSource = [];
    data.forEach(el =>
      newDataSource.push({
        key: el.id,
        ...el,
      })
    );
    setState(newDataSource);
  };

  const { fileList, uploading } = state;

  const props = {
    onRemove: file => {
      setState(state => {
        const index = state.fileList.indexOf(file);
        const newFileList = state.fileList.slice();
        newFileList.splice(index, 1);
        return {
          fileList: newFileList,
        };
      });
    },
    beforeUpload: file => {
      setState(state => ({
        fileList: [...state.fileList, file],
      }));
      return false;
    },
    fileList,
  };

  return (
    <div>
      <StandardTable
        inputs={inputs}
        tableName={tableName}
        tableType={'order_production_tables'}
        columns={rounding => columns(rounding)}
        transformDataSource={transformDataSource}
        dataSource={data}
      />
     <Button.Group>
        <Button type="primary" onClick={showModal}>
          Загрузить через шаблон
        </Button>
        <Button
          type="primary"
          onClick={() => {
            window.open(`${config.api_url}отходы.xlsx`);
          }}
        >
          Скачать пример шаблона
        </Button>
      </Button.Group>
      <Modal
        title="Файл"
        visible={isModalVisible}
        okText="Отправить"
        onOk={handleOk}
        cancelText="Закрыть"
        onCancel={handleCancel}
      >
        <Upload {...props}>
          <Button>Выбрать файл</Button>
        </Upload>
      </Modal>
    </div>
  );
};

const mapStateToProps = ({ userStore: { token } }) => ({
  token,
});

export default connect(mapStateToProps)(InformationOfLimits);

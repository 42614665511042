import { getCompany, getAllCompanies } from '../../api/CompanyAPI';

export const selectCompany = companyId => ({
  type: 'COMPANY_SELECTED',
  payload: companyId,
});

const companyLoaded = company => ({
  type: 'COMPANY_LOADED',
  payload: company,
});

export const resetCompany = () => ({
  type: 'RESET_COMPANY',
});

const companiesLoaded = company => ({
  type: 'COMPANIES_LOADED',
  payload: company,
});

export const selectPromoArea = promoAreaId => ({
  type: 'PROMO_AREA_SELECTED',
  payload: promoAreaId,
});

export const fetchCompany = ({ companyId, token }) => dispatch => {
  getCompany({ companyId, token }).then(({ data: company }) =>
    dispatch(companyLoaded(company))
  );
};

export const fetchAllCompanies = ({ token }) => dispatch => {
  getAllCompanies({ token }).then(({ data: companies }) =>
    dispatch(companiesLoaded(companies))
  );
};

import React, { Component, useEffect, useMemo } from 'react';
import { Form, Input, message,Text } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { Select } from 'antd';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { updateUser } from '../../api/LoginAPI';


const { Option } = Select;

export const Profile = () => {
  const { user, token } = useSelector(state => ({
    ...state.userStore,
  }));

  console.log('USER', user.username);

  const { control, handleSubmit, reset, errors } = useForm({
    defaultValues: useMemo(() => {
      const {
        name,
        bin,
        oked,
        email,
        username,
        fio_leader,
        postcode,
        city_kz,
        street_name_ru,
        home_num,
        kato,
        phone_num,
        fax_num,
        phone_leader,
      } = user.username;
      return {
        name,
        bin,
        oked,
        email,
        username,
        fio_leader,
        postcode,
        city_kz,
        street_name_ru,
        home_num,
        kato,
        phone_num,
        fax_num,
        phone_leader,
      };
    }, [user.username]),
  });

  useEffect(() => {
    reset(user.username);
  }, [user.username, reset]);

  const onSubmit = async data => {
    console.log("DA~RA",data);
    await updateUser({ values: data, id: user.username.id, token })
      .then(res => {
        console.log(res);
        window.location.reload();
      })
      .catch(err => {
        console.log(err);
        message.error('Ошибка');
      });
  };
  console.log(errors);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h3>Регистрационные данные</h3>
        <div
          style={{
            display: 'flex',
          }}
        >
          <div
            style={{
              width: 500,
            }}
          >
            <FormItem label="Наименование">
              <Controller
                as={<Input placeholder="Наименование" />}
                name="name"
                control={control}
                rules={{ 
                  required: true
                }}
              />
            </FormItem>
            <FormItem label="БИН">
              <Controller
                as={<Input placeholder="БИН" />}
                name="bin"
                control={control}
                rules={{ 
                  required: true,
                  maxLength:12,
                }}
              />
              {errors.bin && <div style={{color:'red', position:'absolute',marginTop:'-10px'}}>Поле должно состоять из 12 цифр</div>}
            </FormItem>
            <FormItem label="ОКЭД">
              <Controller
                as={<Input placeholder="ОКЭД" />}
                name="oked"
                control={control}
                rules={{ 
                  required: true
                }}
              />
            </FormItem>
            <FormItem label="Адрес электронной почты">
              <Controller
                as={
                  <Input
                    disabled={true}
                    placeholder="Адрес электронной почты"
                  />
                }
                name="email"
                control={control}
                rules={{ 
                  required: true
                }}
              />
            </FormItem>
            <FormItem label="Имя пользователя">
              <Controller
                as={<Input disabled={true} placeholder="Имя пользователя" />}
                name="username"
                control={control}
                rules={{ 
                  required: true
                }}
              />
            </FormItem>
            <FormItem label="ФИО руководителя">
              <Controller
                as={<Input placeholder="ФИО руководителя" />}
                name="fio_leader"
                control={control}
                rules={{ 
                  required: true
                }}
              />
            </FormItem>
            <FormItem label="Почтовый индекс">
              <Controller
                as={<Input placeholder="Почтовый индекс" />}
                name="postcode"
                control={control}
                rules={{ 
                  required: true
                }}
              />
            </FormItem>
          </div>

          <div style={{ marginLeft: 100 }}>
            <FormItem label="Город">
              <Controller
                as={
                  <Select placeholder="" style={{ width: 500 }}>
                    <Option value="Нур-Султан">Нур-Султан</Option>
                    <Option value="Алматы">Алматы</Option>
                    <Option value="Шымкент">Шымкент</Option>
                    <Option value="Алматинская область">
                      Алматинская область
                    </Option>
                    <Option value="Акмолинская область">
                      Акмолинская область
                    </Option>
                    <Option value="Атырауская областьн">
                      Атырауская область
                    </Option>
                    <Option value="Актюбинская область">
                      Актюбинская область
                    </Option>
                    <Option value="Восточно-Казахстанская область">
                      Восточно-Казахстанская область
                    </Option>
                    <Option value="Жамбылская область">
                      Жамбылская область
                    </Option>
                    <Option value="Западно-Казахстанская область">
                      Западно-Казахстанская область
                    </Option>
                    <Option value="Карагандинская область">
                      Карагандинская область
                    </Option>
                    <Option value="Костанайская область">
                      Костанайская область
                    </Option>
                    <Option value="Кызылординская область">
                      Кызылординская область
                    </Option>
                    <Option value="Мангистауская область">
                      Мангистауская область
                    </Option>
                    <Option value="Павлодарская область">
                      Павлодарская область
                    </Option>
                    <Option value="Северо-Казахстанская область">
                      Северо-Казахстанская область
                    </Option>
                    <Option value="Туркестанская область">
                      Туркестанская область
                    </Option>
                  </Select>
                }
                name="city_kz"
                control={control}
                rules={{ 
                  required: true
                }}
              />
            </FormItem>

            <FormItem label="Название улицы">
              <Controller
                as={<Input placeholder="Название улицы" />}
                name="street_name_ru"
                control={control}
                rules={{ 
                  required: true
                }}
              />
            </FormItem>
            <FormItem label="Номер дома/здания">
              <Controller
                as={<Input placeholder="Номер дома/здания" />}
                name="home_num"
                control={control}
                rules={{ 
                  required: true
                }}
              />
            </FormItem>
            <FormItem label="КАТО">
              <Controller 
                as={<Input  placeholder="КАТО"  />}
                name="kato"
                control={control}
                rules={{ 
                  required: true,
                  maxLength:9,
                }}
              />
              {errors.kato && <div style={{color:'red', position:'absolute',marginTop:'-10px'}}>КАТО должен состоять из 9 цифр</div>}
            </FormItem>
            <FormItem label="Номер телефона">
              <Controller
                as={<Input placeholder="Номер телефона" />}
                name="phone_num"
                control={control}
                rules={{ 
                  required: true
                }}
              />
            </FormItem>
            <FormItem label="Номер факса">
              <Controller
                as={<Input placeholder="Номер факса" />}
                name="fax_num"
                control={control}
                rules={{ 
                  required: true
                }}
              />
            </FormItem>
            <FormItem label="Телефон руководителя">
              <Controller
                as={<Input placeholder="Телефон руководителя" />}
                name="phone_leader"
                control={control}
                rules={{ 
                  required: true
                }}
              />
            </FormItem>
            <button
              type="submit"
              disabled={!!Object.keys(errors).length}
              class="ant-btn login-form-button ant-btn-primary"
            >
              <span>Сохранить</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

const initialState = {
  companyId: null,
  promoAreaId: null,
  companies: [],
  company: {},
};

const companyReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'COMPANY_SELECTED': {
      return {
        ...state,
        companyId: payload,
      };
    }
    case 'COMPANY_LOADED': {
      return {
        ...state,
        company: payload,
      };
    }
    case 'RESET_COMPANY': {
      return {
        ...state,
        company: {},
      };
    }
    case 'COMPANIES_LOADED': {
      return {
        ...state,
        companies: payload,
      };
    }
    case 'PROMO_AREA_SELECTED': {
      return {
        ...state,
        promoAreaId: payload,
      };
    }
    default: {
      return state;
    }
  }
};
export default companyReducer;

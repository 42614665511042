import React, { useRef } from 'react';
import { Button, Tabs, InputNumber, Form } from 'antd';
import { connect } from 'react-redux';
import Emissions from './Emissions';
import { changeMrp } from '../../redux/actions/taxActions';
import Water from './Water/Water'
import compose from '../../hoc/compose';

const { TabPane } = Tabs;

const Taxes = ({ form, mrp, changeMrp }) => {
  const onSubmit = evt => {
    evt.preventDefault();
    const { mrp } = form.getFieldsValue();
    changeMrp(mrp);
  };

  return (
    <div>
      <Form onSubmit={onSubmit}>
        <Form.Item label="МРП на текущий год">
          {form.getFieldDecorator('mrp', {
            initialValue: mrp,
          })(<InputNumber style={{ marginRight: 15 }} />)}
          <Button type="primary" htmlType="submit">
            Рассчитать
          </Button>
        </Form.Item>
      </Form>
      <Tabs>
        <TabPane tab="Эмиссии в ОС" key="1">
          <Emissions />
        </TabPane>
        <TabPane tab="Водные ресурсы" key="2">
          Водные ресурсы
        </TabPane>
        <TabPane tab="НДПИ" key="3">
          НДПИ
        </TabPane>
      </Tabs>
      {/*<Button type="primary">Вывод докуметации</Button>*/}
    </div>
  );
};

const mapStateToProps = ({ taxStore: { mrp } }) => ({
  mrp,
});

const mapDispatchToProps = {
  changeMrp,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  Form.create()
)(Taxes);

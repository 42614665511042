const initialState = {
  loggedIn: false,
  token: null,
  user: {},
  orderProductionId: null,
};
const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'USER': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'USER_RESET': {
      return { ...initialState };
    }
    case 'SET_ORDER_PRODUCTION': {
      return { ...state, orderProductionId: action.payload.orderProductionId };
    }
    default: {
      return state;
    }
  }
};
export default userReducer;

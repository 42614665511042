import React from 'react';
import PresenceWasteTable from './Tables/presenceWaste';

const PresenceWaste = () => {
  return (
    <div>
      <PresenceWasteTable />
    </div>
  );
};

export default PresenceWaste;

import React, { useState } from 'react';
import StandardTable from "../../../modules/StandardTable";
import inputs from "./modalInputs"
import Editor from "../../../modules/Editor";


const tableName = 'normative_information';


const SignificantObjectsTable = () => {
    const [data] = useState([]);
    const [rounding, setRounding] = useState(false);

    const transformDataSource = (data, setState) => {
        const newDataSource = [];
        data.forEach(el =>
            newDataSource.push({
                key: el.id,
                ...el,
            })
        );
        setState(newDataSource);
    };

    const columns = rounding => (token, refresh) => [
        {
            title: '№',
            dataIndex: 'id',
            key: 'id',

        },
        {
            title: 'Наименование вида вредного физического воздействия на окружающую среду*',
            dataIndex: 'environmental_impact_names',
            key: 'environmental_impact_names',
            width: 250,

        },
        {
            title: 'Наименование источника (их совокупности)',
            dataIndex: 'source_name',
            key: 'source_name',
            width: 220,

        },
        {
            title: 'Номер источника',
            dataIndex: 'source_number',
            key: 'source_number',
        },
        {
            title: 'Норматив физического воздействия на окружающую среду , Единица измерения',
            dataIndex: 'standard_of_physical_impact',
            key: 'standard_of_physical_impact',
        },
        {
            title: 'Величина',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Примечание',
            dataIndex: 'note',
            key: 'note',
        },
        {
            title: 'Действия',
            key: 'actions',
            width: 120,
            render: (text, record) => (
                <Editor
                    addInputs={inputs}
                    editInputs={inputs}
                    text={text}
                    tableName={tableName}
                    editTableType={'order_production_tables'}
                    record={record}
                    refresh={refresh}
                />
            ),
        },
    ];

    return(
        <div>
            <StandardTable
                inputs={inputs}
                tableName={tableName}
                tableType={'order_production_tables'}
                columns={rounding => columns(rounding)}
                transformDataSource={transformDataSource}
                dataSource={data}
            />
        </div>
    )
};

export default SignificantObjectsTable;
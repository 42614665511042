import axios from 'axios';
import { BaseAPI } from './BaseAPI';


class DischargeAPI extends BaseAPI {
  getResources = async ({ token, promoAreaId, period, year }, is_fact) =>
    await axios({
      method: 'GET',
      url: `${this.BASE_URL}${this.DISCHARGE_PATH}${is_fact ? 'fact':''}?companyId=${promoAreaId}&period=${period}&year=${year}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(({ data }) => data)
      .catch(error => {
        console.error(error);
      });

  setSource = async ({ token, values, period, name, year, promoAreaId }) =>
    await axios({
      method: 'POST',
      url: `${this.BASE_URL}${this.STANDARD_TABLE_PATH}insert/${name}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        ...values,
        period,
        year,
        company_card_id: promoAreaId,
      },
    })
      .then(res => {
        return res;
      })
      .catch(error => {
        console.error(error);
      });

  setZV = async ({ token, period, year, values, sourceId, promoAreaId }) => {
    console.log({
      ...values,
      period,
      year,
      company_card_id: promoAreaId,
      source_id: sourceId,
    });

    return await axios({
      method: 'POST',
      url: `${this.BASE_URL}${this.DISCHARGE_PATH}insert/discharge_data`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        ...values,
        period,
        year,
        company_card_id: promoAreaId,
        source_id: sourceId,
      },
    })
      .then(data => data)
      .catch(error => {
        console.error(error);
      });
  };

  update = async ({
    token,
    values,
    period,
    year,
    promoAreaId,
    sourceId,
    tableName,
    itemId,
    dataId,
  }) => {
    const path = tableName === 'discharge_sources' ? 'data' : 'discharge';
    const id = tableName === 'discharge_sources' ? itemId : dataId;
    console.log(
      'update: ',
      dataId,
      `${this.BASE_URL}api/${path}/update/${tableName}?id=${id}`
    );
    console.log('data: ', {
      ...values,
      period,
      year,
      company_card_id: promoAreaId,
      source_id: sourceId,
    });
    return await axios({
      method: 'PUT',
      url: `${this.BASE_URL}api/${path}/update/${tableName}?id=${id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        ...values,
        period,
        year,
        company_card_id: promoAreaId,
        source_id: sourceId,
      },
    })
      .then(res => console.log(res))
      .catch(error => {
        console.error(error);
      });
  };

  updateZV = async ({ token, period, year, values, sourceId, promoAreaId }) => {
    console.log('data: ', {
      token,
      period,
      year,
      ...values,
      sourceId,
      promoAreaId,
    });
    return await axios({
      method: 'PUT',
      url: `${this.BASE_URL}${this.DISCHARGE_PATH}update/discharge_data`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        ...values,
        period,
        year,
        company_card_id: promoAreaId,
        source_id: sourceId,
      },
    })
      .then(data => data)
      .catch(error => {
        console.error(error);
      });
  };
}

const { getResources, setSource, setZV, update, updateZV } = new DischargeAPI();

export { getResources, setSource, setZV, update, updateZV };

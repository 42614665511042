import React from 'react';
import Configurator from './components/Configurator';
import CopyForm from './components/CopyForm';

const DateConfigurator = () => {
  return (
    <div style={{ display: 'flex', marginBottom: 20 }}>
      <Configurator />
      <CopyForm />
    </div>
  );
};

export default DateConfigurator;

export default [
  {
    title: '№',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Перечень отходов',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Классификация отходов(Код)',
    dataIndex: 'code',
    key: 'code',
  },
  {
    title: 'Примечание',
    dataIndex: 'note',
    key: 'note',
  },
];

import React, { useState } from 'react';
import { Input } from 'antd';
import { isNumberAndAboveZero } from '../../../../../../utils/validation';

export default [
  {
    title: 'Вид отхода',
    dataIndex: 'waste_type',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Код отхода',
    dataIndex: 'waste_code',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    key: 'waste_code',
    component: <Input />,
  },
  {
    title: 'Образованный объем отходов на предприятии, тонн',
    dataIndex: 'volume_of_waste_enterprise',
    key: 'volume_of_waste_enterprise',
    footerTitle: "Сумма, Образованный объем отходов на предприятии",
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  // {
  //   title: 'Место захоронения отхода (координаты месторасположение)',
  //   dataIndex: 'waste_disposal_site',
  //   key: 'waste_disposal_site',
  //   rules: [
  //     {
  //       required: true,
  //       message: 'Данные обязательны для заполнения',
  //     },
  //   ],
  //   component: <Input />,
  // },
  {
    title: 'Место захоронения отхода (координаты месторасположение)',
    children: [
      {
        title: 'Широта',
        dataIndex: 'latitude',
        key: 'latitude',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
      {
        title: 'Долгота',
        dataIndex: 'longitude',
        key: 'longitude',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
    ],
  },
  {
    title:
      'Захороненный объем отходов на данном месте захоронения на начало отчетного периода, тонн',
    dataIndex: 'buried_volume_of_waste',
    key: 'buried_volume_of_waste',
    footerTitle: "Сумма, Захороненный объем отходов на данном месте захоронения на начало отчетного",
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Лимит захоронения отходов, тонн',
    dataIndex: 'limit_buried_waste',
    key: 'limit_buried_waste',
    footerTitle: "Сумма, Лимит захоронения отходов",
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Фактический объем захороненных отходов за отчетный период, тонн',
    dataIndex: 'actual_volume_buried_waste',
    key: 'actual_volume_buried_waste',
    footerTitle: "Сумма, Фактический объем захороненных отходов за отчетный период",
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  // {
  //   title: 'Переданный объем отходов на проведение операции с ними, тонн',
  //   dataIndex: 'transferred_volume_waste_operations',
  //   key: 'transferred_volume_waste_operations',
  //   rules: [
  //     {
  //       required: true,
  //       message: 'Данные обязательны для заполнения',
  //     },
  //   ],
  //   component: <Input />,
  // },
  // {
  //   title: 'БИН организации, которому передан отход',
  //   dataIndex: 'bin_organization_flash_raw',
  //   key: 'bin_organization_flash_raw',
  //   rules: [
  //     {
  //       required: true,
  //       message: 'Данные обязательны для заполнения',
  //     },
  //   ],
  //   component: <Input />,
  // },
  // {
  //   title: 'Объем отхода, с которым проведены операции на предприятии, тонн',
  //   dataIndex: 'waste_volume_operations_company',
  //   key: 'waste_volume_operations_company',
  //   rules: [
  //     {
  //       required: true,
  //       message: 'Данные обязательны для заполнения',
  //     },
  //   ],
  //   component: <Input />,
  // },
  // {
  //   title: 'Остаток отходов в накопителе на конец отчетного периода, тонн',
  //   dataIndex: 'remaining_waste_period',
  //   key: 'remaining_waste_period',
  //   rules: [
  //     {
  //       required: true,
  //       message: 'Данные обязательны для заполнения',
  //     },
  //   ],
  //   component: <Input />,
  // },
];

import React, { useEffect, useState } from 'react';
import { updateUser } from '../../api/LoginAPI';
import { message } from 'antd';
import { Spin } from 'antd';

export default function AuthUser(props) {
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    update();
  }, []);
  let userVerify = { verify: true };
  let id = props.match.params.id;
  const update = () => {
    setLoader(true);
    // updateUser({ values: userVerify, id: id }).then(res => {
    message.success('Вы успешно прошли верификацию');
    setLoader(false);
    setTimeout(() => window.location.replace('/login'), 3000);
    // });
  };
  return (
    <div style={{ display: 'flex', marginTop: '10%' }}>
      {loader ? (
        <Spin
          size="large"
          style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
          }}
        />
      ) : null}
      {!loader ? (
        <h1 style={{ margin: '0 auto' }}>ВЫ УСПЕШНО ПРОШЛИ ВЕРИФИКАЦИЮ</h1>
      ) : null}
    </div>
  );
}

let count = 0;
const calcColumns = columns => {
  columns.forEach(({ dataIndex, children }) => {
    if (children) calcColumns(children);
    if (dataIndex) count += 1;
  });
  return count;
};

export default calcColumns;

import React from 'react';
import { Input } from 'antd';
import { isNumberAndAboveZero } from '../../../../utils/validation';

export default [
  {
    title: 'Наименование',
    dataIndex: 'name',
    key: 'name',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Синонимы',
    dataIndex: 'synonym',
    key: 'synonym',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Концентрации веществ',
    dataIndex: 'concentration',
    key: 'concentration',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Ед. изм.',
    dataIndex: 'unit',
    key: 'unit',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Показатель вредности',
    dataIndex: 'harm_indicator',
    key: 'harm_indicator',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Класс опасности',
    dataIndex: 'danger_class',
    key: 'danger_class',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
];

import calcColumns from '../../../../utils/calcColumns';

const values = [
  '1. Охрана воздушного бассейна',
  '2. Охрана и рациональное использование водных ресурсов',
  '3. Охрана от воздействия на прибрежные и водные экосистемы',
  '4. Охрана земельных ресурсов',
  '5. Охрана и рациональное использование недр',
  '6. Охрана флоры и фауны',
  '7. Обращение с отходами производства и потребления',
  '8. Радиационная, биологическая и химическая безопасность',
  '9. Внедрение систем управления и наилучших безопасных технологий',
  '10. Научно-исследовательские, изыскательские и другие разработки',
  '11. Экологическое просвещение и пропаганда',
];

const renderContent = (value, row, index) => {
  let target = values.find(value => value === row.number);
  if (!target) target = '';

  const obj = {
    children: value,
    props: {},
  };
  if (row.number === target) {
    obj.props.colSpan = 0;
  }
  return obj;
};

const columns = rounding => [
  {
    title: '№',
    dataIndex: 'number',
    render: (value, row, index) => {
      let target = values.find(value => value === row.number);
      if (!target) target = '';
      if (row.number !== target) {
        return value;
      }
      return {
        children: value,
        props: {
          colSpan: columnSum + 1,
        },
      };
    },
  },
  {
    title: 'Наименование мероприятия',
    dataIndex: 'name',
    render: renderContent,
  },
  {
    title: 'Объем планируемых работ',
    dataIndex: 'volume_work',
    key: 'volume_work',
    render: renderContent,
  },
  {
    title: 'Общая стоимость (тыс.тенге)',
    dataIndex: 'total_price',
    key: 'total_price',
    render: renderContent,
  },
  {
    title: 'Источник финансирования',
    dataIndex: 'finance_source',
    key: 'finance_source',
    render: renderContent,
  },
  {
    title: 'Срок выполнения',
    children: [
      {
        title: 'начало',
        dataIndex: 'start_date',
        key: 'start_date',
        render: renderContent,
      },
      {
        title: 'конец',
        dataIndex: 'finish_date',
        key: 'finish_date',
        render: renderContent,
      },
    ],
  },
  {
    title: 'План финансирования (тыс.тенге)',
    children: [
      {
        title: '20_ год',
        dataIndex: 'year_1',
        key: 'year_1',
        render: renderContent,
      },
      {
        title: '20_ год',
        dataIndex: 'year_2',
        key: 'year_2',
        render: renderContent,
      },
      {
        title: '20_ год',
        dataIndex: 'year_3',
        key: 'year_3',
        render: renderContent,
      },
      {
        title: '20_ год',
        dataIndex: 'year_4',
        key: 'year_4',
        render: renderContent,
      },
      {
        title: '20_ год',
        dataIndex: 'year_5',
        key: 'year_5',
        render: renderContent,
      },
      {
        title: '20_ год',
        dataIndex: 'year_6',
        key: 'year_6',
        render: renderContent,
      },
      {
        title: '20_ год',
        dataIndex: 'year_7',
        key: 'year_7',
        render: renderContent,
      },
      {
        title: '20_ год',
        dataIndex: 'year_8',
        key: 'year_8',
        render: renderContent,
      },
      {
        title: '20_ год',
        dataIndex: 'year_9',
        key: 'year_9',
        render: renderContent,
      },
      {
        title: '20_ год',
        dataIndex: 'year_10',
        key: 'year_10',
        render: renderContent,
      },
    ],
  },
  {
    title: 'Ожидаемый экологический эффект от мероприятия',
    dataIndex: 'expectations',
    key: 'expectations',
    render: renderContent,
  },
];

const columnSum = calcColumns(columns());

export default columns;

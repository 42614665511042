import { BaseAPI } from './BaseAPI';

import axios from 'axios';

class CompanyAPI extends BaseAPI {
  getAllCompanies = ({ token }) =>
    axios({
      method: 'GET',
      url: `${this.BASE_URL}${this.COMPANY_PATH}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

  getCompany = ({ companyId, token }) =>
    axios({
      method: 'GET',
      url: `${this.BASE_URL}${this.COMPANY_PATH}${companyId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

  createCompany = ({ values, token }) =>
    axios({
      method: 'POST',
      url: `${this.BASE_URL}${this.COMPANY_PATH}`,
      data: values,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

  removeCompany = ({ companyId, token }) =>
    axios({
      method: 'DELETE',
      url: `${this.BASE_URL}${this.COMPANY_PATH}?id=${companyId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

  updateCompany = ({ companyId, values, token }) =>
    axios({
      method: 'POST',
      url: `${this.BASE_URL}${this.COMPANY_PATH}?id=${companyId}`,
      data: values,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

  createPromoArea = ({ values, token }) =>
    axios({
      method: 'POST',
      url: `${this.BASE_URL}api/company-card`,
      data: values,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
}

const {
  createCompany,
  removeCompany,
  updateCompany,
  getCompany,
  getAllCompanies,
  createPromoArea
} = new CompanyAPI();

export {
  createCompany,
  removeCompany,
  updateCompany,
  getCompany,
  getAllCompanies,
  createPromoArea
};

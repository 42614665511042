import React, { useEffect, useState } from 'react';
import { message, Switch, Table } from 'antd';
import { connect } from 'react-redux';

import renderUnnecessaryRow from '../../../utils/renderUnnecessaryRow';
import columns from './data/columns';
import staticData from './data/static';
import air from './data/air';
import { getTwoTP } from '../../../api/BaseAPI';

const Air = ({ promoAreaId, token, quarter, year }) => {
  const [data, setData] = useState([]);
  const [rounding, setRounding] = useState(false);

  const roundUp = checked => setRounding(checked);

  const transformDataSource = data => {
    console.log('1: ', data);
    const newDataSource = [];
    data.forEach((el, i) => {
      const value = newDataSource.find(data => data.key === el.code);
      console.log('value', value);
      if (value === undefined) {
        newDataSource.push({
          key: el.code,
          ...el,
        });
      }
    });
    console.log('newDataSource: ', newDataSource);
    setData(newDataSource);
  };

  const fetchData = async () => {
    if (!promoAreaId) return message.error('Не выбрано предприятие!');
    if (promoAreaId && token) {
      console.log('PROMO AREA', promoAreaId);
      try {
        const { data } = await getTwoTP({
          year,
          token,
          quarter,
          companyId: promoAreaId,
        });
        transformDataSource(data);
      } catch (error) {
        console.error(error);
        message.error('Что-то пошло не так!');
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [promoAreaId, quarter, year]);

  return (
    <div>
      <div style={{ marginBottom: 15 }}>
        <p style={{ display: 'inline-block', marginRight: 5 }}>
          Округлить значения:
        </p>
        <Switch onChange={roundUp} />
        <Table
          columns={columns(rounding)}
          scroll={{ x: 1700 + 'px' }}
          dataSource={data}
          bordered
        />
      </div>
    </div>
  );
};

const mapStateToProps = ({
  userStore: { token },
  companyStore: { promoAreaId },
  pekDateStore: { quarter, year },
}) => ({
  promoAreaId,
  token,
  quarter,
  year,
});

export default connect(mapStateToProps)(Air);

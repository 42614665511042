export default [
  {
    value: '{37.5, 0.82, 4089}',
    text: 'Карагандинский бассейн - КР',
  },
  {
    value: '{32.6, 0.81, 4430}',
    text: ' Карагандинский бассейн - КСШ',
  },
  {
    value: '{22.5, 0.81, 5300}',
    text: 'Карагандинский бассейн - К1, К2, концентрат',
  },
  {
    value: '{30.7, 0.84, 4848}',
    text: ' Карагандинский бассейн - КЖР',
  },
  {
    value: '{38.7, 0.9, 3869}',
    text: 'Карагандинский бассейн - К, промпродукт',
  },
  {
    value: '{30.2, 0.84, 4051}',
    text: 'Карагандинский бассейн - К, шлам',
  },
  {
    value: '{40.9, 0.74, 4010}',
    text: 'Куучекинское местрождение - К2Р',
  },
  {
    value: '{40.7, 0.55, 3850}',
    text: 'Боорлинское месторождение - К2Р',
  },
  {
    value: '{42.3, 0.56, 3700}',
    text: 'Экибастузский бассейн в целом - ССР',
  },
  {
    value: '{40.4, 0.56, 3850}',
    text: 'Экибастузский бассейн по группам зольности - ССР',
  },
  {
    value: '{45.6, 0.57, 3489}',
    text: 'Экибастузский бассейн по группам зольности - ССР',
  },
  {
    value: '{14.4, 1.8, 3661}',
    text: 'Ленгерский бассейн - Б3Р,Б3СШ',
  },
  {
    value: '{23, 0.46, 3470}',
    text: 'Майкубенский бассейн (Сарыколь) - Б3',
  },
  {
    value: '{24.6, 0.53, 3731}',
    text: 'Майкубенский бассейн (Шоптыколь) - Б3Р',
  },
  {
    value: '{14.5, 1.6, 2991}',
    text: 'Тургайский бассейн (Кушмурун) - Б2Р',
  },
  {
    value: '{19.1, 0.86, 2710}',
    text: 'Тургайский бассейн (Орловское) - Б2Р',
  },
  {
    value: '{20.3, 0.63, 2669}',
    text: 'Тургайский бассейн (Кызылтал) - Б2Р',
  },
  {
    value: '{14.7, 0.51, 2940}',
    text: 'Тургайский бассейн (Приозерное) - Б2Р',
  },
  {
    value: '{22.1, 0.65, 2479}',
    text: 'Тургайский бассейн (Приозерное) - Б2Р',
  },
  {
    value: '{9.8, 1.04, 3110}',
    text: 'Тургайский бассейн (Нижне-Илийское) - Б1-Б2',
  },
];

export default [
  {
    key: '1. Охрана воздушного бассейна',
    number: '1. Охрана воздушного бассейна',
    type: 1,
  },
  {
    key: 'Итого:1',
    name: 'Итого:',
  },
  {
    key: '2. Охрана и рациональное использование водных ресурсов',
    number: '2. Охрана и рациональное использование водных ресурсов',
    type: 2,
  },
  {
    key: 'Итого:2',
    name: 'Итого:',
  },
  {
    key: '3. Охрана от воздействия на прибрежные и водные экосистемы',
    number: '3. Охрана от воздействия на прибрежные и водные экосистемы',
    type: 3,
  },
  {
    key: 'Итого:3',
    name: 'Итого:',
  },
  {
    key: '4. Охрана земельных ресурсов',
    number: '4. Охрана земельных ресурсов',
    type: 4,
  },
  {
    key: 'Итого:4',
    name: 'Итого:',
  },
  {
    key: '5. Охрана и рациональное использование недр',
    number: '5. Охрана и рациональное использование недр',
    type: 5,
  },
  {
    key: 'Итого:5',
    name: 'Итого:',
  },
  {
    key: '6. Охрана флоры и фауны',
    number: '6. Охрана флоры и фауны',
    type: 6,
  },
  {
    key: 'Итого:6',
    name: 'Итого:',
  },
  {
    key: '7. Обращение с отходами производства и потребления',
    number: '7. Обращение с отходами производства и потребления',
    type: 7,
  },
  {
    key: 'Итого:7',
    name: 'Итого:',
  },
  {
    key: '8. Радиационная, биологическая и химическая безопасность',
    number: '8. Радиационная, биологическая и химическая безопасность',
    type: 8,
  },
  {
    key: 'Итого:8',
    name: 'Итого:',
  },
  {
    key: '9. Внедрение систем управления и наилучших безопасных технологий',
    number: '9. Внедрение систем управления и наилучших безопасных технологий',
    type: 9,
  },
  {
    key: 'Итого:9',
    name: 'Итого:',
  },
  {
    key: '10. Научно-исследовательские, изыскательские и другие разработки',
    number: '10. Научно-исследовательские, изыскательские и другие разработки',
    type: 10,
  },
  {
    key: 'Итого:10',
    name: 'Итого:',
  },
  {
    key: '11. Экологическое просвещение и пропаганда',
    number: '11. Экологическое просвещение и пропаганда',
    type: 11,
  },
  {
    key: 'Итого:11',
    name: 'Итого:',
  },
  {
    key: 'Всего:1',
    name: 'Всего:',
  },
];

import React from 'react';
import { DailyEmissions, QuarterlyEmissions } from './Tables';

const ACM = () => (
  <div>
    <DailyEmissions />
    <QuarterlyEmissions />
  </div>
);

export default ACM;

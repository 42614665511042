import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Typography } from 'antd';
import inputs from './inputs';
import StandardTable from '../../../modules/StandardTable';
import Editor from '../../../modules/Editor';


const WasteTransportation = ({ substance, token, tableName }) => {
  const transformDataSource = (data, setState) => {
    const newDataSource = [];
    data.forEach(el =>
      newDataSource.push({
        key: el.id,
        ...el,
      })
    );
    setState(newDataSource);
  };
  const columns = rounding => (token, refresh) => {
    const columns = [
      {
        title: 'Действие',
        key: 'action',
        render: (text, record) => (
          <Editor
            addInputs={inputs}
            editInputs={inputs}
            text={text}
            tableName={tableName}
            editTableType={'rvpz_pek'}
            record={record}
            refresh={refresh}
          />
        ),
      },
      { title: '№ п/п', dataIndex: 'id', key:'id' },
      {
        title: 'Объем переданных стоков сторонним организациям (м3)',
        dataIndex: 'drains_given',
        key: 'drains_given',
      },
      {
        title: 'Оборотное использование (м3)',
        dataIndex: 'reverse_used',
        key: 'reverse_used',
      },
      {
        title: 'Повторное использование (м3)',
        dataIndex: 'repeatly_used',
        key: 'repeatly_used',
      },
      {
        title: 'Объем закачки воды в пласт (м3)',
        dataIndex: 'insert_volume',
        key: 'insert_volume',
      },
    ];
    return columns;
  };

  return (
    <div>
      <Typography.Title level={4}>
      Перенос загрязнителей в сточных водах за пределы участка
      </Typography.Title>
      <StandardTable
        inputs={inputs}
        tableName={tableName}
        tableType={'rvpz_pek'}
        columns={rounding => columns(rounding)}
        transformDataSource={transformDataSource}
        dataSource={[]}
      />
    </div>
  );
};

const mapStateToProps = ({
  substanceStore: { substance },
  userStore: { token },
  pekDateStore: { year, quarter },
}) => ({
  substance,
  token,
  year,
  quarter,
});

export default connect(mapStateToProps)(WasteTransportation);

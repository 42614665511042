import React from 'react';
import {Input, Select} from 'antd';
import { isNumberAndAboveZero } from '../../../../../../utils/validation';

export default [
    {
        title: 'Наименование объекта воздействия',
        dataIndex: 'name_object',
        key: 'name_object',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
            },
        ],
        component: <Input/>,
    },
    {
        title: 'широта',
        dataIndex: 'latitude',
        key: 'latitude',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
                validator: isNumberAndAboveZero
            },
        ],
        component: <Input/>,
    },
    {
        title: 'долгота',
        dataIndex: 'longitude',
        key: 'longitude',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
                validator: isNumberAndAboveZero
            },
        ],
        component: <Input/>,
    },
    {
        title: 'широта сброса',
        dataIndex: 'latitude_discharge',
        key: 'latitude_discharge',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
                validator: isNumberAndAboveZero
            },
        ],
        component: <Input/>,
    },
    {
        title: 'долгота сброса',
        dataIndex: 'longitude_discharge',
        key: 'longitude_discharge',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
                validator: isNumberAndAboveZero
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Наименование загрязняющих веществ',
        dataIndex: 'name',
        key: 'name',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Установленный норматив, мг/дм3',
        dataIndex: 'established_standard_gram_cube',
        key: 'established_standard_gram_cube',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
                // validator: isNumberAndAboveZero
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Установленный норматив, тонна в год',
        dataIndex: 'established_standard_ton_year',
        key: 'established_standard_ton_year',
        footerTitle: 'Сумма, Установленный норматив т/г',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
                validator: isNumberAndAboveZero
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Фактический результат мониторинга, мг/дм3',
        dataIndex: 'actual_monitoring_result_gram_cube',
        key: 'actual_monitoring_result_gram_cube',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
                validator: isNumberAndAboveZero
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Фактический результат мониторинга, тонн в год',
        dataIndex: 'actual_monitoring_result_ton_year',
        key: 'actual_monitoring_result_ton_year',
        footerTitle: 'Сумма, Фактический результат мониторинга т/г',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
                validator: isNumberAndAboveZero
            },
        ],
        component: <Input/>,
    },
    {
        title:
            'Соблюдение либо превышение нормативов предельно допустимых сбросов',
        dataIndex: 'compliance_excess_standards',
        key: 'compliance_excess_standards',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Мероприятия по устранению нарушений',
        dataIndex: 'actions_violations',
        key: 'actions_violations',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
            },
        ],
        component: <Input/>,
    },
];

import { Select, Tabs } from 'antd';
import React from 'react';
import Coefficient from './Tables/Coefficient';
import ElectionsTable from './Tables/electionsTable';
import InstallationPassport from './Tables/InstallationPassport';
import PlanMonitoring from './Tables/PlanMonitoring';
const { TabPane } = Tabs;
const { Option } = Select;

const GreenhouseGases = () => {
  return (
    <Tabs>
     
      {/* <TabPane tab="Фактический объем выбросов парниковых газов" key="4">
        <ActualAmount />
      </TabPane> */}
      <TabPane tab="Инвентаризация ПГ" key="5">
        <Coefficient />
        {/* <CoefficientSecond /> */}
        <ElectionsTable />
      </TabPane>
      <TabPane tab="План мониторинга" key="2">
      <PlanMonitoring />
      </TabPane>
      <TabPane tab="Паспорт установки" key="3">
        <InstallationPassport />
      </TabPane>
    </Tabs>
  );
};

export default GreenhouseGases;

import { Form, message } from 'antd';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { sendCalculationrequest } from '../../../api/SuperTableAPI';
import compose from '../../../hoc/compose';
import CalculateModalContainer from '../containers/CalculateModal/CalculateModal';
import { meta, types } from '../containers/CalculateModal/InitForm/InitFormMeta';


const CalculateModal = ({
  promoAreaId,
  token,
  inputs,
  form,
  formItemLayout,
  year,
  quarter,
  record,
  refresh,
}) => {
  const [method, setMethod] = useState(0);
  const [visible, setVisible] = useState(false);
  const [sduStatus, setSduStatus] = useState('before_repair');
  const [fuelType, setFuelType] = useState('');
  const { getFieldDecorator, validateFields } = form;
  const modalClose = () => setVisible(false);
  const modalOpen = () => setVisible(true);
  const setBeforeSdu = () => {
    setSduStatus('before_repair');
    form.resetFields(['gross_emission', 'one_emission']);
  };
  const setAfterSdu = () => {
    setSduStatus('after_repair');
    form.resetFields(['gross_emission', 'one_emission']);
  };
  const changeFuelType = type => {
    setFuelType(type);
    form.resetFields(['type_fuel', 'gross_emission', 'one_emission']);
  };

  const onSubmit = evt => {
    evt.preventDefault();
    validateFields(async (err, values) => {
      const { id: sourceId, data_type: dataType } = record;
      const val = method === 1 ? 'method1' : method === 2 ? 'method2' : false;
      sendCalculationrequest({
        method: val,
        year,
        quarter,
        promoAreaId,
        sourceId,
        dataType,
        token,
        values,
        type,
      })
        .then(async data => {
          await refresh();
          message.success('Расчет произошел успешно!');
          modalClose();
        })
        .catch(error => console.error(error));
    });
  };

  const code = record?.source_name?.substring(0, 4)
  const type = types[code];

  const fieldsMeta = meta.boilerMeta.codes.includes(code) ? meta.boilerMeta.fields
    : meta.furnaceMeta.codes.includes(code) ? meta.furnaceMeta.fields
      : meta.torchMeta.codes.includes(code) ? meta.torchMeta.fields
        : meta.desMeta.codes.includes(code)  ? meta.desMeta.fields
          : meta.postMeta.codes.includes(code) ? meta.postMeta.fields
            : meta.wellMeta.codes.includes(code) ? meta.wellMeta.fields
              : meta.tankMeta.codes.includes(code) ? meta.tankMeta.fields
                : meta.pumpMeta.codes.includes(code) ? meta.pumpMeta.fields
                  : meta.weldingMeta.codes.includes(code) ? meta.weldingMeta.fields
                    : meta.drainageMeta.codes.includes(code) ? meta.drainageMeta.fields
                      : [];

  const props = {
    visible,
    modalClose,
    modalOpen,
    formItemLayout,
    inputs,
    method,
    setMethod,
    getFieldDecorator,
    onSubmit,
    sduStatus,
    setBeforeSdu,
    setAfterSdu,
    fuelType,
    changeFuelType,
    record,
    fieldsMeta
  };

  return <CalculateModalContainer {...props} />;
};

const mapStateToProps = ({
  userStore: { token },
  companyStore: { promoAreaId },
  pekDateStore: { year, quarter },
}) => ({ token, promoAreaId, year, quarter });

export default compose(connect(mapStateToProps), Form.create())(CalculateModal);

import React, { useState } from 'react';
import { Input } from 'antd';
import { isNumberAndAboveZero } from '../../../../../../utils/validation';

export default [
  {
    title: 'Код отхода',
    dataIndex: 'waste_code',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    key: 'waste_code',
    component: <Input />,
  },
  {
    title: 'Вид операции',
    dataIndex: 'operation_type',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Объем отхода, с которым проведены операции, тонн',
    dataIndex: 'waste_volume_operations',
    key: 'waste_volume_operations',
    footerTitle: 'Сумма, Объем отхода, с которым проведены операции',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Переданный объем отхода/сырья после операции с ними, тонн',
    dataIndex: 'flash_waste_volume_after_operation',
    key: 'flash_waste_volume_after_operation',
    footerTitle: 'Сумма, Переданный объем отхода/сырья после операции с ними',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  // {
  //     title: 'Место накопления отхода',
  //     children: [
  //         {
  //             title: 'Широта',
  //             dataIndex: 'width',
  //             key: 'width',
  //             rules: [
  //                 {
  //                     required: true,
  //                     message: 'Данные обязательны для заполнения',
  //                     validator: isNumberAndAboveZero
  //                 },
  //             ],
  //             component: <Input/>,
  //         },
  //         {
  //             title: 'Долгота',
  //             dataIndex: 'longitude',
  //             key: 'longitude',
  //             rules: [
  //                 {
  //                     required: true,
  //                     message: 'Данные обязательны для заполнения',
  //                     validator: isNumberAndAboveZero
  //                 },
  //             ],
  //             component: <Input/>,
  //         },
  //     ],
  // },
  {
    title: 'БИН организации, которому передан отход/сырье',
    dataIndex: 'bin_organization_flash_raw',
    key: 'bin_organization_flash_raw',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
      {
        min: 12,
        max: 12,
        message: 'БИН должен состоять из 12 символов',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Оставшиеся объем отходов после проведения операции, тонн',
    dataIndex: 'remaining_waste_volume_after_operation',
    key: 'remaining_waste_volume_after_operation',
    footerTitle: 'Сумма, Оставшиеся объем отходов после проведения операции',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Вид операции с оставшимся объемом отходов',
    dataIndex: 'type_operation_remaining_volume_waste',
    key: 'type_operation_remaining_volume_waste',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  // {
  //     title: 'Переданный объем отходов на проведение операции с ними, тонн',
  //     dataIndex: 'transferred_volume_waste_operations',
  //     key: 'transferred_volume_waste_operations',
  //     rules: [
  //         {
  //             required: true,
  //             message: 'Данные обязательны для заполнения',
  //         },
  //     ],
  //     component: <Input/>,
  // },
  // {
  //     title: 'БИН организации, которому передан отход',
  //     dataIndex: 'bin_organization_flash_raw',
  //     key: 'bin_organization_flash_raw',
  //     rules: [
  //         {
  //             required: true,
  //             message: 'Данные обязательны для заполнения',
  //         },
  //     ],
  //     component: <Input/>,
  // },
  // {
  //     title: 'Объем отхода, с которым проведены операции на предприятии, тонн',
  //     dataIndex: 'waste_volume_operations_company',
  //     key: 'waste_volume_operations_company',
  //     rules: [
  //         {
  //             required: true,
  //             message: 'Данные обязательны для заполнения',
  //         },
  //     ],
  //     component: <Input/>,
  // },
  // {
  //     title: 'Остаток отходов в накопителе на конец отчетного периода, тонн',
  //     dataIndex: 'remaining_waste_period',
  //     key: 'remaining_waste_period',
  //     rules: [
  //         {
  //             required: true,
  //             message: 'Данные обязательны для заполнения',
  //         },
  //     ],
  //     component: <Input/>,
  // },
];

import React, { useEffect, useState } from 'react';
import { SendMessage } from '../../api/RecoveryPasswordAPI';
import { Button, message } from 'antd';
import { Spin } from 'antd';
import { Input } from 'antd';

export default function SendMessagePassword(props) {
  const [loader, setLoader] = useState(false);
  const [email, setEmail] = useState('');
  useEffect(() => {}, []);
  const sendMessage = () => {
    setLoader(true);
    SendMessage({ email: email }).then(res => {
      message.success('Письмо отправлено!');
      setLoader(false);
      setTimeout(() => window.location.replace('/login'), 1000);
    });
  };
  return (
    <div
      style={{
        width: '300px',
        margin: '0 auto',
        marginTop: '10%',
        textAlign: 'center',
      }}
    >
      {loader ? (
        <Spin
          size="large"
          style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
          }}
        />
      ) : (
        <div>
          <h3>Восстановление пароля</h3>
          <p>
            Введите почту, вам придет письмо на страницу восстановление пароля
          </p>
          <Input
            style={{ marginBottom: '10px' }}
            placeholder="email@mail.ru"
            onChange={e => setEmail(e.target.value)}
          />

          <Button
            type="primary"
            style={{
              width: '100%',
              marginTop: '23px',
            }}
            onClick={sendMessage}
          >
            Отправить письмо
          </Button>
        </div>
      )}
    </div>
  );
}

import { BaseAPI } from './BaseAPI';
import axios from 'axios';

class PekAPI extends BaseAPI {
  getPekGrvpzList = ({ token, id, year }) =>
    axios({
      method: 'GET',
      url: `${this.BASE_URL}${this.GRVPZ_PATH}/order-list/${id}?year=${year}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

  getGrvpzCodeData = ({ token }) =>
    axios({
      method: 'GET',
      url: `${this.BASE_URL}${this.GRVPZ_PATH}/code`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

  getGrvpzPossibleEmissions = ({ token, promoId, year }) =>
    axios({
      method: 'GET',
      url: `${this.BASE_URL}${this.GRVPZ_PATH}/possible-emissions?promoId=${promoId}&year=${year}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

  getGrvpzAtmosphere = ({ token, promoId }) =>
    axios({
      method: 'GET',
      url: `${this.BASE_URL}${this.GRVPZ_PATH}/atmosphere?promoId=${promoId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

  getGrvpzAtmosphereSource = ({ token, atmosphereId }) =>
    axios({
      method: 'GET',
      url: `${this.BASE_URL}${this.GRVPZ_PATH}/atmosphere-source?atmosphereId=${atmosphereId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });


  savePekGrvpzList = ({ token, values }) =>
    axios({
      method: 'POST',
      url: `${this.BASE_URL}${this.GRVPZ_PATH}/order-list`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: values,
    });

  getGrvpzHistory = ({ token, userId, year }) =>
    axios({
      method: 'GET',
      url: `${this.BASE_URL}${this.GRVPZ_PATH}/actions/history/${userId}?year=${year}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

  saveGrvpzHistory = ({ token, data }) =>
    axios({
      method: 'POST',
      url: `${this.BASE_URL}${this.GRVPZ_PATH}/actions/history/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });

  sendGrvpzReport = async ({ data, token }) =>
    axios({
      method: 'POST',
      url: `${this.BASE_URL}${this.GRVPZ_PATH}/send/report`,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
      data,
    });

  getInitialPekInfo = ({ token, userId, year }) =>
    axios({
      method: 'GET',
      url: `${this.BASE_URL}${this.GRVPZ_PATH}/pek-info-grvpz/${userId}?year=${year}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

  upDateGrvpzTablesData = async ({ token, tableName, values, itemId }) => {
    const url = `${this.BASE_URL}${this.GRVPZ_PATH}/${tableName}/${itemId}`;
    const info = values;
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const { data } = await axios.put(url, info, config);
      console.log('success: ', data);
      return data;
    } catch (error) {
      console.error('error: ', error);
      return error;
    }
  };
}

const {
  getPekGrvpzList,
  saveGrvpzHistory,
  getGrvpzHistory,
  savePekGrvpzList,
  sendGrvpzReport,
  getInitialPekInfo,
  upDateGrvpzTablesData,
  getGrvpzCodeData,
  getGrvpzPossibleEmissions,
  getGrvpzAtmosphere,
  getGrvpzAtmosphereSource,
} = new PekAPI();

export {
  getPekGrvpzList,
  saveGrvpzHistory,
  getGrvpzHistory,
  savePekGrvpzList,
  sendGrvpzReport,
  getInitialPekInfo,
  upDateGrvpzTablesData,
  getGrvpzCodeData,
  getGrvpzPossibleEmissions,
  getGrvpzAtmosphere,
  getGrvpzAtmosphereSource,
};

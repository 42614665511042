import moment from 'moment';

export default [
  {
    title: 'Код строки',
    dataIndex: 'code_string',
    key: 'code_string',
  },
  {
    title: 'Наименование водного объекта',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Код приемника',
    dataIndex: 'code_reciver',
    key: 'code_reciver',
  },
  {
    title: 'Код моря-реки',
    dataIndex: 'code_sea',
    key: 'code_sea',
  },
  {
    title: 'Притоки',
    key: 'balance',
    children: [
      {
        title: '1',
        dataIndex: 'inflow_1',
        key: 'inflow_1',
      },
      {
        title: '2',
        dataIndex: 'inflow_2',
        key: 'inflow_2',
      },
      {
        title: '3',
        dataIndex: 'inflow_3',
        key: 'inflow_3',
      },
      {
        title: '4',
        dataIndex: 'inflow_4',
        key: 'inflow_4',
      },
      {
        title: '5',
        dataIndex: 'inflow_5',
        key: 'inflow_5',
      },
    ],
  },
  {
    title: 'Код качества',
    dataIndex: 'code_quality',
    key: 'code_quality',
  },
  {
    title: 'Расстояние от устья',
    dataIndex: 'distance',
    key: 'distance',
  },
  {
    title: 'Отведено, сброшено всего',
    dataIndex: 'alloted_dropped',
    key: 'alloted_dropped',
  },
  {
    title: 'Загрязненных',
    children: [
      {
        title: 'без очистки',
        dataIndex: 'polluted_without_clear',
        key: 'polluted_without_clear',
      },
      {
        title: 'недостаточно очищенные',
        dataIndex: 'polluted_insuffiently_cleaned',
        key: 'polluted_insuffiently_cleaned',
      },
    ],
  },
  {
    title: 'Нормативно-чистые (без очистки)',
    dataIndex: 'normally_cleaned_without_cleaned',
    key: 'normally_cleaned_without_cleaned',
  },
  {
    title: 'Нормативно очищенных',
    children: [
      {
        title: 'биологической',
        dataIndex: 'normally_cleaned_without_biological',
        key: 'normally_cleaned_without_biological',
      },
      {
        title: 'физико-химической',
        dataIndex: 'normally_cleaned_without_physical',
        key: 'normally_cleaned_without_physical',
      },
      {
        title: 'механической',
        dataIndex: 'normally_cleaned_without_mechanical',
        key: 'normally_cleaned_without_mechanical',
      },
    ],
  },
  {
    title: 'Содержание загрязняющих веществ в сточных водах, мг/л',
    children: [
      {
        title: 'БПКполн',
        dataIndex: 'content_bpk',
        key: 'content_bpk',
      },
      {
        title: 'нефтепродукты',
        dataIndex: 'content_oil_products',
        key: 'content_oil_products',
      },
      {
        title: 'взвешенные вещества',
        dataIndex: 'content_suspence_substances',
        key: 'content_suspence_substances',
      },
      {
        title: 'сухой остаток',
        dataIndex: 'content_balance',
        key: 'content_balance',
      },
      {
        title: 'код',
        dataIndex: 'content_code',
        key: 'content_code',
      },
      {
        title: 'количество ',
        dataIndex: 'content_count',
        key: 'content_count',
      },
    ],
  },
  {
    title: 'Создан',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (text, record) =>
      record.code_string === 'А' ? text : moment(text).format('YYYY-MM-DD'),
  },
];

import { message } from 'antd';
import Axios from 'axios';
import React, { memo, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getJournalData } from '../../../api/journalAPI';
import {
  getGosPekResources,
  getGrvpzResources,
  getOrderProductionResources,
  getResources,
  getTaxes,
  getWaterResources,
  removeSomeString,
} from '../../../api/StandardTableAPI';
import { getResources as getResourcesWaste } from '../../../api/WasteAPI';
import compose from '../../../hoc/compose';
import { filterGosPekColumnsByOrderStatus } from '../../../utils/columnFilter';
import { addSorterToColumns } from '../../../utils/columnSorter';
import StandardTableContainer from '../containers/Table';

const nonDependentTableNames = [
  'data_on_location',
  'characteristics_of_objects',
];

const StandardTable = ({
  token,
  promoAreaId,
  quarter,
  year,
  order,
  user,
  tableName,
  inputs,
  columns,
  promo_areas,
  transformDataSource,
  mrp,
  scrollMultiplier = 100,
  pagination = { pageSize: 10 },
  company = true,
  tableType = 'standard',
  setDataSourceForInitial = () => {},
  formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 12 },
    },
    wrapperCol: {
      xs: { span: 18 },
      sm: { span: 12 },
    },
  },
}) => {
  const [data, setData] = useState([]);
  const params = useParams();
  const [visible, setVisible] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [rounding, setRounding] = useState(false);
  const { orderProductionId } = useSelector(state => ({
    ...state.userStore,
  }));
  const roundUp = checked => setRounding(checked);

  const modalShow = () => setVisible(true);

  const modalClose = () => setVisible(false);

  const deleteString = async () =>
    await removeSomeString(token, tableName, orderProductionId);

  const fetchData = async () => {
    if (!promoAreaId && nonDependentTableNames.includes())
      return message.error('Не выбрано предприятие!');
    if (
      (promoAreaId && token) ||
      (token && !nonDependentTableNames.includes())
    ) {
      try {
        let result = [];
        switch (tableType) {
          case 'standard':
            result = await getResources(token, tableName);
            break;
          case 'actual_amount':
            //result = [{rowCode: ['Единицы квот', 'Единицы внутренных сокращений', 'Всего',]}]
            break;
          case 'plan_monitoring':
            //result = [{type: ['type', ]}]
            break;
          case 'tax':
            result = await getTaxes(token, {
              promoAreaId,
              period: quarter,
              year,
              mrp,
              tableName,
            });
            break;
          case 'waste':
            result = await getResourcesWaste({
              token,
              name: tableName,
              year,
              period: quarter,
              promoAreaId,
            });
            break;
          case 'statistics':
            result = await getJournalData({
              token,
              type: 'добыча',
              companyId: promoAreaId,
              year,
              option: 'вода',
            });
            break;
          case 'coefficient':
            const res = await Axios({
              method: 'GET',
              url: `http://185.146.3.215:4000/calculation_factor`,
            });
            result = res.data;
            setDataSource(result);
            break;
          case 'information_on_the_limits':
            result = await getResources(token, tableName);
            setData(result);
            break;
          case 'normative_information':
            result = await getResources(token, tableName);
            setData(result);
            break;
          case 'information_on_standards':
            console.log('information_on_standards');
            result = await getResources(token, tableName);
            console.log(result);
            setDataSource(result);
            break;
          case 'pollutants_in_wastewater':
            console.log('information_on_standards');
            result = await getResources(token, tableName);
            console.log(result);
            setDataSource(result);
            break;
          case 'order_production_tables':
            result = await getOrderProductionResources(
              token,
              tableName,
              orderProductionId
            );
            setDataSource(result);
            break;
          case 'gos_pek':
            result = await getGosPekResources({
              token,
              tableName,
              period: quarter,
              year,
              promo_areas,
              id: user.username.id,
              formId: params.id,
            });
            setDataSource(result);
            break;
          case 'rvpz_pek':
            result = await getGrvpzResources({
              token,
              tableName,
              year,
              id: tableName === 'rvpz_source' ? user.username.id : params.id,
            });
            setDataSource(result);
            setDataSourceForInitial(result);
            break;
          case 'pek_water':
            result = await getWaterResources({
              token,
              tableName,
              year,
              id: user.username.id,
            });
            setDataSource(result);
            break;
          default:
            break;
        }
        if (tableName !== 'coefficient') {
          setData(result);
        }
      } catch (error) {
        console.error(error);
        message.error('Что-то пошло не так!');
      }
    }
  };

  const checkRolesForGosPek = columns => {
    if (tableType === 'gos_pek')
      return filterGosPekColumnsByOrderStatus(columns, order);

    return columns;
  };

  useEffect(() => {
    fetchData();
  }, [mrp, quarter, year]);
  useEffect(() => {
    if (data.length > 0) {
      transformDataSource(data, setDataSource);
    }
  }, [data]);

  const columnsListWithSorter = addSorterToColumns(
    columns(rounding)(token, fetchData)
  );
  const columnsListWithSorterAndRole = checkRolesForGosPek(
    columnsListWithSorter
  );

  const props = {
    tableName,
    inputs,
    columns: columnsListWithSorterAndRole,
    dataSource,
    order,
    modalShow,
    visible,
    modalClose,
    fetchData,
    scrollMultiplier,
    pagination,
    company,
    formItemLayout,
    roundUp,
    promo_areas,
    deleteString,
    tableType,
    defaultValues: dataSource[dataSource.length - 1],
  };

  return <StandardTableContainer {...props} />;
};

const mapStateToProps = ({
  userStore: { token, user },
  companyStore: { promoAreaId },
  pekDateStore: { quarter, year, order },
  taxStore: { mrp },
}) => ({
  token,
  promoAreaId,
  quarter,
  year,
  user,
  order,
  mrp,
});

export default compose(memo, connect(mapStateToProps))(StandardTable);

const values = [
  '1. Охрана воздушного бассейна',
  '2. Охрана и рациональное использование водных ресурсов',
  '3. Охрана от воздействия на прибрежные и водные экосистемы',
  '4. Охрана земельных ресурсов',
  '5. Охрана и рациональное использование недр',
  '6. Охрана флоры и фауны',
  '7. Обращение с отходами производства и потребления',
  '8. Радиационная, биологическая и химическая безопасность',
  '9. Внедрение систем управления и наилучших безопасных технологий',
  '10. Научно-исследовательские, изыскательские и другие разработки',
  '11. Экологическое просвещение и пропаганда',
];

const renderContent = (value, row, index) => {
  let target = values.find(value => value === row.number);
  if (!target) target = '';

  const obj = {
    children: value,
    props: {},
  };

  if (row.number === target) {
    obj.props.colSpan = 0;
  }
  return obj;
};

export default rounding => [
  {
    title: '№',
    dataIndex: 'number',
    render: (value, row) => {
      let target = values.find(value => value === row.number);
      if (!target) target = '';
      if (row.number !== target) {
        return value;
      }
      return {
        children: value,
        props: {
          colSpan: 9,
        },
      };
    },
  },
  {
    title: 'Наименование мероприятия',
    dataIndex: 'name',
    key: 'name',
    render: renderContent,
  },
  {
    title: 'Объем запланированных работ',
    dataIndex: 'volume_work',
    key: 'volume_work',
    render: renderContent,
  },
  {
    title: 'Период выполнения мероприятия согласно утвержденному плану',
    dataIndex: 'period_work',
    key: 'period_work',
    render: renderContent,
  },
  {
    title: 'Запланировано (тысяч тенге)',
    dataIndex: 'planned_tenge',
    key: 'planned_tenge',
    render: renderContent,
  },
  {
    title: '% освоенных средств',
    dataIndex: 'percent_disburced_funds',
    key: 'percent_disburced_funds',
    render: renderContent,
  },
  {
    title: '% выполненных работ',
    dataIndex: 'percent_comleted_work',
    key: 'percent_comleted_work',
    render: renderContent,
  },
  {
    title: 'Экологический эффект от мероприятия ',
    dataIndex: 'ecological_effect',
    key: 'ecological_effect',
    render: renderContent,
  },
  {
    title: 'Примечание (причины невыполнения)',
    dataIndex: 'note',
    key: 'note',
    render: renderContent,
  },
];

import React from 'react';
import {DatePicker, Input, Select} from 'antd';
import moment from 'moment';

export default [
    {
        title: 'Наименование производственного объекта',
        dataIndex: 'name_object',
        key: 'name_object',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
            },
        ],
        component: <Input/>,
    },
    {
        title:
            'Месторасположение по коду КАТО (Классификатор административно-территориальных объектов)',
        dataIndex: 'location_kato',
        key: 'location_kato',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Широта',
        dataIndex: 'latitude',
        key: 'latitude',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Долгота',
        dataIndex: 'longitude',
        key: 'longitude',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Бизнес Идентификационный номер оператора объекта (БИН)',
        dataIndex: 'bin',
        key: 'bin',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
            },
        ],
        component: <Input/>,
    },
    {
        title:
            'Вид деятельности по общему классификатору видов экономической деятельности (ОКЭД)',
        dataIndex: 'type_ativity_according_types',
        key: 'type_ativity_according_types',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Краткая характеристика производственного процесса',
        dataIndex: 'brief_description_production_process',
        key: 'brief_description_production_process',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Реквизиты',
        dataIndex: 'requisites',
        key: 'requisites',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Категория объекта',
        dataIndex: 'category_object',
        key: 'category_object',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Проектная мощность предприятия',
        dataIndex: 'design_capacity_enterprise',
        key: 'design_capacity_enterprise',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Фактическая мощность за отчетный период',
        dataIndex: 'actual_capacity_reporting_period',
        key: 'actual_capacity_reporting_period',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Период действия программы производственного мониторинга',
        dataIndex: 'duration_production_monitoring_program',
        key: 'duration_production_monitoring_program',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
            },
        ],
        component: <Input/>,
    },
];

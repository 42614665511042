import React from 'react';

import renderUnnecessaryRow from '../../../../../utils/renderUnnecessaryRow';
import staticData from './data';
import columnList from './data/columns';
import StandardTable from '../../../../../modules/StandardTable';
import inputs from './data/modalInputs';
import Editor from '../../../../../modules/Editor';

const tableName = 'statistic_water_disposal';

const columns = rounding => (token, refresh) => [
  {
    title: 'Действие',
    key: 'action',
    render: (text, record) => {
      const condition = record.code_string === 'А';
      const obj = {
        children: condition ? null : (
          <Editor
            addInputs={inputs}
            editInputs={inputs}
            text={text}
            tableName={tableName}
            record={record}
            refresh={refresh}
          />
        ),
        props: {},
      };
      return obj;
    },
  },
  ...columnList,
];

const transformDataSource = (data, setState) => {
  const newDataSource = [renderUnnecessaryRow(columnList, staticData)];
  data.forEach(el =>
    newDataSource.push({
      key: el.id,
      ...el,
    })
  );
  setState(newDataSource);
};

const WaterDisposal = () => (
  <StandardTable
    tableName={tableName}
    inputs={inputs}
    columns={columns}
    transformDataSource={transformDataSource}
    scrollMultiplier={250}
  />
);

export default WaterDisposal;

import axios from 'axios';
import config from '../config';

class BaseAPI {
    BASE_URL = config.api_url;
    STANDARD_TABLE_PATH = 'api/data/';
    SUPER_TABLE_PATH = 'api/pek/';
    GREENHOUSE_TABLE_PATH = 'api/greenhouse/';
    TAX_PATH = 'api/tax';
    CHART_PATH = 'api/graphics/';
    USER_PATH = 'api/user/';
    DEPARTMENT_PATH = 'api/department';
    COMPANY_PATH = 'api/company/';
    ATMOSPHERE_PATH = 'api/atmosphere/';
    DISCHARGE_PATH = 'api/discharge/';
    WASTE_PATH = 'api/waste';
    ACCOUNTING_PATH = 'api/accounting';
    EMISSION_PATH = 'api/emission/';
    ORDER_PRODUCTION = 'order_production';
    GOS_PEK = 'api/gos-pek';
    GRVPZ_PATH = 'api/grvpz/';
    PEK_WATER_PATH = 'api/pek-water/';
    ORDER_TYPES = 'order_production_types';
    ORDER_TYPES_DETAIL = 'order_production_types_detail';

    checkUserToken = async ({username, token}) => {
        return axios({
            method: 'GET',
            url: `${this.BASE_URL}${this.USER_PATH}index`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        });
    };

    getDocs = ({token, dataType, year, period, companyId}) => {
        return `${this.BASE_URL}${this.SUPER_TABLE_PATH}generate_file?dataType=${dataType}&year=${year}&period=${period}&companyId=${companyId}`;
    };

    getTwoTP = async ({year, token, quarter, companyId}) => {
        return axios({
            method: 'GET',
            url: `${this.BASE_URL}api/two_tp?year=${year}&period=${quarter}&companyId=${companyId}`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        });
    };

    sendReport = async ({year, token, quarter, promoAreaId, dataType}) => {
        return axios({
            method: 'POST',
            url: `${this.BASE_URL}${this.SUPER_TABLE_PATH}gos_report?period=${quarter}&year=${year}&companyId=${promoAreaId}&dataType=${dataType}`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        });
    };
}

const {getDocs, checkUserToken, getTwoTP, sendReport} = new BaseAPI();

export {getDocs, checkUserToken, getTwoTP, BaseAPI, sendReport};

import { Form, message, Input } from 'antd';
import React from 'react';
import { connect, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  postGosPekTablesData,
  postGrvpzTablesData,
  postWaterTablesData,
  setData,
  setGosPekTablesData,
  setOrderProductionTablesData,
  upDateGosPekTablesData,
} from '../../../api/StandardTableAPI';
import { setSource as setSourceWaste } from '../../../api/WasteAPI';
import compose from '../../../hoc/compose';
import ModalContainer from '../containers/Modal';

const Modal = ({
  inputs,
  form,
  visible,
  modalClose,
  tableName,
  token,
  promoAreaId,
  refresh,
  formItemLayout,
  company,
  user,
  year,
  quarter,
  tableType,
  defaultValues,
  dataSource,
}) => {
  const { getFieldDecorator, validateFields, setFieldsValue } = form;
  const { orderProductionId } = useSelector(state => ({
    ...state.userStore,
  }));
  const routeParams = useParams();

  const changeHandler = e => {
    const fvalue = e.target.value;
    const fname = e.target.name;

    setFieldsValue({
      [fname]: fvalue,
    });
  };

  const renderChildrenInputs = (inputs, firstTitle = '') => {
    const items = inputs.map(
      ({ title, dataIndex, children, component, rules = [] }) => {
        if (children) return renderChildrenInputs(children, title);

        console.log(title, dataIndex, children, component, rules);

        let text = title
          .toLowerCase()
          .trim()
          .replace(/\W{1}/, str => str.toUpperCase());

        let newComponent = component;

        return (
          <Form.Item key={dataIndex} label={text}>
            {getFieldDecorator(dataIndex, {
              rules,
            })(newComponent)}
          </Form.Item>
        );
      }
    );

    return (
      <div>
        {firstTitle && <p key={firstTitle}>{firstTitle}</p>}
        {items}
      </div>
    );
  };
  const renderInputs = (inputs, firstTitle = '') => {
    return inputs.map(
      ({ title, dataIndex, children, component, rules = [] }) => {
        if (children) return renderChildrenInputs(children, title);

        let text = title
          .toLowerCase()
          .trim()
          .replace(/\W{1}/, str => str.toUpperCase());

        return (
          <div key={dataIndex}>
            {firstTitle && <p>{firstTitle}</p>}
            <Form.Item label={text}>
              {getFieldDecorator(dataIndex, {
                rules,
              })(component)}
            </Form.Item>
          </div>
        );
      }
    );
  };

  const onSubmit = evt => {
    evt.preventDefault();
    validateFields(async (err, values) => {
      err && message.error('Форма заполнена не правильно!');
      if (!err && token) {
        try {
          // eslint-disable-next-line default-case
          switch (tableType) {
            case 'standard':
              await setData(token, {
                name: tableName,
                values,
                promoAreaId,
                company,
              });
              break;
            case 'coefficient':
              await setData(token, {
                name: tableName,
                values,
                promoAreaId,
                company,
              });
              break;
            case 'information_on_standards':
              await setData(token, {
                name: tableName,
                values,
                // promoAreaId,
                company,
              });
              break;
            // eslint-disable-next-line no-duplicate-case
            case 'pollutants_in_wastewater':
              await setData(token, {
                name: tableName,
                values,
                company,
              });
              break;
            case 'waste':
              await setSourceWaste({
                token,
                values,
                period: quarter,
                year,
                promoAreaId,
              });
              break;
            case 'information_on_the_limits':
              await setData(token, {
                name: tableName,
                values,
              });
              break;
            case 'order_production_tables':
              await setOrderProductionTablesData(token, {
                name: tableName,
                values: { ...values, form_id: orderProductionId },
              });
              break;
            case 'gos_pek':
              await postGosPekTablesData(token, user.username.id, {
                name: tableName,
                values: {
                  ...values,
                  form_id: routeParams.id,
                  year: year,
                  period: quarter,
                },
              });
              break;
            case 'rvpz_pek':
              await postGrvpzTablesData(token, {
                name: tableName,
                values: {
                  ...values,
                  user_id:
                    tableName === 'rvpz_sources'
                      ? user.username.id
                      : routeParams.id,
                  year: year,
                },
              });
              break;
            case 'pek_water':
              await postWaterTablesData(token, {
                name: tableName,
                values: {
                  ...values,
                  pek_water_list_id: user.username.id,
                  year,
                },
              });
          }
          await refresh();
          modalClose();
          message.success('Данные отправлены успешно!');
        } catch (error) {
          modalClose();
          message.error('Что-то пошло не так!');
          console.error(error);
        }
      }
    });
  };

  const props = {
    visible,
    modalClose,
    onSubmit,
    formItemLayout,
    renderInputs,
    inputs,
  };
  return <ModalContainer {...props} />;
};

const mapStateToProps = ({
  userStore: { token, user },
  companyStore: { promoAreaId },
  pekDateStore: { year, quarter },
}) => ({
  token,
  promoAreaId,
  year,
  quarter,
  user,
});

export default compose(
  connect(mapStateToProps),
  Form.create({ name: 'modal_form' })
)(Modal);

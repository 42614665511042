import roundUp from '../../../../utils/roundUp';

const renderContent = (text, row, rounding) => {
  const obj = {
    children: roundUp(text, rounding),
    props: {},
  };

  if (
    row.number === 'Выбросы от стационарных источников:' ||
    row.number === 'Сбросы ЗВ со сточными водами:' ||
    row.number === 'Отходы'
  ) {
    obj.props.colSpan = 0;
  }
  return obj;
};

export default rounding => [
  {
    title: '№п/п',
    dataIndex: 'number',
    key: 'number',
    fixed: 'left',
    width: 120,
    render: (text, row, index) => {
      // console.log(text);
      if (
        text !== 'Выбросы от стационарных источников:' &&
        text !== 'Сбросы ЗВ со сточными водами:' &&
        text !== 'Отходы'
      ) {
        return text;
      }
      return {
        children: text,
        props: {
          colSpan: 14,
        },
      };
    },
  },
  {
    title: 'Виды загрязняющих веществ ',
    dataIndex: 'type',
    key: 'type',
    fixed: 'left',
    width: 250,
    render: (text, row) => renderContent(text, row, rounding),
  },
  {
    title: 'Ставка платы (МРП)',
    key: 'rate',
    children: [
      {
        title: 'за 1 тонну',
        key: 'rate_t',
        dataIndex: 'rate_t',
        render: (text, row) => renderContent(text, row, rounding),
      },
      {
        title: 'за 1 кг',
        key: 'rate_kg',
        dataIndex: 'rate_kg',
        render: (text, row) => renderContent(text, row, rounding),
      },
    ],
  },
  {
    title: 'Ставка платы, тенге',
    dataIndex: 'rate_tenge',
    key: 'rate_tenge',
    render: (text, row) => renderContent(text, row, rounding),
  },
  {
    title: 'Лимит, тонн ',
    dataIndex: 'limit_t',
    key: 'limit_t',
    render: (text, row) => renderContent(text, row, rounding),
  },
  {
    title: 'Остаток лимита на начало периода',
    dataIndex: 'limit_balance_start',
    key: 'limit_balance_start',
    render: (text, row) => renderContent(text, row, rounding),
  },
  {
    title: 'Выбросы, тонн',
    key: 'emissions',
    children: [
      {
        title: 'Факт, тонн',
        dataIndex: 'emissions_fact',
        key: 'emissions_fact',
        render: (text, row) => renderContent(text, row, rounding),
      },
      {
        title: 'Норматив',
        dataIndex: 'emissions_standard',
        key: 'emissions_standard',
        render: (text, row) => renderContent(text, row, rounding),
      },
      {
        title: 'Сверх норматив',
        dataIndex: 'emissions_excess',
        key: 'emissions_excess',
        render: (text, row) => renderContent(text, row, rounding),
      },
    ],
  },
  {
    title: 'Остаток лимита, тонн',
    dataIndex: 'limit_balance_end',
    key: 'limit_balance_end',
    render: (text, row) => renderContent(text, row, rounding),
  },
  {
    title: 'Сумма к оплате, тенге',
    dataIndex: 'sum',
    children: [
      {
        title: 'Норматив',
        dataIndex: 'pay_standard',
        key: 'pay_standard',
        render: (text, row) => renderContent(text, row, rounding),
      },
      {
        title: 'Сверх норматив',
        dataIndex: 'pay_excess',
        key: 'pay_excess',
        render: (text, row) => renderContent(text, row, rounding),
      },
      {
        title: 'Всего',
        dataIndex: 'pay_total',
        key: 'pay_total',
        render: (text, row) => renderContent(text, row, rounding),
      },
    ],
  },
];

import roundUp from '../../../../utils/roundUp';

export default rounding => [
  {
    title: 'Объем выбросов двуокиси углерода, тонн',
    dataIndex: 'volume_co2',
    key: 'volume_co2',
    render: value => roundUp(value, rounding),
  },
  {
    title: 'Объем выбросов метана',
    dataIndex: 'ch4',
    key: 'ch4',
    children: [
      {
        title: 'тонн',
        dataIndex: 'volume_ch4',
        key: 'volume_ch4',
        render: value => roundUp(value, rounding),
      },
      {
        title: 'в эквиваленте тонны двуокиси углерода',
        dataIndex: 'volume_ch4_co2',
        key: 'volume_ch4_co2',
        render: value => roundUp(value, rounding),
      },
    ],
  },
  {
    title: 'Объем выбросов закиси азота',
    dataIndex: 'n2o',
    key: 'n2o',
    children: [
      {
        title: 'тонн',
        dataIndex: 'volume_n2o',
        key: 'volume_n2o',
        render: value => roundUp(value, rounding),
      },
      {
        title: 'в эквиваленте тонны двуокиси углерода',
        dataIndex: 'volume_n2o_co2',
        key: 'volume_n2o_co2',
        render: value => roundUp(value, rounding),
      },
    ],
  },
  {
    title: 'Объем выбросов перфторуглеродов',
    dataIndex: 'hz',
    key: 'hz',
    children: [
      {
        title: 'тонн',
        dataIndex: 'hz',
        key: 'hz',
      },
      {
        title: 'в эквиваленте тонны двуокиси углерода',
        dataIndex: 'hz',
        key: 'hz',
      },
    ],
  },
  {
    title:
      'Объем выбросов парниковых газов в эквиваленте тонны двуокиси углерода, всего',
    dataIndex: 'whole_sum',
    key: 'whole_sum',
    render: value => roundUp(value, rounding),
  },
  {
    title: 'Общий объем выбросов двуокиси углерода по всем источникам, тонн',
    dataIndex: 'sum_co2',
    key: 'sum_co2',
    render: value => (value ? roundUp(value, rounding) : 0),
  },
  {
    title:
      'Общий объем выбросов парниковых газов по всем источникам в эквиваленте тонны двуокиси углерода',
    dataIndex: 'sum_whole_sum',
    key: 'sum_whole_sum',
    render: value => (value ? roundUp(value, rounding) : 0),
  },
];

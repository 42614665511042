import React from 'react';
import { DatePicker, Input, Select } from 'antd';
import moment from 'moment';
import { isNumberAndAboveZero } from '../../../utils/validation';

export default [
  {
    title: 'Долгота',
    dataIndex: 'longitude',
    key: 'longitude',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Широта',
    dataIndex: 'latitude',
    key: 'latitude',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Объем накопленных отходов на полигоне за весь период эксплуатации',
    dataIndex: 'volume_all',
    key: 'volume_all',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Объем образованных отходов за отчетный год',
    dataIndex: 'volume_year',
    key: 'volume_year',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
];

import React from 'react';
import {DatePicker, Input, Select} from 'antd';
import moment from 'moment';
import { isNumberAndAboveZero } from '../../../../../../utils/validation';

export default [
    {
        title: 'Наименование мероприятия',
        dataIndex: 'name',
        key: 'name',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Объект / источник эмиссии',
        dataIndex: 'object_emissions',
        key: 'object_emissions',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Код вещества',
        dataIndex: 'code_substances',
        key: 'code_substances',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Наименование загрязняющих веществ',
        dataIndex: 'name_substances',
        key: 'name_substances',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Показатель нормативов, согласно разрешения',
        dataIndex: 'indicator_standards',
        key: 'indicator_standards',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
                validator: isNumberAndAboveZero
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Фактическая величина на конец отчетного периода',
        dataIndex: 'actual_value_period',
        key: 'actual_value_period',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
                validator: isNumberAndAboveZero
            },
        ],
        component: <Input/>,
    },
    {
        title:
            'Фактические расходы на мероприятие за отчетный период (тыс.тенге)',
        dataIndex: 'actual_costs_event',
        key: 'actual_costs_event',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
                validator: isNumberAndAboveZero
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Проведенные работы по выполнению мероприятия',
        dataIndex: 'activities_carried_out_activities',
        key: 'activities_carried_out_activities',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Экологический эффект от мероприятия, в применимых единицах',
        dataIndex: 'environmental_impact',
        key: 'environmental_impact',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
                validator: isNumberAndAboveZero
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Примечание',
        dataIndex: 'note',
        key: 'note',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
            },
        ],
        component: <Input/>,
    },
];

import axios from 'axios';
import moment from 'moment';
import { BaseAPI } from './BaseAPI';

class StandardTableAPI extends BaseAPI {
  getResources = async (token, name, formId = null) => {
    const url = `${this.BASE_URL}${this.STANDARD_TABLE_PATH}select/${name}?${
      formId ? `form_id=${formId}` : ''
    }`;
    try {
      const { data } = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return data;
    } catch (error) {
      return error;
    }
  };

  getOrderProductionResources = async (token, name, formId) => {
    const url = `${this.BASE_URL}${this.ORDER_PRODUCTION}/${name}/${formId}`;
    try {
      const { data } = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return data;
    } catch (error) {
      return error;
    }
  };

  getGosPekResources = async ({
    token,
    tableName,
    period,
    year,
    promo_areas,
    id,
    formId,
  }) => {
    console.log(promo_areas);
    let url_params;
    if (promo_areas) {
      url_params = `?id=${id}&period=${period}&year=${year}`;
    } else {
      url_params = `?&period=${period}&year=${year}&id=${formId}`;
    }
    const url =
      `${this.BASE_URL}${this.GOS_PEK}/${tableName}/filter` + url_params;
    try {
      const result = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return result.data;
    } catch (error) {
      return error;
    }
  };

  getGrvpzResources = async ({ token, tableName, year, id }) => {
    const url_params = `?year=${year}&id=${id}`;

    const url =
      `${this.BASE_URL}${this.GRVPZ_PATH}/${tableName}/filter` + url_params;
    try {
      const result = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return result.data;
    } catch (error) {
      return error;
    }
  };

  getWaterResources = async ({ token, tableName, year, id }) => {
    const url_params = `?year=${year}&id=${id}`;

    const url =
      `${this.BASE_URL}${this.PEK_WATER_PATH}/${tableName}/filter` + url_params;
    try {
      const result = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return result.data;
    } catch (error) {
      return error;
    }
  };

  getTaxes = async (token, { period, year, promoAreaId, mrp, tableName }) => {
    axios.defaults.headers['Authorization'] = `Bearer ${token}`;
    const path = tableName === 'water' ? '/water' : '';
    const params = `?mrp=${mrp}&period=${period}&year=${year}&companyId=${promoAreaId}`;
    const emissionsPath = `${this.BASE_URL}${this.TAX_PATH}${params}&taxType=stationaryEmission`;
    const dischargesPath = `${this.BASE_URL}${this.TAX_PATH}/water${params}`;
    // console.log('getResources', 'url: ', url);
    try {
      const emissions = axios.get(emissionsPath);
      const discharges = axios.get(dischargesPath);

      const responses = await axios.all([emissions, discharges]);
      console.log('data: ', responses);
      return {
        emissions: responses[0].data,
        discharges: responses[1].data,
      };
    } catch (error) {
      return error;
    }
  };

  setData = async (token, { name, values, promoAreaId, company = true }) => {
    let url = '';
    switch (name) {
      case 'coefficient':
        url = `${this.BASE_URL}calculation_factor`;
        break;
      default:
        url = `${this.BASE_URL}${this.STANDARD_TABLE_PATH}insert/${name}`;
    }

    console.log('setData', 'url: ', url);
    console.log(token);
    const info =
      company &&
      name !== 'coefficient' &&
      name !== 'characteristics_of_objects' &&
      name !== 'data_on_location'
        ? { ...values, company_card_id: promoAreaId }
        : values;
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const { data } = await axios.post(url, info, config);
      return data;
    } catch (error) {
      console.error('error: ', error);
      return error;
    }
  };

  setOrderProductionTablesData = async (token, { name, values }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const { data } = await axios.post(
        `${this.BASE_URL}${this.ORDER_PRODUCTION}/${name}/${values.form_id}`,
        values,
        config
      );
      return data;
    } catch (error) {
      console.error('error: ', error);
      return error;
    }
  };
  postGosPekTablesData = async (token, id, { name, values }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    if (name === 'promo_areas') {
      values.user_id = id;
      values.form_id = null;
      values.date_application = moment().format('YYYY-MM-DD');
    }
    try {
      console.log(values);
      const { data } = await axios.post(
        `${this.BASE_URL}${this.GOS_PEK}/${name}`,
        values,
        config
      );
      return data;
    } catch (error) {
      console.error('error: ', error);
      return error;
    }
  };

  postGrvpzTablesData = async (token, { name, values }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      console.log(values);
      const { data } = await axios.post(
        `${this.BASE_URL}${this.GRVPZ_PATH}/${name}`,
        values,
        config
      );
      return data;
    } catch (error) {
      console.error('error: ', error);
      return error;
    }
  };


  postWaterTablesData = async (token, { name, values }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      console.log(values);
      const { data } = await axios.post(
        `${this.BASE_URL}${this.PEK_WATER_PATH}/${name}`,
        values,
        config
      );
      return data;
    } catch (error) {
      console.error('error: ', error);
      return error;
    }
  };

  remove = async (token, { tableName, itemId }) => {
    const url = `${this.BASE_URL}${this.GOS_PEK}/${tableName}?id=${itemId}`;
    console.log('deleteItem', 'url: ', url);
    try {
      return await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.error('error: ', error);
      return error;
    }
  };

  removeOrderProductionTypes = async (token, { tableName, itemId, formId }) => {
    const url = `${this.BASE_URL}${this.ORDER_PRODUCTION}/${tableName}/${formId}/${itemId}`;
    console.log('deleteItem', 'url: ', url);
    try {
      return await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.error('error: ', error);
      return error;
    }
  };

  removeSomeString = async (token, tableName, formId) => {
    const url = `${this.BASE_URL}${this.ORDER_PRODUCTION}/${tableName}/${formId}`;
    console.log('deleteItem', 'url: ', url);
    try {
      return await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.error('error: ', error);
      return error;
    }
  };

  update = async ({ token, tableName, values, itemId }) => {
    const url = `${this.BASE_URL}${this.STANDARD_TABLE_PATH}update/${tableName}?id=${itemId}`;
    console.log('update', 'url: ', url);
    console.log(token);
    const info = values;
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const { data } = await axios.put(url, info, config);
      console.log('success: ', data);
      return data;
    } catch (error) {
      console.error('error: ', error);
      return error;
    }
  };

  updateOrderProductionTypes = async ({
    token,
    tableName,
    values,
    itemId,
    formId,
  }) => {
    const url = `${this.BASE_URL}${this.ORDER_PRODUCTION}/${tableName}/${formId}/${itemId}`;
    const info = values;
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const { data } = await axios.put(url, info, config);
      console.log('success: ', data);
      return data;
    } catch (error) {
      console.error('error: ', error);
      return error;
    }
  };
  upDateGosPekTablesData = async ({
    token,
    tableName,
    values,
    itemId,
    formId,
  }) => {
    const url = `${this.BASE_URL}${this.GOS_PEK}/${tableName}/${formId}/${itemId}`;
    const info = values;
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const { data } = await axios.put(url, info, config);
      console.log('success: ', data);
      return data;
    } catch (error) {
      console.error('error: ', error);
      return error;
    }
  };
}

const {
  getResources,
  setData,
  setOrderProductionTablesData,
  postGosPekTablesData,
  upDateGosPekTablesData,
  getWaterResources,
  postWaterTablesData,
  remove,
  update,
  updateOrderProductionTypes,
  removeOrderProductionTypes,
  getJoinResources,
  getGosPekResources,
  postGrvpzTablesData,
  getGrvpzResources,
  getTaxes,
  getOrderProductionResources,
  removeSomeString,
} = new StandardTableAPI();

export {
  getResources,
  setData,
  removeOrderProductionTypes,
  remove,
  setOrderProductionTablesData,
  postGosPekTablesData,
  getWaterResources,
  postGrvpzTablesData,
  postWaterTablesData,
  getGosPekResources,
  getGrvpzResources,
  upDateGosPekTablesData,
  update,
  updateOrderProductionTypes,
  getOrderProductionResources,
  getJoinResources,
  getTaxes,
  removeSomeString,
};

import React from 'react';

import SoilsTable from './Tables';

const Soils = () => {
  return (
    <div>
      <SoilsTable />
    </div>
  );
};

export default Soils;

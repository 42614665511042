import React from 'react';
import { Button, Form, Icon, Modal } from 'antd';

const EditForm = ({
  visible,
  modalClose,
  onSubmit,
  formRef,
  renderInputs,
  inputs,
}) => {
  return (
    <Modal width="80vw" visible={visible} onCancel={modalClose} footer={[]}>
      <Form ref={formRef} onSubmit={onSubmit}>
        {renderInputs(inputs)}
        <Button.Group>
          <Button type="danger" onClick={modalClose}>
            <Icon type="stop" />
            Отмена
          </Button>
          <Button type="primary" htmlType="submit">
            <Icon type="check" />
            Добавить
          </Button>
        </Button.Group>
      </Form>
    </Modal>
  );
};

export default EditForm;

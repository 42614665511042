import React from 'react';
import { Button, Col, Icon, Modal as BaseModal, Form, Row } from 'antd';

import BoilerForm from './BoilerForm';
import DieselForm from './DieselForm';
import InitForm from './InitForm/InitForm';

const CalculateModal = ({
  visible,
  modalClose,
  modalOpen,
  onSubmit,
  getFieldDecorator,
  method,
  sduStatus,
  setBeforeSdu,
  setAfterSdu,
  fuelType,
  changeFuelType,
  fieldsMeta,
}) => {
  const boilerForm =
    method === 1 ? (
      <BoilerForm
        getFieldDecorator={getFieldDecorator}
        fuelType={fuelType}
        changeFuelType={changeFuelType}
      />
    ) : null;
  const dieselForm =
    method === 2 ? (
      <DieselForm
        getFieldDecorator={getFieldDecorator}
        sduStatus={sduStatus}
        setBeforeSdu={setBeforeSdu}
        setAfterSdu={setAfterSdu}
      />
    ) : null;

  const initForm = <InitForm
    getFieldDecorator={getFieldDecorator}
    fields={fieldsMeta}
  />;

  return (
    <>
        <Button onClick={modalOpen}>Рассчитать</Button>
        <BaseModal
          visible={visible}
          onCancel={modalClose}
          width="80vw"
          footer={false}
        >
          <Form onSubmit={onSubmit}>
            <Row>
              <Col span={23} style={{ marginBottom: 20 }}>
                {boilerForm}
                {dieselForm}
                {initForm}
              </Col>
            </Row>
            <Button.Group style={{ display: 'block' }}>
              <Button type="danger" onClick={modalClose}>
                <Icon type="stop" />
                Отмена
              </Button>
              <Button type="primary" htmlType="submit">
                <Icon type="check" />
                Добавить
              </Button>
            </Button.Group>
          </Form>
        </BaseModal>
    </>
  );
};

export default CalculateModal;

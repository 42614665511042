import React from 'react';
import { Card, Form, Input, Select } from 'antd';

import * as data from './data';
import { fuelTypes } from './data';

const { Option } = Select;

const BoilerForm = ({ getFieldDecorator, fuelType, changeFuelType }) => {
  const renderOptions = options =>
    options.map(({ text, value }) => (
      <Option value={value} key={text + value}>
        {text}
      </Option>
    ));

  const createSelect = fuelType => {
    const options = data[fuelType] ? data[fuelType] : [];
    const opt = renderOptions(options);
    return (
      <Form.Item label="Месторождение и марка">
        {getFieldDecorator('field')(<Select>{opt}</Select>)}
      </Form.Item>
    );
  };

  return (
    <div>
      <p>Исходные данные:</p>
      <Form.Item label="Вид топлива">
        {getFieldDecorator('type_fuel')(
          <Select onChange={type => changeFuelType(type)}>
            {renderOptions(fuelTypes)}
          </Select>
        )}
      </Form.Item>
      <Form.Item label="Расход топлива, т/год (тыс.м3)">
        {getFieldDecorator('fuel_consumption_t_y')(<Input />)}
      </Form.Item>
      <Form.Item label="Расход топлива, г/с (л/с)">
        {getFieldDecorator('fuel_consumption_g_s')(<Input />)}
      </Form.Item>
      {fuelType ? createSelect(fuelType) : null}
      <Form.Item label="Расчет выполнить:">
        {getFieldDecorator('calculation')(
          <Select>
            <Option value="1">По мощности</Option>
            <Option value="2">По паропроизводительности</Option>
          </Select>
        )}
      </Form.Item>
      <Form.Item label="Фактическая мощность котлоагрегата, кВт (т/ч)">
        {getFieldDecorator('power_t_h')(<Input />)}
      </Form.Item>
      <Form.Item label="Кол-во окислов азота, кг/1 Гдж тепла">
        {getFieldDecorator('quantity_nitrogen')(<Input />)}
      </Form.Item>
      <Form.Item label="Коэфф. снижения выбросов азота в рез-те техн. решений">
        {getFieldDecorator('coefficient_quantity_nitrogen')(<Input />)}
      </Form.Item>
      <Form.Item label="По какому коду определены твердые частицы">
        {getFieldDecorator('code')(<Input />)}
      </Form.Item>
    </div>
  );
};

export default BoilerForm;

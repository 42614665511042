import axios from 'axios';
import { BaseAPI } from './BaseAPI';

class JournalAPI extends BaseAPI {
  updateJournalData = async ({ token, type, data }) => {
    const urlEnd = this._getUrlEnd(type);
    const url = `${this.BASE_URL}/${this.ACCOUNTING_PATH}${urlEnd}`;

    return await axios({
      method: 'PUT',
      url,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data,
    })
      .then(data => data)
      .catch(error => {
        console.error(error);
      });
  };
  addJournalData = async ({ token, type, data }) => {
    const urlEnd = this._getUrlEnd(type);
    const url = `${this.BASE_URL}/${this.ACCOUNTING_PATH}${urlEnd}`;

    return await axios({
      method: 'POST',
      url,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data,
    })
      .then(data => data)
      .catch(error => {
        console.error(error);
      });
  };

  getJournalData = async ({ token, type, companyId, year, option }) => {
    const urlEnd = this._getUrlEnd(type);
    const url = `${this.BASE_URL}/${this.ACCOUNTING_PATH}${urlEnd}/?companyId=${companyId}&year=${year}&type=${option}`;

    return await axios({
      method: 'GET',
      url,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then(data => data)
      .catch(error => {
        console.error(error);
      });
  };
  _getUrlEnd = type => {
    if (type === 'добыча') {
      return '/prod';
    } else if (type === 'стоки') {
      return '/drain';
    } else if (type === 'передано') {
      return '/transfer';
    } else if (type === 'использовано') {
      return '/usage';
    }
    return '';
  };
}
const { addJournalData, getJournalData, updateJournalData } = new JournalAPI();
export { addJournalData, getJournalData, updateJournalData };

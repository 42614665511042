import React, { useEffect, useState } from 'react';
import { updateUserPassword } from '../../api/LoginAPI';
import { Button, message } from 'antd';
import { Spin } from 'antd';
import { Input } from 'antd';
import { useParams } from 'react-router';

export default function RecoveryPassword(props) {
  const [loader, setLoader] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const { iv, content } = useParams();

  const recoveryPassword = () => {
    if (!iv || !content) {
      message.error('ID пользователя неправильный');
      return;
    }

    if ((password.length || confirmPassword.length) === 0) {
      message.error('Заполните поля');
      return;
    }
    if (password === confirmPassword) {
      setLoader(true);
      updateUserPassword({ values: { password }, iv, content }).then(res => {
        message.success('Вы изменили пароль');
        setLoader(false);
        setTimeout(() => window.location.replace('/login'), 1000);
      });
    } else {
      message.error('Пароли не совпадают');
    }
  };
  return (
    <div
      style={{
        width: '300px',
        margin: '0 auto',
        marginTop: '10%',
        textAlign: 'center',
      }}
    >
      {loader ? (
        <Spin
          size="large"
          style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
          }}
        />
      ) : (
        <div>
          <h3>Восстановление пароля</h3>
          <div>
            <Input.Password
              style={{ marginBottom: '10px' }}
              placeholder="Password"
              onChange={e => setPassword(e.target.value)}
            />
            <Input.Password
              placeholder="Confirm to password"
              onChange={e => setConfirmPassword(e.target.value)}
            />
          </div>
          <Button
            type="primary"
            style={{
              width: '100%',
              marginTop: '23px',
            }}
            onClick={recoveryPassword}
          >
            Изменить пароль
          </Button>
        </div>
      )}
    </div>
  );
}

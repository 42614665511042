import React from 'react';

import initialDataSource from './data';
import columnList from './data/columns';
import StandardTable from '../../../modules/StandardTable';
import inputs from './data/modalInputs';
import Editor from '../../../modules/Editor';

const values = [
  '1. Охрана воздушного бассейна',
  '2. Охрана и рациональное использование водных ресурсов',
  '3. Охрана от воздействия на прибрежные и водные экосистемы',
  '4. Охрана земельных ресурсов',
  '5. Охрана и рациональное использование недр',
  '6. Охрана флоры и фауны',
  '7. Обращение с отходами производства и потребления',
  '8. Радиационная, биологическая и химическая безопасность',
  '9. Внедрение систем управления и наилучших безопасных технологий',
  '10. Научно-исследовательские, изыскательские и другие разработки',
  '11. Экологическое просвещение и пропаганда',
  'Итого:',
  'Всего:',
];

const tableName = 'plan_secure_nature';

const columns = rounding => (token, refresh) => [
  {
    title: 'Действие',
    key: 'action',
    render: (text, record) => {
      const obj = {
        children: (
          <Editor
            addInputs={inputs}
            editInputs={inputs}
            text={text}
            tableName={tableName}
            record={record}
            refresh={refresh}
          />
        ),
        props: {},
      };

      const column = values.find(el => el === record.number);
      const total = values.find(el => el === record.name);
      if (column) obj.props.colSpan = 0;
      if (total) obj.children = '';
      return obj;
    },
  },
  ...columnList(rounding),
];

const transformDataSource = (data, setState) => {
  let newDataSource = initialDataSource.slice();
  data.forEach((el, i) => {
    const index = newDataSource.findIndex(obj => obj.type == el.type);
    if (index > -1) {
      const newElement = { key: el.id, number: i + 1, ...el };
      newDataSource = [
        ...newDataSource.slice(0, index + 1),
        newElement,
        ...newDataSource.slice(index + 1),
      ];
    }
  });
  setState(newDataSource);
};

const EnvironmentalMeasures = () => (
  <StandardTable
    tableName={tableName}
    inputs={inputs}
    columns={rounding => columns(rounding)}
    transformDataSource={transformDataSource}
    scrollMultiplier={300}
    pagination={{ pageSize: 150 }}
  />
);

export default EnvironmentalMeasures;

import React from 'react';
import { Input, Select } from 'antd';
import { isNumberAndAboveZero } from '../../../../../utils/validation';

const { Option } = Select;
const select = (
  <Select>
    <Option value="1">1. Охрана воздушного бассейна</Option>
    <Option value="2">
      2. Охрана и рациональное использование водных ресурсов
    </Option>
    <Option value="3">
      3. Охрана от воздействия на прибрежные и водные экосистемы
    </Option>
    <Option value="4">4. Охрана земельных ресурсов</Option>
    <Option value="5">5. Охрана и рациональное использование недр</Option>
    <Option value="6">6. Охрана флоры и фауны</Option>
    <Option value="7">
      7. Обращение с отходами производства и потребления
    </Option>
    <Option value="8">
      8. Радиационная, биологическая и химическая безопасность
    </Option>
    <Option value="9">
      9. Внедрение систем управления и наилучших безопасных технологий
    </Option>
    <Option value="10">
      10. Научно-исследовательские, изыскательские и другие разработки
    </Option>
    <Option value="11">11. Экологическое просвещение и пропаганда</Option>
  </Select>
);

export default [
  {
    title: 'Тип',
    dataIndex: 'type_id',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: select,
  },
  {
    title: 'Наименование мероприятия',
    dataIndex: 'name',
    key: 'name',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Объем запланированных работ',
    dataIndex: 'volume_work',
    key: 'volume_work',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Период выполнения мероприятия согласно утвержденному плану',
    dataIndex: 'period_work',
    key: 'period_work',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Запланировано (тысяч тенге)',
    dataIndex: 'planned_tenge',
    key: 'planned_tenge',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: '% освоенных средств',
    dataIndex: 'percent_disburced_funds',
    key: 'percent_disburced_funds',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: '% выполненных работ',
    dataIndex: 'percent_comleted_work',
    key: 'percent_comleted_work',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Экологический эффект от мероприятия ',
    dataIndex: 'ecological_effect',
    key: 'ecological_effect',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Примечание (причины невыполнения)',
    dataIndex: 'note',
    key: 'note',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
];

import React from 'react';
import { Button, Icon } from 'antd';

import EditForm from '../components/EditForm';

const EditButton = ({
  inputs,
  tableName,
  record,
  refresh,
  modalShow,
  visible,
  modalClose,
  dataType,
  editTableType,
}) => {
  return (
    <>
      <Button onClick={modalShow} type="primary">
        <Icon type="edit" />
      </Button>
      <EditForm
        dataType={dataType}
        record={record}
        tableName={tableName}
        visible={visible}
        inputs={inputs}
        modalClose={modalClose}
        refresh={refresh}
        editTableType={editTableType}
      />
    </>
  );
};

export default EditButton;

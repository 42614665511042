import React from 'react';
import { DatePicker, Input, Select } from 'antd';
import moment from 'moment';
import { isNumberAndAboveZero } from '../../../../../../utils/validation';

export default [
  {
    title: 'Точки отбора проб ',
    dataIndex: 'sampling_points',
    key: 'sampling_points',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Широта',
    dataIndex: 'latitude',
    key: 'latitude',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Долгота',
    dataIndex: 'longitude',
    key: 'longitude',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Код вещества',
    dataIndex: 'substance_code',
    key: 'substance_code',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Наименование загрязняющих веществ',
    dataIndex: 'name_pollutants',
    key: 'name_pollutants',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Предельно допустимая концентрация (максимально разовая, мг/м3)',
    dataIndex: 'maximum_allowable_concentration',
    key: 'maximum_allowable_concentration',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Фактическая концентрация, мг/м3',
    dataIndex: 'actual_concentration',
    key: 'actual_concentration',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Наличие превышения предельно допустимых концентраций, кратность',
    dataIndex: 'existence_exceeding_concentration',
    key: 'existence_exceeding_concentration',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title:
      'Мероприятия по устранению нарушений и улучшению экологической обстановки (с указанием сроков)',
    dataIndex: 'measures_improve_environmental_situation',
    key: 'measures_improve_environmental_situation',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
];

import React, { useEffect, useRef } from 'react';
import { Form, message, Select } from 'antd';
import { connect, useSelector } from 'react-redux';
import moment from 'moment';

import EditFormContainer from '../containers/EditForm';
import compose from '../../../hoc/compose';
import { update as superUpdate } from '../../../api/SuperTableAPI';
import {
  update as standardUpdate,
  upDateGosPekTablesData,
  updateOrderProductionTypes,
} from '../../../api/StandardTableAPI';
import { update as updateDischarge } from '../../../api/DischargeAPI';
import { update as updateWaste } from '../../../api/WasteAPI';
import { upDateGrvpzTablesData } from '../../../api/GRVPZApi';

const dataSender = async ({
  editTableType,
  token,
  tableName,
  values,
  itemId,
  year,
  period,
  sourceId,
  promoAreaId,
  dataType,
  dataId,
  formId,
}) => {
  console.log(editTableType);
  switch (editTableType) {
    case 'super':
      return await superUpdate({
        token,
        tableName,
        values,
        itemId,
        year,
        period,
        sourceId,
        promoAreaId,
        dataType,
      });
    case 'standard':
      return await standardUpdate({ token, tableName, values, itemId });
    case 'discharge':
      return await updateDischarge({
        dataId,
        itemId,
        tableName,
        sourceId,
        token,
        values,
        period,
        year,
        promoAreaId,
      });
    case 'waste':
      return await updateWaste({
        token,
        values,
        period,
        year,
        promoAreaId,
        itemId,
      });
    case 'order_production_tables':
      return await updateOrderProductionTypes({
        token,
        tableName,
        values,
        itemId,
        formId,
      });
    case 'gos_pek':
      return await upDateGosPekTablesData({
        token,
        tableName,
        values,
        itemId,
        formId,
      });
    case 'rvpz_pek':
      return await upDateGrvpzTablesData({
        token,
        tableName,
        values,
        itemId,
      });
    case 'pek_water':
      return await upDateGosPekTablesData({
        token,
        tableName,
        values,
        itemId,
        formId,
      });

    default:
      return;
  }
};

const EditForm = ({
  token,
  promoAreaId,
  year,
  quarter,
  form,
  visible,
  modalClose,
  tableName,
  refresh,
  record,
  inputs = [],
  editTableType,
}) => {
  console.log('WWS', record);
  const { getFieldDecorator, validateFields, setFieldsValue } = form;
  const formRef = useRef(null);
  const { orderProductionId } = useSelector(state => ({
    ...state.userStore,
  }));

  const transformDateToMomentObject = async () => {
    let newRecord = { ...record };
    console.log('CHECKIIING');
    for (let prop in record) {
      const isDate = moment(record[prop], moment.ISO_8601).isValid();
      console.log('isDate', isDate);
      if (isDate) newRecord[prop] = moment(record[prop]);
    }

    return newRecord;
  };

  useEffect(() => {
    async function setFields() {
      const newRecord = await transformDateToMomentObject();
      console.log('NEW RECORD', newRecord);
      setFieldsValue(newRecord);
    }

    setFields();
  }, [record]);

  const renderInputs = (inputs, firstTitle = '') => {
    return inputs.map(
      ({
        title,
        dataIndex,
        children,
        component,
        rules = [],
        initialValue = '1',
        displayNone,
      }) => {
        if (children) return renderInputs(children, title);

        let text = title
          .toLowerCase()
          .trim()
          .replace(/\W{1}/, str => str.toUpperCase());

        const style = displayNone ? { display: 'none' } : {};

        return (
          <div key={dataIndex} style={style}>
            {firstTitle && <p>{firstTitle}</p>}
            <Form.Item label={text}>
              {getFieldDecorator(dataIndex, {
                initialValue,
                rules,
              })(component)}
            </Form.Item>
          </div>
        );
      }
    );
  };

  const onSubmit = evt => {
    evt.preventDefault();
    validateFields(async (err, values) => {
      console.table('values', values);
      if (!err && token) {
        console.log('record: ', record);
        const { id: itemId, source_id, data_type, data_id: dataId } = record;

        dataSender({
          dataId,
          token,
          editTableType,
          tableName,
          values,
          itemId,
          year,
          period: quarter,
          sourceId: source_id,
          promoAreaId,
          dataType: data_type,
          formId: orderProductionId,
        })
          .then(() => {
            refresh();
            modalClose();
            message.success('Данные отправлены успешно!');
          })
          .catch(error => {
            modalClose();
            message.error('Что-то пошло не так!');
            console.error(error);
          });
      }
      err && message.error('Форма заполнена не правильно!');
    });
  };

  const props = {
    visible,
    modalClose,
    onSubmit,
    formRef,
    inputs,
  };

  return <EditFormContainer {...props} renderInputs={renderInputs} />;
};

const mapStateToProps = ({
  userStore: { token },
  companyStore: { promoAreaId },
  pekDateStore: { year, quarter },
  substanceStore: { substance },
}) => ({ token, promoAreaId, year, quarter, substance });

export default compose(connect(mapStateToProps), Form.create())(EditForm);

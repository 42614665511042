import { BaseAPI } from './BaseAPI';
import axios from 'axios';

class ApplicationForm extends BaseAPI {
  createApplicationForm = ({ values, token }) =>
    axios({
      method: 'POST',
      url: `${this.BASE_URL}${this.ORDER_PRODUCTION}`,
      data: values,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    });

  updateApplicationForm = ({ values, id, token }) =>
    axios({
      method: 'POST',
      url: `${this.BASE_URL}${this.ORDER_PRODUCTION}?id=${id}`,
      data: values,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    });

  getApplicationForm = ({ token }) =>
    axios({
      method: 'GET',
      url: `${this.BASE_URL}${this.ORDER_PRODUCTION}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

  uploadFiles = async ({ token, tableName, tableType, formId, file }) => {
    const formData = new FormData();
    formData.append('file', file);
    await axios({
      method: 'POST',
      url: `${this.BASE_URL}order_production/file/${tableType}/${formId}?tableType=${tableType}&form_id=${formId}`,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
      data: formData,
    })
      .then(data => data)
      .catch(error => console.error(error));
  };

  fetchOrderTypes = async () =>
    await axios({
      method: 'GET',
      url: `${this.BASE_URL}${this.ORDER_TYPES}`,
    })
      .then(({ data }) => data)
      .catch(error => {
        console.error(error);
      });

  fetchOrderTypesDetails = async () =>
    await axios({
      method: 'GET',
      url: `${this.BASE_URL}${this.ORDER_TYPES_DETAIL}`,
    })
      .then(({ data }) => data)
      .catch(error => {
        console.error(error);
      });
}

const {
  createApplicationForm,
  fetchOrderTypes,
  fetchOrderTypesDetails,
  getApplicationForm,
  updateApplicationForm,
  uploadFiles,
} = new ApplicationForm();

export {
  createApplicationForm,
  getApplicationForm,
  fetchOrderTypes,
  fetchOrderTypesDetails,
  updateApplicationForm,
  uploadFiles,
};

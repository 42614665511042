import { Button, Icon, message, Popconfirm, Table, Typography } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

const values = ['Единицы', 'Единицы внутренных сокращений', 'Всего'];

const renderContent = (value, row, index) => {
  let target = values.find(value => value === row.number);
  console.log(target);
  if (!target) target = '';

  const obj = {
    children: value,
    props: {},
  };
  if (row.number === target) {
    obj.props.colSpan = 0;
  }
  return obj;
};

const InstallationPassport = ({ data }) => {
  const columns = [
    {
      title: 'Код строки',
      //dataIndex: 'rowCode',
      render: renderContent,
    },
    {
      title: 'Наименование показателей',
      children: [
        {
          title: 'Наименование установки',
          //dataIndex: 'start_date',
          key: 'start_date1',
          render: renderContent,
        },
        {
          title: '№ источника',
          //dataIndex: 'finish_date',
          key: 'finish_date2',
          render: renderContent,
        },
        {
          title: 'Периодичность в соответствии с планом мониторинга',
          //dataIndex: 'finish_date',
          key: 'finish_date3',
          render: renderContent,
        },
        {
          title: 'Отклонения от плана мониторинга',
          //dataIndex: 'finish_date',
          key: 'finish_date4',
          render: renderContent,
        },
        {
          title: 'Причины отклонения',
          //dataIndex: 'finish_date',
          key: 'finish_date5',
          render: renderContent,
        },
        {
          title: 'Примечание',
          //dataIndex: 'finish_date',
          key: 'finish_date6',
          render: renderContent,
        },
      ],
    },
  ];

  return (
    <div>
      <Table
        pagination={{ pageSize: 25 }}
        //dataSource={dataSource}
        columns={columns}
        scroll={{ x: 'calc(1500px + 50%)' }}
        bordered
      />
    </div>
  );
};

export default InstallationPassport;

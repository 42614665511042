import { Input, Select } from 'antd';
import React from 'react';
import { boilerConstants } from '../../../../constants';

export default {
  boiler: [
    {
      title: 'Расход топлива за период ',
      dataIndex: 'bm',
      key: 'bm',
      rules: [
        {
          required: true,
          message: 'Данные обязательны для заполнения',
        },
      ],
      component: <Input />,
    },
    {
      title: 'Вид топлива ',
      dataIndex: 'qr',
      key: 'qr',
      rules: [
        {
          required: true,
          message: 'Данные обязательны для заполнения',
        },
        ({ getFieldValue }) => {
          console.log(parseFloat(getFieldValue('bm')));
          return {
            validator(rule, value, callback) {
              try {
                if (parseFloat(getFieldValue('bm')) < 100) {
                  rule.message = 'Значение должно быть больше 100!';
                }

                callback(rule.message);
              } catch (err) {
                callback(err);
              }
            },
          };
        },
      ],
      component: (
        <Select>
          {boilerConstants.map(option => (
            <Select.Option
              key={option.name}
              value={option.recalculateCoefficent}
            >
              {option.name}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      title: 'удельный коэффициент выбросов СО2',
      dataIndex: 'b',
      key: 'b',
      rules: [
        {
          required: true,
          message: 'Данные обязательны для заполнения',
        },
      ],
      component: <Input />,
    },
    {
      title: 'удельный коэффициент выбросов СH4',
      dataIndex: 'e',
      key: 'e',
      rules: [
        {
          required: true,
          message: 'Данные обязательны для заполнения',
        },
      ],
      component: <Input />,
    },
    {
      title: 'удельный коэффициент выбросов N2O',
      dataIndex: 'h',
      key: 'h',
      rules: [
        {
          required: true,
          message: 'Данные обязательны для заполнения',
        },
      ],
      component: <Input />,
    },
  ],
};

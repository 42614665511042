import React, { useEffect, useState } from 'react';
import { YMaps, Map, Placemark } from 'react-yandex-maps';
import { Alert, Button, Form, Input, message, Select, Spin } from 'antd';
import {
  fetchOrderTypes,
  fetchOrderTypesDetails,
  updateApplicationForm,
} from '../../../api/ApplicationForm';
import compose from '../../../hoc/compose';
import { connect } from 'react-redux';
import { createApplicationForm } from '../../../api/ApplicationForm';
import WithLoader from '../../../hoc/withLoader';
import config from '../../../config';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 11 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 13 },
  },
};

const General = ({
  generalInfo,
  form,
  user,
  token,
  refresh,
  region,
  company,
}) => {
  const [selectedOrderType, setSelectedOrderType] = useState(null);
  const [orderTypes, setOrderTypes] = useState([]);
  const [placemarks, setPlacemarks] = useState([]);
  const [selectedOrderTypeDetails, setSelectedOrderTypeDetails] = useState(
    null
  );
  const [loader, setLoader] = useState(false);
  const [orderTypesDetails, setOrderTypesDetails] = useState([]);
  const [showTypesDetails, setShowTypesDetails] = useState([]);
  const [hasBuilding, setHasBuilding] = useState('no');
  const { getFieldDecorator, validateFields, setFieldsValue } = form;
  const [conclusionFile, setConclusionFile] = useState(null);
  const [resolutionFile, setResolutionFile] = useState(null);
  const { Option } = Select;

  const setConclusionFileCheck = file => {
    if ((file.size / 1024 / 1024).toFixed(4) > 50) {
      message.error('Файл больше 50мб');
      return;
    }
    setConclusionFile(file);
  };

  const setResolutionFileCheck = file => {
    if ((file.size / 1024 / 1024).toFixed(4) > 50) {
      message.error('Файл больше 50мб');
      return;
    }
    setResolutionFile(file);
  };

  useEffect(() => {
    if (generalInfo) {
      setFieldsValue(generalInfo);
      if (generalInfo?.coordinates && generalInfo?.coordinates !== 'undefined')
        setPlacemarks([{ geo: JSON.parse(generalInfo.coordinates) }]);
    }
  }, generalInfo);
  const onSubmit = evt => {
    const formData = new FormData();
    evt.preventDefault();
    if (placemarks.length === 0) {
      message.error('Выберите точку на карте !');
      return;
    }
    validateFields((err, values) => {
      console.log(values);
      err && message.error('Ошибка в заполнении формы!');

      Object.keys(values).forEach(key => {
        formData.append(key, values[key]);
      });
      formData.append('coordinates', JSON.stringify(placemarks[0]?.geo));
      formData.append('username', user.username.username);
      formData.append('conclusion_file', conclusionFile);
      formData.append('resolution_file', resolutionFile);

      if (generalInfo) {
        formData.append('id', generalInfo.id);
      }

      console.log(formData);

      if (!err) {
        setLoader(true);
        const request = generalInfo
          ? updateApplicationForm({
              values: formData,
              id: generalInfo.id,
              token,
            })
          : createApplicationForm({
              values: formData,
              token,
            });
        request
          .then(() => {
            message.success('Успешно !');
            refresh();
          })
          .catch(err => {
            console.log(err);
            message.error('Произошла ошибка !');
          })
          .finally(() => {
            setLoader(false);
          });
      }
    });
  };
  console.log(loader);

  const renderActionButton = () => {
    return (
      <Button type="primary" htmlType="submit">
        Сохранить
      </Button>
    );
  };

  useEffect(() => {
    fetchOrderTypes().then(setOrderTypes);
    fetchOrderTypesDetails().then(setOrderTypesDetails);
  }, []);

  useEffect(() => {
    if (!selectedOrderType) {
      setShowTypesDetails(orderTypesDetails);
    } else {
      const filtered = orderTypesDetails?.filter(detail => {
        console.log(detail, selectedOrderType);
        return detail.order_production_types_id === selectedOrderType;
      });
      setShowTypesDetails(filtered);
    }
  }, [selectedOrderType, orderTypesDetails]);

  function onSearch(val) {
    console.log('search:', val);
  }
  return (
    <div>
      {loader ? <WithLoader /> : null}
      <Form {...formItemLayout} defaultValue={generalInfo} onSubmit={onSubmit}>
        <Form.Item label="Наименование объекта">
          {getFieldDecorator('name', {
            rules: [
              { required: true, message: 'Поле обязательно для заполнения' },
            ],
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Информация о виде (видах) и сроках осуществления деятельности предприятия">
          {getFieldDecorator('detail_information', {
            rules: [
              { required: true, message: 'Поле обязательно для заполнения' },
            ],
          })(<Input />)}
        </Form.Item>
        <Form.Item
          required={true}
          label="Географическое и административное расположение объекта"
        >
          <YMaps>
            <Map
              width={600}
              defaultState={{ center: [43.25, 76.92], zoom: 9 }}
              onClick={e => setPlacemarks([{ geo: e.get('coords') }])}
            >
              {placemarks.map((placemark, index) => (
                <Placemark geometry={placemark.geo} key={index} />
              ))}
            </Map>
          </YMaps>
        </Form.Item>
        <Form.Item label="Отрасль (часть отрасли) производства">
          {getFieldDecorator('order_production_type_id', {
            rules: [
              { required: true, message: 'Поле обязательно для заполнения' },
            ],
          })(
            <Select onSelect={setSelectedOrderType}>
              {orderTypes?.map((orderType, index) => (
                <Select.Option value={orderType?.id} key={index}>
                  {orderType?.name}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="Краткая характеристика технологического процесса: ">
          {getFieldDecorator('order_production_type_detail_id', {
            rules: [
              { required: true, message: 'Поле обязательно для заполнения' },
            ],
          })(
            <Select
              onSearch={onSearch}
              showSearch
              onSelect={(_, event) => {
                setSelectedOrderTypeDetails(event.props.children);
              }}
              filterOption={(input, option) => {
                console.log(option);
                return (
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                );
              }}
            >
              {showTypesDetails?.map((orderTypeDetail, index) => (
                <Select.Option value={orderTypeDetail?.id} key={index}>
                  {/* {/\d/.test(orderTypeDetail?.name) === true
                    ? null
                    : orderTypeDetail?.name} */}
                  {orderTypeDetail?.name}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="Есть ли строительные операций на объекте">
          {getFieldDecorator('has_building')(
            <Select onSelect={setHasBuilding}>
              <Select.Option value="no">Нет</Select.Option>
              <Select.Option value="more_than_year">
                Продолжительностью более одного года
              </Select.Option>
              <Select.Option value="less_than_year">
                Продолжительностью менее одного года
              </Select.Option>
            </Select>
          )}
        </Form.Item>
        <Form.Item required={true} label="Заключения ГЭЭ (максимум 50мб)">
          {getFieldDecorator('conclusion_file', {
            rules: [
              {
                required: false,
                message: 'Поле обязательно для заполнения',
              },
            ],
          })(
            <>
              {generalInfo?.conclusion_file && (
                <a
                  onClick={() =>
                    window.open(
                      `${config.api_url}${generalInfo?.conclusion_file}`
                    )
                  }
                >
                  {generalInfo?.conclusion_file}
                </a>
              )}
              <div>
                <input
                  type="file"
                  onChange={e => setConclusionFileCheck(e.target.files[0])}
                />
              </div>
            </>
          )}
        </Form.Item>
        <Form.Item
          required={true}
          label="Разрешение на эмиссии (максимум 30мб)"
        >
          {getFieldDecorator('resolution_file', {
            rules: [
              {
                required: false,
                message: 'Поле обязательно для заполнения',
              },
            ],
          })(
            <>
              {generalInfo?.resolution_file && (
                <a
                  onClick={() =>
                    window.open(
                      `${config.api_url}${generalInfo?.resolution_file}`
                    )
                  }
                >
                  {generalInfo?.resolution_file}
                </a>
              )}
              <div>
                <input
                  type="file"
                  onChange={e => setResolutionFileCheck(e.target.files[0])}
                />
              </div>
            </>
          )}
        </Form.Item>
        {renderActionButton()}
      </Form>
    </div>
  );
};

const mapStateToProps = store => ({
  user: store.userStore.user,
});

const mapDispatchToProps = {};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  Form.create()
)(General);

import React from 'react';

import renderUnnecessaryRow from '../../../utils/renderUnnecessaryRow';
import staticData from './data/os';
import columnList from './data/columns';
import StandardTable from '../../../modules/StandardTable';
import inputs from './data/modalInputs';
import Editor from '../../../modules/Editor';

const tableName = 'statistic_water_os';

const columns = rounding => (token, refresh) => [
  {
    title: 'Действие',
    key: 'action',
    render: (text, record) => {
      const condition = record.code_string === 'А';
      const obj = {
        children: condition ? null : (
          <Editor
            addInputs={inputs}
            editInputs={inputs}
            text={text}
            tableName={tableName}
            record={record}
            refresh={refresh}
          />
        ),
        props: {},
      };
      // if () obj.children = 9;
      return obj;
    },
  },
  ...columnList,
];

const transformDataSource = (data, setState) => {
  const newDataSource = [renderUnnecessaryRow(columnList, staticData)];
  data.forEach(el =>
    newDataSource.push({
      key: el.id,
      ...el,
    })
  );
  setState(newDataSource);
};

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
  wrapperCol: {
    xs: { span: 18 },
    sm: { span: 8 },
  },
};

const OS = () => (
  <StandardTable
    tableName={tableName}
    inputs={inputs}
    columns={columns}
    formItemLayout={formItemLayout}
    transformDataSource={transformDataSource}
    scrollMultiplier={250}
  />
);

export default OS;

import React, { useState } from 'react';

import MainWrapperContainer from '../containers/MainWrapper';

const MainWrapper = ({ children }) => {
  const [collapsed, setCollapsed] = useState(true);
  const toggle = () => {
    setCollapsed(prevState => !prevState);
  };

  const props = {
    children,
    collapsed,
    toggle,
  };
  return <MainWrapperContainer {...props} />;
};

export default MainWrapper;

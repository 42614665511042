import React from 'react';
import { DatePicker, Input, Select } from 'antd';
import moment from 'moment';
import { isNumberAndAboveZero } from '../../../utils/validation';

export default [
  {
    title: 'Вид отхода',
    dataIndex: 'waste_type',
    key: 'waste_type',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Объем, накопленных отходов на начало отчетного года (т)',
    dataIndex: 'waste_volume_start',
    key: 'waste_volume_start',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Код отхода в соответствии с классификатором отходов*',
    dataIndex: 'waste_code',
    key: 'waste_code',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Вид операции, которому подвергается отход ("У"/ "В")',
    dataIndex: 'operation_type',
    key: 'operation_type',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Остаток отходов на конец отчетного года (т)',
    dataIndex: 'waste_volume_end',
    key: 'waste_volume_end',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
];

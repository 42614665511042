import {
  Button,
  Icon,
  Input,
  message,
  Tabs,
  Upload,
  Modal,
  Select,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Route, Switch, useHistory } from 'react-router';
import { compose } from 'redux';
import { getGrvpzCodeData, getPekGrvpzList } from '../../api/GRVPZApi';
import { RegistrationApi } from '../../api/RegistrationApi';
import Configurator from '../../modules/DateConfigurator/components/Configurator';
import { selectGrvpzData } from '../../redux/actions/grvpzActions';
import Air from './Air/Air';
import Drains from './Drains/Drains';
import GeneralInformation from './GeneralInformation';
import GRVPZHistory from './GRVPZHistory';
import GRVPZObjects from './GRVPZObjects';
import WasteEndVolume from './WasteEndVolume/WasteEndVolume';
import WasteTransportation from './WasteTransportation/WasteTransportation';
import WasteVolume from './WasteVolume/WasteVolume';

const { TabPane } = Tabs;

function GRVPZ({ year, user, token, currentPromo }) {
  const [grvpzList, setGrvpzList] = useState();
  const history = useHistory();
  const dispatch = useDispatch();

  const getGrvpzList = async () => {
    getPekGrvpzList({
      token: token,
      id: user.username.id,
      year: year,
    })
      .then(({ data }) => {
        setGrvpzList(data);
        message.success('Данные загружены !');
      })
      .catch(err => {
        message.error('Произошла ошибка !');
      });
  };

  useEffect(() => {
    getGrvpzList();
  }, [year]);

  useEffect(() => {
    getGrvpzCodeData({ token }).then(res =>
      dispatch(selectGrvpzData(res.data))
    );
  }, []);

  console.log('CP', currentPromo);

  return (
    <div>
      <Route
        path="/"
        component={() => <Configurator onlyYear={true} />}
        exact
      />

      <Tabs>
        <TabPane tab="Общие сведения" key="1">
          <GeneralInformation refresh={getGrvpzList} report={grvpzList} />
        </TabPane>
        <TabPane tab="Объекты" key="2">
          <Route path="/" component={GRVPZObjects} exact />
          <Route
            path="/:id"
            exact
            component={() => (
              <>
                <Button onClick={() => history.goBack()}>Назад</Button>
                <Tabs>
                  <TabPane tab="Атмосфера" key="2-1">
                    <Air tableName="rvpz_air" />
                  </TabPane>
                  <TabPane tab="Приложение 2 стоки" key="2-2">
                    <Drains tableName="rvpz_drains" />
                  </TabPane>

                  <TabPane
                    tab="Перенос загрязнителей в сточных водах за пределы участка"
                    key="2-4"
                  >
                    <WasteTransportation tableName="rvpz_drains_transport" />
                  </TabPane>
                  <TabPane tab="Данные об объемах отходов" key="2-5">
                    <WasteVolume tableName="rvpz_waste_volume" />
                  </TabPane>
                  <TabPane
                    tab="Данные об отходах, выявленных в отчетном году"
                    key="2-6"
                  >
                    <WasteEndVolume tableName="rvpz_waste_end_volume" />
                  </TabPane>
                </Tabs>
              </>
            )}
          />
        </TabPane>
        <TabPane tab="История отчета" key="3">
          <GRVPZHistory />
        </TabPane>
      </Tabs>
    </div>
  );
}

const mapStateToProps = ({
  userStore: { token, user },
  pekDateStore: { order, year },
  grvpzStore: { currentPromo },
}) => ({ token, user, order, year, currentPromo });

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps))(GRVPZ);

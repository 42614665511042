import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Typography } from 'antd';
import inputs from './inputs';
import StandardTable from '../../../modules/StandardTable';
import Editor from '../../../modules/Editor';

const Drains = ({ substance, token, tableName }) => {
  const transformDataSource = (data, setState) => {
    const newDataSource = [];
    data.forEach(el =>
      newDataSource.push({
        key: el.id,
        ...el,
      })
    );
    setState(newDataSource);
  };
  const columns = rounding => (token, refresh) => {
    const columns = [
      {
        title: 'Действие',
        key: 'action',
        render: (text, record) => (
          <Editor
            addInputs={inputs}
            editInputs={inputs}
            text={text}
            tableName={tableName}
            editTableType={'rvpz_pek'}
            record={record}
            refresh={refresh}
          />
        ),
        width: 130,
      },
      { title: '№ п/п', dataIndex: 'id', key: 'id', width: 100 },
      {
        title: 'Категория (группа) веществ*',
        dataIndex: 'category',
        key: 'category',
        width: 200,
      },
      {
        title: 'Номер по CAS',
        dataIndex: 'cas_number',
        key: 'cas_number',
        width: 200,
      },
      {
        title: 'Загрязнитель',
        dataIndex: 'emission_name',
        key: 'emission_name',
        width: 200,
      },
      {
        title: 'Код ЗВ',
        dataIndex: 'emission_code',
        key: 'emission_code',
        width: 100,
      },
      {
        title:
          'Пороговые значения выбросов в воздух по отраслям промышленности (видам деятельности), кг/год',
        children: [
          {
            title: 'Энергетика',
            dataIndex: 'energy_limit',
            key: 'energy_limit',
            width: 200,
          },
          {
            title: 'Производство и обработка металлов',
            dataIndex: 'metal_limit',
            key: 'metal_limit',
            width: 200,
          },
          {
            title: 'Промышленность по переработке минерального сырья',
            dataIndex: 'mineral_limit',
            key: 'mineral_limit',
            width: 200,
          },
          {
            title: 'Химическая промышленность',
            dataIndex: 'chemical_limit',
            key: 'chemical_limit',
            width: 200,
          },
          {
            title: 'Управление отходами и сточными водами',
            dataIndex: 'discharge_waste_limit',
            key: 'discharge_waste_limit',
            width: 200,
          },
          {
            title: 'Производство и обработка бумаги и древесины',
            dataIndex: 'paper_wood_limit',
            key: 'paper_wood_limit',
            width: 200,
          },
          {
            title: 'Интенсивное животноводство и аквакультура',
            dataIndex: 'animal_aqua_limit',
            key: 'animal_aqua_limit',
            width: 200,
          },
          {
            title: 'Пищевая промышленность',
            dataIndex: 'food_limit',
            key: 'food_4limit',
            width: 200,
          },
        ],
      },
    ];
    return columns;
  };

  return (
    <div>
      <Typography.Title level={4}>Приложение 2 стоки</Typography.Title>
      <StandardTable
        inputs={inputs}
        tableName={tableName}
        tableType={'rvpz_pek'}
        columns={rounding => columns(rounding)}
        transformDataSource={transformDataSource}
        dataSource={[]}
      />
    </div>
  );
};

const mapStateToProps = ({
  substanceStore: { substance },
  userStore: { token },
  pekDateStore: { year, quarter },
}) => ({
  substance,
  token,
  year,
  quarter,
});

export default connect(mapStateToProps)(Drains);

const notEditableStatuses = ['send', 'accepted'];

const checkEditableOrder = order => {
  const isEditable = !notEditableStatuses.includes(order?.status);
  return isEditable;
};

const filterGosPekColumnsByOrderStatus = (columns = [], order) => {
  const isOrderEditable = checkEditableOrder(order);

  if (isOrderEditable) return columns;

  const filteredColumns = columns.filter(column => column?.key !== 'action');

  return filteredColumns;
};

export { checkEditableOrder, filterGosPekColumnsByOrderStatus };

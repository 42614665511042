import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Button, message, Modal, Select, Table, Typography } from 'antd';
import inputs from './inputs';
import StandardTable from '../../../modules/StandardTable';
import Editor from '../../../modules/Editor';
import { Controller, useForm } from 'react-hook-form';
import FormItem from 'antd/lib/form/FormItem';
import { postGrvpzTablesData } from '../../../api/StandardTableAPI';
import { useParams } from 'react-router';
import { getGrvpzAtmosphere } from '../../../api/GRVPZApi';

const { Option } = Select;

const Air = ({ substance, token, tableName }) => {
  const [atmosphere, setAtmosphere] = useState([]);
  const params = useParams();
  const [visibleAtmosphereAdd, setVisibleAtmosphereAdd] = useState(false);

  const { airData } = useSelector(state => ({
    ...state.grvpzStore,
  }));

  const { control, handleSubmit, reset, errors } = useForm();

  const refresh = () => {
    getGrvpzAtmosphere({ token, promoId: params.id })
      .then(res => {
        setAtmosphere(res.data);
      })
      .catch(() => {
        message.error('Произошла ошибка!');
      });
  };

  useEffect(() => {
    refresh();
  }, []);

  const onSubmit = async data => {
    console.log('DA~RA', data);
    setVisibleAtmosphereAdd(false)
    postGrvpzTablesData(token, {
      name: 'rvpz_atmosphere',
      values: { ...data, rvpz_list_id: params.id },
    })
      .then(() => {
        refresh();
        message.success('Успешно добавлено!');
      })
      .catch(() => {
        message.error('Произошла ошибка!');
      });
  };

  const columns = [
    {
      title: 'Действие',
      key: 'action',
      render: (text, record) => (
        <Editor
          addInputs={inputs}
          editInputs={inputs}
          editable={false}
          text={text}
          tableName={'rvpz_atmosphere'}
          editTableType={'rvpz_pek'}
          record={record}
          refresh={refresh}
        />
      ),
      width: 130,
    },
    { title: '№ п/п', dataIndex: 'id', key: 'id', width: 100 },
    {
      title: 'Номер по CAS',
      dataIndex: 'cas_number',
      key: 'cas_number',
      width: 200,
    },
    {
      title: 'Категория (группа) веществ*',
      dataIndex: 'category',
      key: 'category',
      width: 200,
    },
    {
      title: 'Загрязнитель',
      dataIndex: 'emission_name',
      key: 'emission_name',
      width: 200,
    },
    {
      title: 'Код ЗВ',
      dataIndex: 'emission_code',
      key: 'emission_code',
      width: 100,
    },
    {
      title:
        'Тип методологии, использовавшейся для получения информации о количестве загрязнителей с указанием того, на чем основана информация',
      dataIndex: 'method_type',
      key: 'method_type',
      width: 300,
    },
  ];

  const expandedRowRender = row => {
    console.log('AHHAHHAHAH', row);
    const columns = [
      {
        title: 'Наименование источника',
        dataIndex: 'source_name',
        key: 'source_name',
      },
      { title: 'всего (плановые)', dataIndex: 'total', key: 'total' },
      {
        title: 'в результате аварии',
        dataIndex: 'after_crash',
        key: 'after_crash',
      },
    ];

    const data = [];
    for (let i = 0; i < 3; ++i) {
      data.push({
        key: i,
        source_name: `Источник ${i}`,
        total: i,
        after_crash: i,
      });
    }
    return <Table columns={columns} dataSource={data} pagination={false} />;
  };

  return (
    <div>
      <Typography.Title level={4}>Атмосфера</Typography.Title>
      <Table
        columns={columns}
        expandedRowRender={expandedRowRender}
        dataSource={atmosphere}
      />
      <Button type="primary" onClick={() => setVisibleAtmosphereAdd(true)}>Добавить</Button>
      <Modal
        visible={visibleAtmosphereAdd}
        okButtonProps={{ disabled: !!Object.keys(errors).length }}
        onOk={handleSubmit(onSubmit)}
        onCancel={() => setVisibleAtmosphereAdd(false)}
      >
        <FormItem label="Загрязнитель">
          <Controller
            as={
              <Select
                placeholder=""
                showSearch
                style={{ width: 450 }}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {airData.map(item => {
                  return <Option value={item.id}>{item.emission_name}</Option>;
                })}
              </Select>
            }
            name="rvpz_air_id"
            control={control}
            rules={{
              required: true,
            }}
          />
        </FormItem>

        <FormItem label="Тип методологии">
          <Controller
            as={
              <Select placeholder="" style={{ width: 450 }}>
                <Option value="Измерение">Измерение</Option>
                <Option value="Расчет">Расчет</Option>
                <Option value="Оценка">Оценка</Option>
              </Select>
            }
            name="method_type"
            control={control}
            rules={{
              required: true,
            }}
          />
        </FormItem>
        {Object.keys(errors).length > 0 && (
          <div
            style={{ color: 'red', position: 'absolute', marginTop: '-10px' }}
          >
            Заполните форму правильно !
          </div>
        )}
      </Modal>
    </div>
  );
};

const mapStateToProps = ({
  substanceStore: { substance },
  userStore: { token },
  pekDateStore: { year, quarter },
}) => ({
  substance,
  token,
  year,
  quarter,
});

export default connect(mapStateToProps)(Air);

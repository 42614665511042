import { BaseAPI } from './BaseAPI';
import axios from 'axios';

class PekAPI extends BaseAPI {
  KATO_PATH = 'api/kato/';
  SOURCE_TYPE_PATH = 'api/source-type/';
  GENERAL_INFO_PEK_PATH = 'api/gos-pek/general';
  ORDER_LIST_PEK_PATH = 'api/gos-pek/order-list';

  getKatoSelectOptions = ({ token }) =>
    axios({
      method: 'GET',
      url: `${this.BASE_URL}${this.KATO_PATH}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

  getSourceTypeSelectOptions = ({ token }) =>
    axios({
      method: 'GET',
      url: `${this.BASE_URL}${this.SOURCE_TYPE_PATH}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

  getPekGeneralInformation = ({ token, id }) =>
    axios({
      method: 'GET',
      url: `${this.BASE_URL}${this.GENERAL_INFO_PEK_PATH}/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

  getPekOrderList = ({ token, id, quarter, year }) =>
    axios({
      method: 'GET',
      url: `${this.BASE_URL}${this.ORDER_LIST_PEK_PATH}/${id}?year=${year}&quarter=${quarter}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

  savePekOrderList = ({ token, values }) =>
    axios({
      method: 'POST',
      url: `${this.BASE_URL}${this.ORDER_LIST_PEK_PATH}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: values,
    });

  getPromoAreaById = ({ token, id }) =>
    axios({
      method: 'GET',
      url: `${this.BASE_URL}${this.GOS_PEK}/promo/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

  getMonitoringById = ({ token, id, year, quarter }) =>
    axios({
      method: 'GET',
      url: `${this.BASE_URL}${this.GOS_PEK}/monitoring/${id}?year=${year}&period=${quarter}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

  getPekHistory = ({ token, userId, year, quarter }) =>
    axios({
      method: 'GET',
      url: `${this.BASE_URL}${this.GOS_PEK}/actions/history/${userId}?year=${year}&quarter=${quarter}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

  savePekHistory = ({ token, data }) =>
    axios({
      method: 'POST',
      url: `${this.BASE_URL}${this.GOS_PEK}/actions/history/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });

  sendPekReport = async ({ data, token }) =>
    axios({
      method: 'POST',
      url: `${this.BASE_URL}${this.GOS_PEK}/send/pek/report`,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
      data,
    });

  createOrUpdateMonitoring = ({ values, id = null, token }) =>
    axios({
      method: 'POST',
      url: `${this.BASE_URL}${this.GOS_PEK}/monitoring/production${
        id ? `?id=${id}` : ''
      }`,
      data: values,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    });

  uploadPekProgramFile = (data, token) =>
    axios({
      method: 'POST',
      url: `${this.BASE_URL}${this.GOS_PEK}/save/file`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
      data,
    });

  uploadPekPromoAreasFile = (data, token) =>
    axios({
      method: 'POST',
      url: `${this.BASE_URL}${this.GOS_PEK}/promo/save/file`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
      data,
    });

  deletePekPromoAreasFile = ({ columnName, id, fileName }, token) =>
    axios({
      method: 'DELETE',
      url: `${this.BASE_URL}${this.GOS_PEK}/promo/delete/file?columnName=${columnName}&id=${id}&fileName=${fileName}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

  uploadPekShablon = (data, token) =>
    axios({
      method: 'POST',
      url: `${this.BASE_URL}${this.GOS_PEK}/parse/file`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
      data,
    });

  deleteAllFromPekTable = (formId, tableName, token, year, period) =>
    axios({
      method: 'DELETE',
      url: `${this.BASE_URL}${this.GOS_PEK}/${tableName}/${formId}?year=${year}&period=${period}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
}

const {
  getKatoSelectOptions,
  getSourceTypeSelectOptions,
  getPekGeneralInformation,
  uploadPekShablon,
  deleteAllFromPekTable,
  uploadPekProgramFile,
  uploadPekPromoAreasFile,
  getPromoAreaById,
  getMonitoringById,
  createOrUpdateMonitoring,
  getPekHistory,
  savePekHistory,
  getPekOrderList,
  savePekOrderList,
  deletePekPromoAreasFile,
  sendPekReport,
} = new PekAPI();

export {
  getKatoSelectOptions,
  getSourceTypeSelectOptions,
  getPekGeneralInformation,
  uploadPekShablon,
  deleteAllFromPekTable,
  uploadPekProgramFile,
  uploadPekPromoAreasFile,
  getPromoAreaById,
  getMonitoringById,
  createOrUpdateMonitoring,
  getPekHistory,
  savePekHistory,
  getPekOrderList,
  savePekOrderList,
  deletePekPromoAreasFile,
  sendPekReport,
};

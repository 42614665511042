import React from 'react';

import columnList from './data/columns';
import StandardTable from '../../../../modules/StandardTable';
import inputs from './data/modalInputs';
import Editor from '../../../../modules/Editor';

const values = [
    'N п/п',
    'Наименование промышленной площадки',
    'Район, населенный пункт, адрес (местонахождение) объекта',
    'Район, населенный пункт',
    'Координаты,  град., мин., сек.',
    'Занимаемая территория, га',
];

const tableName = 'data_on_location';

const columns = rounding => (token, refresh) => [
    {
        title: 'Действие',
        key: 'action',
        render: (text, record) => {
            const obj = {
                children: (
                    <Editor
                        addInputs={inputs}
                        editInputs={inputs}
                        text={text}
                        tableName={tableName}
                        editTableType={'order_production_tables'}
                        record={record}
                        refresh={refresh}
                    />
                ),
                props: {},
            };

            const column = values.find(el => el === record.number);
            const total = values.find(el => el === record.name);
            if (column) obj.props.colSpan = 0;
            if (total) obj.children = '';
            return obj;
        },
    },
    ...columnList(rounding),
];

const transformDataSource = (data, setState) => {
    console.log( data)
    const newDataSource = [];
    data.forEach(el =>
        newDataSource.push({
            key: el.id,
            ...el,
        })
    );
    setState(newDataSource);
};

const  LocationIndustrialSites = () => (
    <StandardTable
        tableName={tableName}
        tableType={'order_production_tables'}
        inputs={inputs}
        columns={rounding => columns(rounding)}
        formItemLayout={{}}
        transformDataSource={transformDataSource}
    />
);

export default LocationIndustrialSites;

import React from 'react';
import StandardTable from '../../../modules/StandardTable';

const values = ['Единицы', 'Единицы внутренных сокращений', 'Всего'];
const tableName = 'calculation_factor';

const renderContent = (value, row, index) => {
  let target = values.find(value => value === row.number);
  console.log(target);
  if (!target) target = '';

  const obj = {
    children: value,
    props: {},
  };
  if (row.number === target) {
    obj.props.colSpan = 0;
  }
  return obj;
};

const transformDataSource = (data, setState) => {
  let newDataSource = values.slice();
  data.forEach((el, i) => {
    const index = newDataSource.findIndex(obj => obj.type == el.type);
    if (index > -1) {
      const newElement = { key: el.id, number: i + 1, ...el };
      newDataSource = [
        ...newDataSource.slice(0, index + 1),
        newElement,
        ...newDataSource.slice(index + 1),
      ];
    }
  });
  setState(newDataSource);
};

const ElectionsTable = ({ data }) => {
  const columns = rounding => (token, refresh) => [
    {
      title: 'Код строки',
      dataIndex: 'rowCode',
      width: 120,
      render: renderContent,
    },
    {
      title: 'Наименование показателей',
      children: [
        {
          title: 'Вид углеродной организаций',
          //dataIndex: 'start_date',
          key: 'start_date1',
          width: 120,
          render: renderContent,
        },
        {
          title:
            'Полученные единицы квот на период действия Национального плана распределения квот',
          dataIndex: 'finish_date',
          key: 'finish_date2',
          width: 120,
          render: renderContent,
        },
        {
          title:
            'Остаток полученных единиц квот на период действия Национального плана распределения квот',
          dataIndex: 'finish_date',
          key: 'finish_date3',
          width: 120,
          render: renderContent,
        },
        {
          title: 'Дополнительные объем квот, выданный за отченный год',
          dataIndex: 'finish_date',
          key: 'finish_date4',
          width: 120,
          render: renderContent,
        },
        {
          title: 'Приобретенные за отченный год',
          dataIndex: 'finish_date',
          key: 'finish_date5',
          width: 120,
          render: renderContent,
        },
        {
          title: 'Квоты планируемый к приобретению за отчетный год',
          dataIndex: 'finish_date',
          key: 'finish_date6',
          width: 120,
          render: renderContent,
        },
        {
          title:
            'Планируемые к получению единицы внутреннего сокращения за отчетный год',
          dataIndex: 'finish_date',
          key: 'finish_date7',
          width: 120,
          render: renderContent,
        },
        {
          title: 'Отчужденные за отчетный год',
          dataIndex: 'finish_date',
          key: 'finish_date8',
          width: 120,
          render: renderContent,
        },
        {
          title: 'Планируемые к отчуждению за отчетный год',
          dataIndex: 'finish_date',
          key: 'finish_date9',
          width: 120,
          render: renderContent,
        },
        {
          title: 'Переданные для погашения квоты за отчетный год',
          dataIndex: 'finish_date',
          key: 'finish_date10',
          width: 120,
          render: renderContent,
        },
        {
          title: 'Планируемые к передаче погашения квоты за отчетный год',
          dataIndex: 'finish_date',
          key: 'finish_date11',
          width: 120,
          render: renderContent,
        },
      ],
    },
  ];

  return (
    <div>
      <StandardTable
        //dataSource={dataSource}
        inputs={[]}
        tableName={tableName}
        columns={rounding => columns(rounding)}
        scroll={{ x: 'calc(1500px + 50%)' }}
        transformDataSource={transformDataSource}
        bordered
      />
    </div>
  );
};

export default ElectionsTable;

import { Input, Select, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Map, Placemark, YMaps } from 'react-yandex-maps';
import { getInitialPekInfo } from '../../api/GRVPZApi';
import Editor from '../../modules/Editor';
import StandardTable from '../../modules/StandardTable';
import { selectCurrentPromo } from '../../redux/actions/grvpzActions';
import { rebuildCoordinates } from '../../utils/coordinatesControl';

const tableName = 'rvpz_sources';

const emissionInputs = [
  {
    title: 'Площадка',
    dataIndex: 'area_id',
    key: 'area_id',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Select />,
  },
  {
    title: 'Вид деятельности',
    dataIndex: 'order_production_id',
    key: 'order_production_id',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Select />,
  },
];

const GRVPZObjects = () => {
  const [promoAreas, setPromoAreas] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [orderProduction, setOrderProduction] = useState([]);

  const dispatch = useDispatch();
  const { token, user, year } = useSelector(state => ({
    ...state.userStore,
    ...state.pekDateStore,
  }));

  useEffect(() => {
    refreshInitialEmissionSources();
  }, []);

  useEffect(() => {
    refreshEmissionSources();
  }, [user.username.id, year]);

  const refreshEmissionSources = () => {
    // getGrvpzHistory({ token, userId: user.username.id, year }).then(res =>
    //   setGrvpzHistory(res.data)
    // );
  };

  const refreshInitialEmissionSources = () => {
    getInitialPekInfo({ token, userId: user.username.id, year }).then(res => {
      setPromoAreas(res.data?.promoAreas);
      setOrderProduction(res.data?.orderProduction);
    });
  };

  const transformDataSource = (data, setState) => {
    const newDataSource = [];
    data.forEach(el =>
      newDataSource.push({
        key: el.id,
        ...el,
      })
    );
    setState(newDataSource);
  };

  const emissionSourceIndex = emissionInputs.findIndex(
    ({ dataIndex }) => dataIndex === 'area_id'
  );

  const orderProductionIndex = emissionInputs.findIndex(
    ({ dataIndex }) => dataIndex === 'order_production_id'
  );

  const newInputs = emissionInputs.map((item, index) => {
    return index === emissionSourceIndex
      ? {
          ...item,
          component: (
            <Select
              showSearch
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {promoAreas.map((option, index) => (
                <Select.Option key={option.id}>
                  {option.name_area}
                </Select.Option>
              ))}
            </Select>
          ),
        }
      : index === orderProductionIndex
      ? {
          ...item,
          component: (
            <Select
              showSearch
              filterOption={(input, option) =>
                option.props.children
                  .join(' ')
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {orderProduction.map(option => (
                <Select.Option key={option.id}>
                  {option.name} {option.detail}
                </Select.Option>
              ))}
            </Select>
          ),
        }
      : item;
  });

  const emissionSourceColumns = rounding => (token, refresh) => [
    {
      key: 'id',
      dataIndex: 'id',
      title: 'ID',
      render: (id, record) => (
        <Link
          onClick={() => dispatch(selectCurrentPromo(record))}
          to={`/${id}`}
        >
          {id}
        </Link>
      ),
    },
    {
      key: 'name_area',
      dataIndex: 'name_area',
      title: 'Наименование площадки',
    },
    {
      key: 'bin',
      dataIndex: 'bin',
      title: 'БИН',
    },
    {
      key: 'order_production_name',
      dataIndex: 'order_production_name',
      title: 'Вид деятельности',
    },
    {
      key: 'order_production_group_name',
      dataIndex: 'order_production_group_name',
      title: 'Группа вида деятельности',
    },
    {
      key: 'latitude',
      dataIndex: 'latitude',
      title: 'Широта',
      render: latitude => rebuildCoordinates(latitude),
    },
    {
      key: 'longitude',
      dataIndex: 'longitude',
      title: 'Долгота',
      render: longitude => rebuildCoordinates(longitude),
    },
    {
      title: 'Действия',
      key: 'actions',
      width: 120,
      render: (text, record) => (
        <Editor
          addInputs={newInputs}
          editInputs={newInputs}
          text={text}
          editable={false}
          tableName={tableName}
          editTableType={'rvpz_pek'}
          record={record}
          refresh={refresh}
        />
      ),
    },
  ];

  return (
    <div>
      <Typography.Title>Список ваших объектов</Typography.Title>

      <StandardTable
        inputs={newInputs}
        tableName={tableName}
        transformDataSource={transformDataSource}
        tableType={'rvpz_pek'}
        columns={rounding => emissionSourceColumns(rounding)}
        setDataSourceForInitial={setDataSource}
      />
      <Typography.Title>Карта объектов</Typography.Title>

      <YMaps>
        <Map
          width={'80%'}
          style={{ margin: '10px auto', height: 400 }}
          defaultState={{
            center: [48.0196, 66.9237],
            zoom: 5,
          }}
        >
          {promoAreas.map((area, index) => (
            <Placemark
              properties={{
                iconContent: 'П',
              }}
              geometry={[
                rebuildCoordinates(area?.latitude),
                rebuildCoordinates(area?.longitude),
              ]}
              key={index}
            />
          ))}
        </Map>
      </YMaps>
    </div>
  );
};

export default GRVPZObjects;

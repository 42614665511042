import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Select } from 'antd';
import { Typography, Table } from 'antd';
import { renderColumns } from './data/columns';
import inputs from './data/inputs';
// import columnList from './data/columns';
import expandedInputs from './data/expandedInputs';
import editableExpandedInputs from './data/editableExpandedInputs';
import SuperSelect from '../../../../../components/SuperSelect';
// import Table from '../../../../../modules/SuperTable';
import Editor from '../../../../../modules/Editor';
import expandedColsList from './data/expandedCols';
import {
  getKatoSelectOptions,
  getSourceTypeSelectOptions,
} from '../../../../../api/PekAPI';
import StandardTable from '../../../../../modules/StandardTable';
import PekUploadShablon from '../../../PekUploadShablon';
import PekDeleteAllFromTable from '../../../PekDeleteAllFromTable';
import PekPromoFilesUpload from '../../../PekPromoFilesUpload';

const { Option } = Select;
const PAGE_SIZE = 15;

const Pollutants = ({ substance, fetchSumSources, token, tableName }) => {
  const [katoSelectOptions, setKatoSelectOptions] = useState();
  const [sourceTypeSelectOptions, setSourceTypeSelectOptions] = useState();
  const [currentPage, setCurrentPage] = useState('1');
  const [totalPage, setTotalPage] = useState(0);

  // const renderSelectOption = () => {
  //   return substance.map(({ code, name }) => (
  //     <Option
  //       key={code}
  //       value={`${code} - ${name}`}
  //     >{`${code} - ${name}`}</Option>
  //   ));
  // };
  //
  // const tableName = 'calculation_source';

  const columns = rounding => (token, refresh) => {
    const columns = [
      {
        title: 'Действие',
        key: 'action',
        render: (text, record) => (
          <Editor
            addInputs={inputs}
            editInputs={inputs}
            text={text}
            tableName={tableName}
            editTableType={'gos_pek'}
            record={record}
            refresh={refresh}
          />
        ),
      },
      {
        title: 'Наименование объекта воздействия',
        dataIndex: 'name_object',
        key: 'name_object',
        width: '11%'
      },
      {
        title: 'Координаты',
        children: [
          {
            title: 'широта',
            dataIndex: 'latitude',
            key: 'latitude',
          },
          {
            title: 'долгота',
            dataIndex: 'longitude',
            key: 'longitude',
          },
        ],
      },
      {
        title: 'Координаты места сброса сточных вод',
        children: [
          {
            title: 'широта',
            dataIndex: 'latitude_discharge',
            key: 'latitude_discharge',
          },
          {
            title: 'долгота',
            dataIndex: 'longitude_discharge',
            key: 'longitude_discharge',
          },
        ],
      },
      {
        title: 'Наименование загрязняющих веществ',
        dataIndex: 'name',
        key: 'name',
        width: '11%'
      },
      {
        title: 'Установленный норматив',
        children: [
          {
            title: 'мг/дм3',
            dataIndex: 'established_standard_gram_cube',
            key: 'established_standard_gram_cube',
          },
          {
            title: 'тонна в год',
            dataIndex: 'established_standard_ton_year',
            key: 'established_standard_ton_year',
          },
        ],
      },
      {
        title: 'Фактический результат мониторинга',
        children: [
          {
            title: 'мг/дм3',
            dataIndex: 'actual_monitoring_result_gram_cube',
            key: 'actual_monitoring_result_gram_cube',
          },
          {
            title: 'тонна в год',
            dataIndex: 'actual_monitoring_result_ton_year',
            key: 'actual_monitoring_result_ton_year',
          },
        ],
      },
      {
        title:
          'Соблюдение либо превышение нормативов предельно допустимых сбросов',
        dataIndex: 'compliance_excess_standards',
        key: 'compliance_excess_standards',
        width: '11%'
      },
      {
        title: 'Мероприятия по устранению нарушений',
        dataIndex: 'actions_violations',
        key: 'actions_violations',
      },
    ];
    return columns;
  };

  const transformDataSource = (data, setState) => {
    const newDataSource = [];
    data.forEach(el =>
      newDataSource.push({
        key: el.id,
        ...el,
      })
    );
    setState(newDataSource);
  };

  // useEffect(() => {
  //   getKatoSelectOptions({ token }).then(res => {
  //     setKatoSelectOptions(
  //       res.data &&
  //         res.data.map(option => ({
  //           value: option.id,
  //           label: option.name,
  //         }))
  //     );
  //   });
  //
  //   getSourceTypeSelectOptions({ token }).then(res => {
  //     setSourceTypeSelectOptions(
  //       res.data &&
  //         res.data.map(option => ({
  //           value: option.id,
  //           label: option.name,
  //         }))
  //     );
  //   });
  //
  //   const inputIndex = expandedInputs.findIndex(
  //     ({ title }) => title === 'Код и наименование ЗВ'
  //   );
  //   const select = (
  //     <SuperSelect
  //       showSearch
  //       filterOption={(input, option) =>
  //         option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
  //       }
  //     >
  //       {renderSelectOption()}
  //     </SuperSelect>
  //   );
  //   expandedInputs[inputIndex].component = select;
  //   editableExpandedInputs[inputIndex].component = select;
  // }, []);
  //
  // const insertEditor = ({ target, fixed = false, width = 300, rounding }) => (
  //   token,
  //   refresh
  // ) => [
  //   ...target(rounding),
  //   {
  //     title: 'Область аккредитации испытательной лаборатории',
  //     key: 'action',
  //     width: width,
  //     fixed: fixed ? 'right' : false,
  //     render: (text, record) => {
  //       const { is_source, data_type } = record;
  //       const props = {
  //         text,
  //         refresh,
  //         record,
  //         editInputs: is_source ? inputs : editableExpandedInputs,
  //         addInputs: expandedInputs,
  //         tableName: is_source ? 'calculation_source' : 'calculation_data',
  //         dataType: 'fact_emissions',
  //         isFactEmissions:
  //           data_type === 'instrumental_result' && is_source ? true : false,
  //         tableType: 'emissions',
  //         editTableType: 'super',
  //       };
  //       return <Editor {...props} />;
  //     },
  //   },
  // ];
  // const katoSelectIndex = inputs.findIndex(
  //   ({ dataIndex }) => dataIndex === 'kato_id'
  // );
  // const sourceTypeSelectIndex = inputs.findIndex(
  //   ({ dataIndex }) => dataIndex === 'source_type_id'
  // );
  // const newInputs = inputs.map((item, index) => {
  //   return index === katoSelectIndex
  //     ? {
  //         ...item,
  //         component: (
  //           <Select>
  //             {katoSelectOptions &&
  //               katoSelectOptions.map(option => (
  //                 <Option key={option.value}>{option.label}</Option>
  //               ))}
  //           </Select>
  //         ),
  //       }
  //     : index === sourceTypeSelectIndex
  //     ? {
  //         ...item,
  //         component: (
  //           <Select>
  //             {sourceTypeSelectOptions &&
  //               sourceTypeSelectOptions.map(option => (
  //                 <Option key={option.value}>{option.label}</Option>
  //               ))}
  //           </Select>
  //         ),
  //       }
  //     : item;
  // });
  // const props = {
  //   tableName,
  //   modalTables: ['calculation_source', 'calculation_data'],
  //   // columns: rounding =>
  //   // insertEditor({ target: columnList, width: 270, rounding }),
  //   expandedCols: rounding =>
  //     insertEditor({
  //       target: expandedColsList,
  //       fixed: true,
  //       width: 120,
  //       rounding,
  //     }),
  //   inputs: newInputs,
  //   expandedInputs,
  //   dataType: 'fact_emissions',
  //   scrollMultiplier: 170,
  //   transformDataSource,
  //   fetchSumSources,
  //   company: true,
  //   tableType: 'emissions',
  // };

  return (
    <div>
      <StandardTable
        inputs={inputs}
        tableName={tableName}
        tableType={'gos_pek'}
        columns={rounding => columns(rounding)}
        scrollMultiplier={150}
        transformDataSource={transformDataSource}
        dataSource={[]}
      />
      <Button.Group style={{ marginBottom: 20 }}>
        <PekUploadShablon tableName={tableName} shablonName="ПЭК_Вод_8.xlsx" />
        <PekDeleteAllFromTable tableName={tableName} />
      </Button.Group>

      <div>
        <PekPromoFilesUpload
          columnName="water_result_labaratory_file"
          title="Протокол, (максимум 50мб)"
        />
      </div>
    </div>
  );
};

const mapStateToProps = ({
  substanceStore: { substance },
  companyStore: { promoAreaId },
  pekDateStore: { year, quarter },
  userStore: { token },
}) => ({
  substance,
  promoAreaId,
  year,
  quarter,
  token,
});

export default connect(mapStateToProps)(Pollutants);

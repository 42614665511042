import React from 'react';
import { Card, Form, Input, Select } from 'antd';
const { Option } = Select;

const DieselForm = ({
  getFieldDecorator,
  sduStatus,
  setBeforeSdu,
  setAfterSdu,
}) => {
  const beforeRepairG = (
    <Select>
      <Option value="{7.2, 10.3, 3.6, 0.7, 1.1, 0.15, 0.000013}">А</Option>
      <Option value="{6.2, 9.6, 2.9, 0.5, 1.2, 0.12, 0.000012}">Б</Option>
      <Option value="{5.3, 8.4, 2.4, 0.35, 1.4, 0.1, 0.000011}">В</Option>
      <Option value="{7.2, 10.8, 3.6, 0.6, 1.2, 0.15, 0.000013}">Г</Option>
    </Select>
  );

  const afterRepairG = (
    <Select>
      <Option value="{8.6, 9.8, 4.5, 0.9, 1.2, 0.2, 0.000016}">А</Option>
      <Option value="{7.4, 9.1, 3.6, 0.65, 1.3, 0.15, 0.000015}">Б</Option>
      <Option value="{6.4, 8, 3, 0.45, 1.5, 0.12, 0.000014}">В</Option>
      <Option value="{8.6, 10.3, 4.5, 0.75, 1.3, 0.2, 0.000016}">Г</Option>
    </Select>
  );

  const selectG = sduStatus === 'before_repair' ? beforeRepairG : afterRepairG;

  const beforeRepairO = (
    <Select>
      <Option value="{30, 43, 15, 3, 4.5, 0.6, 0.000055}">А</Option>
      <Option value="{26, 40, 12, 2, 5, 0.5, 0.000055}">Б</Option>
      <Option value="{22, 35, 10, 1.5, 6, 0.4, 0.000045}">В</Option>
      <Option value="{30, 45, 15, 2.5, 5, 0.6, 0.000055}">Г</Option>
    </Select>
  );

  const afterRepairO = (
    <Select>
      <Option value="{36, 41, 18.8, 3.75, 4.6, 0.7, 0.000069}">А</Option>
      <Option value="{31, 38, 15.5, 2.5, 5.1, 0.6, 0.000063}">Б</Option>
      <Option value="{26, 33, 12.5, 1.9, 6.1, 0.5, 0.000056}">В</Option>
      <Option value="{36, 43, 18.8, 3.15, 5.1, 0.7, 0.000056}">Г</Option>
    </Select>
  );

  const selectO = sduStatus === 'before_repair' ? beforeRepairO : afterRepairO;

  return (
    <div>
      <p>Исходные данные:</p>
      <Form.Item label="Производитель стационарной дизельной установки (СДУ): ">
        {getFieldDecorator(
          'manufacturer',
          {}
        )(
          <Select>
            <Option value="1">Отечественный </Option>
            <Option value="2">Зарубежный</Option>
          </Select>
        )}
      </Form.Item>
      <Form.Item label="Группа СДУ:">
        {getFieldDecorator(
          'group_sdu',
          {}
        )(
          <Select>
            <Option
              value="1"
              children="номинальная мощность < 73,6 кВт, число оборотов=1000-3000 в мин"
            />
            <Option value="2">
              номинальная мощность 73,6-736 кВт, число оборотов=500-1500 в мин
            </Option>
            <Option value="3">
              номинальная мощность 736-7360 кВт, число оборотов=500-1000 в мин
            </Option>
            <Option value="4">
              номинальная мощность 736-7360 кВт, число оборотов=1500-3000 в мин,
              число целиндров >30{' '}
            </Option>
          </Select>
        )}
      </Form.Item>
      <Form.Item label="Расход топлива стационарной дизельной установки за год B, т">
        {getFieldDecorator('fuel_consumption', {})(<Input />)}
      </Form.Item>
      <Form.Item label="Эксплуатационная мощность стационарной дизельной установки P, кВт">
        {getFieldDecorator('power_sdu', {})(<Input />)}
      </Form.Item>
      <Form.Item label="Удельный расход топлива на экспл./номин. режиме работы двигателя b, г/кВт*ч">
        {getFieldDecorator('specific_consumption', {})(<Input />)}
      </Form.Item>
      <Form.Item label="Температура отработавших газов T, K">
        {getFieldDecorator('temp_gases', {})(<Input />)}
      </Form.Item>
      <Form.Item label="Состояние СДУ:">
        {getFieldDecorator(
          'status_sdu',
          {}
        )(
          <Select
            onChange={value => {
              value === 'before_repair' ? setBeforeSdu() : setAfterSdu();
            }}
          >
            <Option value="before_repair">до капитального ремонта </Option>
            <Option value="after_repair">после капитального ремонта</Option>
          </Select>
        )}
      </Form.Item>
      <Form.Item label="Расчет максимального из разовых выброса, г/с">
        {getFieldDecorator('gross_emission', {})(selectG)}
      </Form.Item>
      <Form.Item label="Расчет максимального из разовых выброса, г/с">
        {getFieldDecorator('one_emission', {})(selectO)}
      </Form.Item>
    </div>
  );
};

export default DieselForm;

import { Button, Col, Modal, Row } from 'antd';
import React from 'react';
import CarbonMonoxide from '../components/CarbonMonoxide';
import CarbonMonoxidePie from '../components/CarbonMonoxidePie';
import NitricOxide from '../components/NitricOxide';


const Charts = ({ visible, modalOpen, modalClose }) => {
  return (
    <div>
      <Button type="primary" onClick={modalOpen}>
        Статистика
      </Button>
      <Modal
        title="Статистика"
        visible={visible}
        onCancel={modalClose}
        footer={[]}
        width="80vw"
      >
        <Row>
          <Col span={12}>
            <NitricOxide title="Оксиды азота" visible={visible} />
          </Col>
          <Col span={12}>
            <CarbonMonoxide title="Оксид углерода" visible={visible} />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <CarbonMonoxidePie visible={visible} />
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default Charts;

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Typography } from 'antd';
import inputs from './inputs';
import StandardTable from '../../../modules/StandardTable';
import Editor from '../../../modules/Editor';

const WasteEndVolume = ({ substance, token, tableName }) => {
  const transformDataSource = (data, setState) => {
    const newDataSource = [];
    data.forEach(el =>
      newDataSource.push({
        key: el.id,
        ...el,
      })
    );
    setState(newDataSource);
  };
  const columns = rounding => (token, refresh) => {
    const columns = [
      {
        title: 'Действие',
        key: 'action',
        render: (text, record) => (
          <Editor
            addInputs={inputs}
            editInputs={inputs}
            text={text}
            tableName={tableName}
            editTableType={'rvpz_pek'}
            record={record}
            refresh={refresh}
          />
        ),
      },
      { title: '№ п/п', dataIndex: 'id', key: 'id' },
      {
        title: 'Географические координаты полигонов',
        children: [
          {
            title: 'Долгота',
            dataIndex: 'longitude',
            key: 'longitude',
          },
          {
            title: 'Широта',
            dataIndex: 'latitude',
            key: 'latitude',
          },
        ],
      },
      {
        title: 'Количество каждого вида отхода, выявленного за отчетный год, т/год',
        children: [
          {
            title: 'Объем накопленных отходов на полигоне за весь период эксплуатации',
            dataIndex: 'volume_all',
            key: 'volume_all',
          },
          {
            title: 'Объем образованных отходов за отчетный год',
            dataIndex: 'volume_year',
            key: 'volume_year',
          },
        ],
      },
    ];
    return columns;
  };

  return (
    <div>
      <Typography.Title level={4}>Данные об отходах, выявленных в отчетном году</Typography.Title>
      <StandardTable
        inputs={inputs}
        tableName={tableName}
        tableType={'rvpz_pek'}
        columns={rounding => columns(rounding)}
        transformDataSource={transformDataSource}
        dataSource={[]}
      />
    </div>
  );
};

const mapStateToProps = ({
  substanceStore: { substance },
  userStore: { token },
  pekDateStore: { year, quarter },
}) => ({
  substance,
  token,
  year,
  quarter,
});

export default connect(mapStateToProps)(WasteEndVolume);

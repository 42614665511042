export default [
  {
    value: '{0, 0, 7542, 0.858}',
    text: 'Кумертау-Ишимбай-Магнитогорск',
  },
  {
    value: '{0, 0, 6648, 0.758}',
    text: 'Бухара-Урал',
  },
  {
    value: '{0, 0, 6452, 0.741}',
    text: 'Игрим-Пунга-Серов-Нижний Тагил',
  },
  {
    value: '{0, 0, 8018, 0.883}',
    text: 'Оренбург-Совхозное',
  },
  {
    value: '{0, 0, 12103, 1.196, 0.8}',
    text: 'Каменный Лог-Пермь',
  },
  {
    value: '{0, 0, 13.395, 1.196, 1}',
    text: 'Ярино-Пермь',
  },
  {
    value: '{0, 0, 1078, 1.194}',
    text: 'Газ доменных печей, работающих на коксе с добавл. природного газа',
  },
  {
    value: '{0, 0, 1717, 0.424}',
    text: 'Газ коксовых печей',
  },
];

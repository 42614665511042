import {
  Button,
  Form,
  Icon,
  Input,
  message,
  Modal,
  Select,
  Upload,
} from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from '../../../hoc/compose';
import withInteractivity from '../../../hoc/withInteractivity';
import GeneralInformation from './Tables/GeneralInformation';
import Pollutants from './Tables/Pollutants';
import styles from '../../../assets/Main.module.css';
import {
  getPekGeneralInformation,
  getPekOrderList,
  savePekHistory,
  sendPekReport,
} from '../../../api/PekAPI';
import { statusList } from '../PekHistory';
import { RegistrationApi } from '../../../api/RegistrationApi';
import { updateUser } from '../../../api/LoginAPI';
import { pekOrderChange } from '../../../redux/actions/pekDateActions';
import WithLoader from '../../../hoc/withLoader';

class Info extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stationarySources: 0,
      organized: 0,
      unorganized: 0,
      active: '',
      generalInfo: null,
      visibleSignModal: false,
      loading: false,
      departments: [],
    };
  }

  componentDidMount() {
    this.getGeneralInfo();
    this.getPekOrderList();
    this.getDepartmentData();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.year !== prevProps.year ||
      this.props.quarter !== prevProps.quarter
    ) {
      this.getPekOrderList();
    }
  }

  getGeneralInfo = async () => {
    getPekGeneralInformation({
      token: this.props.token,
      id: this.props.user.username.id,
    })
      .then(({ data }) => {
        this.setState({ generalInfo: data });
        message.success('Данные загружены !');
      })
      .catch(err => {
        message.error('Произошла ошибка !');
      });
  };

  getPekOrderList = async () => {
    getPekOrderList({
      token: this.props.token,
      id: this.props.user.username.id,
      year: this.props.year,
      quarter: this.props.quarter,
    })
      .then(({ data }) => {
        this.props.pekOrderChange(data);
        message.success('Данные загружены !');
      })
      .catch(err => {
        message.error('Произошла ошибка !');
      });
  };

  withdrawPek = async () => {
    savePekHistory({
      token: this.props.token,
      data: {
        action_type: 'withdraw',
        user_id: this.props.user.username.id,
        year: this.props.year,
        quarter: this.props.quarter,
      },
    })
      .then(() => window.location.reload())
      .catch(() => {
        message.error('Ошибка');
      });
  };

  handleSend = async () => {
    this.setState({ loading: true });
    const formData = new FormData();
    formData.append('ecp', this.state.ecp.file.originFileObj);
    formData.append('password', this.state.password);
    formData.append('userId', this.props.user.username.id);
    formData.append('year', this.props.year);
    formData.append('quarter', this.props.quarter);
    formData.append('departmentId', this.state.departmentId);
    await sendPekReport({
      data: formData,
      token: this.props.token,
    })
      .then(() => {
        window.location.reload();
      })
      .catch(() => message.error('Ошибка !'))
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  getDepartmentData = () => {
    const response = new RegistrationApi();
    response
      .getDepartmentData()
      .then(res => {
        this.setState({ departments: res.data });
      })
      .catch(() => {
        message.error(
          'Не смогли загрузить список департаментов, провертье интернет соединение или блокировку через ваших программистов',
          15
        );
      })
      .finally(res => {
        console.log('HELLO', res);
      });
  };

  render() {
    const { measurements, pollutants } = this.props.state;
    const { showTableHandler } = this.props;
    const { active } = this.state;
    console.log('TUPOI', this.props.order?.status);
    return (
      <div>
        {this.state.loading && <WithLoader />}
        <div>
          <div style={{ fontSize: 20, marginBottom: 20 }}>
            Статус:{' '}
            {statusList[this.props.order?.status]?.title ||
              'Заполняется предприятием'}
          </div>
          <Button.Group style={{ marginBottom: 20 }}>
            <Button
              onClick={() => this.setState({ visibleSignModal: true })}
              type="primary"
              disabled={
                this.props.order?.status === 'send' ||
                this.props.order?.status === 'comment_ppm'
              }
            >
              Отправить отчет
            </Button>
            <Button
              disabled={this.props.order?.status !== 'send'}
              type="danger"
              onClick={this.withdrawPek}
            >
              Отозвать
            </Button>
          </Button.Group>

          <Modal
            width="50vw"
            visible={this.state.visibleSignModal}
            onCancel={() => this.setState({ visibleSignModal: false })}
            footer={[]}
          >
            <div style={{ margin: '20px 0px' }}>
              <Upload
                accept=".p12"
                onChange={info => this.setState({ ecp: info })}
                showUploadList={false}
              >
                <Button>
                  <Icon type="upload" /> Выбрать ЭЦП
                </Button>
              </Upload>
              <div>
                {' ' + this.state.ecp ? this.state.ecp?.file?.name : ''}
              </div>
            </div>
            <div style={{ margin: '20px 0px' }}>
              <Input
                type="password"
                onChange={e => this.setState({ password: e.target.value })}
                placeholder="Пароль"
              />
            </div>
            <Select
              placeholder="Выберите департамент"
              style={{ marginBottom: 20, width: '100%' }}
              onSelect={id => {
                console.log('UHH', id);
                this.setState({ departmentId: id });
              }}
            >
              {this.state.departments
                .filter(
                  el =>
                    el.name !==
                    'Комитет экологического регулирования и контроля Министерства экологии, геологии и природных ресурсов Республики Казахстан'
                )
                .map((el, index) => (
                  <Select.Option key={index} value={el.id}>
                    {el.name}
                  </Select.Option>
                ))}
            </Select>
            <Button.Group>
              <Button
                type="danger"
                onClick={() => this.setState({ visibleSignModal: false })}
              >
                <Icon type="stop" />
                Отмена
              </Button>
              <Button
                disabled={
                  !(this.state.password?.length > 0) ||
                  !this.state.ecp ||
                  !this.state.departmentId ||
                  this.state.loading
                }
                type="primary"
                onClick={this.handleSend}
              >
                <Icon type="check" />
                Подписать
              </Button>
            </Button.Group>
          </Modal>

          {/* <Button type="primary" style={{ marginBottom: 20 }}>
            Исходные данные
          </Button> */}
        </div>
        {/* <Button.Group style={{ marginBottom: 20 }}>
          <Button
            type="primary"
            className={active === 'measurements' ? {} : styles.btn_group}
            onClick={() => {
              if (active !== 'measurements') {
                this.setState({
                  active: 'measurements',
                });
              } else {
                this.setState({
                  active: '',
                });
              }
              showTableHandler('measurements', this.state);
            }}
          >
            Общие сведения по оператору объекта
          </Button>
          -
          <Button
            className={active === 'pollutants' ? {} : styles.btn_group}
            type="primary"
            onClick={() => {
              if (active !== 'pollutants') {
                this.setState({
                  active: 'pollutants',
                });
              } else {
                this.setState({
                  active: '',
                });
              }
              showTableHandler('pollutants');
            }}
          >
            Производственный мониторинг
          </Button>
        </Button.Group> */}
        {/* {measurements && ( */}
        <GeneralInformation
          generalInfo={this.state.generalInfo}
          refresh={this.getPekOrderList}
          changeSumSources={this.changeSumSources}
          fetchSumSources={this.fetchSumSources}
          tableName={'general_information_object_operator'}
        />
        {/* )} */}
        {/* {pollutants && (
          <Pollutants
            changeSumSources={this.changeSumSources}
            fetchSumSources={this.fetchSumSources}
            tableName={'production_monitoring'}
          />
        )} */}
        {/*{acm && <ACM />}*/}
      </div>
    );
  }
}

const mapStateToProps = ({
  userStore: { token, user },
  companyStore: { promoAreaId },
  pekDateStore: { year, quarter, order },
}) => ({ token, promoAreaId, year, quarter, user, order });

const tableState = {
  measurements: false,
  pollutants: false,
  acm: false,
  visible: false,
};

export default compose(
  withInteractivity(tableState),
  connect(mapStateToProps, {
    pekOrderChange,
  }),
  Form.create()
)(Info);

import React, { useEffect, useState } from 'react';
import DataSet from '@antv/data-set';
import { connect } from 'react-redux';

import CarbonMonoxidePieContainer from '../containers/CarbonMonoxidePie';
import { message } from 'antd';
import { getPieCarbonMonoxide } from '../../../api/ChartsAPI';

const CarbonMonoxidePie = ({ promoAreaId, token }) => {
  const { DataView } = DataSet;
  const [data, setData] = useState([]);

  const fetchData = async () => {
    if (!promoAreaId) return message.error('Не выбрано предприятие!');
    if (promoAreaId && token) {
      try {
        const result = await getPieCarbonMonoxide(token, {
          promoAreaId,
        });
        console.table(result);
        setData(result);
      } catch (error) {
        console.error(error);
        message.error('Что-то пошло не так!');
      }
    }
  };

  const transformData = data => {
    let emissionLimit = 0;
    const res = data.map(el => {
      const { year, period, limit, emission } = el;
      emissionLimit = limit;
      return {
        item: `${period} кв. ${year} г.`,
        ...el,
        emission: Number(emission),
      };
    });

    const vals = data.reduce(
      (accum, curr) => {
        return {
          limit: Number(accum.limit) + Number(curr.limit),
        };
      },
      {
        limit: 0,
        total: 0,
      }
    );

    res.push({
      item: 'лимит',
      emission: vals.limit,
    });
    return res;
  };

  useEffect(() => {
    fetchData();
  }, [promoAreaId]);

  const dv = new DataView();
  dv.source(transformData(data)).transform({
    type: 'percent',
    field: 'emission',
    dimension: 'item',
    as: 'percent',
  });

  function roundUp(num, precision) {
    precision = Math.pow(10, precision);
    return Math.ceil(num * precision) / precision;
  }

  const cols = {
    percent: {
      formatter: val => {
        val = roundUp(val * 100, 3) + '%';
        return val;
      },
    },
  };

  const props = {
    cols,
    dv,
  };
  return <CarbonMonoxidePieContainer {...props} />;
};

const mapStateToProps = ({
  companyStore: { promoAreaId },
  userStore: { token },
}) => ({ promoAreaId, token });

export default connect(mapStateToProps)(CarbonMonoxidePie);

import React from 'react';
import { DatePicker, Input, Select } from 'antd';
import moment from 'moment';

export default [
  {
    title: 'Производство от природных источников ',
    dataIndex: 'manufactured_from_natural_sources',
    key: 'manufactured_from_natural_sources',
    footerTitle: 'Сумма, Производственный водозабор от природных источников',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Производство от других организации',
    dataIndex: 'manufacturing_from_other_organizations',
    key: 'manufacturing_from_other_organizations',
    footerTitle: 'Сумма, Производственный водозабор от других организации',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Хозяйственно-бытовое производство от природных источников ',
    dataIndex: 'househol_from_natural_sources',
    key: 'househol_from_natural_sources',
    footerTitle: 'Сумма, Хозяйственно-бытовой водозабор от природных источников',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Хозяйственно-бытовое производство от других организации',
    dataIndex: 'househol_from_other_organizations',
    key: 'househol_from_other_organizations',
    footerTitle: 'Сумма, Хозяйственно-бытовой водозабор от других организации',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },

  {
    title: 'Фактический объем сбросов за отчетный период от производственных',
    dataIndex: 'actual_volume_discharges_production',
    key: 'actual_volume_discharges_production',
    footerTitle: 'Сумма, Фактический объем производственных сбросов за отчетный период',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title:
      'Фактический объем сбросов за отчетный период от хозяйственно-бытовых',
    dataIndex: 'actual_volume_discharges_household',
    key: 'actual_volume_discharges_household',
    footerTitle: 'Сумма, Фактический объем хозяйственно-бытовых сбросов за отчетный период',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Объем переданных стоков сторонним организациям (м3)',
    dataIndex: 'volume_waste_water_transferred',
    key: 'volume_waste_water_transferred',
    footerTitle: 'Сумма, Объем переданных стоков сторонним организациям',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Оборотное использование (м3)',
    dataIndex: 'negotiable_use',
    key: 'negotiable_use',
    footerTitle: 'Сумма, Оборотное использование',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Повторное использование (м3)',
    dataIndex: 'reuse',
    footerTitle: 'Сумма, Повторное использование',
    key: 'reuse',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Объем закачки воды в пласт (м3)',
    dataIndex: 'water_injection_volume',
    footerTitle: 'Сумма, Объем закачки воды в пласт',
    key: 'water_injection_volume',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
];

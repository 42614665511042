import { Typography } from 'antd';
import React from 'react';
import Editor from '../../../../../modules/Editor';
import StandardTable from '../../../../../modules/StandardTable';
import columnList from './columns';
import inputs from './modalInputs';


const { Title } = Typography;

const tableName = 'emission_atmosphere';

const columns = rounding => (token, refresh) => [
  {
    title: 'Действие',
    key: 'action',
    render: (text, record) => (
      <Editor
        addInputs={inputs}
        editInputs={inputs}
        text={text}
        tableName={tableName}
        record={record}
        refresh={refresh}
      />
    ),
  },
  ...columnList(rounding),
];

const transformDataSource = (data, setState) => {
  const newDataSource = [];
  data.forEach((el, i) =>
    newDataSource.push({
      key: el.id,
      number: i + 1,
      ...el,
    })
  );
  setState(newDataSource);
};

const Emissions = () => (
  <div>
    <Title level={2}>
      Выбросы загрязняющих веществ в атмосферу от стационарных источников
    </Title>
    <StandardTable
      tableName={tableName}
      inputs={inputs}
      columns={rounding => columns(rounding)}
      transformDataSource={transformDataSource}
      scrollMultiplier={170}
    />
  </div>
);

export default Emissions;

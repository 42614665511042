import { Button, message, Table, Typography } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import StandardTable from '../../../../../modules/StandardTable';
import inputs from './data/inputs';
import Editor from '../../../../../modules/Editor';
import PekUploadShablon from '../../../PekUploadShablon';
import PekDeleteAllFromTable from '../../../PekDeleteAllFromTable';
import PekPromoFilesUpload from '../../../PekPromoFilesUpload';

const PAGE_SIZE = 15;

const InformationOperationsProductionWaste = ({
  data,
  tableName,
  promoAreaId,
  quarter,
  year,
  setRefresh,
  token,
}) => {
  const { user } = useSelector(state => ({
    ...state.userStore,
  }));
  const [operation, setOperation] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  let query = ' ';
  const [search, setSearch] = useState('');
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState('1');
  const [departments, setDepartments] = useState([]);
  const [id, setId] = useState();

  const categoryStatusTypes = {
    'Ожидается определение категории': 'waiting',
    'Ожидается подпись': 'signing',
    'Отказано ': 'rejected',
    'Категория определена: I': 1,
    'Категория определена: II ': 2,
    'Категория определена: III': 3,
    'Категория определена: IV': 4,
  };
  const [selectedCategory, setSelectedCategory] = useState(
    user.username?.role_id === '5'
      ? 'Ожидается подпись'
      : 'Ожидается определение категории'
  );

  // useEffect(() => {
  //   const params = new URLSearchParams();
  //   if (search) {
  //     params.append('search', search);
  //   } else {
  //     params.delete('search');
  //   }
  //   if (currentPage) {
  //     params.append('page', currentPage);
  //   }
  //   history.push({ search: params.toString() });
  // }, [search, history, currentPage]);
  //
  // useEffect(() => {
  //   console.log("WWS",search, currentPage, selectedCategory);
  //   fetchUsers();
  // }, [search, currentPage, selectedCategory]);

  // const onDeleteAsset = async id => {
  //     await axios.delete(
  //         `http://185.146.3.215:4000/api/data/delete/files?id=${id}`
  //     );
  //     console.log('Удаление');
  //     submit({year, quarter, promoAreaId});
  // };
  //
  // const onAcceptFile = async id => {
  //     await axios({
  //         method: 'POST',
  //         url: `http://185.146.3.215:4000/api/pek/excel/accept/${id}`,
  //         headers: {
  //             Authorization: `Bearer ${token}`,
  //         },
  //     })
  //         .then(() => {
  //             message.success('Успешно');
  //         })
  //         .catch(() => {
  //             message.error('Ошибка');
  //         });
  //     submit({year, quarter, promoAreaId});
  // };
  //
  // const closePopup = () => {
  //     setOperation(false);
  //     submit({year, quarter, promoAreaId});
  // };

  const submit = async () => {
    message.loading('Загружаем данные...');
    axios
      .get('http://185.146.3.215:4000/api/department', {
        Authorization: `Bearer ${token}`,
      })
      .then(res => {
        setDepartments(res.data);
      });
  };

  // const fetchUsers = async () => {
  //     await axios({
  //         method: 'GET',
  //         url: `http://185.146.3.215:4000/api/user/category?pageSize=${PAGE_SIZE}&pageNumber=${currentPage}&search=${search}&category=${categoryStatusTypes[selectedCategory]}`,
  //         headers: {
  //             Authorization: `Bearer ${token}`,
  //         },
  //     })
  //         .then(function (response) {
  //             message.success('Успешно загрузили');
  //             //handle success
  //             console.log(response.data);
  //             setDataSource(response.data.users);
  //             setTotalPage(response.data.total)
  //             console.log(response.data);
  //         })
  //         .catch(function (response) {
  //             //handle error
  //             console.log(response);
  //         });
  // };

  useEffect(() => {
    submit();
  }, []);

  // const categoryList = {
  //     1: 'I',
  //     2: 'II',
  //     3: 'III',
  //     4: 'IV',
  // };
  const transformDataSource = (data, setState) => {
    const newDataSource = [];
    data.forEach(el =>
      newDataSource.push({
        key: el.id,
        ...el,
      })
    );
    setState(newDataSource);
  };

  const columns = rounding => (token, refresh) => {
    const columns = [
      {
        title: 'Действие',
        key: 'action',
        render: (text, record) => (
          <Editor
            addInputs={inputs}
            editInputs={inputs}
            text={text}
            tableName={tableName}
            editTableType={'gos_pek'}
            record={record}
            refresh={refresh}
          />
        ),
      },
      {
        title: 'Код отхода',
        dataIndex: 'waste_code',
        key: 'waste_code',
      },
      {
        title: 'БИН организации, от которого получен отход',
        dataIndex: 'bin_organization',
        key: 'bin_organization',
      },
      {
        title: 'Объем полученного отхода, тонн',
        dataIndex: 'waste_volume',
        key: 'waste_volume',
      },
      {
        title: 'Объем отхода, направленный на проведение операций с ними, тонн',
        dataIndex: 'waste_volume_to_operations',
        key: 'waste_volume_to_operations',
      },
      {
        title: 'Вид операции',
        dataIndex: 'operation_type',
        key: 'operation_type',
      },
      {
        title: 'Переданный объем отхода/сырья после операции с ними, тонн',
        dataIndex: 'flash_waste_volume_after_operation',
        key: 'flash_waste_volume_after_operation',
      },
      {
        title: 'БИН организации, которому передан отход/сырье',
        dataIndex: 'bin_organization_flash_raw',
        key: 'bin_organization_flash_raw',
      },
      {
        title:
          'Вид образованного отхода после проведения операции с изначальным видом отхода',
        dataIndex: 'type_waste_after_operation',
        key: 'type_waste_after_operation',
      },
      {
        title:
          'Код отхода, образованного после проведения операции с изначальным видом отхода',
        dataIndex: 'code_waste_after_operation',
        key: 'code_waste_after_operation',
      },
      {
        title:
          'Объем образованного отхода после проведения операции с изначальным видом отхода, тонн',
        dataIndex: 'waste_volume_after_operations',
        key: 'waste_volume_after_operations',
      },
      {
        title: 'Вид операции с образованным после проведения операции отхода',
        dataIndex: 'type_operation_after_waste_typed',
        key: 'type_operation_after_waste_typed',
      },
      {
        title: 'Объем отхода, направленный на проведение повторной операций с ними, тонна',
        dataIndex: 'waste_volume_repeated',
        key: 'waste_volume_repeated',
      },
      {
        title:
          'БИН организации, которому передан оставшихся объемы отходов, в случае их передачи',
        dataIndex: 'bin_organization_flash_volume',
        key: 'bin_organization_flash_volume',
      },
    ];
    return columns;
  };

  return (
    <div>
      <Typography.Text>
        Заполняется при осуществлении операции с отходами, полученных от
        сторонней организации
      </Typography.Text>
      <StandardTable
        inputs={inputs}
        tableName={tableName}
        tableType={'gos_pek'}
        scrollMultiplier={200}
        columns={rounding => columns(rounding)}
        transformDataSource={transformDataSource}
        dataSource={[]}
      />

      <Button.Group style={{ marginBottom: 20 }}>
        <PekUploadShablon
          tableName={tableName}
          shablonName="ПЭК_Отходы_5.xlsx"
        />
        <PekDeleteAllFromTable tableName={tableName} />
      </Button.Group>
      <div>
        <PekPromoFilesUpload
          columnName="discharge_send_another_org_file"
          title="Акт, (максимум 50мб)"
        />
      </div>
    </div>
  );
};

const mapStateToProps = ({
  userStore: { token },
  companyStore: { promoAreaId },
  pekDateStore: { quarter, year },
}) => ({
  promoAreaId,
  token,
  quarter,
  year,
});

export default connect(mapStateToProps)(InformationOperationsProductionWaste);

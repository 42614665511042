import React from 'react';
import {DatePicker, Input, Select} from 'antd';
import moment from 'moment';
import { isNumberAndAboveZero } from '../../../../../../utils/validation';

export default [
    {
        title: 'Наименование',
        dataIndex: 'name',
        key: 'name',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
            },
        ],
        component: <Input/>,
    },
    {
        title: 'широта',
        dataIndex: 'latitude',
        key: 'latitude',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
                validator: isNumberAndAboveZero
            },
        ],
        component: <Input/>,
    },
    {
        title: 'долгота',
        dataIndex: 'longitude',
        key: 'longitude',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
                validator: isNumberAndAboveZero
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Источник выброса наименование',
        dataIndex: 'ejection_source_name',
        key: 'ejection_source_name',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Источник выброса номер',
        dataIndex: 'ejection_source_number',
        key: 'ejection_source_number',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
                validator: isNumberAndAboveZero
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Код вещества',
        dataIndex: 'substance_code',
        key: 'substance_code',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
                validator: isNumberAndAboveZero
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Наименование загрязняющих веществ',
        dataIndex: 'name_pollutants',
        key: 'name_pollutants',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Установленный норматив по ПДВ, ОВОС, г/с',
        dataIndex: 'established_standard_gram_sek',
        key: 'established_standard_gram_sek',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
                validator: isNumberAndAboveZero
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Установленный норматив по ПДВ, ОВОС, тонн/год',
        dataIndex: 'established_standard_ton_year',
        key: 'established_standard_ton_year',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
                validator: isNumberAndAboveZero
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Фактический результат, г/с',
        dataIndex: 'actual_result_sek_gram',
        key: 'actual_result_sek_gram',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
                validator: isNumberAndAboveZero
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Фактический результат, тонн/год',
        dataIndex: 'actual_result_year_ton',
        key: 'actual_result_year_ton',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
                validator: isNumberAndAboveZero
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Превышение нормативов предельно допустимых выбросов (ПДВ)',
        dataIndex: 'exceeding_emission_standards',
        key: 'exceeding_emission_standards',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
                validator: isNumberAndAboveZero
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Мероприятия по устранению нарушения (с указанием сроков)',
        dataIndex: 'corrective_measures',
        key: 'corrective_measures',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
            },
        ],
        component: <Input/>,
    },
];

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Form, Input, message, Popconfirm } from 'antd';
import { Typography } from 'antd';
import WithLoader from '../../../../../hoc/withLoader';
import compose from '../../../../../hoc/compose';
import config from '../../../../../config';
import { useParams } from 'react-router';
import {
  createOrUpdateMonitoring,
  getMonitoringById,
} from '../../../../../api/PekAPI';
import ButtonGroup from 'antd/lib/button/button-group';
import { remove } from '../../../../../api/StandardTableAPI';
import { checkEditableOrder } from '../../../../../utils/columnFilter';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 11 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 13 },
  },
};

const Pollutants = ({
  token,
  form,
  year,
  quarter,
  order,
  tableName,
  refresh,
  productionMonitoring,
}) => {
  const { getFieldDecorator, validateFields, setFieldsValue } = form;
  const [loader, setLoader] = useState(false);
  const [nameFile, setNameFile] = useState(null);
  const [numberFile, setNumberFile] = useState(null);
  const [regionFile, setRegionFile] = useState(null);
  const params = useParams();

  const renderActionButton = () => {
    const canEditFile = checkEditableOrder(order);

    return (
      canEditFile && (
        <ButtonGroup>
          <Button type="primary" htmlType="submit">
            Сохранить
          </Button>
          {productionMonitoring && (
            <Popconfirm title="Вы уверены ?" onConfirm={onDelete}>
              <Button type="danger">Удалить</Button>
            </Popconfirm>
          )}
        </ButtonGroup>
      )
    );
  };

  useEffect(() => {
    console.log(productionMonitoring);
    if (productionMonitoring) {
      console.log('FIRST', productionMonitoring);

      setFieldsValue(productionMonitoring);
    } else {
      console.log('SECOND', productionMonitoring);
      setFieldsValue({
        name_accredited_laboratory: '',
        number_validity_lab_certificate: '',
        scope_accreditation_testing_laboratory: '',
      });
    }
  }, [productionMonitoring]);

  const setNameFileCheck = file => {
    if ((file.size / 1024 / 1024).toFixed(4) > 50) {
      message.error('Файл больше 50мб');
      return;
    }
    setNameFile(file);
  };

  const setNumberFileCheck = file => {
    if ((file.size / 1024 / 1024).toFixed(4) > 50) {
      message.error('Файл больше 50мб');
      return;
    }
    setNumberFile(file);
  };

  const setRegionFileCheck = file => {
    if ((file.size / 1024 / 1024).toFixed(4) > 50) {
      message.error('Файл больше 50мб');
      return;
    }
    setRegionFile(file);
  };

  const onSubmit = evt => {
    const formData = new FormData();
    evt.preventDefault();
    validateFields((err, values) => {
      console.log(values);
      err && message.error('Ошибка в заполнении формы!');

      Object.keys(values).forEach(key => {
        formData.append(key, values[key]);
      });
      formData.append('name_accredited_laboratory_file', nameFile);
      formData.append('number_validity_lab_certificate_file', numberFile);
      formData.append(
        'scope_accreditation_testing_laboratory_file',
        regionFile
      );

      console.log('DANI', productionMonitoring);

      if (productionMonitoring) {
        formData.append('id', productionMonitoring.id);
      } else {
        formData.append('form_id', params.id);
      }

      console.log(formData);

      if (!err) {
        setLoader(true);
        const request = createOrUpdateMonitoring({
          values: formData,
          id: productionMonitoring?.id,
          token,
        });

        request
          .then(() => {
            message.success('Успешно !');
            refresh();
          })
          .catch(err => {
            console.log(err);
            message.error('Произошла ошибка !');
          })
          .finally(() => {
            setLoader(false);
          });
      }
    });
  };

  const onDelete = () => {
    setLoader(true);
    remove(token, { tableName, itemId: productionMonitoring.id })
      .then(() => {
        message.success('Успешно !');
        refresh();
      })
      .catch(err => {
        console.log(err);
        message.error('Произошла ошибка !');
      })
      .finally(() => {
        setLoader(false);
      });
  };

  return (
    <div>
      {loader ? <WithLoader /> : null}
      <Form
        layout="vertical"
        {...formItemLayout}
        defaultValue={productionMonitoring}
        onSubmit={onSubmit}
      >
        <Form.Item label="Наименование аккредитованной испытательной лаборатории">
          {getFieldDecorator('name_accredited_laboratory', {
            rules: [
              { required: true, message: 'Поле обязательно для заполнения' },
            ],
          })(<Input />)}
        </Form.Item>
        <Form.Item
          required={true}
          label="Свидетельство о гос регистрации, файл (максимум 50мб)"
        >
          <>
            {productionMonitoring?.name_accredited_laboratory_file && (
              <a
                onClick={() =>
                  window.open(
                    `${config.api_url}${productionMonitoring?.name_accredited_laboratory_file}`
                  )
                }
              >
                {productionMonitoring?.name_accredited_laboratory_file}
              </a>
            )}
            <div>
              <input
                type="file"
                onChange={e => setNameFileCheck(e.target.files[0])}
              />
            </div>
          </>
        </Form.Item>
        <Form.Item label="Номер и срок действия аттестата аккредитации испытательной лаборатории">
          {getFieldDecorator('number_validity_lab_certificate', {
            rules: [
              { required: true, message: 'Поле обязательно для заполнения' },
            ],
          })(<Input />)}
        </Form.Item>

        <Form.Item
          required={true}
          label="Номер и срок действия аттестата аккредитации испытательной лаборатории, файл (максимум 50мб)"
        >
          <>
            {productionMonitoring?.number_validity_lab_certificate_file && (
              <a
                onClick={() =>
                  window.open(
                    `${config.api_url}${productionMonitoring?.number_validity_lab_certificate_file}`
                  )
                }
              >
                {productionMonitoring?.number_validity_lab_certificate_file}
              </a>
            )}
            <div>
              <input
                type="file"
                onChange={e => setNumberFileCheck(e.target.files[0])}
              />
            </div>
          </>
        </Form.Item>

        <Form.Item label="Область аккредитации испытательной лаборатории">
          {getFieldDecorator('scope_accreditation_testing_laboratory', {
            rules: [
              { required: true, message: 'Поле обязательно для заполнения' },
            ],
          })(<Input />)}
        </Form.Item>

        <Form.Item
          required={true}
          label="Область аккредитации испытательной лаборатории, файл (максимум 50мб)"
        >
          <>
            {productionMonitoring?.scope_accreditation_testing_laboratory_file && (
              <a
                onClick={() =>
                  window.open(
                    `${config.api_url}${productionMonitoring?.scope_accreditation_testing_laboratory_file}`
                  )
                }
              >
                {
                  productionMonitoring?.scope_accreditation_testing_laboratory_file
                }
              </a>
            )}
            <div>
              <input
                type="file"
                onChange={e => setRegionFileCheck(e.target.files[0])}
              />
            </div>
          </>
        </Form.Item>
        {renderActionButton()}
      </Form>
    </div>
  );
};

const mapStateToProps = ({
  substanceStore: { substance },
  companyStore: { promoAreaId },
  pekDateStore: { year, quarter, order },
  userStore: { token },
}) => ({
  substance,
  promoAreaId,
  year,
  quarter,
  token,
  order,
});

export default compose(connect(mapStateToProps), Form.create())(Pollutants);

import isNumeric from 'validator/es/lib/isNumeric';

const isNumberAndAboveZero = async (rule, value, callback) => {
  try {
    if (+value < 0) {
      rule.message = 'Значение должно быть больше 0!';
    }

    if (!isNumeric(value, { no_symbols: true })) {
      rule.message = 'Должно быть введено число!';
    }

    if (isNumeric(value, { no_symbols: false }) && +value >= 0) {
      rule.message = 'Данные обязательны для заполнения';
      return true;
    }

    callback(rule.message);
  } catch (err) {
    callback(err);
  }
};

export { isNumberAndAboveZero };

import React from 'react';
import { Form, Icon, Input, Button, message, Typography, Tooltip } from 'antd';
import { connect } from 'react-redux';

import { userSetAction } from '../../redux/actions/userActions';
import { login } from '../../api/LoginAPI';
import moment from 'moment';

class LoginPage extends React.Component {
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        login({
          values: {
            username: values.username.trim(),
            password: values.password.trim(),
          },
        })
          .then(res => {
            console.log(res.data.user.username);
            // if (res.data.user.username.verify === false && moment(res.data.user.username.created_at) > new Date('2021-07-14')) {
            //     message.error("Вы не прошли веррификацию, " +
            //         "проверьте почту и попробуйте снова")
            //     return
            // }
            this.props.userSetAction(true, res.data.accessToken, res.data.user);
            console.log(res.data.user.username.role_id);
            this.props.history.push('/');
          })
          .catch(err => {
            console.log(err.response);
            message.error('Неправильный логин или пароль');
          });
      }
    });
  };

  render() {
    const { history } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <div className="login-container">
        <Form onSubmit={this.handleSubmit}>
          <Form.Item>
            {getFieldDecorator('username', {
              rules: [{ required: true, message: 'Введите логин!' }],
            })(
              <Input
                prefix={
                  <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                placeholder="Логин"
              />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('password', {
              rules: [{ required: true, message: 'Введите пароль!' }],
            })(
              <Input.Password
                prefix={
                  <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                type="password"
                placeholder="Пароль"
              />
            )}
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              Войти
            </Button>
          </Form.Item>
          <Typography
            onClick={() => history.push('/registration')}
            style={{ cursor: 'pointer' }}
          >
            Регистрация
          </Typography>
          <Typography
            onClick={() => history.push('/recovery-password')}
            style={{ cursor: 'pointer' }}
          >
            Восстановить пароль
          </Typography>
        </Form>
      </div>
    );
  }
}

const LoginPageForm = Form.create({ name: 'normal_login' })(LoginPage);

export default connect(({ userStore }) => ({ userStore }), {
  userSetAction,
})(LoginPageForm);

import {
  Button,
  Col,
  Form,
  Icon,
  Input,
  message,
  Modal,
  Select,
  Upload,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { uploadPekWaterProgramFile } from '../../../api/PekWaterApi';
import {
  getPekWaterList,
  savePekWaterList,
  saveWaterHistory,
  sendWaterReport,
} from '../../../api/PekWaterApi';
import { RegistrationApi } from '../../../api/RegistrationApi';
import config from '../../../config';
import WithLoader from '../../../hoc/withLoader';
import { statusList } from './PekWaterHistory';
import PekWaterTable from './PekWaterTable';

function PekWaterGeneralInformation({ form, user, year, token, refresh }) {
  const [loader, setLoader] = useState(false);
  const [report, setReport] = useState();
  const [departments, setDepartments] = useState([]);
  const [visibleSignModal, setVisibleSignModal] = useState(false);
  const [ecp, setEcp] = useState();
  const [departmentId, setDepartmentId] = useState();
  const [ecpPassword, setEcpPassword] = useState();

  const handleSend = async () => {
    setLoader(true);
    const formData = new FormData();
    formData.append('ecp', ecp.file.originFileObj);
    formData.append('password', ecpPassword);
    formData.append('userId', user.username.id);
    formData.append('year', year);
    formData.append('departmentId', departmentId);
    await sendWaterReport({
      data: formData,
      token,
    })
      .then(() => {
        window.location.reload();
      })
      .catch(() => message.error('Ошибка !'))
      .finally(() => {
        setLoader(false);
      });
  };

  const withdrawPek = async () => {
    saveWaterHistory({
      token,
      data: {
        action_type: 'withdraw',
        user_id: user.username.id,
        year: year,
      },
    })
      .then(() => window.location.reload())
      .catch(() => {
        message.error('Ошибка');
      });
  };

  const getWaterList = async () => {
    getPekWaterList({
      token,
      id: user.username.id,
      year,
    })
      .then(({ data }) => {
        setReport(data);
        message.success('Данные загружены !');
      })
      .catch(err => {
        message.error('Произошла ошибка !');
      });
  };

  const getDepartments = () => {
    const response = new RegistrationApi();
    response
      .getDepartmentData()
      .then(res => {
        setDepartments(res.data);
      })
      .catch(() => {
        message.error(
          'Не смогли загрузить список департаментов, провертье интернет соединение или блокировку через ваших программистов',
          15
        );
      })
      .finally(res => {
        console.log('HELLO', res);
      });
  };

  useEffect(() => {
    getDepartments();
    getWaterList();
  }, [year]);

  const onSubmit = async (file, type) => {
    setLoader(true);

    const formData = new FormData();

    formData.append('file', file);
    formData.append('columnName', type);

    if (report?.id) {
      formData.append('orderId', report?.id);
      await uploadPekWaterProgramFile(formData, token)
        .then(() => {
          message.success('Успешно !');
          window.location.reload();
        })
        .catch(err => {
          console.log(err);
          message.error('Произошла ошибка !');
        })
        .finally(() => {
          setLoader(false);
        });
    } else {
      savePekWaterList({
        values: {
          userId: user.username.id,
          orderId: report?.id,
          year,
        },
        token,
      })
        .then(async res => {
          formData.append('orderId', res.data.id);
          await uploadPekWaterProgramFile(formData, token);
          message.success('Успешно !');
          window.location.reload();
        })
        .catch(err => {
          console.log(err);
          message.error('Произошла ошибка !');
        })
        .finally(() => {
          setLoader(false);
        });
    }
  };

  const pekWaterHelpFile = report?.pek_help_file || null;
  const protocolFile = report?.protocol_file || null;

  return (
    <div>
      <div style={{ fontSize: 20, marginBottom: 20 }}>
        Статус:{' '}
        {statusList[report?.status]?.title || 'Заполняется предприятием'}
      </div>
      <Button.Group style={{ marginBottom: 20 }}>
        <Button
          onClick={() => setVisibleSignModal(true)}
          type="primary"
          disabled={report?.status === 'send'}
        >
          Отправить отчет
        </Button>
        <Button
          onClick={withdrawPek}
          disabled={report?.status !== 'send'}
          type="danger"
        >
          Отозвать
        </Button>
      </Button.Group>
      <Modal
        width="50vw"
        visible={visibleSignModal}
        onCancel={() => setVisibleSignModal(false)}
        footer={[]}
      >
        <div style={{ margin: '20px 0px' }}>
          <Upload
            accept=".p12"
            onChange={info => setEcp(info)}
            showUploadList={false}
          >
            <Button>
              <Icon type="upload" /> Выбрать ЭЦП
            </Button>
          </Upload>
          <div>{' ' + ecp ? ecp?.file?.name : ''}</div>
        </div>
        <div style={{ margin: '20px 0px' }}>
          <Input
            type="password"
            onChange={e => setEcpPassword(e.target.value)}
            placeholder="Пароль"
          />
        </div>
        <Select
          placeholder="Выберите департамент"
          style={{ marginBottom: 20, width: '100%' }}
          onSelect={id => {
            console.log('UHH', id);
            setDepartmentId(id);
          }}
        >
          {departments
            .filter(
              el =>
                el.name !==
                'Комитет экологического регулирования и контроля Министерства экологии, геологии и природных ресурсов Республики Казахстан'
            )
            .map((el, index) => (
              <Select.Option key={index} value={el.id}>
                {el.name}
              </Select.Option>
            ))}
        </Select>
        <Button.Group>
          <Button type="danger" onClick={() => setVisibleSignModal(false)}>
            <Icon type="stop" />
            Отмена
          </Button>
          <Button
            disabled={
              !(ecpPassword?.length > 0) || !ecp || !departmentId || loader
            }
            onClick={handleSend}
            type="primary"
          >
            <Icon type="check" />
            Подписать
          </Button>
        </Button.Group>
      </Modal>
      {loader ? <WithLoader /> : null}

      <PekWaterTable tableName="pek_water" report={report} />

      <Form.Item required={true} label="Пояснительная записка (максимум 50мб)">
        {pekWaterHelpFile && (
          <div style={{ marginBottom: 15 }}>
            <a
              onClick={() =>
                window.open(`${config.api_url}${pekWaterHelpFile}`)
              }
            >
              {pekWaterHelpFile}
            </a>
          </div>
        )}
        <div>
          <input
            type="file"
            onChange={e => onSubmit(e.target.files[0], 'pek_help_file')}
          />
        </div>
      </Form.Item>
      <Form.Item required={true} label="Протокол (максимум 50мб)">
        {protocolFile && (
          <div style={{ marginBottom: 15 }}>
            <a onClick={() => window.open(`${config.api_url}${protocolFile}`)}>
              {protocolFile}
            </a>
          </div>
        )}
        <div>
          <input
            type="file"
            onChange={e => onSubmit(e.target.files[0], 'protocol_file')}
          />
        </div>
      </Form.Item>
    </div>
  );
}

const mapStateToProps = ({
  userStore: { token, user },
  pekDateStore: { order, year, quarter },
}) => ({ token, user, order, year, quarter });

const mapDispatchToProps = {};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  Form.create()
)(PekWaterGeneralInformation);

import React, {useEffect, useState} from 'react';
import {Table} from "antd";
import {Modal, Button} from 'antd';
import {Input} from 'antd';
import {Select} from 'antd';
import {OrderProductionService} from "./service";
import Editor from "../../../modules/Editor";
import inputs from "./modalInputs";
import inputs2 from "./modalInputs2"

const {Option} = Select;

const tableName = 'order_production_types';
const tableName2 = 'order_production_types_detail';

const OrderProduction = () => {

    const first_column = [
        {
            title: '№',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Типы заказов продукции',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Действие',
            key: 'action',
            width: 170,
            render: (text, record) => (
                <Editor
                    addInputs={inputs}
                    editInputs={inputs}
                    text={text}
                    tableName={tableName}
                    record={record}
                    refresh={getProductionTypes}
                />
            ),
        },
    ];

    const columns = [
        {
            title: '№',
            dataIndex: 'id',
            key: 'id',

        },
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'name',
            width: 250,

        },
        {
            title: 'Окед',
            dataIndex: 'oked',
            key: 'oked',
            width: 220,

        },
        {
            title: 'Тип ID',
            dataIndex: 'order_production_types_id',
            key: 'order_production_types_id',
        },
        {
            title: 'категория',
            dataIndex: 'category',
            key: 'category',
        },
        {
            title: 'Действие',
            key: 'action',
            width: 170,
            render: (text, record) => (
                <Editor
                    addInputs={inputs2}
                    editInputs={inputs2}
                    text={text}
                    tableName={tableName2}
                    record={record}
                    refresh={getProductionTypesDetail}
                />
            ),
        },
    ];
    const [order_production_types, set_order_production_types] = useState('');
    const [modalInputValue, setModalInputValue] = useState({
        name: '',
        oked: '',
        order_production_types_id: '',
        category: ''
    });

    const [first_column_data, setFirstColumnData] = useState([]);
    const [data,setData] = useState([]);


    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalVisible2, setIsModalVisible2] = useState(false);

    const getProductionTypes = () => {
        const response = new OrderProductionService().getProductionTypes();
        response.then(res=>{
            setFirstColumnData(res.data)
        }).catch(err=>{
            console.log(err);
        })
    };

    const postProductionTypes = (body) => {
        const response = new OrderProductionService().postProductionTypes(body);
        response.then(res=>{
            console.log(res);
            getProductionTypes();
        })
    };



    const getProductionTypesDetail = () => {
        const response = new OrderProductionService().getProductionTypesDetail();
        response.then(res=>{
            setData(res.data)

        })
    };

    const postProductionTypesDetail = (body) => {
        const response = new OrderProductionService().postProductionTypesDetail(body);
        response.then(res=>{
            console.log(res);
            getProductionTypesDetail();
        })
    };

    useEffect(()=>{
        getProductionTypes();
        getProductionTypesDetail();
    },[]);


    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
        const copy = [...first_column_data];
        const obj = {
            name:order_production_types
        };
        copy.push(obj);
        postProductionTypes({
            name: order_production_types
        });
        setFirstColumnData(copy);
        set_order_production_types('');
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const showModal2 = () => {
        setIsModalVisible2(true);
    };

    const handleOk2 = () => {
        setIsModalVisible2(false);
        const copy = [...data];
        copy.push(modalInputValue);
        setData(copy);
        postProductionTypesDetail(modalInputValue);
        setModalInputValue({
            name: '',
            oked: '',
            order_production_types_id: '',
            category: ''
        });
    };

    const handleCancel2 = () => {
        setIsModalVisible2(false);
    };

    const inputChanged = (event) => {
        console.log(event.target.name);
        const copy = {...modalInputValue};
        copy[event.target.name] = event.target.value;
        setModalInputValue(copy);
    };

    function handleChange(value) {
        const copy = {...modalInputValue};
        copy['order_production_types_id'] = value;
        setModalInputValue(copy)
    }

    function handleChange2(value) {
        const copy = {...modalInputValue};
        copy['category'] = value;
        setModalInputValue(copy)
    }

    return (
        <div>
            <Modal title="Типы заказов продукции" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <Input value={order_production_types} onChange={(e) => set_order_production_types(e.target.value)}
                       placeholder="Типы заказов продукции"/>
            </Modal>
            <Modal title="Типы заказов продукции детали" visible={isModalVisible2} onOk={handleOk2}
                   onCancel={handleCancel2}>
                <Input onChange={inputChanged} name="name" placeholder="Название"/>
                <Input style={{marginTop:10}} onChange={inputChanged} name="oked" placeholder="Окед"/>
                <div style={{marginTop:10}}>
                <Select placeholder="Выберите тип заказа"
                        style={{width: '100%'}} onChange={handleChange}>
                    {first_column_data?.map((el, index) => (
                        <Option key={index} value={el.id}>{el.name}</Option>
                    ))}
                </Select>
                </div>
                <div style={{marginTop:10}}>
                <Select placeholder="Выберите категорию" style={{width: '100%'}} onChange={handleChange2}>
                    <Option value={"1"}>1</Option>
                    <Option value={"2"}>2</Option>
                    <Option value={"3"}>3</Option>
                    <Option value={"4"}>4</Option>
                </Select>
                </div>
            </Modal>
            <Table dataSource={first_column_data} columns={first_column} bordered/>
            <Button onClick={showModal} type="primary">Добавить</Button>
            <hr/>
            <Table dataSource={data} columns={columns} bordered/>
            <Button onClick={showModal2} type="primary">Добавить</Button>
        </div>
    )
};

export default OrderProduction;
import React from 'react';
import { DatePicker, Input, Select } from 'antd';
import moment from 'moment';
import { isNumberAndAboveZero } from '../../../utils/validation';

export default [
  {
    title: 'Вид отхода',
    dataIndex: 'waste_type',
    key: 'waste_type',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Объем переданных стоков сторонним организациям (м3)',
    dataIndex: 'drains_given',
    key: 'drains_given',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero
      },
    ],
    component: <Input />,
  },
  {
    title: 'Оборотное использование (м3)',
    dataIndex: 'reverse_used',
    key: 'reverse_used',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero
      },
    ],
    component: <Input />,
  },
  {
    title: 'Повторное использование (м3)',
    dataIndex: 'repeatly_used',
    key: 'repeatly_used',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero
      },
    ],
    component: <Input />,
  },
  {
    title: 'Объем закачки воды в пласт (м3)',
    dataIndex: 'insert_volume',
    key: 'insert_volume',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero
      },
    ],
    component: <Input />,
  },

];

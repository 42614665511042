import { combineReducers } from 'redux';
import userReducer from './userReducer';
import companyReducer from './companyReducer';
import substanceReducer from './substanceReducer';
import pekDateReducer from './pekDateReducer';
import taxReducer from './taxReducer';
import tableDataRedicer from './tableDataRedicer';
import grpvzReducer from './grvpzReducer';

const rootReducer = combineReducers({
  userStore: userReducer,
  companyStore: companyReducer,
  substanceStore: substanceReducer,
  pekDateStore: pekDateReducer,
  taxStore: taxReducer,
  tableDataStore: tableDataRedicer,
  grvpzStore: grpvzReducer,
});

export default rootReducer;

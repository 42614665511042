import React from 'react';
import { Tabs, Form, Input, Button } from 'antd';

import Report from './Report';
import Analysis from './Analysis';

import formItemLayout from '../../utils/formItemLayout';

const { TabPane } = Tabs;

const NatureManagement = () => {
  return (
    <div>
      <Form {...formItemLayout}>
        <Form.Item label="Разрешение на эмиссии">
          <Input />
        </Form.Item>
      </Form>
      <Tabs>
        <TabPane key="1" tab="Отчет по эмиссиям">
          <Report />
        </TabPane>
        <TabPane key="2" tab="Анализ выполнения ПМ">
          <Analysis />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default NatureManagement;

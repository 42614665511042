import { Typography } from 'antd';
import { Axis, Chart, Geom, Legend, Tooltip } from 'bizcharts';
import React from 'react';

const { Title } = Typography;
const data = [
  {
    // setup_standard: '10:10',
    emission: 12,
    setup_standard: 2,
    period: '2019г 1кв',
  },
  {
    // setup_standard: '10:15',
    emission: 15,
    setup_standard: 6,
    period: '2019г 1кв',
  },
  {
    time: '10:20',
    call: 13,
    waiting: 2,
    people: 5,
  },
];

const Diagram = ({ title, data, chartIns, scale }) => (
  <div>
    <Title level={3}>{title}</Title>
    <Chart
      padding={[20, 30]}
      scale={scale}
      width={500}
      height={400}
      data={data}
      onGetG2Instance={chart => {
        chartIns = chart;
        chartIns.on('interval:mouseenter', e => {
          chartIns.get('geoms').forEach(g => {
            if (g.get('type') === 'interval') {
              (g.getShapes() || []).forEach(s => {
                s.set('origin_fill', s.get('attrs').fill);
              });
            }
          });
        });
        chartIns.on('interval:mouseleave', e => {
          chartIns.get('geoms').forEach(g => {
            if (g.get('type') === 'interval') {
              (g.getShapes() || []).forEach(s => {
                s.attr('fill', s.get('origin_fill'));
              });
            }
          });
        });
      }}
    >
      <Legend
        custom={true}
        allowAllCanceled={true}
        items={[
          {
            value: 'выброс т/год',
            marker: {
              symbol: 'square',
              fill: '#3182bd',
              radius: 5,
            },
          },
          {
            value: 'ПДВ, г.сек',
            marker: {
              symbol: 'hyphen',
              stroke: '#ffae6b',
              radius: 5,
              lineWidth: 3,
            },
          },
        ]}
      />
      <Axis
        name="emission"
        grid={null}
        label={{
          textStyle: {
            fill: '#fdae6b',
          },
        }}
      />
      <Tooltip />
      <Geom
        type="interval"
        position="date*emission"
        tooltip={[
          'date*emission',
          (date, emission) => {
            return {
              //自定义 tooltip 上显示的 title 显示内容等。
              name: 'выброс т/год',
              value: emission,
            };
          },
        ]}
        color="#3182bd"
      />
      <Geom
        type="line"
        position="date*setup_standard"
        tooltip={[
          'date*setup_standard',
          (date, setup_standard) => {
            return {
              //自定义 tooltip 上显示的 title 显示内容等。
              name: 'ПДВ, г.сек',
              value: setup_standard,
            };
          },
        ]}
        color="#fdae6b"
        size={3}
        shape="smooth"
      />
      <Geom
        type="point"
        position="date*setup_standard"
        tooltip={[
          'date*setup_standard',
          (date, setup_standard) => {
            return {
              //自定义 tooltip 上显示的 title 显示内容等。
              name: 'ПДВ, г.сек',
              value: setup_standard,
            };
          },
        ]}
        color="#fdae6b"
        size={3}
        shape="circle"
      />
    </Chart>
  </div>
);

export default Diagram;

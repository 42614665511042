export const greenhouseCategory = [
  { id: 1, title: 'Печь', name: 'bake' },
  { id: 2, title: 'Котел', name: 'boiler' },
  { id: 3, title: 'Газопровод', name: 'gas_pipeline' },
  { id: 4, title: 'Нефтепровод', name: 'oil_pipeline' },
  { id: 5, title: 'Добыча угля', name: 'coal_mining' },
  { id: 6, title: 'Добыча нефти', name: 'oil_mining' },
];

export const boilerConstants = [
  { name: 'Сырая нефть', recalculateCoefficent: 40.12 },
  { name: 'Газовый конденсат', recalculateCoefficent: 40.12 },
  { name: 'Бензин авиационный', recalculateCoefficent: 44.21 },
  { name: 'Бензин автомобильный', recalculateCoefficent: 44.21 },
  { name: 'Реактивное топливо типа бензина', recalculateCoefficent: 44.21 },
  { name: 'Реактивное топливо типа керосина', recalculateCoefficent: 4332 },
  { name: 'Керосин осветительный и прочий', recalculateCoefficent: 44.75 },
  { name: 'Дизельное топливо', recalculateCoefficent: 43.02 },
  { name: 'Топливо печное бытовое', recalculateCoefficent: 42.54 },
  {
    name: 'Топливо для тихоходных дизелей (моторное)',
    recalculateCoefficent: 42.34,
  },
  { name: 'Топливо нефтяное (мазут)', recalculateCoefficent: 41.15 },
  { name: 'Мазут флотский', recalculateCoefficent: 41.15 },
  { name: 'Пропан и бутан сжиженные', recalculateCoefficent: 47.31 },
  { name: 'Битум нефтяной и сланцевый', recalculateCoefficent: 40.19 },
  { name: 'Отработанные масла (прочие масла)', recalculateCoefficent: 40.19 },
  { name: 'Кокс нефтяной и сланцевый', recalculateCoefficent: 31 },
  { name: 'Прочие виды топлива', recalculateCoefficent: 29.309 },
  {
    name: 'Коксующийся уголь карагандинского бассейна',
    recalculateCoefficent: 24.0,
  },
  { name: 'Уголь каменный', recalculateCoefficent: 17.62 },
  { name: 'Лигнит (бурый уголь)', recalculateCoefficent: 15.73 },
  { name: 'Кокс и полукокс из каменного угля', recalculateCoefficent: 25.12 },
  { name: 'Коксовый газ', recalculateCoefficent: 16.73 },
  { name: 'Доменный газ', recalculateCoefficent: 4.19 },
  { name: 'Газ природный', recalculateCoefficent: 34.78 },
  { name: 'Дрова для отопления', recalculateCoefficent: 10.22 },
  { name: 'Карагандинский бассейн', recalculateCoefficent: 21.77 },
  { name: 'В том числе коксующиеся', recalculateCoefficent: 23.86 },
  { name: 'Шубаркольское месторождение', recalculateCoefficent: 19.64 },
  { name: 'Куу-чекинское месторождение', recalculateCoefficent: 17.83 },
  { name: 'Борлинское месторождение', recalculateCoefficent: 14.54 },
  { name: 'Экибастузский бассейн', recalculateCoefficent: 16.5 },
  { name: 'Майкубенский бассейн', recalculateCoefficent: 16.98 },
  { name: 'Месторождение Юбилейное «Каражыра»', recalculateCoefficent: 18.58 },
];

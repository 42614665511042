import moment from 'moment';

export default [
  {
    title: 'Код строки',
    dataIndex: 'code_string',
    key: 'code_string',
  },
  {
    title: 'Наименование водного объекта',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Код источника',
    dataIndex: 'code_source',
    key: 'code_source',
  },
  {
    title: 'Код передающего предприятия',
    dataIndex: 'code_company',
    key: 'code_company',
  },
  {
    title: 'Код моря-реки',
    dataIndex: 'code_sea',
    key: 'code_sea',
  },
  {
    title: 'Притоки',
    key: 'balance',
    children: [
      {
        title: '1',
        dataIndex: 'inflow_1',
        key: 'inflow_1',
      },
      {
        title: '2',
        dataIndex: 'inflow_2',
        key: 'inflow_2',
      },
      {
        title: '3',
        dataIndex: 'inflow_3',
        key: 'inflow_3',
      },
      {
        title: '4',
        dataIndex: 'inflow_4',
        key: 'inflow_4',
      },
      {
        title: '5',
        dataIndex: 'inflow_5',
        key: 'inflow_5',
      },
    ],
  },
  {
    title: 'Код качества',
    dataIndex: 'code_quality',
    key: 'code_quality',
  },
  {
    title: 'Расстояние от устья',
    dataIndex: 'distance',
    key: 'distance',
  },
  {
    title: 'Забрано, получено за год',
    dataIndex: 'recived_year',
    key: 'recived_year',
  },
  {
    title: 'В том числе по месяцам',
    children: [
      {
        title: 'Январь',
        dataIndex: 'january',
        key: 'recived_jan',
      },
      {
        title: 'Февраль',
        dataIndex: 'february',
        key: 'recived_feb',
      },
      {
        title: 'Март',
        dataIndex: 'march',
        key: 'recived_mar',
      },
      {
        title: 'Апрель',
        dataIndex: 'april',
        key: 'recived_apr',
      },
      {
        title: 'Май',
        dataIndex: 'may',
        key: 'recived_may',
      },
      {
        title: 'Июнь',
        dataIndex: 'june',
        key: 'recived_june',
      },
      {
        title: 'Июль',
        dataIndex: 'july',
        key: 'recived_july',
      },
      {
        title: 'Август',
        dataIndex: 'august',
        key: 'recived_aug',
      },
      {
        title: 'Сентябрь',
        dataIndex: 'september',
        key: 'recived_sep',
      },
      {
        title: 'Октябрь',
        dataIndex: 'october',
        key: 'recived_oct',
      },
      {
        title: 'Ноябрь',
        dataIndex: 'november',
        key: 'recived_nov',
      },
      {
        title: 'Декабрь',
        dataIndex: 'december',
        key: 'recived_dec',
      },
    ],
  },
  {
    title: 'Использовано, передано',
    children: [
      {
        title: 'код вещества',
        dataIndex: 'used_transferred_code',
        key: 'used_transferred_code',
      },
      {
        title: 'количество',
        dataIndex: 'used_transferred_count',
        key: 'used_transferred_count',
      },
    ],
  },
  {
    title: 'Оборотное использование',
    dataIndex: 'reverse_use',
    key: 'reverse_use',
  },
  {
    title: 'Повторное использование',
    dataIndex: 'repeat_use',
    key: 'repeat_use',
  },
  {
    title: 'Передано после использования',
    dataIndex: 'transferred_use',
    key: 'transferred_use',
  },
  {
    title: 'Потери при транспортировке',
    dataIndex: 'losses',
    key: 'losses',
  },
  {
    title: 'Площадь орошения (гектар)',
    dataIndex: 'irrigination_area',
    key: 'irrigination_area',
  },
  {
    title: 'Создан',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (text, record) =>
      record.code_string === 'А' ? text : moment(text).format('YYYY-MM-DD'),
  },
];

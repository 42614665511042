import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Diagram from '../containers/Diagram';
import { message } from 'antd';
import { getCarbonMonoxide } from '../../../api/ChartsAPI';

const CarbonMonoxide = ({ style, title, promoAreaId, token, visible }) => {
  const [data, setData] = useState([]);

  const fetchData = async () => {
    if (!promoAreaId) return message.error('Не выбрано предприятие!');
    if (promoAreaId && token) {
      try {
        const data = await getCarbonMonoxide(token, {
          promoAreaId,
        });
        const result = data.map(
          ({ emission, setup_standard, period, year }) => ({
            emission: +emission,
            setup_standard: +setup_standard,
            date: `${year}г ${period}кв`,
          })
        );
        setData(result);
      } catch (error) {
        console.error(error);
        message.error('Что-то пошло не так!');
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [promoAreaId, visible]);

  const scale = {
    emission: {
      min: 0,
    },
    setup_standard: {
      min: 0,
    },
  };

  let chartIns = null;
  const props = {
    data,
    scale,
    chartIns,
    style,
    title,
  };
  return <Diagram {...props} />;
};

const mapStateToProps = ({
  userStore: { token },
  companyStore: { promoAreaId },
}) => ({ token, promoAreaId });

export default connect(mapStateToProps)(CarbonMonoxide);

import { Typography, Button } from 'antd';
import React from 'react';
import RadiationMonitoringTable from './Tables';
import inputs from './Tables/data/modalInputs';
import StandardTable from '../../../modules/StandardTable';
import Editor from '../../../modules/Editor';
import PekUploadShablon from '../PekUploadShablon';
import PekDeleteAllFromTable from '../PekDeleteAllFromTable';
import PekPromoFilesUpload from '../PekPromoFilesUpload';

const RadiationMonitoring = () => {
  const transformDataSource = (data, setState) => {
    const newDataSource = [];
    data.forEach(el =>
      newDataSource.push({
        key: el.id,
        ...el,
      })
    );
    setState(newDataSource);
  };
  const tableName = 'radiation_monitoring_information';
  const columns = rounding => (token, refresh) => {
    const columns = [
      {
        title: 'Действие',
        key: 'action',
        render: (text, record) => (
          <Editor
            addInputs={inputs}
            editInputs={inputs}
            text={text}
            tableName={'radiation_monitoring_information'}
            editTableType={'gos_pek'}
            record={record}
            refresh={refresh}
          />
        ),
      },
      {
        title: 'Наименование источников воздействия ',
        dataIndex: 'name_sources_exposure',
        key: 'name_sources_exposure',
      },
      {
        title: 'Установленный норматив микрозиверт в час (мкЗв/час)',
        dataIndex: 'established_standard',
        key: 'established_standard',
      },
      {
        title: 'Фактический результат мониторинга (мкЗв/час)',
        dataIndex: 'actual_result',
        key: 'actual_result',
      },
      {
        title:
          'Превышение нормативов "Санитарно-эпидемиологические требования к обеспечению радиационной безопасности", кратность',
        dataIndex: 'exceeding_standards',
        key: 'exceeding_standards',
      },
      {
        title: 'Мероприятия по устранению нарушения (с указанием сроков)',
        dataIndex: 'corrective_measures',
        key: 'corrective_measures',
      },
    ];
    return columns;
  };

  return (
    <>
      <Typography.Title level={4}>
        Сведения по радиационному мониторингу
      </Typography.Title>
      <Typography.Text>
        Все виды работ, связанные с радиационным мониторингом, выполняются в
        соответствии с действующими нормативными правовыми актами Республики
        Казахстан. При осуществлении радиационного мониторинга сторонними
        организациями, необходимо наличие у сторонней организации
        соответствующей лицензии в области использования атомной энергии.
      </Typography.Text>
      <StandardTable
        inputs={inputs}
        tableName={tableName}
        tableType={'gos_pek'}
        columns={rounding => columns(rounding)}
        transformDataSource={transformDataSource}
        dataSource={[]}
      />
      <Button.Group style={{ marginBottom: 20 }}>
        <PekUploadShablon tableName={'radiation_monitoring_information'} shablonName="ПЭК_РД_11.xlsx" />
        <PekDeleteAllFromTable tableName={'radiation_monitoring_information'} />
      </Button.Group>
      <div>
        <PekPromoFilesUpload
          columnName="radiation_file"
          title="Протокол, (максимум 50мб)"
        />
      </div>
    </>
  );
};

export default RadiationMonitoring;

import React from 'react';
import { DatePicker, Input, Select } from 'antd';
import moment from 'moment';
import { isNumberAndAboveZero } from '../../../utils/validation';

export default [
  {
    title: 'Категория (группа) веществ*',
    dataIndex: 'category',
    key: 'category',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Номер по CAS',
    dataIndex: 'cas_number',
    key: 'cas_number',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Загрязнитель',
    dataIndex: 'emission_name',
    key: 'emission_name',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Код ЗВ',
    dataIndex: 'emission_code',
    key: 'emission_code',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },

  {
    title: 'Энергетика',
    dataIndex: 'energy_limit',
    key: 'energy_limit',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Производство и обработка металлов',
    dataIndex: 'metal_limit',
    key: 'metal_limit',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Промышленность по переработке минерального сырья',
    dataIndex: 'mineral_limit',
    key: 'mineral_limit',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Химическая промышленность',
    dataIndex: 'chemical_limit',
    key: 'chemical_limit',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Управление отходами и сточными водами',
    dataIndex: 'discharge_waste_limit',
    key: 'discharge_waste_limit',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Производство и обработка бумаги и древесины',
    dataIndex: 'paper_wood_limit',
    key: 'paper_wood_limit',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Интенсивное животноводство и аквакультура',
    dataIndex: 'animal_aqua_limit',
    key: 'animal_aqua_limit',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Пищевая промышленность',
    dataIndex: 'food_limit',
    key: 'food_limit',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
];

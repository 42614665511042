export default [
  {
    value: 'solid',
    text: 'Твердое (уголь, торф и др.)',
    solid: 'Твердое (уголь, торф и др.)',
  },
  {
    value: 'gasNatural',
    text: 'Газ (природный)',
    gasNatural: 'Газ (природный)',
  },
  {
    value: 'oil',
    text: 'Мазут, нефть',
    oil: 'Мазут, нефть',
  },
  {
    value: 'firewood',
    text: 'Дрова',
    firewood: 'Дрова',
  },
  {
    value: 'liquid',
    text: 'Жидкое другое (Дизельное топливо и т.п.)',
    // liquid: 'Жидкое другое (Дизельное топливо и т.п.)',
  },
  {
    value: 'gasLiquid',
    text: 'Газ сжиженный (напр. СПБТ и др.)',
    gasLiquid: 'Газ сжиженный (напр. СПБТ и др.)',
  },
  {
    value: 'other',
    text: 'Другое',
    // solid: 'Другое',
  },
];

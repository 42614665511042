import {
  Button,
  Col,
  Form,
  Icon,
  Input,
  message,
  Modal,
  Select,
  Upload,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { saveGrvpzHistory, savePekGrvpzList, sendGrvpzReport } from '../../api/GRVPZApi';
import { RegistrationApi } from '../../api/RegistrationApi';
import WithLoader from '../../hoc/withLoader';
import formItemLayout from '../../utils/formItemLayout';
import { statusList } from './GRVPZHistory';

function GeneralInformation({ form, report, user, year, token, refresh }) {
  const [loader, setLoader] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [visibleSignModal, setVisibleSignModal] = useState(false);
  const [ecp, setEcp] = useState();
  const [departmentId, setDepartmentId] = useState();
  const [ecpPassword, setEcpPassword] = useState();

  const { getFieldDecorator, validateFields, setFieldsValue } = form;

  const handleSend = async () => {
    setLoader(true);
    const formData = new FormData();
    formData.append('ecp', ecp.file.originFileObj);
    formData.append('password', ecpPassword);
    formData.append('userId', user.username.id);
    formData.append('year', year);
    formData.append('departmentId', departmentId);
    await sendGrvpzReport({
      data: formData,
      token,
    })
      .then(() => {
        window.location.reload();
      })
      .catch(() => message.error('Ошибка !'))
      .finally(() => {
        setLoader(false);
      });
  };

  const onSubmit = evt => {
    evt.preventDefault();
    validateFields(async (err, values) => {
      console.log(values);
      err && message.error('Ошибка в заполнении формы!');

      const { methodology_name, rvpz_lead_name } = values;

      const requestData = {
        methodology_name,
        rvpz_lead_name,
      };

      console.log(requestData);

      if (!err) {
        setLoader(true);
        savePekGrvpzList({
          values: {
            order: requestData,
            userId: user.username.id,
            orderId: report?.id,
            year,
          },
          token,
        })
          .then(async res => {
            message.success('Успешно !');
            refresh();
          })
          .catch(err => {
            console.log(err);
            message.error('Произошла ошибка !');
          })
          .finally(() => {
            setLoader(false);
          });
      }
    });
  };

  const withdrawPek = async () => {
    saveGrvpzHistory({
      token,
      data: {
        action_type: 'withdraw',
        user_id: user.username.id,
        year: year,
      },
    })
      .then(() => window.location.reload())
      .catch(() => {
        message.error('Ошибка');
      });
  };

  const getDepartments = () => {
    const response = new RegistrationApi();
    response
      .getDepartmentData()
      .then(res => {
        setDepartments(res.data);
      })
      .catch(() => {
        message.error(
          'Не смогли загрузить список департаментов, провертье интернет соединение или блокировку через ваших программистов',
          15
        );
      })
      .finally(res => {
        console.log('HELLO', res);
      });
  };

  useEffect(() => {
    if (report || user.username) {
      setFieldsValue({
        ...report,
        ...user.username,
      });
    }
  }, [report, user.username]);

  useEffect(() => {
    getDepartments();
  }, []);

  const renderActionButton = () => {
    return (
      <Button type="primary" htmlType="submit">
        Сохранить
      </Button>
    );
  };

  return (
    <div>
      <div style={{ fontSize: 20, marginBottom: 20 }}>
        Статус:{' '}
        {statusList[report?.status]?.title || 'Заполняется предприятием'}
      </div>
      <Button.Group style={{ marginBottom: 20 }}>
        <Button
          onClick={() => setVisibleSignModal(true)}
          type="primary"
          disabled={report?.status === 'send'}
        >
          Отправить отчет
        </Button>
        <Button onClick={withdrawPek} disabled={report?.status !== 'send'} type="danger">
          Отозвать
        </Button>
      </Button.Group>
      <Modal
        width="50vw"
        visible={visibleSignModal}
        onCancel={() => setVisibleSignModal(false)}
        footer={[]}
      >
        <div style={{ margin: '20px 0px' }}>
          <Upload
            accept=".p12"
            onChange={info => setEcp(info)}
            showUploadList={false}
          >
            <Button>
              <Icon type="upload" /> Выбрать ЭЦП
            </Button>
          </Upload>
          <div>{' ' + ecp ? ecp?.file?.name : ''}</div>
        </div>
        <div style={{ margin: '20px 0px' }}>
          <Input
            type="password"
            onChange={e => setEcpPassword(e.target.value)}
            placeholder="Пароль"
          />
        </div>
        <Select
          placeholder="Выберите департамент"
          style={{ marginBottom: 20, width: '100%' }}
          onSelect={id => {
            console.log('UHH', id);
            setDepartmentId(id);
          }}
        >
          {departments
            .filter(
              el =>
                el.name !==
                'Комитет экологического регулирования и контроля Министерства экологии, геологии и природных ресурсов Республики Казахстан'
            )
            .map((el, index) => (
              <Select.Option key={index} value={el.id}>
                {el.name}
              </Select.Option>
            ))}
        </Select>
        <Button.Group>
          <Button type="danger" onClick={() => setVisibleSignModal(false)}>
            <Icon type="stop" />
            Отмена
          </Button>
          <Button
            disabled={
              !(ecpPassword?.length > 0) || !ecp || !departmentId || loader
            }
            onClick={handleSend}
            type="primary"
          >
            <Icon type="check" />
            Подписать
          </Button>
        </Button.Group>
      </Modal>
      {loader ? <WithLoader /> : null}
      <Form {...formItemLayout} onSubmit={onSubmit}>
        <Form.Item label="Наименование: ">
          {getFieldDecorator('name', {
            rules: [
              {
                required: false,
                message: 'Поле обязательно для заполнения',
              },
            ],
          })(<Input disabled={true} />)}
        </Form.Item>

        <Form.Item label="КАТО">
          {getFieldDecorator('kato', {
            rules: [
              {
                required: false,
                message: 'Поле обязательно для заполнения',
              },
            ],
          })(<Input disabled={true} />)}
        </Form.Item>

        <Form.Item label="БИН">
          {getFieldDecorator('bin', {
            rules: [
              {
                required: false,
                message: 'Поле обязательно для заполнения',
              },
            ],
          })(<Input disabled={true} />)}
        </Form.Item>

        <Form.Item label="Email">
          {getFieldDecorator('email', {
            rules: [
              {
                required: false,
                message: 'Поле обязательно для заполнения',
              },
            ],
          })(<Input disabled={true} />)}
        </Form.Item>

        <Form.Item label="ФИО руководителя">
          {getFieldDecorator('fio_leader', {
            rules: [
              {
                required: false,
                message: 'Поле обязательно для заполнения',
              },
            ],
          })(<Input disabled={true} />)}
        </Form.Item>

        <Form.Item
          labelCol={8}
          label={`ФИО лица, уполномоченного соответствующим оператором на представление от его имени информации в Регистр выбросов и переноса загрязнителей`}
        >
          {getFieldDecorator('rvpz_lead_name', {
            rules: [
              {
                required: false,
                message: 'Поле обязательно для заполнения',
              },
            ],
          })(<Input style={{ marginLeft: '21%' }} />)}
        </Form.Item>

        <Form.Item label="Город">
          {getFieldDecorator('city_kz', {
            rules: [
              {
                required: false,
                message: 'Поле обязательно для заполнения',
              },
            ],
          })(<Input disabled={true} />)}
        </Form.Item>

        <Form.Item label="Название улицы">
          {getFieldDecorator('street_name_ru', {
            rules: [
              {
                required: false,
                message: 'Поле обязательно для заполнения',
              },
            ],
          })(<Input disabled={true} />)}
        </Form.Item>

        <Form.Item label="Номер дома/здания">
          {getFieldDecorator('home_num', {
            rules: [
              {
                required: false,
                message: 'Поле обязательно для заполнения',
              },
            ],
          })(<Input disabled={true} />)}
        </Form.Item>

        <Form.Item
          labelCol={4}
          label="Тип методологии, использовавшейся для получения информации о количествах загрязнителей и отходов"
        >
          {getFieldDecorator('methodology_name', {
            rules: [
              {
                required: false,
                message: 'Поле обязательно для заполнения',
              },
            ],
          })(<Input style={{ marginLeft: '21%' }} />)}
        </Form.Item>
        {report?.status !== 'send' && report?.status !== 'accepted' && renderActionButton()}
      </Form>
    </div>
  );
}

const mapStateToProps = ({
  userStore: { token, user },
  pekDateStore: { order, year, quarter },
}) => ({ token, user, order, year, quarter });

const mapDispatchToProps = {};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  Form.create()
)(GeneralInformation);

const initialState = {
  currentPromo: null,
  airData: [],
  drainData: [],
};

const grpvzReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'SET_CURRENT_PROMO':
      return {
        ...state,
        currentPromo: payload,
      };
    case 'SET_CODE_DATA':
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default grpvzReducer;

import { Button, Icon, message, Popconfirm, Table, Typography } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import StandardTable from '../../../modules/StandardTable';

const values = [
  'приобретение',
  'отчуждение',
  'аутсорсинг',
  'сокращение производства',
  'расширение производства',
  'Осуществление мероприятий по энергоэффективности и энергосбережению, переход на другую технологию или топливо',
  'осуществление внутренних проектов по сокращению выбросов парниковых газов (объем парниковых газов)',
  'изменение методологии расчетов (плана мониторинга) (да/нет)',
  'другие',
];

const numbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];

const tableName = 'plan_monitoring';

const transformDataSource = (data, setState) => {
  let newDataSource = values.slice();
  //let newNumbers = values.slice();
  data.forEach((el, i) => {
    const index = newDataSource.findIndex(obj => obj.type == el.type);
    if (index > -1) {
      const newElement = { key: el.id, number: i + 1, ...el };
      newDataSource = [
        ...newDataSource.slice(0, index + 1),
        newElement,
        ...newDataSource.slice(index + 1),
      ];
    }
  });
  setState(newDataSource);
  //setState(newNumbers);
};

const PlanMonitoring = () => {
  const renderContent = (value, row, index) => {
    let target = values.find(value => value === row.number);
    console.log(target);
    if (!target) target = '';

    const obj = {
      children: value,
      props: {},
    };
    if (row.number === target) {
      obj.props.colSpan = 0;
    }
    return obj;
  };

  const columns = rounding => (token, refresh) => [
    {
      title: 'Код строки',
      dataIndex: 'rowCode',
      key: 'rowCode',
      render: renderContent,
    },
    {
      title: 'Вид деятельности',
      //dataIndex: 'type',
      key: 'type',
      render: renderContent,
    },
    {
      title: 'Изменение данных установок за отчетный год',
      dataIndex: 'changes',
      key: 'changes',
      render: renderContent,
    },
    {
      title: 'Примечание',
      dataIndex: 'others',
      key: 'others',
      render: renderContent,
    },
  ];
  return (
    <StandardTable
      //pagination={{ pageSize: 25 }}
      //dataSource={dataSource}
      inputs={[]}
      tableName={tableName}
      columns={rounding => columns(rounding)}
      transformDataSource={transformDataSource}
      scroll={{ x: 'calc(1500px + 50%)' }}
      bordered
    />
  );
};

export default PlanMonitoring;

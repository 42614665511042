import React, { useState } from 'react';
import ChartsContainer from '../containers/Charts';

const Charts = () => {
  const [visible, setVisible] = useState(false);
  const modalClose = () => setVisible(false);
  const modalOpen = () => setVisible(true);

  const props = {
    visible,
    modalClose,
    modalOpen,
  };
  return <ChartsContainer {...props} />;
};

export default Charts;

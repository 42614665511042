// renderUnnecessaryString
export default (columns, data) => {
  const dataIndexes = [];
  const pullColDataIndex = columns => {
    columns.forEach(({ dataIndex, children }) => {
      children && pullColDataIndex(children);
      dataIndex && dataIndexes.push(dataIndex);
    });
    return dataIndexes;
  };

  const renderDataSource = () => {
    const columnDataIndexes = pullColDataIndex(columns);
    const obj = { key: 1 };
    columnDataIndexes.map((el, i) => {
      obj[el] = data[i];
    });
    return obj;
  };

  return renderDataSource();
};

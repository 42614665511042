import React from 'react';
import { Input } from 'antd';
import { isNumberAndAboveZero } from '../../../../../../utils/validation';

export default [
  {
    title: 'Код и наименование ЗВ',
    dataIndex: 'code',
    key: 'code',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Установленный норматив (грамм в секунду/ тонна в год)',
    key: 'Установленный норматив (грамм в секунду/ тонна в год)',
    children: [
      {
        title: 'грамм в секунду',
        dataIndex: 'setup_standard_g_sec',
        key: 'setup_standard_g_sec',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
      {
        title: 'тонн в год',
        dataIndex: 'setup_standard_t_year',
        key: 'setup_standard_t_year',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
    ],
  },
  {
    title: 'Фактический результат мониторинга (грамм в секунду/  тонн в год)',
    key: 'Фактический результат мониторинга',
    children: [
      {
        title: 'грамм в секунду',
        dataIndex: 'fact_result_g_sec',
        key: 'fact_result_g_sec',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
    ],
  },
  {
    title: 'Мероприятия по устранению нарушения',
    dataIndex: 'events',
    key: 'events',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
];

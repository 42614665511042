import { Button, message, Table, Typography } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import inputs from './data/inputs';
import StandardTable from '../../../../../modules/StandardTable';
import Editor from '../../../../../modules/Editor';
import PekUploadShablon from '../../../PekUploadShablon';
import PekDeleteAllFromTable from '../../../PekDeleteAllFromTable';
import PekPromoFilesUpload from '../../../PekPromoFilesUpload';

const PAGE_SIZE = 15;

const GasMonitoringWaste = ({
  data,
  tableName,
  promoAreaId,
  quarter,
  year,
  setRefresh,
  token,
}) => {
  const { user } = useSelector(state => ({
    ...state.userStore,
  }));
  const [operation, setOperation] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  let query = ' ';
  const [search, setSearch] = useState('');
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState('1');
  const [departments, setDepartments] = useState([]);
  const [id, setId] = useState();

  // const categoryStatusTypes = {
  //     'Ожидается определение категории': 'waiting',
  //     'Ожидается подпись': 'signing',
  //     'Отказано ': 'rejected',
  //     'Категория определена: I': 1,
  //     'Категория определена: II ': 2,
  //     'Категория определена: III': 3,
  //     'Категория определена: IV': 4,
  // };
  const [selectedCategory, setSelectedCategory] = useState(
    user.username?.role_id === '5'
      ? 'Ожидается подпись'
      : 'Ожидается определение категории'
  );

  // useEffect(() => {
  //   const params = new URLSearchParams();
  //   if (search) {
  //     params.append('search', search);
  //   } else {
  //     params.delete('search');
  //   }
  //   if (currentPage) {
  //     params.append('page', currentPage);
  //   }
  //   history.push({ search: params.toString() });
  // }, [search, history, currentPage]);
  //
  // useEffect(() => {
  //   console.log("WWS",search, currentPage, selectedCategory);
  //   fetchUsers();
  // }, [search, currentPage, selectedCategory]);

  // const onDeleteAsset = async id => {
  //     await axios.delete(
  //         `http://185.146.3.215:4000/api/data/delete/files?id=${id}`
  //     );
  //     console.log('Удаление');
  //     submit({year, quarter, promoAreaId});
  // };
  //
  // const onAcceptFile = async id => {
  //     await axios({
  //         method: 'POST',
  //         url: `http://185.146.3.215:4000/api/pek/excel/accept/${id}`,
  //         headers: {
  //             Authorization: `Bearer ${token}`,
  //         },
  //     })
  //         .then(() => {
  //             message.success('Успешно');
  //         })
  //         .catch(() => {
  //             message.error('Ошибка');
  //         });
  //     submit({year, quarter, promoAreaId});
  // };
  //
  // const closePopup = () => {
  //     setOperation(false);
  //     submit({year, quarter, promoAreaId});
  // };

  const submit = async () => {
    message.loading('Загружаем данные...');
    axios
      .get('http://185.146.3.215:4000/api/department', {
        Authorization: `Bearer ${token}`,
      })
      .then(res => {
        setDepartments(res.data);
      });
  };

  const transformDataSource = (data, setState) => {
    const newDataSource = [];
    data.forEach(el =>
      newDataSource.push({
        key: el.id,
        ...el,
      })
    );
    setState(newDataSource);
  };

  // const fetchUsers = async () => {
  //     await axios({
  //         method: 'GET',
  //         url: `http://185.146.3.215:4000/api/user/category?pageSize=${PAGE_SIZE}&pageNumber=${currentPage}&search=${search}&category=${categoryStatusTypes[selectedCategory]}`,
  //         headers: {
  //             Authorization: `Bearer ${token}`,
  //         },
  //     })
  //         .then(function (response) {
  //             message.success('Успешно загрузили');
  //             //handle success
  //             console.log(response.data);
  //             setDataSource(response.data.users);
  //             setTotalPage(response.data.total)
  //             console.log(response.data);
  //         })
  //         .catch(function (response) {
  //             //handle error
  //             console.log(response);
  //         });
  // };

  useEffect(() => {
    submit();
  }, []);

  // const categoryList = {
  //     1: 'I',
  //     2: 'II',
  //     3: 'III',
  //     4: 'IV',
  // };

  const columns = rounding => (token, refresh) => {
    return [
      {
        title: 'Действие',
        key: 'action',
        render: (text, record) => (
          <Editor
            addInputs={inputs}
            editInputs={inputs}
            text={text}
            tableName={tableName}
            editTableType={'gos_pek'}
            record={record}
            refresh={refresh}
          />
        ),
      },
      {
        title: 'Наименование объекта',
        dataIndex: 'name_object',
        key: 'name_object',
      },
      {
        title: 'Точки отбора',
        dataIndex: 'sampling_points',
        key: 'sampling_points',
      },
      {
        title: 'Наблюдаемые компоненты',
        dataIndex: 'observable_components',
        key: 'observable_components',
      },
      {
        title: 'Методика проведения мониторинга',
        dataIndex: 'monitoring_methodology',
        key: 'monitoring_methodology',
      },
      {
        title: 'Результаты',
        children: [
          {
            title: '(мг/м3)',
            dataIndex: 'results',
            key: 'results',
          },
        ],
      },
      {
        title: 'Наличие превышений/причина',
        dataIndex: 'excessiveness',
        key: 'excessiveness',
      },
      {
        title: 'Вид операции с оставшимся объемом отходов',
        dataIndex: 'type_operation_volume_waste',
        key: 'type_operation_volume_waste',
      },
    ];
  };

  return (
    <div>
      {/*<div style={{display: 'flex'}}>*/}
      {/*    <Select*/}
      {/*        value={selectedCategory}*/}
      {/*        onSelect={setSelectedCategory}*/}
      {/*        style={{width: 270}}*/}
      {/*    >*/}
      {/*        {Object.keys(categoryStatusTypes).map((categoryType, index) => (*/}
      {/*            <Select.Option key={index} value={categoryType}>*/}
      {/*                {categoryType}*/}
      {/*            </Select.Option>*/}
      {/*        ))}*/}
      {/*    </Select>*/}
      {/*    <Input*/}
      {/*        defaultValue={search}*/}
      {/*        onChange={e => setSearch(e.target.value)}*/}
      {/*        style={{width: 270, marginLeft: 10}}*/}
      {/*        placeholder="Поиск"*/}
      {/*    />*/}
      {/*</div>*/}
      <Typography.Text>Заполняется владельцами полигонов ТБО</Typography.Text>
      <StandardTable
        inputs={inputs}
        tableName={tableName}
        tableType={'gos_pek'}
        scrollMultiplier={150}
        columns={rounding => columns(rounding)}
        transformDataSource={transformDataSource}
        dataSource={[]}
      />

      <Button.Group style={{ marginBottom: 20 }}>
        <PekUploadShablon
          tableName={tableName}
          shablonName="ПЭК_Отходы_6.xlsx"
        />
        <PekDeleteAllFromTable tableName={tableName} />
      </Button.Group>

      <div>
        <PekPromoFilesUpload
          columnName="discharge_gas_file"
          title="Протокол, (максимум 50мб)"
        />
      </div>
    </div>
  );
};

const mapStateToProps = ({
  userStore: { token },
  companyStore: { promoAreaId },
  pekDateStore: { quarter, year },
}) => ({
  promoAreaId,
  token,
  quarter,
  year,
});

export default connect(mapStateToProps)(GasMonitoringWaste);

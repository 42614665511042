import { Spin } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import styles from '../../assets/Main.module.css';
import {
  Admin,
  CardCompany,
  EnvironmentalMeasures,
  GreenhouseGases,
  NatureManagement,
  PEK,
  Statistic,
  Substance,
  Taxes,
  ApplicationForm,
} from '../';
import { checkUserToken } from '../../api/BaseAPI';
import Select from '../../components/Select';
import DateConfigurator from '../../modules/DateConfigurator';
import MainContainer from '../../modules/MainWrapper';
import {
  fetchAllCompanies,
  fetchCompany,
  selectCompany,
  selectPromoArea,
} from '../../redux/actions/companyActions';
import fetchSubstances from '../../redux/actions/substanceActions';
import {
  userResetAction,
  userSetAction,
} from '../../redux/actions/userActions';
import { Users } from '../Users';
import './style.css';
import AdminListFrom from '../AdminFormList/AdminList/AdminList';
import { Profile } from '../Profile/Profile';
import PEKInfo from '../Pek_info';
import config from '../../config';
import GRVPZ from '../GRVPZ';

const regions = [
  {
    label: 'Акмолинская область',
    id: 1,
  },
  {
    label: 'Актюбинская область',
    id: 2,
  },
  {
    label: 'Алматинская область',
    id: 3,
  },
  {
    label: 'Атырауская область',
    id: 4,
  },
  {
    label: 'Восточно-Казахстанская область',
    id: 5,
  },
  {
    label: 'Жамбылская область',
    id: 6,
  },
  {
    label: 'Западно-Казахстанская область',
    id: 7,
  },
  {
    label: 'Карагандинская область',
    id: 8,
  },
  {
    label: 'Костанайская область',
    id: 9,
  },
  {
    label: 'Кызылординская область',
    id: 10,
  },
  {
    label: 'Мангистауская область',
    id: 11,
  },
  {
    label: 'Павлодарская область',
    id: 12,
  },
  {
    label: 'Северо-Казахстанская область',
    id: 13,
  },
  {
    label: 'Туркестанская область',
    id: 14,
  },
  {
    label: 'г. Алматы',
    id: 15,
  },
  {
    label: 'г. Нур-Султан',
    id: 16,
  },
  {
    label: 'г. Шымкент',
    id: 17,
  },
  {
    label: 'в целом по Казахстану',
    id: 18,
  },
];

const Main = ({
  token,
  fetchSubstances,
  username,
  userResetAction,
  user,
  userSetAction,
  selectCompany,
  fetchCompanyAction,
  fetchAllCompanies,
  companies,
  promoAreas,
  selectPromoArea,
}) => {
  const [loading, setLoading] = useState(true);
  const [companiesList, setCompaniesList] = useState([]);
  const [region, setRegion] = useState(18);
  const history = useHistory();

  useEffect(() => {
    if (region === 18) {
      setCompaniesList(companies);
    } else {
      const filteredCompanies = companies.filter(cmp => {
        console.log('REG ID', cmp.region_id, region);
        return cmp.region_id == region;
      });
      console.log('FILTERED', filteredCompanies, region);
      setCompaniesList(filteredCompanies);
    }
  }, [region, companies]);

  const refresh = useCallback(() => {
    fetchAllCompanies({ token });
  }, []);

  const baseProcedures = async () => {
    try {
      await checkUserToken({ username, token })
        .then(res => {
          console.log('WWWWS', res.data);
          userSetAction(true, token, { username: res.data });
          setLoading(false);
        })
        .catch(() => {
          userResetAction();
          // history.push('/login');
        });
      refresh();
      // fetchSubstances(token);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    baseProcedures();
  }, []);

  const { isRvpz } = config;

  return (
    <Spin spinning={loading}>
      <MainContainer>
        <div className={styles.selects}>
          {/* {location.pathname === '/' && (
            <>
              <Select
                onSelect={value => {
                  setRegion(value);
                  selectCompany();
                }}
                data={regions}
                defaultValue={region}
                optionTextFieldName="label"
                placeholder="Выберите регион"
              />
              <Select
                onSelect={onSelectCompany}
                data={companiesList}
                optionTextFieldName="name"
                placeholder="Наименование предприятия"
              />
            </>
          )} */}

          {/* {location.pathname !== '/form' && location.pathname !== '/form-list' && (
            <Select
              data={promoAreas}
              onSelect={selectPromoArea}
              optionTextFieldName="name"
              placeholder="Производственная площадка"
            />
          )}

          {location.pathname !== '/' && location.pathname !== '/form' && location.pathname !== '/form-list' && (
            <DateConfigurator />
          )} */}
        </div>
        {isRvpz ? (
          <Switch>
            <Route path="/" component={GRVPZ} />
          </Switch>
        ) : (
          <Switch>
            <Route path="/" component={ApplicationForm} exact />
            <Route path="/pek" component={PEK} />
            <Route path="/pek-info/:id" component={PEKInfo} />
            <Route path="/taxes" component={Taxes} />
            <Route path="/statistic" component={Statistic} />
            <Route path="/nature-management" component={NatureManagement} />
            <Route path="/events" component={EnvironmentalMeasures} />
            <Route path="/greenhouse-gases" component={GreenhouseGases} />
            <Route path="/substance" component={Substance} />
            <Route path="/documents" component={Admin} />
            <Route path="/admin" component={Users} />
            <Route path="/form-list" component={AdminListFrom} />
            <Route exact path="/form" component={ApplicationForm} />
            <Route path="/profile" component={Profile} />
          </Switch>
        )}
      </MainContainer>
    </Spin>
  );
};

const mapStateToProps = ({
  userStore: {
    token,
    loggedIn,
    user: { username },
  },
  companyStore: { companies, companyId },
}) => ({
  token,
  loggedIn,
  username,
  companies,
  promoAreas: companyId
    ? companies.find(company => company.id === companyId).company_cards
    : [],
});

const mapDispatchToProps = {
  selectCompany,
  fetchSubstances,
  userResetAction,
  fetchCompanyAction: fetchCompany,
  fetchAllCompanies,
  selectPromoArea,
  userSetAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);

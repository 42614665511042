import {
  Button,
  Icon,
  Layout,
  message,
  Modal,
  Popconfirm,
  Tabs,
  Timeline,
  Upload,
} from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getPekHistory } from '../../api/PekAPI';

export const statusList = {
  send: {
    title: 'Доставлено',
    color: 'blue',
  },
  reject: {
    title: 'Отказано',
    color: 'red',
  },
  withdraw: {
    title: 'Отозвано',
    color: 'red',
  },
  accepted: {
    title: 'Принято',
    color: 'green',
  },
  comment_ppm: {
    title: 'Замечания ппм',
    color: '#FFCC00',
  },
};

const PekHistory = () => {
  const [pekHistory, setPekHistory] = useState([]);

  const { token, user, year, quarter } = useSelector(state => ({
    ...state.userStore,
    ...state.pekDateStore,
  }));

  useEffect(() => {
    refresh();
  }, [user.username.id, year, quarter]);

  const refresh = () => {
    getPekHistory({ token, userId: user.username.id, year, quarter }).then(res =>
      setPekHistory(res.data)
    );
  };

  return (
    <Layout.Content
      style={{ backgroundColor: '#f0f2f5', borderRadius: 20, padding: 20 }}
    >
      <Timeline>
        <Timeline.Item color="gray">Заполняется предприятием</Timeline.Item>
        {pekHistory.map(history => {
          return (
            <Timeline.Item color={statusList[history.action_type].color}>
              {statusList[history.action_type].title}{' '}
              {moment(history.created_at).format('YYYY-MM-DD')}
              {history.action_type === 'reject' && (
                <div>Причина: {history.comment}</div>
              )}
              {history.action_type === 'comment_ppm' && (
                <div>Замечание: {history.comment}</div>
              )}
              {history.action_type === 'send' && (
                <div>Куда: {history.department_name}</div>
              )}
              <div>
                Отчет за квартал: {history?.period}, {history?.year}
              </div>
            </Timeline.Item>
          );
        })}
      </Timeline>
    </Layout.Content>
  );
};

export default PekHistory;

import { Switch, Table } from 'antd';
import React, { useEffect, useRef } from 'react';
import Charts from '../../Charts';
import Modal from '../components/Modal';

const SuperTable = ({
  tableType,
  buttons,
  dataSource,
  columns,
  isEditable = true,
  firstTable,
  visibleFirst,
  visibleSecond,
  setVisibleFirst,
  fetchSumSources,
  setVisibleSecond,
  fetchData,
  inputs,
  dataType,
  expandedCols,
  roundUp,
  scrollMultiplier = 100,
  formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 12 },
    },
    wrapperCol: {
      xs: { span: 18 },
      sm: { span: 12 },
    },
  },
  pagination = { pageSize: 10 },
  company = true,
}) => {
  const table = useRef();
  const expandedRowRender = info => {
    return (
      <Table
        columns={expandedCols}
        dataSource={info?.babies}
        pagination={false}
        scroll={{ x: 'calc(1500px + 100%)', y: '100vh' }}
      />
    );
  };

  useEffect(() => {
    if (table.current) {
      document
        .getElementsByClassName('table-to-xls')[0]
        .setAttribute('id', 'table-to-xls');
    }
  }, [table]);
  return (
    <div>
      <div style={{ marginBottom: 15 }}>
        <p style={{ display: 'inline-block', marginRight: 5 }}>
          Округлить значения:{' '}
        </p>
        <Switch onChange={roundUp} />
      </div>

      <Table
        className="table-to-xls"
        style={{ marginBottom: 15 }}
        ref={table}
        columns={columns}
        dataSource={dataSource}
        expandedRowRender={expandedRowRender}
        scroll={{ x: 'calc(1500px + 100%)', y: '100vh' }}
        // scroll={{ x: columns.length * scrollMultiplier + 'px', y: '100vh' }}
        pagination={pagination}
        bordered
      />
      {isEditable ? buttons : null}
      <Modal
        tableType={tableType}
        dataType={dataType}
        formItemLayout={formItemLayout}
        tableName={firstTable}
        visible={[visibleFirst, visibleSecond]}
        inputs={inputs}
        modalClose={[
          () => setVisibleFirst(false),
          () => setVisibleSecond(false),
        ]}
        modalOpen={[() => setVisibleSecond(true)]}
        refresh={() => {
          fetchData();
          if (fetchSumSources) {
            fetchSumSources();
          }
        }}
        company={company}
      />
      <Charts />
    </div>
  );
};

export default SuperTable;

import React, { useState } from 'react';

import { renderColumns } from './data/columns';
import inputs from './data/modalInputs';
import Editor from '../../../../modules/Editor';
import { Table, Typography, Button } from 'antd';
import StandardTable from '../../../../modules/StandardTable';
import PekUploadShablon from '../../PekUploadShablon';
import PekDeleteAllFromTable from '../../PekDeleteAllFromTable';
import PekPromoFilesUpload from '../../PekPromoFilesUpload';

const PAGE_SIZE = 15;

const Soils = ({ tableName }) => {
  const [currentPage, setCurrentPage] = useState('1');
  const [totalPage, setTotalPage] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const transformDataSource = (data, setState) => {
    const newDataSource = [];
    data.forEach(el =>
      newDataSource.push({
        key: el.id,
        ...el,
      })
    );
    setState(newDataSource);
  };
  const columns = rounding => (token, refresh) => {
    const columns = [
      {
        title: 'Действие',
        key: 'action',
        render: (text, record) => (
          <Editor
            addInputs={inputs}
            editInputs={inputs}
            text={text}
            tableName={'soil_impact_monitoring_information'}
            editTableType={'gos_pek'}
            record={record}
            refresh={refresh}
          />
        ),
      },
      {
        title: 'Точки отбора проб ',
        dataIndex: 'sampling_points',
        key: 'sampling_points',
      },
      {
        title: 'координаты',
        children: [
          {
            title: 'Долгота',
            dataIndex: 'latitude',
            key: 'latitude',
          },
          {
            title: 'Широта',
            dataIndex: 'longitude',
            key: 'longitude',
          },
        ],
      },

      {
        title: 'Наименование загрязняющих веществ',
        dataIndex: 'name_pollutants',
        key: 'name_pollutants',
      },
      {
        title: 'Предельно допустимая концентрация (мг/кг)',
        dataIndex: 'maximum_allowable_concentration',
        key: 'buried_volume_of_waste',
      },
      {
        title: 'Фактическая концентрация (мг/кг)',
        dataIndex: 'actual_concentration',
        key: 'actual_concentration',
      },
      {
        title:
          'Наличие превышения предельно допустимых концентраций, кратность',
        dataIndex: 'existence_exceeding_concentrations',
        key: 'existence_exceeding_concentrations',
      },
      {
        title:
          'Мероприятия по устранению нарушений и улучшению экологической обстановки (с указанием сроков)',
        dataIndex: 'measures_eliminate_violations_improve',
        key: 'measures_eliminate_violations_improve',
      },
    ];
    return columns;
  };

  return (
    <>
      <Typography.Title level={4}>
        Сведения по мониторингу воздействия на почвенный покров
      </Typography.Title>
      <StandardTable
        inputs={inputs}
        tableName={'soil_impact_monitoring_information'}
        tableType={'gos_pek'}
        columns={rounding => columns(rounding)}
        transformDataSource={transformDataSource}
        dataSource={[]}
      />
      <Button.Group style={{ marginBottom: 20 }}>
        <PekUploadShablon tableName={'soil_impact_monitoring_information'} shablonName="ПЭК_Почвы_10.xlsx" />
        <PekDeleteAllFromTable tableName={'soil_impact_monitoring_information'} />
      </Button.Group>
      <div>
        <PekPromoFilesUpload
          columnName="soil_file"
          title="Протокол, (максимум 50мб)"
        />
      </div>
    </>
  );
};

export default Soils;

import {Input} from "antd";
import React from "react";

export default [
    {
        title: 'Типы заказов продукции',
        dataIndex: 'name',
        key: 'name',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
            },
        ],
        component: <Input />,
    }
]
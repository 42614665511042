import { Button, message, Table, Typography } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { pekTableGetData } from '../../../../../redux/actions/TableActions';
import { pekQuarterChange } from '../../../../../redux/actions/pekDateActions';
import Modal from '../../../../../modules/StandardTable/components/Modal';
import inputs from './data/inputs';
import Editor from '../../../../../modules/Editor';
import StandardTable from '../../../../../modules/StandardTable';
import PekUploadShablon from '../../../PekUploadShablon';
import PekDeleteAllFromTable from '../../../PekDeleteAllFromTable';

const PAGE_SIZE = 15;

const InformationOnTheAccumulation = ({
  promoAreaId,
  quarter,
  year,
  token,
  tableName,
  company = true,
  tableType = 'standard',
  formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 12 },
    },
    wrapperCol: {
      xs: { span: 18 },
      sm: { span: 12 },
    },
  },
}) => {
  const { user } = useSelector(state => ({
    ...state.userStore,
  }));
  const [operation, setOperation] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  let query = ' ';
  const [search, setSearch] = useState('');
  const [visible, setVisible] = useState(false);
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState('1');
  const [departments, setDepartments] = useState([]);
  const [dataTablePek, setdataTablePek] = useState([]);
  const [id, setId] = useState();

  const modalShow = () => setVisible(true);

  const modalClose = () => setVisible(false);

  const categoryStatusTypes = {
    'Ожидается определение категории': 'waiting',
    'Ожидается подпись': 'signing',
    'Отказано ': 'rejected',
    'Категория определена: I': 1,
    'Категория определена: II ': 2,
    'Категория определена: III': 3,
    'Категория определена: IV': 4,
  };
  const [selectedCategory, setSelectedCategory] = useState(
    user.username?.role_id === '5'
      ? 'Ожидается подпись'
      : 'Ожидается определение категории'
  );

  // useEffect(() => {
  //   const params = new URLSearchParams();
  //   if (search) {
  //     params.append('search', search);
  //   } else {
  //     params.delete('search');
  //   }
  //   if (currentPage) {
  //     params.append('page', currentPage);
  //   }
  //   history.push({ search: params.toString() });
  // }, [search, history, currentPage]);
  //
  // useEffect(() => {
  //   console.log("WWS",search, currentPage, selectedCategory);
  //   fetchUsers();
  // }, [search, currentPage, selectedCategory]);

  const transformDataSource = (data, setState) => {
    const newDataSource = [];
    data.forEach(el =>
      newDataSource.push({
        key: el.id,
        ...el,
      })
    );
    setState(newDataSource);
  };

  // const onDeleteAsset = async id => {
  //     await axios.delete(
  //         `http://185.146.3.215:4000/api/data/delete/files?id=${id}`
  //     );
  //     console.log('Удаление');
  //     submit({year, quarter, promoAreaId});
  // };
  //
  // const onAcceptFile = async id => {
  //     await axios({
  //         method: 'POST',
  //         url: `http://185.146.3.215:4000/api/pek/excel/accept/${id}`,
  //         headers: {
  //             Authorization: `Bearer ${token}`,
  //         },
  //     })
  //         .then(() => {
  //             message.success('Успешно');
  //         })
  //         .catch(() => {
  //             message.error('Ошибка');
  //         });
  //     submit({year, quarter, promoAreaId});
  // };
  //
  // const closePopup = () => {
  //     setOperation(false);
  //     submit({year, quarter, promoAreaId});
  // };

  const submit = async () => {
    message.loading('Загружаем данные...');
    axios
      .get('http://185.146.3.215:4000/api/department', {
        header: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => {
        setDepartments(res.data);
      });
  };

  const fetchUsers = async () => {
    await axios({
      method: 'GET',
      url: `http://185.146.3.215:4000/api/user/category?pageSize=${PAGE_SIZE}&pageNumber=${currentPage}&search=${search}&category=${categoryStatusTypes[selectedCategory]}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(function(response) {
        message.success('Успешно загрузили');
        //handle success
        console.log(response.data);
        setDataSource(response.data.users);
        setTotalPage(response.data.total);
        console.log(response.data);
      })
      .catch(function(response) {
        //handle error
        console.log(response);
      });
  };
  useEffect(() => {
    submit();
  }, []);

  const categoryList = {
    1: 'I',
    2: 'II',
    3: 'III',
    4: 'IV',
  };

  const columns = rounding => (token, refresh) => {
    console.log(dataSource.filter(data => data.department_id === '6'));
    return [
      {
        title: 'Действие',
        key: 'action',
        render: (text, record) => (
          <Editor
            addInputs={inputs}
            editInputs={inputs}
            text={text}
            tableName={tableName}
            editTableType={'gos_pek'}
            record={record}
            refresh={refresh}
          />
        ),
      },
      {
        title: 'Вид отхода',
        dataIndex: 'waste_type', //??
        key: 'waste_type',
      },
      {
        title: 'Код отхода',
        dataIndex: 'waste_code',
        key: 'waste_code',
      },
      {
        title: 'Лимит накопления отходов',
        dataIndex: 'limit_waste',
        key: 'limit_waste',
      },
      {
        title: 'Срок накопления',
        dataIndex: 'accumulation_term',
        key: 'accumulation_term',
      },
      {
        title: 'Место накопления отхода',
        children: [
          {
            title: 'Широта',
            dataIndex: 'latitude',
            key: 'latitude',
          },
          {
            title: 'Долгота ',
            dataIndex: 'longitude',
            key: 'longitude',
          },
        ],
      },
      {
        title: 'Остаток на начало отчетного периода, тонн',
        dataIndex: 'balance_reporting_period',
        key: 'balance_reporting_period',
      },
      {
        title: 'Образованный объем отходов на предприятии, тонн',
        dataIndex: 'volume_of_waste_enterprise',
        key: 'volume_of_waste_enterprise',
      },
      {
        title: 'Фактический объем накопления за отчетный период, тонн',
        dataIndex: 'actual_volume_accumulation_period',
        key: 'actual_volume_accumulation_period',
      },
      {
        title: 'Переданный объем отходов на проведение операции с ними, тонн',
        dataIndex: 'transferred_volume_waste_operations',
        key: 'transferred_volume_waste_operations',
      },
      {
        title: 'БИН организации, которому передан отход ',
        dataIndex: 'bin_organization_flash_raw',
        key: 'bin_organization_flash_raw',
      },
      {
        title:
          'Объем отхода, с которым проведены операции на предприятии, тонн',
        dataIndex: 'waste_volume_operations_company',
        key: 'waste_volume_operations_company',
      },
      {
        title: 'Остаток отходов в накопителе на конец отчетного периода, тонн',
        dataIndex: 'remaining_waste_period',
        key: 'remaining_waste_period',
      },
    ];
  };

  return (
    <div>
      <Typography.Text>
        Заполняется при наличии накопления отходов производства и потребления на
        объектах оператора
      </Typography.Text>
      <StandardTable
        inputs={inputs}
        tableName={tableName}
        tableType={'gos_pek'}
        scrollMultiplier={170}
        columns={rounding => columns(rounding)}
        transformDataSource={transformDataSource}
        dataSource={[]}
      />

      <Button.Group style={{ marginBottom: 20 }}>
        <PekUploadShablon tableName={tableName} shablonName="ПЭК_Отходы_2.xlsx" />
        <PekDeleteAllFromTable tableName={tableName} />
      </Button.Group>
    </div>
  );
};

const mapStateToProps = ({
  userStore: { token },
  companyStore: { promoAreaId },
  pekDateStore: { quarter, year },
  tableDataStore: { dataTable },
}) => ({
  promoAreaId,
  token,
  quarter,
  year,
  dataTable,
});
const mapDispatchToProps = {
  pekTableGetData,
  pekQuarterChange,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InformationOnTheAccumulation);

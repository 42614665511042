import React from 'react';
import {Input} from 'antd';
import {isNumberAndAboveZero} from '../../../../../utils/validation';

export default [
    {
        title: 'Наименование источников воздействия',
        dataIndex: 'name_sources_exposure',
        key: 'name_sources_exposure',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Установленный норматив микрозиверт в час (мкЗв/час)',
        dataIndex: 'established_standard',
        key: 'established_standard',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
                validator: isNumberAndAboveZero,
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Фактический результат мониторинга (мкЗв/час)',
        dataIndex: 'actual_result',
        key: 'actual_result',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
                validator: isNumberAndAboveZero,
            },
        ],
        component: <Input/>,
    },
    {
        title:
            'Превышение нормативов "Санитарно-эпидемиологические требования к обеспечению радиационной безопасности", кратность',
        dataIndex: 'exceeding_standards',
        key: 'exceeding_standards',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Мероприятия по устранению нарушения (с указанием сроков)',
        dataIndex: 'corrective_measures',
        key: 'corrective_measures',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
            },
        ],
        component: <Input/>,
    },
];

import { Button, Icon, Popconfirm } from 'antd';
import React from 'react';
import { connect, useSelector } from 'react-redux';
import {
  remove,
  removeOrderProductionTypes,
} from '../../../api/StandardTableAPI';

const deleteItem = async (token, id, tableName, refresh, editTableType, formId) => {
  try {
    if (editTableType === 'order_production_tables') {
      await removeOrderProductionTypes(token, { tableName, itemId: id, formId });
    } else {
      await remove(token, { tableName, itemId: id });
    }
    await refresh();
  } catch (error) {
    console.error(error);
  }
};

const DeleteButton = ({ record, token, tableName, refresh, editTableType }) => {
  const { orderProductionId } = useSelector(state => ({
    ...state.userStore,
  }));

  return (
    <>
      <Popconfirm
        title="Вы уверены?"
        onConfirm={() =>
          deleteItem(
            token,
            record.data_id ? record.data_id : record.id,
            tableName === 'waste' ? 'waste_data' : tableName,
            refresh,
            editTableType,
            orderProductionId
          )
        }
        okText="Да"
        cancelText="Нет"
      >
        <Button type="danger">
          <Icon type="delete" />
        </Button>
      </Popconfirm>
    </>
  );
};

const mapStateToProps = ({ userStore: { token } }) => ({ token });

export default connect(mapStateToProps)(DeleteButton);

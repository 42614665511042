const meta = {
  boilerMeta: {
    fields: [
      { label: 'Плостность,р, кг/м3', name: 'p' },
      { label: 'Общее время работы , t, ч/период', name: 't' },
      { label: 'Годовой расход газа, В, т/период', name: 'B' },
      { label: 'Секундный расход топлива, b, кг/ч', name: 'b' },
      { label: 'Массовая концентрация общей серы, m1,  г/м3', name: 'm1' },
      { label: 'Массовая концентрация меркаптановой серы,m2,  г/м3', name: 'm2' },
      { label: 'Массовая концентрация сероводорода , m3, г/м3', name: 'm3' },
      { label: 'Теплота сгорания натурального топлива, Qri, МДж/кг', name: 'Q_r_i' },
    ],
    codes: ['0001', '0110', '0232']
  },
  furnaceMeta: {
    fields: [
      { label: 'Плостность,р, кг/м3', name: 'p' },
      { label: 'Общее время работы , t, ч/период', name: 't' },
      { label: 'Годовой расход, В, кг/период', name: 'B' },
      { label: 'Секундный расход топлива, Bo, м3/с', name: 'B_o' },
      { label: 'Содержание серы в топливе на рабочую массу (общая сера), m, %', name: 'm' },
      { label: 'Содержание серы в топливе на рабочую массу (меркаптановая сера), m1, %', name: 'm1' },
    ],
    codes: ['0122', '0226']
  },
  torchMeta: {
    fields: [
      { label: 'Плостность,р, кг/м3', name: 'p' },
      { label: 'Общее время работы , t, ч/период', name: 't' },
      { label: 'Годовой расход, В, м3/период', name: 'B' }
    ],
    codes: ['0309', '0337', '0216']
  },
  desMeta: {
    fields: [
      { label: 'Номинальный расход топлива, b, кг/ч', name: 'b' },
      { label: 'Расход дизельного топлива, B, т/период\n', name: 'B' }
    ],
    codes: ['0174', '0265']
  },
  postMeta: {
    fields: [
      { label: 'Время работы, t, кг/период', name: 't' },
      { label: 'Расход краски за период, В, кг/период', name: 'B' },
    ],
    codes: ['6005']
  },
  wellMeta: {
    fields: [],
    codes: ['7073', '8063', '7649']
  },
  tankMeta: {
    fields: [
      { label: 'Количество жидкости, закачиваемое в резервуар в течение года, В, т/период', name: 'B' },
      { label: 'Плостность,р, т/м3', name: 'p' }
    ],
    codes: ['0182']
  },
  pumpMeta: {
    fields: [
      { label: 'Коэффициент использование оборудования, k', name: 'k' },
      { label: 'Углеводороды С1-С5, c1', name: 'c1' },
      { label: 'Сернистый ангидрид, c2', name: 'c2' },
      { label: 'Фланцы , шт', name: 'n1' },
      { label: 'ЗРА, шт', name: 'n2' },
      { label: 'Сальник , шт', name: 'n3' },
    ],
    codes: ['8187']
  },
  weldingMeta: {
    fields: [
      { label: 'Расход электрода, В, кг/год', name: 'B' },
      { label: 'Максимальный расход, b, кг/ч', name: 'b' }
    ],
    codes: ['7179', '7542']
  },
  drainageMeta: {
    fields: [],
    codes: ['7502', '7536']
  }
};

const types = {
  '0001': 'boiler1',
  '0110': 'boiler2',
  '0232': 'boiler3',
  '0122': 'furnace1',
  '0226': 'furnace2',
  '0309': 'torch1',
  '0337': 'torch2',
  '0216': 'torch3',
  '0174': 'des1',
  '0265': 'des2',
  '6005': 'post',
  '7073': 'well1',
  '8063': 'well2',
  '7649': 'well3',
  '0182': 'tank',
  '8187': 'pumps',
  '7179': 'welding1',
  '7542': 'welding2',
  '7502': 'drainage1',
  '7536': 'drainage2',
}

export {
  meta,
  types
};

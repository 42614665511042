import { Button, Collapse, Form, InputNumber } from 'antd';
import axios from 'axios';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from '../../../hoc/compose';
import withInteractivity from '../../../hoc/withInteractivity';
import InfoAtmos from './Tables/Tab1';
import Pollutants from './Tables/Tab2';
import InstrumentalMeasurements from './Tables/Tab3';
import PollutantsResults from './Tables/Tab4';
import InfoMonitoring from './Tables/Tab5';
import styles from '../../../assets/Main.module.css';
import { Typography } from 'antd';

const { Panel } = Collapse;

class Emissions extends Component {
  state = {
    stationarySources: 0,
    organized: 0,
    unorganized: 0,
    active: '',
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      measurements,
      pollutants,
      acm,
      infoAtmos,
      pollutantsResults,
      infoMonitoring,
    } = this.props.state;
    const { showTableHandler } = this.props;
    const { active } = this.state;
    console.log(this.state);
    return (
      <div>
        <Collapse bordered={false}>
          <Panel header="Сведения об источниках загрязнения атмосферы" key="1">
            <InfoAtmos
              changeSumSources={this.changeSumSources}
              fetchSumSources={this.fetchSumSources}
              tableName={'information_sources'}
            />
          </Panel>

          <Panel header="Фактические выбросы загрязняющих веществ" key="2">
            <Pollutants
              changeSumSources={this.changeSumSources}
              fetchSumSources={this.fetchSumSources}
              tableName={'actual_emissions_pollutants'}
            />
          </Panel>

          <Panel
            header="Результаты на основе измерений выбросов загрязняющих веществ"
            key="3"
          >
            <InstrumentalMeasurements
              changeSumSources={this.changeSumSources}
              fetchSumSources={this.fetchSumSources}
              tableName={'results_based_air_pollutant_emissions'}
            />
          </Panel>

          <Panel
            header="Результаты на основе расчетов выбросов загрязняющих веществ в атмосферный воздух"
            key="4"
          >
            <PollutantsResults
              changeSumSources={this.changeSumSources}
              fetchSumSources={this.fetchSumSources}
              tableName={'results_based_calculations_emissions'}
            />
          </Panel>

          <Panel
            header="Сведения по мониторингу воздействия на атмосферный воздух"
            key="5"
          >
            <InfoMonitoring
              changeSumSources={this.changeSumSources}
              fetchSumSources={this.fetchSumSources}
              tableName={'information_monitoring_impact_air'}
            />
          </Panel>
        </Collapse>
      </div>
    );
  }
}

const mapStateToProps = ({
  userStore: { token },
  companyStore: { promoAreaId },
  pekDateStore: { year, quarter },
}) => ({ token, promoAreaId, year, quarter });

const tableState = {
  infoAtmos: false,
  measurements: false,
  pollutants: false,
  pollutantsResults: false,
  infoMonitoring: false,
  acm: false,
  visible: false,
};

export default compose(
  withInteractivity(tableState),
  connect(mapStateToProps),
  Form.create()
)(Emissions);

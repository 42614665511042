import React, { useState } from 'react';
import AddButtonContainer from '../containers/AddButton';


const AddButton = ({
  inputs,
  tableName,
  record,
  refresh,
  dataType,
  tableType,
  columnName
}) => {
  const [visible, setVisible] = useState(false);

  const modalShow = () => setVisible(true);

  const modalClose = () => setVisible(false);

  const props = {
    inputs,
    tableName,
    record,
    refresh,
    modalShow,
    visible,
    modalClose,
    dataType,
    tableType,
    columnName
  };

  return <AddButtonContainer {...props} />;
};

export default AddButton;

import { Button, Form, message, Popconfirm } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  deletePekPromoAreasFile,
  getPromoAreaById,
  uploadPekPromoAreasFile,
} from '../../api/PekAPI';
import config from '../../config';
import { checkEditableOrder } from '../../utils/columnFilter';

const PekPromoFilesUpload = ({ columnName, title }) => {
  const params = useParams();
  const [promoArea, setPromoArea] = useState();

  const { token, order } = useSelector(state => ({
    ...state.userStore,
    ...state.pekDateStore,
  }));

  useEffect(() => {
    fetchPromoArea();
  }, [params.id]);

  const fetchPromoArea = async () => {
    getPromoAreaById({ token, id: params.id }).then(res =>
      setPromoArea(res.data)
    );
  };

  const setFileCheck = file => {
    if ((file?.size / 1024 / 1024).toFixed(4) > 50) {
      message.error('Файл больше 50мб');
      return;
    }
    handleUpload(file);
  };

  const handleUpload = async file => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('columnName', columnName);
    formData.append('promoId', promoArea.id);
    await uploadPekPromoAreasFile(formData, token)
      .then(() => {
        window.location.reload();
      })
      .catch(() => message.error('Произошла ошибка'));
  };

  const handleDelete = async () => {
    await deletePekPromoAreasFile(
      { columnName, id: promoArea.id, fileName: promoArea[columnName] },
      token
    )
      .then(() => {
        window.location.reload();
      })
      .catch(() => message.error('Произошла ошибка'));
  };

  const canEditFile = checkEditableOrder(order);

  return (
    <div>
      <Form.Item label={title}>
        {promoArea && promoArea[columnName] && (
          <div style={{ marginBottom: 15 }}>
            <a
              onClick={() =>
                window.open(`${config.api_url}${promoArea[columnName]}`)
              }
            >
              {promoArea[columnName]}
            </a>
            {canEditFile && (
              <Popconfirm
                title="Точно хотите удалить файл ?"
                onConfirm={handleDelete}
              >
                <Button
                  style={{ marginLeft: 15 }}
                  type="danger"
                  shape="circle"
                  icon="delete"
                />
              </Popconfirm>
            )}
          </div>
        )}
        {canEditFile && (
          <div>
            <input
              type="file"
              onChange={e => setFileCheck(e.target.files[0])}
            />
          </div>
        )}
      </Form.Item>
    </div>
  );
};

export default PekPromoFilesUpload;

import { Button, message, Modal, Tabs, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { uploadPekShablon } from '../../api/PekAPI';
import config from '../../config';
import { checkEditableOrder } from '../../utils/columnFilter';

const PekUploadShablon = ({ tableName, shablonName, report }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const params = useParams();
  const [state, setState] = useState({
    fileList: [],
    uploading: false,
  });

  const { order, token, year, quarter } = useSelector(state => ({
    ...state.userStore,
    ...state.pekDateStore,
  }));

  const { fileList, uploading } = state;

  const props = {
    onRemove: file => {
      setState(state => {
        const index = state.fileList.indexOf(file);
        const newFileList = state.fileList.slice();
        newFileList.splice(index, 1);
        return {
          fileList: newFileList,
        };
      });
    },
    beforeUpload: file => {
      setState(state => ({
        fileList: [...state.fileList, file],
      }));
      return false;
    },
    fileList,
  };

  const handleOk = () => {
    console.log(state.fileList[0], tableName, year, quarter, params.id);
    const formData = new FormData();
    formData.append('file', state.fileList[0]);
    formData.append('tableName', tableName);
    formData.append('year', year);
    formData.append('period', quarter);
    formData.append('formId', params.id);

    console.log('WWS', formData.get('file'), formData.get('tableName'));

    uploadPekShablon(formData, token)
      .then(() => window.location.reload())
      .catch(err => {
        console.log(err);
      });

    setIsModalVisible(false);
  };

  const canAddFile = checkEditableOrder(
    tableName === 'pek_water' ? report : order
  );

  return (
    canAddFile && (
      <>
        <Button
          onClick={() => setIsModalVisible(true)}
          type="primary"
          style={{ marginRight: 20, borderRadius: 4 }}
        >
          Загрузить через шаблон
        </Button>
        <Button
          onClick={() =>
            window.open(`${config.api_url}pek_shablon/${shablonName}`)
          }
          type="primary"
          style={{ marginRight: 20, borderRadius: 4 }}
        >
          Скачать пример шаблона
        </Button>
        <Modal
          title="Файл"
          visible={isModalVisible}
          okText="Отправить"
          onOk={handleOk}
          cancelText="Закрыть"
          onCancel={() => setIsModalVisible(false)}
        >
          <Upload {...props}>
            <Button>Выбрать файл</Button>
          </Upload>
        </Modal>
      </>
    )
  );
};

export default PekUploadShablon;

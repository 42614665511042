import { Button } from 'antd';
import React from 'react';
import AddButton from './components/AddButtton';
import CalculateModal from './components/CalculateModal';
import DeleteButton from './components/DeleteButton';
import EditButton from './components/EditButton';

const Editor = ({
  tableType,
  text,
  tableName,
  refresh,
  editInputs,
  addInputs,
  dataType,
  editable = true,
  record = {},
  editTableType = 'standard',
  columnName = false,
}) => {
  return (
    <Button.Group>
      {editTableType === 'waste' && (
        <AddButton
          tableType={editTableType}
          record={record}
          tableName={tableName}
          inputs={addInputs}
          refresh={refresh}
          dataType={dataType}
          columnName={columnName}
        />
      )}
      {tableName === 'greenhouse_gas_data' ||
        (editable && (
          <EditButton
            record={record}
            tableName={tableName}
            inputs={editInputs}
            refresh={refresh}
            dataType={dataType}
            editTableType={editTableType}
          />
        ))}
      <DeleteButton
        text={text}
        editTableType={editTableType}
        tableName={tableName}
        record={record}
        refresh={refresh}
      />
      {record.is_source ? (
        <>
          <AddButton
            tableType={tableType}
            record={record}
            tableName={tableName}
            inputs={addInputs}
            refresh={refresh}
            dataType={dataType}
            columnName={columnName}
          />
          {record.calculation_data && dataType !== 'instrumental_result' && (
            <CalculateModal record={record} refresh={refresh} />
          )}
        </>
      ) : null}
    </Button.Group>
  );
};

export default Editor;

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Select } from 'antd';
import inputs from './inputs';
import Editor from '../../../modules/Editor';
import StandardTable from '../../../modules/StandardTable';
import PekUploadShablon from '../PekUploadShablon';
import PekDeleteAllFromTable from '../PekDeleteAllFromTable';

const { Option } = Select;

const pekWaterTableType = [
  { value: 'hydrometeorological', label: 'Гидрометеорологические параметры' },
  { value: 'atmosphere', label: 'Атмосферный воздух' },
  { value: 'sea_water', label: 'Морские воды' },
  { value: 'bottom_sediments', label: 'Донные отложения' },
  { value: 'bentos', label: 'Бентос' },
  { value: 'phytoplankton', label: 'Фитопланктон' },
  { value: 'zooplankton', label: 'Зоопланктон' },
  { value: 'aquatic_vegetation', label: 'Водная растительность' },
  { value: 'ichthyofauna', label: 'Ихтиофауна' },
  { value: 'avifauna', label: 'Орнитофауна' },
  { value: 'seals', label: 'Тюлени' },
];

const PekWaterTable = ({ token, tableName, report }) => {
  const transformDataSource = (data, setState) => {
    const newDataSource = [];
    data.forEach(el =>
      newDataSource.push({
        key: el.id,
        ...el,
      })
    );
    setState(newDataSource);
  };

  const typeIndex = inputs.findIndex(({ dataIndex }) => dataIndex === 'type');

  const newInputs = inputs.map((item, index) => {
    return index === typeIndex
      ? {
          ...item,
          component: (
            <Select>
              {pekWaterTableType.map(option => (
                <Option key={option.label}>{option.label}</Option>
              ))}
            </Select>
          ),
        }
      : item;
  });

  const columns = rounding => (token, refresh) => {
    const columns = [
      {
        title: 'Действие',
        key: 'action',
        render: (text, record) => (
          <Editor
            addInputs={newInputs}
            editInputs={newInputs}
            text={text}
            tableName={tableName}
            editTableType={'pek_water'}
            record={record}
            refresh={refresh}
          />
        ),
      },
      {
        title: 'Тип',
        dataIndex: 'type',
        key: 'type',
        render: type => (
          <span>{type}</span>
        ),
      },
      {
        title: 'Определяемые компоненты',
        dataIndex: 'defining_component',
        key: 'defining_component',
      },
      {
        title: 'Наименование станции',
        dataIndex: 'station_name',
        key: 'station_name',
      },
      {
        title: 'Координаты',
        children: [
          {
            title: 'Широта',
            dataIndex: 'latitude',
            key: 'latitude',
          },
          {
            title: 'Долгота',
            dataIndex: 'longitude',
            key: 'longitude',
          },
        ],
      },
      {
        title: 'Сезон года',
        dataIndex: 'year_sezon',
        key: 'year_sezon',
      },
      {
        title: 'Повторность отбора данных',
        dataIndex: 'interval_data_collection',
        key: 'interval_data_collection',
      },
      {
        title: 'Результат анализа',
        dataIndex: 'analys_result',
        key: 'analys_result',
      },
      {
        title: 'Метод проведения анализа',
        dataIndex: 'analys_method',
        key: 'analys_method',
      },
    ];
    return columns;
  };

  return (
    <div>
      <StandardTable
        inputs={newInputs}
        tableName={tableName}
        tableType={'pek_water'}
        scrollMultiplier={200}
        columns={rounding => columns(rounding)}
        transformDataSource={transformDataSource}
        dataSource={[]}
      />
      <Button.Group style={{ marginBottom: 20 }}>
        <PekUploadShablon tableName={tableName} report={report} shablonName="ПЭК_Море.xlsx" />
        <PekDeleteAllFromTable tableName={tableName} report={report} />
      </Button.Group>
    </div>
  );
};

const mapStateToProps = ({
  pekDateStore: { year, quarter },
  userStore: { token },
}) => ({
  year,
  quarter,
  token,
});

export default connect(mapStateToProps)(PekWaterTable);

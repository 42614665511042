const initialState = {
  substance: [],
  waterSubstance: [],
};

const substanceReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'SUBSTANCE_LIST':
      return {
        ...state,
        substance: payload,
      };
    case 'WATER_SUBSTANCE_LIST':
      return {
        ...state,
        waterSubstance: payload,
      };
    default:
      return state;
  }
};

export default substanceReducer;

import React from 'react';

import columnList from './data/columns';
import StandardTable from '../../../modules/StandardTable';
import inputs from './data/modalInputs';

const initialDataSource = [
  {
    key: '-10',
    number: 'Выбросы от стационарных источников:',
    children: [],
  },
  {
    key: '-3',
    number: 'Сбросы ЗВ со сточными водами:',
    children: [],
  },
  {
    key: '-5',
    number: 'Отходы',
    children: [],
  },
];

const tableName = 'tax';

const columns = rounding => (token, refresh) => [...columnList(rounding)];

const transformDataSource = ({ emissions = [], discharges = [] }, setState) => {
  let newDataSource = initialDataSource.slice();
  newDataSource[0].children = [];
  newDataSource[1].children = [];
  console.log('emissions: ', emissions);
  emissions.forEach((el, i) => {
    newDataSource[0].children.push({
      key: 'Выбросы' + i,
      number: i + 1,
      ...el,
    });
  });
  discharges.forEach((el, i) => {
    newDataSource[1].children.push({ key: 'Сбросы' + i, number: i + 1, ...el });
  });

  setState(newDataSource);
};

const Soils = () => (
  <StandardTable
    tableName={tableName}
    inputs={inputs}
    columns={rounding => columns(rounding)}
    transformDataSource={transformDataSource}
    scrollMultiplier={200}
    tableType="tax"
    isTax
  />
);

export default Soils;

import React, { useState } from 'react';
import { Input } from 'antd';
import { isNumberAndAboveZero } from '../../../../../../utils/validation';

export default [
  {
    title: 'Наименование объекта',
    dataIndex: 'name_object',
    key: 'name_object',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Точки отбора',
    dataIndex: 'sampling_points',
    key: 'sampling_points',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Наблюдаемые компоненты',
    dataIndex: 'observable_components',
    key: 'observable_components',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Методика проведения мониторинга',
    dataIndex: 'monitoring_methodology',
    key: 'monitoring_methodology',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  // {
  //   title: 'Место накопления отхода',
  //   children: [
  //     {
  //       title: 'Широта',
  //       dataIndex: 'width',
  //       key: 'width',
  //       rules: [
  //         {
  //           required: true,
  //           message: 'Данные обязательны для заполнения',
  //           validator: isNumberAndAboveZero
  //         },
  //       ],
  //       component: <Input />,
  //     },
  //     {
  //       title: 'Долгота',
  //       dataIndex: 'longitude',
  //       key: 'longitude',
  //       rules: [
  //         {
  //           required: true,
  //           message: 'Данные обязательны для заполнения',
  //           validator: isNumberAndAboveZero
  //         },
  //       ],
  //       component: <Input />,
  //     },
  //   ],
  // },
  {
    title: 'Результаты',
    dataIndex: 'results',
    key: 'results',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Наличие превышений/причина',
    dataIndex: 'excessiveness',
    key: 'excessiveness',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Вид операции с оставшимся объемом отходов',
    dataIndex: 'type_operation_volume_waste',
    key: 'type_operation_volume_waste',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  // {
  //   title: 'Переданный объем отходов на проведение операции с ними, тонн',
  //   dataIndex: 'transferred_volume_waste_operations',
  //   key: 'transferred_volume_waste_operations',
  //   rules: [
  //     {
  //       required: true,
  //       message: 'Данные обязательны для заполнения',
  //     },
  //   ],
  //   component: <Input />,
  // },
  // {
  //   title: 'БИН организации, которому передан отход',
  //   dataIndex: 'bin_organization_flash_raw',
  //   key: 'bin_organization_flash_raw',
  //   rules: [
  //     {
  //       required: true,
  //       message: 'Данные обязательны для заполнения',
  //     },
  //   ],
  //   component: <Input />,
  // },
  // {
  //   title: 'Объем отхода, с которым проведены операции на предприятии, тонн',
  //   dataIndex: 'waste_volume_operations_company',
  //   key: 'waste_volume_operations_company',
  //   rules: [
  //     {
  //       required: true,
  //       message: 'Данные обязательны для заполнения',
  //     },
  //   ],
  //   component: <Input />,
  // },
  // {
  //   title: 'Остаток отходов в накопителе на конец отчетного периода, тонн',
  //   dataIndex: 'remaining_waste_period',
  //   key: 'remaining_waste_period',
  //   rules: [
  //     {
  //       required: true,
  //       message: 'Данные обязательны для заполнения',
  //     },
  //   ],
  //   component: <Input />,
  // },
];

export const rebuildCoordinates = coordinate => {
  if (!!coordinate === false) return '';

  const numericCoordinate = parseFloat(coordinate);

  if (numericCoordinate > 100) {
    const dot = '.';
    const position = 2;

    const output = [
      coordinate.slice(0, position),
      dot,
      coordinate.slice(position),
    ].join('');

    return parseFloat(output);
  } else return numericCoordinate;
};

import React from 'react';

import columnList from './data/columns';
import StandardTable from '../../../../modules/StandardTable';
import inputs from './data/modalInputs';
import Editor from '../../../../modules/Editor';

const tableName = 'pm_analys';

const columns = rounding => (token, refresh) => [
  {
    title: 'Действие',
    key: 'action',
    render: (text, record) => (
      <Editor
        addInputs={inputs}
        editInputs={inputs}
        text={text}
        tableName={tableName}
        record={record}
        refresh={refresh}
      />
    ),
  },
  ...columnList(rounding),
];

const transformDataSource = (data, setState) => {
  const newDataSource = [];
  data.forEach((el, i) =>
    newDataSource.push({
      key: el.id,
      number: i + 1,
      ...el,
    })
  );
  setState(newDataSource);
};

const Analysis = () => (
  <StandardTable
    tableName={tableName}
    inputs={inputs}
    columns={rounding => columns(rounding)}
    transformDataSource={transformDataSource}
    scrollMultiplier={170}
  />
);

export default Analysis;

import { Input, Select } from 'antd';
import React from 'react';

export default [
  {
    title: 'Категория источника',
    dataIndex: 'source_name',
    key: 'source_name',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Select />,
  },
  {
    title: 'Номер источника',
    dataIndex: 'source_number',
    key: 'source_number',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
];

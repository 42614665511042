import React from 'react';
import { Input, Select } from 'antd';
import { isNumberAndAboveZero } from '../../../../../../utils/validation';

export default [
  {
    title: 'Наименование',
    dataIndex: 'name_area',
    key: 'name_area',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Широта',
    dataIndex: 'latitude',
    key: 'latitude',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Долгота',
    dataIndex: 'longitude',
    key: 'longitude',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Инвентаризационный номер источников выбросов',
    dataIndex: 'emission_source_inventory_number',
    key: 'emission_source_inventory_number',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Наименование источников выбросов',
    dataIndex: 'name_emission_sources',
    key: 'name_emission_sources',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Код вещества',
    dataIndex: 'substance_code',
    key: 'substance_code',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Наименование загрязняющих веществ',
    dataIndex: 'name_pollutants',
    key: 'name_pollutants',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Установленный норматив, г/с',
    dataIndex: 'established_standard_gramm_sek',
    key: 'established_standard_gramm_sek',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Установленный норматив, тонн/год',
    dataIndex: 'established_standard_tonn',
    key: 'established_standard_tonn',
    footerTitle: 'Сумма, Установленный норматив тонн/год',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Фактический объем выбросов загрязняющих веществ, г/с',
    dataIndex: 'actual_volume_emissions_pollutants_gramm_sek',
    key: 'actual_volume_emissions_pollutants_gramm_sek',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title:
      'Фактический объем выбросов загрязняющих веществ,тонн/кв',
    dataIndex: 'actual_volume_emissions_pollutants_tonn_quarter',
    key: 'actual_volume_emissions_pollutants_tonn_quarter',
    footerTitle: 'Сумма, Фактический объем выбросов ЗВ тонн/кв',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title:
      'Фактический объем выбросов загрязняющих веществ,тонн/год',
    dataIndex: 'actual_volume_emissions_pollutants_tonn',
    key: 'actual_volume_emissions_pollutants_tonn',
    footerTitle: 'Сумма, Фактический объем выбросов ЗВ тонн/год',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Объем выбросов в атмосферный воздух без очистки,тонн/год',
    dataIndex: 'volume_purification',
    key: 'volume_purification',
    footerTitle: 'Сумма, Объем выбросов в атмосферный воздух без очистки,тонн/год',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Объем уловленных и обезвреженных ЗВ,всего тонна в год',
    dataIndex: 'volume_captured_ton_year',
    footerTitle: 'Сумма, Объем уловленных и обезвреженных ЗВ всего,тонн/год',
    key: 'volume_captured_ton_year',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title:
      'Объем уловленных и обезвреженных ЗВ,из них утилизировано тонна в год',
    dataIndex: 'volume_captured_ton_year_reclaimed',
    footerTitle:
      'Сумма, Объем уловленных и обезвреженных ЗВ из них утилизированных,тонн/год',
    key: 'volume_captured_ton_year_reclaimed',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Сверхнормативные выбросы, грамм в секунду',
    dataIndex: 'excessive_emissions_sek',
    key: 'excessive_emissions_sek',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Сверхнормативные выбросы, тонна в год',
    dataIndex: 'excessive_emissions_year_ton',
    key: 'excessive_emissions_year_ton',
    footerTitle: 'Сумма, Сверхнормативные выбросы т/г',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title:
      'Увеличение или снижение выбросов ЗВ в сравнении с разрешенным, % (т/г) тонна в год',
    dataIndex: 'increase_pollutant_emissions_comparison_year_ton',
    // footerTitle:
    //   'Сумма, Увеличение или снижение выбросов ЗВ в сравнении с разрешенным',
    key: 'increase_pollutant_emissions_comparison_year_ton',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Причины увеличения',
    dataIndex: 'reasons_increase',
    key: 'reasons_increase',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
];

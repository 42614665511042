import { Button, Icon, Switch, Table } from 'antd';
import { Axis, Chart, Coord, Geom, Label, Tooltip } from 'bizcharts';
import React, { memo, useEffect, useState } from 'react';
import Modal from '../components/Modal';
import { DeleteOutlined } from '@ant-design/icons';
import { removeSomeString } from '../../../api/StandardTableAPI';
import { checkEditableOrder } from '../../../utils/columnFilter';

const cols = {
  sales: {
    tickInterval: 10,
  },
};

const StandardTable = ({
  tableName,
  inputs,
  columns,
  dataSource,
  promo_areas,
  modalShow,
  visible,
  modalClose,
  fetchData,
  order,
  roundUp,
  deleteString,
  orderProductionId,
  tableType,
  defaultValues,
  scrollMultiplier = 100,
  pagination = { pageSize: 10 },
  company = true,
  formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 12 },
    },
    wrapperCol: {
      xs: { span: 18 },
      sm: { span: 12 },
    },
  },
}) => {
  const [graphData, setGraphData] = useState([]);

  useEffect(() => {
    if (tableName === 'emission_atmosphere' && dataSource?.length) {
      console.log(dataSource);
      const data = [
        {
          label: '1 квартал',
          value: parseFloat(dataSource[0]?.fact_discharges_1),
        },
        {
          label: '2 квартал',
          value: parseFloat(dataSource[0]?.fact_discharges_2),
        },
        {
          label: '3 квартал',
          value: parseFloat(dataSource[0]?.fact_discharges_3),
        },
        {
          label: '4 квартал',
          value: parseFloat(dataSource[0]?.fact_discharges_4),
        },
      ];
      setGraphData(data);
    }
  }, [tableName, dataSource]);

  const canAddItem = tableType === 'gos_pek' ? checkEditableOrder(order) : true;

  return (
    <div>
      {!promo_areas ? (
        <div style={{ marginBottom: 15 }}>
          <p style={{ display: 'inline-block', marginRight: 5 }}>
            Округлить значения:{' '}
          </p>
          <Switch onChange={roundUp} />
        </div>
      ) : null}

      <Table
        style={{ marginBottom: 15 }}
        columns={columns}
        size="small"
        dataSource={dataSource}
        bordered
        scroll={{ x: columns.length * scrollMultiplier + 'px', y: '100vh' }}
        pagination={pagination}
        footer={() => {
          if (tableName !== 'information_on_standards') {
            if (tableName === 'pollutants_in_wastewater') {
              const sums = { standards_for_discharges: 0 };
              dataSource.forEach(item => {
                sums.standards_for_discharges += parseFloat(
                  (item.standards_for_discharges || '0').replace(',', '.')
                );
              });
              return (
                <div style={{ textAlign: 'center' }}>
                  тонн/год: {sums.standards_for_discharges}{' '}
                  {/* <span style={{ marginLeft: 15 }}>тонн/год: {sums.tons}</span> */}
                </div>
              );
            } else if (tableName === 'information_on_the_limits') {
              const sums = {
                waste_disposal_limits: 0,
                waste_accumulation_limits: 0,
              };

              dataSource.forEach(item => {
                sums.waste_disposal_limits += parseFloat(
                  (item.waste_disposal_limits || '0').replace(',', '.')
                );
                sums.waste_accumulation_limits += parseFloat(
                  (item.waste_accumulation_limits || '0').replace(',', '.')
                );
              });
              return (
                <div style={{ textAlign: 'center' }}>
                  захоронения отходов тонн/год: {sums.waste_disposal_limits}{' '}
                  <span style={{ marginLeft: 15 }}>
                    накопление отходов тонн/год:{' '}
                    {sums.waste_accumulation_limits}
                  </span>
                </div>
              );
            }
            return (
              <div className="ant-table ant-table-default">
                <table>
                  <thead className="ant-table-thead">
                    <tr>
                      {inputs
                        .filter(item => item.footerTitle)
                        .map(itm => (
                          <th key={itm.dataIndex}>
                            {itm.footerTitle ? `${itm.footerTitle}` : null}
                          </th>
                        ))}
                    </tr>
                    <tr>
                      {inputs
                        .filter(item => item.footerTitle)
                        .map(itm => (
                          <th key={itm.dataIndex}>
                            {itm.footerTitle
                              ? `${dataSource
                                  .reduce(
                                    (sum, data) =>
                                      sum + parseFloat(data[itm.dataIndex]),
                                    0
                                  )
                                  .toFixed(7)}`
                              : null}
                          </th>
                        ))}
                    </tr>
                  </thead>
                </table>
                <style>{`.ant-table-footer{padding: 0px;}`}</style>
              </div>
            );
          }

          const sums = { gram: 0, tons: 0 };
          dataSource.forEach(item => {
            sums.gram += parseFloat((item.gram || '0').replace(',', '.'));
            sums.tons += parseFloat((item.tons || '0').replace(',', '.'));
          });
          return (
            <div style={{ textAlign: 'center' }}>
              грамм/сек: {sums.gram}{' '}
              <span style={{ marginLeft: 15 }}>тонн/год: {sums.tons}</span>
            </div>
          );
        }}
      />
      {tableName === 'emission_atmosphere' && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Chart
            height={450}
            width={580}
            data={graphData}
            scale={cols}
            forceFit
          >
            <Axis name="label" />
            <Axis name="value" />
            <Tooltip
            // crosshairs用于设置 tooltip 的辅助线或者辅助框
            // crosshairs={{
            //  type: "y"
            // }}
            />
            <Geom type="interval" position="label*value" />
          </Chart>
          <Chart
            data={graphData}
            width={400}
            forceFit
            onIntervalClick={ev => {
              const data = ev.data;
              if (data) {
                const name = data._origin['label'];
                window.open('http://www.baidu.com/s?wd=' + name);
              }
            }}
          >
            <Coord type="theta" />
            <Tooltip showTitle={false} />
            <Geom type="intervalStack" position="value" color="label">
              <Label content="label" />
            </Geom>
          </Chart>
        </div>
      )}

      {tableName !== 'report' && (
        <div>
          {canAddItem && (
            <Button
              type="primary"
              onClick={modalShow}
              style={{ marginBottom: 15 }}
            >
              <Icon type="plus" />
              Добавить
            </Button>
          )}
          {tableType === 'order_production_tables' ? (
            <Button
              type="danger "
              onClick={async () => {
                await deleteString();
                fetchData();
              }}
              style={{ marginBottom: 15, marginLeft: 10 }}
            >
              <DeleteOutlined />
              Удалить все
            </Button>
          ) : null}
          <Modal
            formItemLayout={formItemLayout}
            tableName={tableName}
            visible={visible}
            inputs={inputs}
            modalClose={modalClose}
            refresh={fetchData}
            company={company}
            tableType={tableType}
            defaultValues={defaultValues}
            dataSource={dataSource}
          />
        </div>
      )}
    </div>
  );
};

export default memo(StandardTable);

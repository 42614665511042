export const userSetAction = (loggedIn, token, user) => ({
  type: 'USER',
  payload: {
    token,
    loggedIn,
    user,
  },
});

export const orderProductionSetAction = orderProductionId => ({
  type: 'SET_ORDER_PRODUCTION',
  payload: {
    orderProductionId,
  },
});

export const userResetAction = () => ({
  type: 'USER_RESET',
});

import React from 'react';
import { Input } from 'antd';
import { isNumberAndAboveZero } from '../../../../utils/validation';

export default [
  {
    title: 'Код строки',
    dataIndex: 'code_string',
    key: 'code_string',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Направление затрат в соответствии с КДЗООС и УР1',
    dataIndex: 'cost_direction',
    key: 'cost_direction',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Объем текущих затрат',
    dataIndex: 'volume_expences',
    key: 'volume_expences',
    component: <Input />,
  },
  {
    title: 'Из них по основным видам:',
    children: [
      {
        title: 'материальные затраты',
        dataIndex: 'volume_expences_material',
        key: 'volume_expences_material',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
      {
        title: 'затраты на оплату труда и отчисления на социальные нужды',
        dataIndex: 'volume_expences_salary',
        key: 'volume_expences_salary',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
      {
        title:
          'выплачено иным предприятиям (организациям) за оказание природоохранных услуг',
        dataIndex: 'volume_expences_other_companies',
        key: 'volume_expences_other_companies',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
    ],
  },
  {
    title: 'В том числе по источникам финансирования',
    children: [
      {
        title: 'бюджетные средства',
        children: [
          {
            title: 'республиканский бюджет',
            dataIndex: 'republician_budget',
            key: 'republician_budget',
            rules: [
              {
                required: true,
                message: 'Данные обязательны для заполнения',
                validator: isNumberAndAboveZero,
              },
            ],
            component: <Input />,
          },
          {
            title: 'местный бюджет',
            dataIndex: 'local_budget',
            key: 'local_budget',
            rules: [
              {
                required: true,
                message: 'Данные обязательны для заполнения',
                validator: isNumberAndAboveZero,
              },
            ],
            component: <Input />,
          },
        ],
      },
      {
        title: 'собственные средства',
        dataIndex: 'own_budget',
        key: 'own_budget',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
      {
        title: 'прочие средства',
        dataIndex: 'other_budget',
        key: 'other_budget',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
    ],
  },
];

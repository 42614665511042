import React from 'react';
import { DatePicker, Input, Select } from 'antd';
import moment from 'moment';
import { isNumberAndAboveZero } from '../../../../../../utils/validation';

export default [
  {
    title: 'Количество стационарных источников выбросов ЗВ, всего единиц',
    dataIndex: 'number_stationary_sources_pollutant_emissions',
    key: 'number_stationary_sources_pollutant_emissions',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'организованные',
    dataIndex: 'organized',
    key: 'organized',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'неорганизованные',
    dataIndex: 'unorganized',
    key: 'unorganized',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'оборудованные очистными сооружениями',
    dataIndex: 'equipped_treatment_facilities',
    key: 'equipped_treatment_facilities',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'без очистки',
    dataIndex: 'without_cleaning',
    key: 'without_cleaning',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
];

import React from 'react';
import EmissionsTable from './Emissions';

const Emissions = () => {
  return (
    <div>
      <EmissionsTable />
    </div>
  );
};

export default Emissions;

import React from 'react';
import { Chart, Geom, Axis, Tooltip, Coord, Label, Legend } from 'bizcharts';
const CarbonMonoxidePie = ({ dv, cols }) => {
  function roundUp(num, precision) {
    precision = Math.pow(10, precision);
    return Math.ceil(num * precision) / precision;
  }

  return (
    <div>
      <Chart
        height={400}
        width={500}
        data={dv}
        scale={cols}
        padding={[0, 100, 0, 0]}
        forceFit
      >
        <Coord type="theta" radius={0.75} />
        <Axis name="percent" />
        <Legend position="right" offsetY={-window.innerHeight / 2 + 200} />
        <Tooltip
          showTitle={false}
          itemTpl='<li><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}</li>'
        />
        <Geom
          type="intervalStack"
          position="percent"
          color="item"
          tooltip={[
            'item*percent',
            (item, percent) => {
              percent = roundUp(percent * 100, 3) + '%';
              return {
                name: item,
                value: percent,
              };
            },
          ]}
          style={{
            lineWidth: 1,
            stroke: '#fff',
          }}
        >
          <Label
            content="percent"
            offset={-40}
            textStyle={{
              rotate: 0,
              textAlign: 'center',
              shadowBlur: 2,
              shadowColor: 'rgba(0, 0, 0, .45)',
            }}
          />
        </Geom>
      </Chart>
    </div>
  );
};

export default CarbonMonoxidePie;

import React from 'react';
import { Button, Card, Col, Form, Icon, Modal as BaseModal, Row } from 'antd';

const Modal = ({
  visible,
  modalClose,
  onSubmit,
  formItemLayout,
  renderInputs,
  inputs,
}) => {
  return (
    <BaseModal width="80vw" visible={visible} onCancel={modalClose} footer={[]}>
      <Form {...formItemLayout} onSubmit={onSubmit}>
        <Row>
          <Col span={23} style={{ marginBottom: 20 }}>
            <Card>{renderInputs(inputs)}</Card>
          </Col>
        </Row>
        <Button.Group>
          <Button type="danger" onClick={modalClose}>
            <Icon type="stop" />
            Отмена
          </Button>
          <Button type="primary" htmlType="submit">
            <Icon type="check" />
            Добавить
          </Button>
        </Button.Group>
      </Form>
    </BaseModal>
  );
};

export default Modal;

import React, { useEffect, useState } from 'react';
import { Button, Icon, message, Select, Tabs, Tooltip } from 'antd';
import General from './General';
import SignificantObjectsTable from './SignificantObjectsTable';
import InformationOfLimits from './InformationOfLimits';
import InformationOfEmissions from './InformationOfEmissions';
import InformationForDischarges from './InformationForDischarges';
import PresenceWaste from './PresenceWaste';
import LocationIndustrialsSites from './locationIndustrialSites';
import { getApplicationForm } from '../../api/ApplicationForm';
import { useDispatch, useSelector } from 'react-redux';
import { el } from 'date-fns/locale';
import { orderProductionSetAction } from '../../redux/actions/userActions';
import { Typography } from 'antd';
import { defineCategory, getUser, updateUser } from '../../api/LoginAPI';
import ButtonGroup from 'antd/lib/button/button-group';
import SavedWaste from './SavedWaste';
import moment from 'moment';
import { RegistrationApi } from './../../api/RegistrationApi';
import { Modal } from 'antd';
import { axios } from 'axios';
import config from '../../config';
const { Option } = Select;

const { Text } = Typography;

const categoryList = {
  1: 'I',
  2: 'II',
  3: 'III',
  4: 'IV',
};

const ApplicationForm = () => {
  const [deps, setDeps] = useState({
    code: 'department_id',
    type: 'select',
    message: 'Введите лизенциара!',
    placeholder: 'Выбрать лизенциара',
    required: true,
    answers: [],
    name: 'department_id',
    value: '',
  });

  const [generalInfo, setGeneralInfo] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [userFull, setUser] = useState(null);
  const dispatch = useDispatch();
  const { user, token } = useSelector(state => ({
    ...state.userStore,
  }));

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedDep, setSelectedDep] = useState('');

  useEffect(() => {
    getGeneralInfo();
    getUser({ token }).then(res => {
      setUser(res.data);
    });
    get_department_data();
  }, []);

  useEffect(() => {
    if (generalInfo) {
      dispatch(orderProductionSetAction(generalInfo.id));
      setDisabled(false);
    } else setDisabled(true);
  }, [generalInfo]);

  const getGeneralInfo = async () => {
    getApplicationForm({ token })
      .then(({ data }) => {
        setGeneralInfo(data);
        message.success('Данные загружены !');
      })
      .catch(err => {
        message.error('Произошла ошибка !');
      });
  };
  const get_department_data = () => {
    const response = new RegistrationApi();
    response
      .getDepartmentData()
      .then(res => {
        setDeps(prevState => {
          return {
            ...prevState,
            answers: res.data,
          };
        });
        console.log(deps.answers);
      })
      .catch(() => {
        message.error(
          'Не смогли загрузить список департаментов, провертье интернет соединение или блокировку через ваших программистов',
          15
        );
      })
      .finally(res => {
        console.log('HELLO', res);
      });
  };

  const renderDeps = () => {
    return (
      <Select
        style={{
          width: '90%',
          marginTop: '20px',
        }}
        onSelect={setSelectedDep}
      >
        {deps.answers.map((el, index) => (
          <Option key={index} value={el.id}>
            {el.name}
          </Option>
        ))}
      </Select>
    );
  };

  const handleOk = () => {
    if (selectedDep === '') message.error(deps.message);
    else {
      message.loading('Подождите');
      setIsModalVisible(false);
      updateUser({
        values: {
          department_id: selectedDep,
          category_status: 'waiting',
          category_before_send: user.username.category,
          send_report_date: moment().format('YYYY-MM-DD'),
        },
        token,
      }).then(() => {
        defineCategory({ username: user.username.username, token });
        window.location.reload();
      });
    }
  };

  return (
    <div>
      <ButtonGroup style={{ display: 'flex' }}>
        <Button
          type="primary"
          disabled={userFull?.category_status === 'waiting' || disabled}
          onClick={() => {
            setIsModalVisible(true);
          }}
        >
          Заявка на определение категории
        </Button>
        {userFull?.category_status === 'finished' && (
          <Button
            type="primary"
            onClick={() => {
              window.open(
                `${config.api_url}categoryFiles/${userFull?.category_filename}`
              );
            }}
          >
            Решение по определению категории
          </Button>
        )}
        {userFull?.category_status === 'rejected' && (
          <div style={{ marginLeft: 20 }}>
            <div>
              <Text type="danger">Статус: Отказано</Text>
            </div>
            <div>
              <Text type="danger">Причина отказа: {userFull?.reason}</Text>
            </div>
          </div>
        )}

        <div style={{ marginLeft: 20 }} />
        <Tooltip title="Руководство">
          <div
            onClick={() => {
              window.open(`${config.api_url}help.pdf`);
            }}
            style={{
              borderRadius: 2000,
              border: '1px solid black',
              width: 30,
              height: 30,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Icon type="question" />
          </div>
        </Tooltip>
      </ButtonGroup>
      <div style={{ margin: '15px 0 0 10px', fontSize: 20 }}>
        {' '}
        Статус:
        {userFull?.category_status
          ? userFull?.category_status === 'waiting'
            ? ' Ожидается определение категории'
            : userFull?.category_status === 'rejected'
            ? ' Отказано'
            : userFull?.category_status === 'signing'
            ? ` Ожидается подпись (категория: ${
                categoryList[userFull?.category]
              })`
            : ` Категория определена: ${categoryList[userFull?.category]}`
          : ' Заполняется предприятием'}
        {userFull?.category_status && (
          <div>
            Дата подачи заявки:{' '}
            {moment(userFull?.send_report_date).format('YYYY-MM-DD')}
          </div>
        )}
      </div>
      <Tabs>
        <Tabs.TabPane tab="Общая информация" key="1">
          <General
            token={token}
            generalInfo={generalInfo}
            refresh={getGeneralInfo}
          />
        </Tabs.TabPane>

        <Tabs.TabPane
          disabled={disabled}
          generalInfo={generalInfo}
          tab="Наличие промышленных площадок"
          key="6"
        >
          <LocationIndustrialsSites generalInfo={generalInfo} />
        </Tabs.TabPane>
        <Tabs.TabPane
          disabled={disabled}
          generalInfo={generalInfo}
          tab="Наличие образования, хранения и переработки серы, характеристика объектов (карт) "
          key="7"
        >
          <PresenceWaste />
        </Tabs.TabPane>

        <Tabs.TabPane
          disabled={disabled}
          tab="Нормативы выбросов загрязняющих веществ в атмосферу"
          key="4"
        >
          <InformationOfEmissions generalInfo={generalInfo} />
        </Tabs.TabPane>
        <Tabs.TabPane
          disabled={disabled}
          tab="Нормативы сбросов загрязняющих веществ в сточных водах"
          key="5"
        >
          <InformationForDischarges generalInfo={generalInfo} />
        </Tabs.TabPane>
        <Tabs.TabPane
          disabled={disabled}
          tab="Лимиты накопления и захоронения отходов / количество и виды отходов (образовываемых, накапливаемых и передаваемых специализированным организациям по управлению отходами)"
          key="3"
        >
          <InformationOfLimits generalInfo={generalInfo} />
        </Tabs.TabPane>
        <Tabs.TabPane
          disabled={disabled}
          tab="Нормативы, установленные для физических факторов окружающей среды (шума, вибрации, электрических, электромагнитных, магнитных полей, радиации, тепла)"
          key="2"
        >
          <SignificantObjectsTable generalInfo={generalInfo} />
        </Tabs.TabPane>
        <Tabs.TabPane
          disabled={disabled}
          tab="Наличие накапливаемых и (или) захораниваемых отходов"
          key="8"
        >
          <SavedWaste generalInfo={generalInfo} />
        </Tabs.TabPane>
      </Tabs>
      <Modal
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={handleOk}
        okButtonProps={{
          children: 'Custom OK',
        }}
        cancelButtonProps={{
          children: 'Custom cancel',
        }}
        okText="Добавить"
        cancelText="Отмена"
        width="40vw"
      >
        <span>Выбрать департамент:</span>
        {renderDeps()}
      </Modal>
    </div>
  );
};

export default ApplicationForm;

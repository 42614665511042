import { BaseAPI } from './BaseAPI';
import axios from 'axios';

class RecoveryPasswordAPI extends BaseAPI {
  SendMessage = async email =>
    axios({
      method: 'POST',
      url: `${this.BASE_URL}api/recovery/recovery-password`,
      data: email,
      headers: {
        'Content-Type': 'application/json',
      },
    });
}

const { SendMessage } = new RecoveryPasswordAPI();

export { SendMessage };

export default (rounding, companyCardName) => [
  {
    title: 'Наименование установки',
    render: () => companyCardName,
  },
  {
    title: 'Категория источника',
    dataIndex: 'source_name',
    key: 'source_name',
  },
  {
    title: '№ источника',
    dataIndex: 'source_number',
    key: 'source_number',
  },
];

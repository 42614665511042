import moment from 'moment';

const renderContent = (value, row) => {
  const obj = {
    children: value,
    props: {},
  };

  if (row.cost_direction === 'из них') {
    obj.props.colSpan = 0;
  }

  return obj;
};
const columns = [
  {
    title: 'Код строки',
    dataIndex: 'code_string',
    key: 'code_string',
    render: renderContent,
  },
  {
    title: 'Направление затрат в соответствии с КДЗООС и УР1',
    dataIndex: 'cost_direction',
    key: 'cost_direction',
    render: (value, row) => {
      if (row.cost_direction !== 'из них') {
        return value;
      }

      return {
        children: value,
        props: {
          colSpan: 10,
        },
      };
    },
  },
  {
    title: 'Объем текущих затрат',
    dataIndex: 'volume_expences',
    key: 'volume_expences',
    render: renderContent,
  },
  {
    title: 'Из них по основным видам:',
    children: [
      {
        title: 'материальные затраты',
        dataIndex: 'volume_expences_material',
        key: 'volume_expences_material',
        render: renderContent,
      },
      {
        title: 'затраты на оплату труда и отчисления на социальные нужды',
        dataIndex: 'volume_expences_salary',
        key: 'volume_expences_salary',
        render: renderContent,
      },
      {
        title:
          'выплачено иным предприятиям (организациям) за оказание природоохранных услуг',
        dataIndex: 'volume_expences_other_companies',
        key: 'volume_expences_other_companies',
        render: renderContent,
      },
    ],
  },
  {
    title: 'В том числе по источникам финансирования',
    children: [
      {
        title: 'бюджетные средства',
        children: [
          {
            title: 'республиканский бюджет',
            dataIndex: 'republician_budget',
            key: 'republician_budget',
            render: renderContent,
          },
          {
            title: 'местный бюджет',
            dataIndex: 'local_budget',
            key: 'local_budget',
            render: renderContent,
          },
        ],
      },
      {
        title: 'собственные средства',
        dataIndex: 'own_budget',
        key: 'own_budget',
        render: renderContent,
      },
      {
        title: 'прочие средства',
        dataIndex: 'other_budget',
        key: 'other_budget',
        render: renderContent,
      },
    ],
  },
  {
    title: 'Создан',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (value, row) => {
      const obj = {
        children:
          row.code_string === 'А' ? value : moment(value).format('YYYY-MM-DD'),
        props: {},
      };

      if (row.cost_direction === 'из них') {
        obj.props.colSpan = 0;
      }

      return obj;
    },
  },
];

export default columns;

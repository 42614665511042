import {Input} from "antd";
import React from "react";

export default [
    {
        title: 'Название',
        dataIndex: 'name',
        key: 'name',
        width: 250,
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
            },
        ],
        component: <Input />,
    },
    {
        title: 'Окед',
        dataIndex: 'oked',
        key: 'oked',
        width: 220,
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
            },
        ],
        component: <Input />,
    },
    {
        title: 'Тип ID',
        dataIndex: 'order_production_types_id',
        key: 'order_production_types_id',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
            },
        ],
        component: <Input />,
    },
    {
        title: 'категория',
        dataIndex: 'category',
        key: 'category',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
            },
        ],
        component: <Input />,
    },
]
import { Button, Icon, message } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getResources as getResourcesAtmosphere } from '../../../api/AtmosphereAPI';
import { sendReport as sendReportAPI } from '../../../api/BaseAPI';
import { getResources as getResourcesDischarge } from '../../../api/DischargeAPI';
import {
  getGreenhouse,
  getResources as getResourcesEmissions,
} from '../../../api/SuperTableAPI';
import compose from '../../../hoc/compose';
import SuperTableContainer from '../containers/Table';

const dataGetter = async ({
  tableType,
  token,
  promoAreaId,
  period,
  year,
  quarter,
  tableName,
}) => {
  switch (tableType) {
    case 'atmosphere':
      return await getResourcesAtmosphere({ token, promoAreaId, period, year });
    case 'discharge':
      return await getResourcesDischarge(
        { token, promoAreaId, period, year },
        false
      );
    case 'discharge_fact':
      return await getResourcesDischarge(
        { token, promoAreaId, period, year },
        true
      );
    case 'emissions':
      return await getResourcesEmissions(token, {
        name: tableName,
        year,
        period: quarter,
        promoAreaId,
      });
    case 'greenhouse':
      return await getGreenhouse(token, {
        name: tableName,
        year,
        period: quarter,
        promoAreaId,
      });
    default:
      return;
  }
};

const SuperTable = ({
  tableType,
  token,
  promoAreaId,
  tableName,
  year,
  quarter,
  modalTables,
  inputs,
  columns,
  dataType,
  fetchSumSources,
  transformDataSource,
  scrollMultiplier = 100,
  formItemLayout,
  pagination,
  company,
  expandedCols,
}) => {
  const [data, setData] = useState([]);
  const [visibleFirst, setVisibleFirst] = useState(false);
  const [visibleSecond, setVisibleSecond] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [rounding, setRounding] = useState(false);

  const fetchData = async () => {
    if (!promoAreaId || !year || !quarter)
      return message.error('Не выбрано предприятие, год, квартал!');
    if (promoAreaId && token && year && quarter) {
      try {
        const result = await dataGetter({
          tableType,
          tableName,
          token,
          promoAreaId,
          period: quarter,
          year,
          quarter,
        });
        setData(result);
        if (fetchSumSources) {
          fetchSumSources();
        }
      } catch (error) {
        console.error(error);
        message.error('Что-то пошло не так!');
      }
    }
  };

  const roundUp = checked => setRounding(checked);

  const sendReport = async () => {
    sendReportAPI({ token, promoAreaId, year, quarter, dataType })
      .then(data => message.success('Отчет отправлен!'))
      .catch(({ response }) => {
        if (response.status === 400) message.error(response.data.message);
      });
  };

  useEffect(() => {
    fetchData();
  }, [promoAreaId, year, quarter]);

  useEffect(() => {
    setDataSource(transformDataSource(data));
  }, [data]);

  const columnsList = columns(rounding)(token, fetchData);
  const expandedColsList = expandedCols(rounding)(token, fetchData);
  const [firstTable, secondTable] = modalTables;
  const buttons = (
    <div>
      <Button.Group
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '30%',
          marginBottom: '20px',
        }}
      >
        <Button
          type="primary"
          onClick={() => setVisibleFirst(true)}
          style={{ marginBottom: 15 }}
        >
          Добавить
        </Button>

        <Button
          type="danger"
          onClick={() => setVisibleFirst(true)}
          style={{ marginBottom: 15 }}
        >
          удалить
        </Button>
        <Button
          type="primary"
          onClick={() => setVisibleFirst(true)}
          style={{ marginBottom: 15 }}
        >
          редактировать
        </Button>
      </Button.Group>
    </div>
  );

  const isCurrentQuarter = moment(new Date()).quarter() === quarter;
  const tenDaysAgo =
    +moment('2020-06-10')
      .subtract(10, 'days')
      .format('M') < +moment().format('M');
  // const isEditable = isCurrentQuarter || tenDaysAgo;
  const isEditable = true;

  const props = {
    buttons,
    dataSource,
    columns: columnsList,
    isEditable,
    firstTable,
    visibleFirst,
    visibleSecond,
    setVisibleFirst,
    fetchSumSources,
    setVisibleSecond,
    fetchData,
    inputs,
    dataType,
    scrollMultiplier,
    formItemLayout,
    pagination,
    company,
    expandedCols: expandedColsList,
    tableType,
    roundUp,
  };
  return <SuperTableContainer {...props} />;
};

const mapStateToProps = ({
  userStore: { token },
  companyStore: { promoAreaId },
  pekDateStore: { year, quarter },
}) => ({ token, promoAreaId, year, quarter });

export default compose(connect(mapStateToProps))(SuperTable);

import React from 'react';
import { Input } from 'antd';
import { isNumberAndAboveZero } from '../../../../../../utils/validation';

export default [
  {
    title: 'Код строки',
    dataIndex: 'code_string',
    key: 'code_string',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Наименование водного объекта',
    dataIndex: 'name',
    key: 'name',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Код приемника',
    dataIndex: 'code_reciver',
    key: 'code_reciver',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Код моря-реки',
    dataIndex: 'code_sea',
    key: 'code_sea',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Притоки',
    key: 'balance',
    children: [
      {
        title: '1',
        dataIndex: 'inflow_1',
        key: 'inflow_1',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
          },
        ],
        component: <Input />,
      },
      {
        title: '2',
        dataIndex: 'inflow_2',
        key: 'inflow_2',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
          },
        ],
        component: <Input />,
      },
      {
        title: '3',
        dataIndex: 'inflow_3',
        key: 'inflow_3',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
          },
        ],
        component: <Input />,
      },
      {
        title: '4',
        dataIndex: 'inflow_4',
        key: 'inflow_4',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
          },
        ],
        component: <Input />,
      },
      {
        title: '5',
        dataIndex: 'inflow_5',
        key: 'inflow_5',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
          },
        ],
        component: <Input />,
      },
    ],
  },
  {
    title: 'Код качества',
    dataIndex: 'code_quality',
    key: 'code_quality',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Расстояние от устья',
    dataIndex: 'distance',
    key: 'distance',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Отведено, сброшено всего',
    dataIndex: 'alloted_dropped',
    key: 'alloted_dropped',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Загрязненных',
    children: [
      {
        title: 'без очистки',
        dataIndex: 'polluted_without_clear',
        key: 'polluted_without_clear',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
      {
        title: 'недостаточно очищенные',
        dataIndex: 'polluted_insuffiently_cleaned',
        key: 'polluted_insuffiently_cleaned',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
    ],
  },
  {
    title: 'Нормативно-чистые (без очистки)',
    dataIndex: 'normally_cleaned_without_cleaned',
    key: 'normally_cleaned_without_cleaned',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Нормативно очищенных',
    children: [
      {
        title: 'биологической',
        dataIndex: 'normally_cleaned_without_biological',
        key: 'normally_cleaned_without_biological',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
      {
        title: 'физико-химической',
        dataIndex: 'normally_cleaned_without_physical',
        key: 'normally_cleaned_without_physical',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
      {
        title: 'механической',
        dataIndex: 'normally_cleaned_without_mechanical',
        key: 'normally_cleaned_without_mechanical',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
    ],
  },
  {
    title: 'Содержание загрязняющих веществ в сточных водах, мг/л',
    children: [
      {
        title: 'БПКполн',
        dataIndex: 'content_bpk',
        key: 'content_bpk',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
      {
        title: 'нефтепродукты',
        dataIndex: 'content_oil_products',
        key: 'content_oil_products',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
      {
        title: 'взвешенные вещества',
        dataIndex: 'content_suspence_substances',
        key: 'content_suspence_substances',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
      {
        title: 'сухой остаток',
        dataIndex: 'content_balance',
        key: 'content_balance',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
      {
        title: 'код',
        dataIndex: 'content_code',
        key: 'content_code',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
      {
        title: 'количество ',
        dataIndex: 'content_count',
        key: 'content_count',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
    ],
  },
];

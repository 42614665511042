import React from 'react';
import { Input } from 'antd';
import { isNumberAndAboveZero } from '../../../utils/validation';

export default [
  {
    title:
      'Наименование вида вредного физического воздействия на окружающую среду*',
    dataIndex: 'environmental_impact_names',
    key: 'environmental_impact_names',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Наименование источника (их совокупности)',
    dataIndex: 'source_name',
    key: 'source_name',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        // validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Номер источника',
    dataIndex: 'source_number',
    key: 'source_number',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title:
      'Норматив физического воздействия на окружающую среду , Единица измерения',
    dataIndex: 'standard_of_physical_impact',
    key: 'standard_of_physical_impact',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Величина',
    dataIndex: 'quantity',
    key: 'quantity',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Примечание',
    dataIndex: 'note',
    key: 'note',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
];

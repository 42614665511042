export default [
  {
    value: '{0.1, 0.5, 9611}',
    text: 'Мазут малосернистый',
  },
  {
    value: '{0.1, 1.9, 9518}',
    text: 'Мазут сернистый',
  },
  {
    value: '{0.1, 4.1, 9288}',
    text: 'Мазут высокосернистый',
  },
  {
    value: '{0.1, 2.9, 9529}',
    text: 'Стабилизированная нефть',
  },
  {
    value: '{0.025, 0.3, 10210}',
    text: 'Дизельное топливо',
  },
  {
    value: '{0.02, 0.3, 10141}',
    text: 'Солярное масло',
  },
  {
    value: '{0.05, 0.4, 9909}',
    text: 'Моторное масло',
  },
];

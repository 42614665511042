import { BaseAPI } from './BaseAPI';

import axios from 'axios';

class CopyAPI extends BaseAPI {
  copy = async (token, { year, fromPeriod, toPeriod, companyId }) => {
    const url = `${this.BASE_URL}${this.SUPER_TABLE_PATH}copy?year=${year}&fromPeriod=${fromPeriod}&toPeriod=${toPeriod}&companyId=${companyId}`;
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const { data } = await axios.post(url, {}, config);
      return data;
    } catch (error) {
      console.error('error: ', error);
      return error;
    }
  };
}

const { copy } = new CopyAPI();

export { copy };

import React from 'react';
import { DatePicker, Input, Select } from 'antd';
import moment from 'moment';
import { isNumberAndAboveZero } from '../../../utils/validation';

export default [
  {
    title: 'Тип',
    dataIndex: 'type',
    key: 'type',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Select />,
  },
  {
    title: 'Определяемые компоненты',
    dataIndex: 'defining_component',
    key: 'defining_component',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Наименование станции',
    dataIndex: 'station_name',
    key: 'station_name',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Широта',
    dataIndex: 'latitude',
    key: 'latitude',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Долгота',
    dataIndex: 'longitude',
    key: 'longitude',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Сезон года',
    dataIndex: 'year_sezon',
    key: 'year_sezon',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Повторность отбора данных',
    dataIndex: 'interval_data_collection',
    key: 'interval_data_collection',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Результат анализа',
    dataIndex: 'analys_result',
    key: 'analys_result',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Метод проведения анализа',
    dataIndex: 'analys_method',
    key: 'analys_method',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
];

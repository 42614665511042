import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { store, persistor } from './redux/store';
import { Spin } from 'antd';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
  useLocation,
} from 'react-router-dom';
import 'antd/dist/antd.css';
import './App.css';
import LoginPageForm from './Views/Login/LoginPage';
import Main from './Views/Main/Main';
import RegistrationPageForm from './Views/Registration/RegistrationPage';
import AuthUser from './Views/AuthUser/AuthUser';
import RecoveryPassword from './Views/RecoveryPassword/RecoveryPassword';
import SendMessagePassword from './Views/SendMessagePassword/SendMessagePassword';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const location = useLocation();
  return (
    <Route
      {...rest}
      render={props => {
        const { loggedIn } = store.getState().userStore;

        return loggedIn ? (
          <Component {...props} />
        ) : (
          <>
            {location.pathname !== ('/registration' || '/email/:email') && (
              <Redirect to="/login" />
            )}
            ;
          </>
        );
      }}
    />
  );
};

const LoadingView = () => {
  return <Spin size="large" />;
};

export default class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={<LoadingView />} persistor={persistor}>
          <Router>
            <Switch>
              <Route path="/login" exact component={LoginPageForm} />
              <Route path="/user-id/:id" exact component={AuthUser} />
              <Route
                exact
                path="/recovery-password/user-id/:iv/:content"
                component={RecoveryPassword}
              />
              <Route
                path="/recovery-password"
                exact
                component={SendMessagePassword}
              />
              <Route
                path="/registration"
                exact
                component={RegistrationPageForm}
              />
              <PrivateRoute path="/" component={Main} />
            </Switch>
          </Router>
        </PersistGate>
      </Provider>
    );
  }
}

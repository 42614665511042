import React from 'react';

import Emissions from './Tables/Emissions';
import Discharges from './Tables/Discharges';
import Waste from './Tables/Waste';
import './style.css';

const Report = () => {
  return (
    <div>
      <Emissions />
      <Discharges />
      <Waste />
    </div>
  );
};

export default Report;

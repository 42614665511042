import React from 'react';
import { Input } from 'antd';
import { isNumberAndAboveZero } from '../../../../../utils/validation';

export default [
  {
    title: 'Наименование площадки',
    dataIndex: 'name',
    key: 'name',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'лимит, тонн/год',
    dataIndex: 'limit_value',
    key: 'limit_value',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Фактически размещено , тонн/квартал',
    children: [
      {
        title: '1 квартал',
        dataIndex: 'fact_discharges_1',
        key: 'fact_discharges_1',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
      {
        title: '2 квартал',
        dataIndex: 'fact_discharges_2',
        key: 'fact_discharges_2',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
      {
        title: '3 квартал',
        dataIndex: 'fact_discharges_3',
        key: 'fact_discharges_3',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
      {
        title: '4 квартал',
        dataIndex: 'fact_discharges_4',
        key: 'fact_discharges_4',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
    ],
  },
  {
    title: 'Итого за год',
    dataIndex: 'total',
    key: 'total',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
];

import {
  Button, Form,



  Icon, Input,


  message,
  Modal, Select, Typography
} from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import isInt from 'validator/es/lib/isInt';
import isLength from 'validator/es/lib/isLength';
import {
  createCompany,


  createPromoArea as createPromoAreaAPI, removeCompany,
  updateCompany as updateCompanyAPI
} from '../../api/CompanyAPI';
import compose from '../../hoc/compose';
import {
  fetchAllCompanies, resetCompany
} from '../../redux/actions/companyActions';
import './style.css';



const { Option } = Select;
const { Title } = Typography;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};

const CardCompany = ({
  refresh,
  region,
  token,
  form,
  company,
  resetCompany,
  fetchCompany,
  companyId,
  fetchAllCompanies,
}) => {
  console.log('company: ', company);
  const { getFieldDecorator, validateFields, setFieldsValue } = form;
  const [visible, setVisible] = useState(false);

  const showModal = () => setVisible(true);

  const handleOk = e => setVisible(false);
  const handleCancel = e => setVisible(false);

  const onSubmit = evt => {
    evt.preventDefault();
    validateFields((err, values) => {
      err && message.error('Ошибка в заполнении формы!');
      if (!err && token) {
        createCompany({ values: {...values, region_id: region}, token })
          .then(() => {
            message.success('Успешно !');
            refresh();
          })
          .catch(err => {
            console.log(err);
            message.error('Произолшла ошибка !');
          });
      }
    });
  };

  useEffect(() => {
    setFieldsValue(company);
  }, [company]);

  const deleteCompany = companyId => {
    removeCompany({ companyId, token }).then(() => {
      refresh();
      resetCompany();
      message.success('Удалено!');
    });
  };

  const updateCompany = companyId => {
    validateFields((err, values) => {
      err && message.error('Ошибка в заполнении формы!');
      console.log(!err && token, token);
      if (!err && token) {
        updateCompanyAPI({ companyId, values, token })
          .then(async () => {
            await refresh();
            await fetchCompany(companyId);
          })
          .then(() => {
            console.log('updated company', company);
            setFieldsValue(company);
            message.success('Обновлено!');
          })
          .catch(error => {
            console.error(error);
            message.error('Что-то пошло не так!');
          });
      }
    });
  };

  const createPromoArea = evt => {
    evt.preventDefault();

    validateFields((err, values) => {
      err && message.error('Ошибка в заполнении формы!');
      console.log(companyId);
      if (!err && token) {
        createPromoAreaAPI({
          values: { company_id: companyId, name: values.namePromoArea },
          token,
        })
          .then(() => {
            fetchAllCompanies({ token });
          })
          .then(() => {
            message.success('Создано!');
            handleCancel();
          })
          .catch(error => {
            console.error(error.response);
            message.error('Что-то пошло не так!');
          });
      }
    });
  };

  const renderActionButton = id => {
    if (!id) {
      return (
        <Button type="primary" htmlType="submit">
          Отправить
        </Button>
      );
    } else {
      return (
        <Button.Group>
          <Button type="primary" onClick={() => updateCompany(id)}>
            Обновить
          </Button>
          <Button type="danger" onClick={() => deleteCompany(id)}>
            Удалить
          </Button>
        </Button.Group>
      );
    }
  };

  return (
    <div>
      <div style={{ marginBottom: 20 }}>
        <Button type="primary" onClick={showModal}>
          Создать производственную площадку
        </Button>
        {visible && (
          <Modal visible={visible} onOk={handleOk} footer={[]}>
            <Form onSubmit={createPromoArea}>
              <Form.Item label="Название производственной площадки">
                {getFieldDecorator('namePromoArea', {
                  rules: [
                    {
                      required: true,
                      message: 'Поле обязательно для заполнения',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
              <Button.Group>
                <Button type="danger" onClick={handleCancel}>
                  <Icon type="stop" />
                  Отмена
                </Button>
                <Button type="primary" htmlType="submit">
                  <Icon type="check" />
                  Добавить
                </Button>
              </Button.Group>
            </Form>
          </Modal>
        )}
      </div>
      <Title style={{ fontSize: 18, textAlign: 'center' }}>
         Общие сведения
      </Title>
      <Form {...formItemLayout} onSubmit={onSubmit}>
        <Form.Item label="Название предприятия">
          {getFieldDecorator('name', {
            rules: [
              { required: true, message: 'Поле обязательно для заполнения' },
            ],
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Краткое описание деятельности">
          {getFieldDecorator('short_description', {
            rules: [
              { required: true, message: 'Поле обязательно для заполнения' },
            ],
          })(<Input />)}
        </Form.Item>
        <Form.Item label="БИН">
          {getFieldDecorator('bin', {
            rules: [
              {
                required: true,
                type: 'number',
                message: 'Поле должно состоять из 12 цифр',
                validator: (rule, value) => {
                  const BIN_LENGTH = 12;
                  const condition =
                    !isInt(value) ||
                    !isLength(value, { min: BIN_LENGTH, max: BIN_LENGTH });

                  if (condition) {
                    console.log('value: ', value);
                    return false;
                  }
                  return true;
                },
              },
            ],
          })(<Input />)}
        </Form.Item>
        {/* <Form.Item label="Область">
          {getFieldDecorator('region', {
            rules: [
              { required: true, message: 'Поле обязательно для заполнения' },
            ],
          })(
            <Select
              showSearch
              defaultActiveFirstOption
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {regions.map(({ name, id }) => {
                return (
                  <Option key={id} value={name}>
                    {name}
                  </Option>
                );
              })}
            </Select>
          )}
        </Form.Item> */}
        <Form.Item label="Район">
          {getFieldDecorator('district', {
            rules: [
              { required: true, message: 'Поле обязательно для заполнения' },
            ],
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Город/село/участок">
          {getFieldDecorator('city', {
            rules: [
              { required: true, message: 'Поле обязательно для заполнения' },
            ],
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Почтовый адрес">
          {getFieldDecorator('post_address', {
            rules: [
              {
                required: true,
                message: 'Поле обязательно для заполнения!',
              },
            ],
          })(<Input />)}
        </Form.Item>
        <div>
          <p>Руководитель</p>
          <Form.Item label="Должность">
            {getFieldDecorator('head_position', {
              rules: [
                {
                  required: true,
                  message: 'Поле обязательно для заполнения',
                },
              ],
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Ф.И.О">
            {getFieldDecorator('head_full_name', {
              rules: [
                {
                  required: true,
                  message: 'Поле обязательно для заполнения',
                },
              ],
            })(<Input />)}
          </Form.Item>
        </div>
        <div>
          <p>Главный бухгалтер</p>
          <Form.Item label="Ф.И.О">
            {getFieldDecorator('accountant_full_name', {
              rules: [
                {
                  required: true,
                  message: 'Поле обязательно для заполнения',
                },
              ],
            })(<Input />)}
          </Form.Item>
        </div>
        <Form.Item label="Телефон">
          {getFieldDecorator('phone', {
            rules: [
              { required: true, message: 'Поле обязательно для заполнения' },
            ],
          })(<Input placeholder="+79001112233" />)}
        </Form.Item>
        <Form.Item label="Эл.адрес">
          {getFieldDecorator('email', {
            rules: [
              { required: true, message: 'Поле обязательно для заполнения' },
              {
                type: 'email',
                message:
                  'Поле должно содержать только латинские символы и символ @',
              },
            ],
          })(<Input />)}
        </Form.Item>

        <Form.Item label="Периодичность контроля">
          {getFieldDecorator('control_interval', {
            rules: [
              { required: true, message: 'Поле обязательно для заполнения' },
            ],
          })(
            <Select>
              <Option value="1 в квартал">1 раз в квартал</Option>
              <Option value="1 в месяц ">1 раз в месяц</Option>
              <Option value="1 в год">1 раз в год</Option>
            </Select>
          )}
        </Form.Item>
        <Form.Item label="Сведения о лаборатории">
          {getFieldDecorator('about_lab', {
            rules: [
              { required: true, message: 'Поле обязательно для заполнения' },
            ],
          })(<Input />)}
        </Form.Item>
        {renderActionButton(company.id)}
      </Form>
    </div>
  );
};

const mapStateToProps = ({
  userStore: { token },
  companyStore: { company, companyId },
}) => ({ token, company, companyId });

const mapDispatchToProps = {
  resetCompany,
  fetchAllCompanies,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  Form.create()
)(CardCompany);

import React, { useState } from 'react';
import { Input } from 'antd';
import { isNumberAndAboveZero } from '../../../../../utils/validation';

export default [
  {
    title: 'Наименование промышленной площадки',
    dataIndex: 'name',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Область, город республиканского значения',
    dataIndex: 'area',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    key: 'area',
    component: <Input />,
  },
  {
    title: 'Район, населенный пункт, адрес (местонахождение) объекта',
    dataIndex: 'district',
    key: 'district',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Координаты,  град., мин., сек.',
    children: [
      {
        title: 'широта',
        dataIndex: 'latitude',
        key: 'latitude',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero
          },
        ],
        component: <Input />,
      },
      {
        title: 'долгота',
        dataIndex: 'longitude',
        key: 'longitude',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero
          },
        ],
        component: <Input />,
      },
    ],
  },
  {
    title: 'Занимаемая территория, га',
    dataIndex: 'territory',
    key: 'territory',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
];

import React, { useState } from 'react';
import { Button, Modal, Upload } from 'antd';
import StandardTable from '../../../modules/StandardTable';
import { connect, useSelector } from 'react-redux';
import inputs from './modalInputs';
import Editor from '../../../modules/Editor';
import roundUp from '../../../utils/roundUp';
import { uploadFiles } from '../../../api/ApplicationForm';
import { isNumberAndAboveZero } from '../../../utils/validation';
import config from '../../../config';

const tableName = 'information_on_standards';

const InformationOfEmissions = ({ token }) => {
  const [data] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { orderProductionId } = useSelector(state => ({
    ...state.userStore,
  }));
  const [state, setState] = useState({
    fileList: [],
    uploading: false,
  });

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    uploadFiles({
      token,
      tableName,
      formId: orderProductionId,
      tableType: 'emission',
      file: state.fileList[0],
    }).then(() => window.location.reload());

    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const transformDataSource = (data, setState) => {
    const newDataSource = [];
    data.forEach(el =>
      newDataSource.push({
        key: el.id,
        ...el,
      })
    );
    setState(newDataSource);
  };

  const renderContent = (text, row, rounding) => {
    const obj = {
      children: roundUp(text, rounding),
      props: {},
    };

    if (
      row.number === 'Выбросы от стационарных источников:' ||
      row.number === 'Сбросы ЗВ со сточными водами:' ||
      row.number === 'Отходы'
    ) {
      obj.props.colSpan = 0;
    }
    return obj;
  };

  const columns = rounding => (token, refresh) => [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Наименование промышленной площадки',
      dataIndex: 'name',
      key: 'name',
      width: 250,
    },
    {
      title: 'Наименование источников выделения загрязняющих веществ',
      dataIndex: 'name_of_sources',
      key: 'name_of_sources',
      width: 220,
    },
    {
      title: 'Код загрязняющего вещества',
      dataIndex: 'pollutant_code',
      key: 'pollutant_code',
      rules: [
        {
          required: true,
          message: 'Данные обязательны для заполнения',
          validator: isNumberAndAboveZero,
        },
      ],
    },
    {
      title: 'Наименование веществ',
      dataIndex: 'name_of_substances',
      key: 'name_of_substances',
    },
    {
      title: 'Нормативы выбросов загрязняющих веществ в атмосферу *',
      width: 220,
      dataIndex: 'standards_for_emissions',
      key: 'standards_for_emissions',
      children: [
        {
          title: 'грамм/сек',
          key: 'gram',
          dataIndex: 'gram',
          render: (text, row) => renderContent(text, row, rounding),
        },
        {
          title: '(тонн/год)',
          key: 'tons',
          dataIndex: 'tons',
          render: (text, row) => renderContent(text, row, rounding),
        },
      ],
    },
    {
      title: 'Примечание',
      dataIndex: 'note',
      key: 'note',
    },
    {
      title: 'Действия',
      key: 'actions',
      width: 120,
      render: (text, record) => (
        <Editor
          addInputs={inputs}
          editInputs={inputs}
          text={text}
          tableName={tableName}
          editTableType={'order_production_tables'}
          record={record}
          refresh={refresh}
        />
      ),
    },
  ];

  const { fileList, uploading } = state;

  const props = {
    onRemove: file => {
      setState(state => {
        const index = state.fileList.indexOf(file);
        const newFileList = state.fileList.slice();
        newFileList.splice(index, 1);
        return {
          fileList: newFileList,
        };
      });
    },
    beforeUpload: file => {
      setState(state => ({
        fileList: [...state.fileList, file],
      }));
      return false;
    },
    fileList,
  };

  return (
    <div>
      <StandardTable
        inputs={inputs}
        tableName={tableName}
        tableType={'order_production_tables'}
        columns={rounding => columns(rounding)}
        transformDataSource={transformDataSource}
        dataSource={data}
      />
      <Button.Group>
        <Button type="primary" onClick={showModal}>
          Загрузить через шаблон
        </Button>
        <Button
          type="primary"
          onClick={() => {
            window.open(`${config.api_url}шаблон выбросы.xlsx`);
          }}
        >
          Скачать пример шаблона
        </Button>
      </Button.Group>
      <Modal
        title="Файл"
        visible={isModalVisible}
        okText="Отправить"
        onOk={handleOk}
        cancelText="Закрыть"
        onCancel={handleCancel}
      >
        <Upload {...props}>
          <Button>Выбрать файл</Button>
        </Upload>
      </Modal>
    </div>
  );
};

const mapStateToProps = ({ userStore: { token } }) => ({
  token,
});

export default connect(mapStateToProps)(InformationOfEmissions);

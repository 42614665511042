import { Form, message } from 'antd';
import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { setZV as setZVAtmosphere } from '../../../api/AtmosphereAPI';
import { setZV as setZVDischarge } from '../../../api/DischargeAPI';
import { setData } from '../../../api/StandardTableAPI';
import { setGreenhouseData, setZV as setZVEmissions } from '../../../api/SuperTableAPI';
import compose from '../../../hoc/compose';
import AddFormContainer from '../containers/AddForm';

const dataSender = async ({
  tableType,
  dataType,
  token,
  year,
  promoAreaId,
  quarter,
  itemId,
  values,
  sourceName,
  columnName
}) => {
  switch (tableType) {
    case 'discharge':
      return await setZVDischarge({
        token,
        year,
        period: quarter,
        sourceName,
        values,
        promoAreaId,
        sourceId: itemId,
      });
    case 'waste':
      return await setData(token, {
        name: 'waste_data',
        values: { ...values, period: quarter, year },
        promoAreaId,
      });
    case 'atmosphere':
      return await setZVAtmosphere({
        token,
        period: quarter,
        year,
        values,
        sourceName,
        promoAreaId,
        itemId,
      });
    case 'emissions':
      return await setZVEmissions({
        token,
        name: 'calculation_data',
        year,
        promoAreaId,
        period: quarter,
        itemId,
        dataType,
        values,
      });
    case 'greenhouse':
      return await setGreenhouseData({
        token,
        name: 'greenhouse_gas_data',
        year,
        promoAreaId,
        period: quarter,
        itemId,
        dataType,
        values,
        columnName
      });
    default:
      return;
  }
};

const EditForm = ({
  tableType,
  token,
  promoAreaId,
  year,
  quarter,
  dataType,
  form,
  visible,
  modalClose,
  refresh,
  record,
  inputs = [],
  columnName
}) => {
  const { getFieldDecorator, validateFields, setFieldsValue } = form;
  const formRef = useRef(null);

  useEffect(() => {
    if (tableType === 'waste') {
      setFieldsValue({
        name: record.name,
        code: record.code,
        place: record.place,
      });
    }
  }, [visible]);

  const renderInputs = (inputs, firstTitle = '') => {
    return inputs.map(
      ({ title, dataIndex, children, component, rules = [] }) => {
        if (children) return renderInputs(children, title);

        let text = title
          .toLowerCase()
          .trim()
          .replace(/\W{1}/, str => str.toUpperCase());

        return (
          <div key={dataIndex}>
            {firstTitle && <p>{firstTitle}</p>}
            <Form.Item label={text}>
              {getFieldDecorator(dataIndex, {
                rules,
              })(component)}
            </Form.Item>
          </div>
        );
      }
    );
  };

  const onSubmit = evt => {
    evt.preventDefault();
    validateFields(async (err, values) => {
      console.table('values', values);
      if (!err && token) {
        console.table(values);
        try {
          const { id: itemId, source_name: sourceName } = record;
          const res = await dataSender({
            tableType,
            token,
            dataType,
            quarter,
            year,
            values,
            promoAreaId,
            itemId,
            sourceName,
            columnName
          });
          console.log(res);
          //
          // if (!res.data) {
          //   return message.error(res?.match(/[А-ЯЁ][ а-яё]*/m));
          // }
          await refresh();
          modalClose();
          message.success('Данные отправлены успешно!');
        } catch (error) {
          modalClose();
          message.error('Что-то пошло не так!');
          console.error(error);
        }
      }
      err && message.error('Форма заполнена не правильно!');
    });
  };

  const props = {
    visible,
    modalClose,
    onSubmit,
    formRef,
    inputs,
  };

  return <AddFormContainer {...props} renderInputs={renderInputs} />;
};

const mapStateToProps = ({
  userStore: { token },
  companyStore: { promoAreaId },
  pekDateStore: { year, quarter },
}) => ({ token, promoAreaId, year, quarter });

export default compose(connect(mapStateToProps), Form.create())(EditForm);

import React, {useState} from 'react';
import {Input} from 'antd';
import {isNumberAndAboveZero} from '../../../../../../utils/validation';

export default [
    {
        title: 'Вид отхода',
        dataIndex: 'waste_type',
        key: 'waste_type',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Код отхода',
        dataIndex: 'waste_code',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
            },
        ],
        key: 'waste_code',
        component: <Input/>,
    },
    {
        title: 'Лимит накопления отходов',
        dataIndex: 'limit_waste',
        key: 'limit_waste',
        footerTitle: 'Сумма, Лимит накопления отходов: ',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
                validator: isNumberAndAboveZero
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Срок накопления',
        dataIndex: 'accumulation_term',
        key: 'accumulation_term',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Место накопления отхода',
        children: [
            {
                title: 'Широта',
                dataIndex: 'latitude',
                key: 'latitude',
                rules: [
                    {
                        required: true,
                        message: 'Данные обязательны для заполнения',
                        validator: isNumberAndAboveZero
                    },
                ],
                component: <Input/>,
            },
            {
                title: 'Долгота',
                dataIndex: 'longitude',
                key: 'longitude',
                rules: [
                    {
                        required: true,
                        message: 'Данные обязательны для заполнения',
                        validator: isNumberAndAboveZero
                    },
                ],
                component: <Input/>,
            },
        ],
    },
    {
        title: 'Остаток на начало отчетного периода, тонн',
        dataIndex: 'balance_reporting_period',
        key: 'balance_reporting_period',
        footerTitle: 'Сумма, Остаток на начало отчетного периода',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
                validator: isNumberAndAboveZero

            },
        ],
        component: <Input/>,
    },
    {
        title: 'Образованный объем отходов на предприятии, тонн',
        dataIndex: 'volume_of_waste_enterprise',
        key: 'volume_of_waste_enterprise',
        footerTitle: 'Сумма, Образованный объем отходов на предприятии',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
                validator: isNumberAndAboveZero
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Фактический объем накопления за отчетный период, тонн',
        dataIndex: 'actual_volume_accumulation_period',
        key: 'actual_volume_accumulation_period',
        footerTitle: 'Сумма, Фактический объем накопления за отчетный период',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
                validator: isNumberAndAboveZero
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Переданный объем отходов на проведение операции с ними, тонн',
        dataIndex: 'transferred_volume_waste_operations',
        key: 'transferred_volume_waste_operations',
        footerTitle:
        'Сумма, Переданный объем отходов на проведение операции с ними',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
                validator: isNumberAndAboveZero
            },
        ],
        component: <Input/>,
    },
    {
        title: 'БИН организации, которому передан отход',
        dataIndex: 'bin_organization_flash_raw',
        key: 'bin_organization_flash_raw',
        rules: [
            {
              required: true,
              message: 'Данные обязательны для заполнения',
              validator: isNumberAndAboveZero,
            },
            {
              min: 12,
              max: 12,
              message: 'БИН должен состоять из 12 символов',
            },
          ],
        component: <Input/>,
    },
    {
        title: 'Объем отхода, с которым проведены операции на предприятии, тонн',
        dataIndex: 'waste_volume_operations_company',
        key: 'waste_volume_operations_company',
        footerTitle:
          'Сумма, Объем отхода, с которым проведены операции на предприятии',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
                validator: isNumberAndAboveZero
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Остаток отходов в накопителе на конец отчетного периода, тонн',
        dataIndex: 'remaining_waste_period',
        key: 'remaining_waste_period',
        footerTitle:
        'Сумма, Остаток отходов в накопителе на конец отчетного периода',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
                validator: isNumberAndAboveZero
            },
        ],
        component: <Input/>,
    },
];

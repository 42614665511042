import React, { useState } from 'react';
import { Form, message } from 'antd';
import { connect } from 'react-redux';

import { copy } from '../../../api/CopyAPI';
import CopyFormContainer from '../containers/CopyForm';

const CopyForm = ({ form, year, promoAreaId, token }) => {
  const { getFieldDecorator, validateFields } = form;
  const [visible, setVisible] = useState(false);

  const handleCancel = () => setVisible(false);

  const onSubmit = evt => {
    evt.preventDefault();
    if (!promoAreaId) return message.error('Не выбрано предприятие!');
    validateFields(async (err, values) => {
      err && message.error('Форма заполнена не правильно!');
      if (!err && token) {
        const { fromPeriod, toPeriod } = values;
        try {
          await copy(token, { year, fromPeriod, toPeriod, promoAreaId });
          handleCancel();
          message.success('Данные отправлены успешно!');
        } catch (error) {
          handleCancel();
          message.error('Что-то пошло не так!');
          console.error(error);
        }
      }
    });
  };

  return (
    <CopyFormContainer
      visible={visible}
      setVisible={setVisible}
      onSubmit={onSubmit}
      getFieldDecorator={getFieldDecorator}
      handleCancel={handleCancel}
    />
  );
};

const mapStateToProps = ({
  userStore: { token },
  companyStore: { promoAreaId },
  pekDateStore: { year },
}) => ({ promoAreaId, year, token });

export default Form.create()(connect(mapStateToProps)(CopyForm));

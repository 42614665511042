import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Select,
  Row,
  Typography,
  Upload,
  Icon,
} from 'antd';

import columnList from './data/columns';
import inputs from './data/inputs';
import expandedInputs from './data/expandedInputs';
import Table from '../../../../../modules/SuperTable';
import SuperSelect from '../../../../../components/SuperSelect';
import Editor from '../../../../../modules/Editor';
import editableExpandedInputs from './data/editableExpandedInputs';
import expandedColsList from './data/expandedCols';
import {
  getKatoSelectOptions,
  getSourceTypeSelectOptions,
  savePekOrderList,
  uploadPekProgramFile,
} from '../../../../../api/PekAPI';
import { compose } from 'redux';
import WithLoader from '../../../../../hoc/withLoader';
import config from '../../../../../config';
import { updateUser } from '../../../../../api/LoginAPI';
import moment from 'moment';
import { isNumberAndAboveZero } from '../../../../../utils/validation';

const { Option } = Select;

const formItemLayout = {
  layout: 'vertical',
};

const GeneralInformation = ({
  order,
  generalInfo,
  form,
  user,
  token,
  year,
  quarter,
  refresh,
  region,
  company,
}) => {
  const [selectedOrderType, setSelectedOrderType] = useState(null);
  const [orderTypes, setOrderTypes] = useState([]);
  const [placemarks, setPlacemarks] = useState([]);
  const [selectedOrderTypeDetails, setSelectedOrderTypeDetails] = useState(
    null
  );
  const [loader, setLoader] = useState(false);
  const [orderTypesDetails, setOrderTypesDetails] = useState([]);
  const [showTypesDetails, setShowTypesDetails] = useState([]);
  const { getFieldDecorator, validateFields, setFieldsValue } = form;
  const [programFile, setProgramFile] = useState(null);
  const [helpFile, setHelpFile] = useState(null);
  const { Option } = Select;

  const setProgramFileCheck = file => {
    if ((file?.size / 1024 / 1024).toFixed(4) > 50) {
      message.error('Файл больше 50мб');
      return;
    }
    setProgramFile(file);
  };

  const setHelpFileCheck = file => {
    if ((file?.size / 1024 / 1024).toFixed(4) > 50) {
      message.error('Файл больше 50мб');
      return;
    }
    setHelpFile(file);
  };

  useEffect(() => {
    if (order || generalInfo) {
      console.log(order);
      setFieldsValue({
        ...generalInfo,
        ...Object.entries(order).reduce(
          (a, [k, v]) => (v === null ? a : ((a[k] = v), a)),
          {}
        ),
        active_period: order?.active_period
          ? moment(order?.active_period)
          : generalInfo?.active_period
          ? moment(generalInfo?.active_period)
          : null,
        pek_confirm_date: order?.pek_confirm_date
          ? moment(order?.pek_confirm_date)
          : generalInfo?.pek_confirm_date
          ? moment(generalInfo?.pek_confirm_date)
          : null,
      });
    }
  }, [order, generalInfo]);

  const onSubmit = evt => {
    const formData = new FormData();
    evt.preventDefault();
    validateFields(async (err, values) => {
      console.log(values);
      err && message.error('Ошибка в заполнении формы!');

      const {
        active_period,
        company_power,
        fact_power,
        pek_confirm_date,
        pek_confirm_person,
      } = values;

      const requestData = {
        active_period,
        company_power,
        fact_power,
        pek_confirm_date,
        pek_confirm_person,
      };

      console.log(requestData);

      if (!err) {
        setLoader(true);
        savePekOrderList({
          values: {
            order: requestData,
            userId: user.username.id,
            orderId: order?.id,
            year,
            quarter,
          },
          token,
        })
          .then(async res => {
            if (programFile) {
              const formData = new FormData();
              formData.append('file', programFile);
              formData.append('columnName', 'pek_program_file');
              formData.append('orderId', res.data.id);
              await uploadPekProgramFile(formData, token);
            }
            if (helpFile) {
              const formData = new FormData();
              formData.append('file', helpFile);
              formData.append('columnName', 'pek_help_file');
              formData.append('orderId', res.data.id);
              await uploadPekProgramFile(formData, token);
            }
            message.success('Успешно !');
            refresh();
          })
          .catch(err => {
            console.log(err);
            message.error('Произошла ошибка !');
          })
          .finally(() => {
            setLoader(false);
          });
      }
    });
  };
  console.log(loader);

  const renderActionButton = () => {
    return (
      <Button type="primary" htmlType="submit">
        Сохранить
      </Button>
    );
  };

  useEffect(() => {
    if (!selectedOrderType) {
      setShowTypesDetails(orderTypesDetails);
    } else {
      const filtered = orderTypesDetails?.filter(detail => {
        console.log(detail, selectedOrderType);
        return detail.order_production_types_id === selectedOrderType;
      });
      setShowTypesDetails(filtered);
    }
  }, [selectedOrderType, orderTypesDetails]);

  const pekHelpFile =
    order?.pek_help_file || generalInfo?.pek_help_file || null;
  const pekProgramFile =
    order?.pek_program_file || generalInfo?.pek_program_file || null;

  return (
    <div>
      {loader ? <WithLoader /> : null}
      <Form {...formItemLayout} onSubmit={onSubmit}>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item label="Наименование объекта">
              {getFieldDecorator('name', {
                rules: [
                  {
                    required: false,
                    message: 'Поле обязательно для заполнения',
                  },
                ],
              })(<Input disabled={true} />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="КАТО">
              {getFieldDecorator('kato', {
                rules: [
                  {
                    required: false,
                    message: 'Поле обязательно для заполнения',
                  },
                ],
              })(<Input disabled={true} />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="БИН">
              {getFieldDecorator('bin', {
                rules: [
                  {
                    required: false,
                    message: 'Поле обязательно для заполнения',
                  },
                ],
              })(<Input disabled={true} />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="ОКЭД">
              {getFieldDecorator('oked', {
                rules: [
                  {
                    required: false,
                    message: 'Поле обязательно для заполнения',
                  },
                ],
              })(<Input disabled={true} />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Краткая характеристика производственного процесса">
              {getFieldDecorator('order_production_type_detail_name', {
                rules: [
                  {
                    required: false,
                    message: 'Поле обязательно для заполнения',
                  },
                ],
              })(<Input disabled={true} />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Email">
              {getFieldDecorator('email', {
                rules: [
                  {
                    required: false,
                    message: 'Поле обязательно для заполнения',
                  },
                ],
              })(<Input disabled={true} />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Номер телефона">
              {getFieldDecorator('phone_num', {
                rules: [
                  {
                    required: false,
                    message: 'Поле обязательно для заполнения',
                  },
                ],
              })(<Input disabled={true} />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Телефон руководителя">
              {getFieldDecorator('phone_leader', {
                rules: [
                  {
                    required: false,
                    message: 'Поле обязательно для заполнения',
                  },
                ],
              })(<Input disabled={true} />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="ФИО руководителя">
              {getFieldDecorator('fio_leader', {
                rules: [
                  {
                    required: false,
                    message: 'Поле обязательно для заполнения',
                  },
                ],
              })(<Input disabled={true} />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Почтовый индекс">
              {getFieldDecorator('postcode', {
                rules: [
                  {
                    required: false,
                    message: 'Поле обязательно для заполнения',
                  },
                ],
              })(<Input disabled={true} />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Город">
              {getFieldDecorator('city_kz', {
                rules: [
                  {
                    required: false,
                    message: 'Поле обязательно для заполнения',
                  },
                ],
              })(<Input disabled={true} />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Название улицы">
              {getFieldDecorator('street_name_ru', {
                rules: [
                  {
                    required: false,
                    message: 'Поле обязательно для заполнения',
                  },
                ],
              })(<Input disabled={true} />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Номер дома/здания">
              {getFieldDecorator('home_num', {
                rules: [
                  {
                    required: false,
                    message: 'Поле обязательно для заполнения',
                  },
                ],
              })(<Input disabled={true} />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Категория объекта">
              {getFieldDecorator('category', {
                rules: [
                  {
                    required: false,
                    message: 'Поле обязательно для заполнения',
                  },
                ],
              })(<Input disabled={true} />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Проектная мощность предприятия">
              {getFieldDecorator('company_power', {
                rules: [
                  {
                    required: true,
                    message: 'Поле обязательно для заполнения',
                    validator: isNumberAndAboveZero,
                  },
                ],
              })(<Input />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Фактическая мощность за отчетный период">
              {getFieldDecorator('fact_power', {
                rules: [
                  {
                    required: true,
                    message: 'Поле обязательно для заполнения',
                    validator: isNumberAndAboveZero,
                  },
                ],
              })(<Input />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Период действия программы ПЭК">
              {getFieldDecorator('active_period', {
                rules: [
                  {
                    type: 'object',
                    required: true,
                    message: 'Поле обязательно для заполнения',
                  },
                ],
              })(
                <DatePicker
                  style={{ width: '100%' }}
                  placeholder="Выберите дату"
                  format="YYYY-MM-DD"
                />
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Кем утвержден">
              {getFieldDecorator('pek_confirm_person', {
                rules: [
                  {
                    required: true,
                    message: 'Поле обязательно для заполнения',
                  },
                ],
              })(<Input />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Дата утверждения ПЭК">
              {getFieldDecorator('pek_confirm_date', {
                rules: [
                  {
                    type: 'object',
                    required: true,
                    message: 'Поле обязательно для заполнения',
                  },
                ],
              })(
                <DatePicker
                  style={{ width: '100%' }}
                  placeholder="Выберите дату"
                  format="YYYY-MM-DD"
                />
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item required={true} label="Программа ПЭК (максимум 50мб)">
              {pekProgramFile && (
                <div style={{ marginBottom: 15 }}>
                  <a
                    onClick={() =>
                      window.open(`${config.api_url}${pekProgramFile}`)
                    }
                  >
                    {pekProgramFile}
                  </a>
                </div>
              )}
              <div>
                <input
                  type="file"
                  onChange={e => setProgramFileCheck(e.target.files[0])}
                />
              </div>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              required={true}
              label="Пояснительная записка (максимум 50мб)"
            >
              {pekHelpFile && (
                <div style={{ marginBottom: 15 }}>
                  <a
                    onClick={() =>
                      window.open(`${config.api_url}${pekHelpFile}`)
                    }
                  >
                    {pekHelpFile}
                  </a>
                </div>
              )}
              <div>
                <input
                  type="file"
                  onChange={e => setHelpFileCheck(e.target.files[0])}
                />
              </div>
            </Form.Item>
          </Col>
          {/* <Col span={12}>
            <Form.Item label="Номер и срок действия аттестата аккредитации испытательной лаборатории (максимум 30мб)">
              {getFieldDecorator('conclusion_file', {
                rules: [
                  {
                    required: false,
                    message: 'Поле обязательно для заполнения',
                  },
                ],
              })(
                <>
                  {generalInfo?.conclusion_file && (
                    <a
                      onClick={() =>
                        window.open(
                          `${config.api_url}${generalInfo?.conclusion_file}`
                        )
                      }
                    >
                      {generalInfo?.conclusion_file}
                    </a>
                  )}
                  <div>
                    <input
                      type="file"
                      onChange={e => setConclusionFileCheck(e.target.files[0])}
                    />
                  </div>
                </>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Наименование аккредитованной испытательной лаборатории (максимум 30мб)">
              {getFieldDecorator('resolution_file', {
                rules: [
                  {
                    required: false,
                    message: 'Поле обязательно для заполнения',
                  },
                ],
              })(
                <>
                  {generalInfo?.resolution_file && (
                    <a
                      onClick={() =>
                        window.open(
                          `${config.api_url}${generalInfo?.resolution_file}`
                        )
                      }
                    >
                      {generalInfo?.resolution_file}
                    </a>
                  )}
                  <div>
                    <input
                      type="file"
                      onChange={e => setResolutionFileCheck(e.target.files[0])}
                    />
                  </div>
                </>
              )}
            </Form.Item>
          </Col> */}
        </Row>
        {renderActionButton()}
      </Form>
    </div>
  );
};

const mapStateToProps = ({
  userStore: { token, user },
  pekDateStore: { order, year, quarter },
}) => ({ token, user, order, year, quarter });

const mapDispatchToProps = {};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  Form.create()
)(GeneralInformation);

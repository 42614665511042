import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Select, Typography } from 'antd';
import columnList from './data/columns';
import inputs from './data/inputs';
import expandedInputs from './data/expandedInputs';
// import Table from '../../../../../modules/SuperTable';
// import SuperSelect from '../../../../../components/SuperSelect';
// import Editor from '../../../../../modules/Editor';
import editableExpandedInputs from './data/editableExpandedInputs';
import expandedColsList from './data/expandedCols';
import {
  getKatoSelectOptions,
  getSourceTypeSelectOptions,
} from '../../../api/PekAPI';
import StandardTable from '../../../modules/StandardTable';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Editor from '../../../modules/Editor';

const { Option } = Select;

const tableName = 'promo_areas'

const PromAreas = ({ substance, token }) => {
  const [katoSelectOptions, setKatoSelectOptions] = useState();
  const [sourceTypeSelectOptions, setSourceTypeSelectOptions] = useState();
  // const renderSelectOption = () => {
  //     return substance.map(({code, name}) => (
  //         <Option
  //             key={code}
  //             value={`${code} - ${name}`}
  //         >{`${code} - ${name}`}</Option>
  //     ));
  // };
  //
  // useEffect(() => {
  //     getKatoSelectOptions({token}).then(res => {
  //         setKatoSelectOptions(
  //             res.data &&
  //             res.data.map(option => ({
  //                 value: option.id,
  //                 label: option.name,
  //             }))
  //         );
  //     });
  //
  //     getSourceTypeSelectOptions({token}).then(res => {
  //         setSourceTypeSelectOptions(
  //             res.data &&
  //             res.data.map(option => ({
  //                 value: option.id,
  //                 label: option.name,
  //             }))
  //         );
  //     });
  //
  //     const inputIndex = expandedInputs.findIndex(
  //         ({title}) => title === 'Код и наименование ЗВ'
  //     );
  //
  //     const select = (
  //         <SuperSelect
  //             showSearch
  //             filterOption={(input, option) =>
  //                 option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
  //             }
  //         >
  //             {renderSelectOption()}
  //         </SuperSelect>
  //     );
  //     expandedInputs[inputIndex].component = select;
  //     editableExpandedInputs[inputIndex].component = select;
  // }, []);

  const transformDataSource = (data, setState) => {
    const newDataSource = [];
    data.forEach(el =>
      newDataSource.push({
        key: el.id,
        ...el,
      })
    );
    setState(newDataSource);
  };
  const columns = rounding => (token, refresh) => {
    const columns = [
      {
        title: 'Действие',
        key: 'action',
        render: (text, record) => (
          <Editor
            addInputs={inputs}
            editInputs={inputs}
            text={text}
            tableName={tableName}
            editTableType={'gos_pek'}
            record={record}
            refresh={refresh}
          />
        ),
      },
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        render: form_id => <Link to={`pek-info/${form_id}`}>{form_id}</Link>,
      },
      {
        title: 'Наименование производственного объекта',
        dataIndex: 'name_area',
        key: 'name_area',
      },
      {
        title: 'Бизнес Идентификационный номер оператора объекта (БИН)',
        dataIndex: 'bin',
        key: 'bin',
      },
      {
        title: 'Широта',
        dataIndex: 'latitude',
        key: 'latitude',
      },
      {
        title: 'Долгота',
        dataIndex: 'longitude',
        key: 'longitude',
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Номер телефона',
        dataIndex: 'phone_number',
        key: 'phone_number',
      },
      // {
      //   title: 'Статус заявки',
      //   dataIndex: 'status_category',
      //   key: 'status_category',
      // },
      {
        title: 'Дата заявки',
        dataIndex: 'date_application',
        key: 'date_application',
        render: text => moment(text).format('DD-MM-YYYY'),
      },
    ];
    return columns;
  };

  // const insertEditor = ({target, fixed = false, rounding, width = 300}) => (
  //     token,
  //     refresh
  // ) => [
  //     ...target(rounding),
  //     {
  //         title: 'Период действия программы производственного мониторинга',
  //         key: 'action',
  //         width: width,
  //         fixed: fixed ? 'right' : false,
  //         render: (text, record) => {
  //             const {is_source} = record;
  //             const props = {
  //                 text,
  //                 refresh,
  //                 record,
  //                 editInputs: is_source ? inputs : editableExpandedInputs,
  //                 addInputs: expandedInputs,
  //                 tableName: is_source ? 'calculation_source' : 'calculation_data',
  //                 dataType: 'instrumental_result',
  //                 tableType: 'emissions',
  //                 editTableType: 'super',
  //             };
  //             return <Editor {...props} />;
  //         },
  //     },
  // ];
  //
  // const formItemLayout = {
  //     labelCol: {
  //         xs: {span: 24},
  //         sm: {span: 12},
  //     },
  //     wrapperCol: {
  //         xs: {span: 18},
  //         sm: {span: 12},
  //     },
  // };
  //
  // const katoSelectIndex = inputs.findIndex(
  //     ({dataIndex}) => dataIndex === 'kato_id'
  // );
  // const sourceTypeSelectIndex = inputs.findIndex(
  //     ({dataIndex}) => dataIndex === 'source_type_id'
  // );
  // const newInputs = inputs.map((item, index) => {
  //     return index === katoSelectIndex
  //         ? {
  //             ...item,
  //             component: (
  //                 <Select>
  //                     {katoSelectOptions &&
  //                     katoSelectOptions.map(option => (
  //                         <Option key={option.value}>{option.label}</Option>
  //                     ))}
  //                 </Select>
  //             ),
  //         }
  //         : index === sourceTypeSelectIndex
  //             ? {
  //                 ...item,
  //                 component: (
  //                     <Select>
  //                         {sourceTypeSelectOptions &&
  //                         sourceTypeSelectOptions.map(option => (
  //                             <Option key={option.value}>{option.label}</Option>
  //                         ))}
  //                     </Select>
  //                 ),
  //             }
  //             : item;
  // });
  //
  // const props = {
  //     tableName,
  //     modalTables: [
  //         'instrumental_calculation_source',
  //         'instrumental_calculation_data',
  //     ],
  //     formItemLayout,
  //     inputs: newInputs,
  //     columns: rounding => insertEditor({target: columnList, rounding}),
  //     expandedCols: rounding =>
  //         insertEditor({
  //             target: expandedColsList,
  //             fixed: true,
  //             width: 120,
  //             rounding,
  //         }),
  //     expandedInputs,
  //     scrollMultiplier: 190,
  //     transformDataSource,
  //     dataType: 'instrumental_result',
  //     company: true,
  //     tableType: 'emissions',
  // };

  return (
    <div>
      <Typography.Title level={4}>
        {/* Результаты инструментальных замеров */}
      </Typography.Title>
      <StandardTable
        inputs={inputs}
        tableName={tableName}
        tableType={'gos_pek'}
        promo_areas={'promo'}
        columns={rounding => columns(rounding)}
        transformDataSource={transformDataSource}
        dataSource={[]}
      />
    </div>
  );
};

const mapStateToProps = ({
  substanceStore: { substance },
  userStore: { token },
  pekDateStore: { year, quarter },
}) => ({
  substance,
  token,
  year,
  quarter,
});

export default connect(mapStateToProps)(PromAreas);

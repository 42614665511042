import { Button, Collapse, Select, Tabs, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Atmosphere from '../PEK/Atmosphere';
import Discharges from '../PEK/Discharges';
// import Info from './Info';
import ActionPlans from '../PEK/ActionPlans';
import RadiationMonitoring from '../PEK/RadiationMonitoring';
import Soils from '../PEK/Soils';
import Water from '../PEK/Water';
// import moment from "moment";
import Configurator from '../../modules/DateConfigurator/components/Configurator';
import { useParams } from 'react-router-dom';
import Pollutants from '../PEK/Info/Tables/Pollutants';
import { getMonitoringById } from '../../api/PekAPI';

const { TabPane } = Tabs;
const { Option } = Select;

const PEKInfo = () => {
  console.log(useParams().id);
  const { year, quarter, promoAreaId, token } = useSelector(state => ({
    ...state.userStore,
    ...state.pekDateStore,
    ...state.companyStore,
  }));
  const [data, setData] = useState([]);
  const [productionMonitorings, setProductionMonitorings] = useState([]);
  const params = useParams();

  const refreshPollutants = () => {
    getMonitoringById({ token, id: params.id, year, quarter }).then(res => {
      console.log('TVR', res.data);
      setProductionMonitorings(res.data);
    });
  };

  useEffect(() => {
    refreshPollutants();
  }, [params.id, year, quarter]);

  const searchData = (value, nameValue) => {
    setData(prevFilterValues => ({
      ...prevFilterValues,
      [nameValue]: value,
    }));
  };
  return (
    <div>
      {/*<div style={{display: 'flex'}}>*/}
      {/*    <Button*/}
      {/*        onClick={() =>*/}
      {/*            window.open(*/}
      {/*                `http://185.146.3.215:4000/api/pek/yerlan_test?companyId=32&period=${quarter}&year=${year}`*/}
      {/*            )*/}
      {/*        }*/}
      {/*    >*/}
      {/*        Вывести документацию*/}
      {/*    </Button>*/}
      {/*    <div style={{display: 'flex', marginRight: 15, marginLeft: 15}}>*/}
      {/*        <p style={{marginRight: 15}}>Год:</p>*/}
      {/*        <InputNumber*/}
      {/*            min={2000}*/}
      {/*            name="year"*/}
      {/*            onChange={(value, name = 'year') => searchData(value, name)}*/}
      {/*            max={+moment().format('YYYY')}*/}
      {/*            defaultValue={2021}*/}
      {/*        />*/}
      {/*    </div>*/}
      {/*    <div style={{display: 'flex'}}>*/}
      {/*        <p style={{marginRight: 15}}>Квартал:</p>*/}
      {/*        <Select*/}
      {/*            placeholder="Квартал"*/}
      {/*            name="period"*/}
      {/*            style={{width: 200}}*/}
      {/*            defaultValue={3}*/}
      {/*            onChange={(value, name) => searchData(value, name = "period")}*/}
      {/*        >*/}
      {/*            <Option value={1}>1</Option>*/}
      {/*            <Option value={2}>2</Option>*/}
      {/*            <Option value={3}>3</Option>*/}
      {/*            <Option value={4}>4</Option>*/}
      {/*        </Select>*/}
      {/*    </div>*/}
      {/*</div>*/}

      <Tabs>
        {/*<TabPane tab="Общие сведения" key="1">*/}
        {/*    <Info searchData={data}/>*/}
        {/*</TabPane>*/}
        <TabPane tab="Сведения о лаборатории" key="1">
          <Typography.Title level={4}>
            Сведения об аккредитованной испытательной лаборатории
          </Typography.Title>
          <Collapse bordered={false} defaultActiveKey={[]}>
            {productionMonitorings.map((productionMonitoring, index) => (
              <Collapse.Panel
                header={productionMonitoring.name_accredited_laboratory}
                key={index}
              >
                <Pollutants
                  refresh={refreshPollutants}
                  tableName={'production_monitoring'}
                  productionMonitoring={productionMonitoring}
                />
              </Collapse.Panel>
            ))}
            <Collapse.Panel
              header="Добавить"
              key={productionMonitorings.length}
            >
              <Pollutants
                refresh={refreshPollutants}
                tableName={'production_monitoring'}
              />
            </Collapse.Panel>
          </Collapse>
        </TabPane>

        <TabPane tab="Отходы" key="2">
          <Discharges searchData={data} />
        </TabPane>
        {/*<TabPane tab="Отходы" key="3">*/}
        {/*  <Waste year={year} />*/}
        {/*</TabPane>*/}
        <TabPane tab="Атмосферный воздух" key="4">
          <Atmosphere searchData={data} />
        </TabPane>
        <TabPane tab="Поверхностные и подземные воды" key="5">
          <Water searchData={data} />
        </TabPane>
        <TabPane tab="Почвы" key="6">
          <Soils searchData={data} />
        </TabPane>
        <TabPane tab="Радиационный контроль" key="7">
          <RadiationMonitoring searchData={data} />
        </TabPane>
        <TabPane tab="ПМ" key="8">
          <ActionPlans searchData={data} />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default PEKInfo;

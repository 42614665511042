import React from 'react';
import {Input, Select} from 'antd';
import { isNumberAndAboveZero } from '../../../../../../utils/validation';

export default [
    {
        title: 'Наименование Площадки',
        dataIndex: 'name',
        key: 'name',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
            },
        ],
        component: <Input/>,
    },
    {
        title: 'широта',
        dataIndex: 'latitude',
        key: 'latitude',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
                validator: isNumberAndAboveZero
            },
        ],
        component: <Input/>,
    },
    {
        title: 'долгота',
        dataIndex: 'longitude',
        key: 'longitude',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
                validator: isNumberAndAboveZero
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Источник выброса наименование',
        dataIndex: 'ejection_source_name',
        key: 'ejection_source_name',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Источник выброса номер',
        dataIndex: 'ejection_source_number',
        key: 'ejection_source_number',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
                validator: isNumberAndAboveZero
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Код вещества',
        dataIndex: 'substance_code',
        key: 'substance_code',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
                validator: isNumberAndAboveZero
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Наименование загрязняющих веществ',
        dataIndex: 'name_pollutants',
        key: 'name_pollutants',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Установленный норматив по ПДВ, ОВОС, грамм в секунду',
        dataIndex: 'established_standard_gram_sek',
        key: 'established_standard_gram_sek',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
                validator: isNumberAndAboveZero
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Установленный норматив по ПДВ, ОВОС, тонна в год',
        dataIndex: 'established_standard_ton_year',
        key: 'established_standard_ton_year',
        footerTitle: 'Сумма, Установленный норматив по ПДВ, ОВОС т/г',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
                validator: isNumberAndAboveZero
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Фактический результат,грамм в секунду',
        dataIndex: 'actual_result_gram_sek',
        key: 'actual_result_gram_sek',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
                validator: isNumberAndAboveZero
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Фактический результат, тонна в год',
        dataIndex: 'actual_result_ton_year',
        key: 'actual_result_ton_year',
        footerTitle: 'Сумма, Фактический результат т/г',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
                validator: isNumberAndAboveZero
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Методика расчета',
        dataIndex: 'calculation_method',
        key: 'calculation_method',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Вид потребляемого сырья/ материала (название)',
        dataIndex: 'type_waste',
        key: 'type_waste',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Расход сырья/ материала, тонн',
        dataIndex: 'row_consumption_ton',
        key: 'row_consumption_ton',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Время работы оборудования, часов',
        dataIndex: 'time_work',
        key: 'time_work',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
            },
        ],
        component: <Input/>,
    },
    {
        title: 'Превышение нормативов ПДВ',
        dataIndex: 'exceeding_standards',
        key: 'exceeding_standards',
        footerTitle: 'Сумма, Превышение нормативов ПДВ',
        rules: [
            {
                required: true,
                message: 'Данные обязательны для заполнения',
            },
        ],
        component: <Input/>,
    },
];

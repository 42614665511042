import calcColumns from '../../../../../utils/calcColumns';
import { isNumberAndAboveZero } from '../../../../../utils/validation';

const values = [
  'N п/п',
  'Наименование площадки/карты (карты №)',
  'Координаты,град., мин., сек.',
  'Занимаемая территория, га',
  'Нормативы размещения серы в открытом  виде, т/год*',
  'Метод вторичной переработки/ликвидации, т/год',
  'Примечание',
];

const renderContent = (value, row, index) => {
  let target = values.find(value => value === row.number);
  if (!target) target = '';

  const obj = {
    children: value,
    props: {},
  };
  if (row.number === target) {
    obj.props.colSpan = 0;
  }
  return obj;
};

const columns = rounding => [
  {
    title: 'N п/п',
    dataIndex: 'number',
    render: (value, row, index) => {
      let target = values.find(value => value === row.number);
      if (!target) target = '';
      if (row.number !== target) {
        return value;
      }
      return {
        children: value,
        props: {
          colSpan: columnSum + 1,
        },
      };
    },
  },
  {
    title: 'Наименование площадки/карты (карты №)',
    dataIndex: 'name',
    render: renderContent,
  },
  {
    title: 'Координаты,  град., мин., сек.',
    children: [
      {
        title: 'широта',
        dataIndex: 'latitude',
        key: 'latitude',
        render: renderContent,
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
      },
      {
        title: 'долгота',
        dataIndex: 'longitude',
        key: 'longitude',
        render: renderContent,
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
      },
    ],
  },
  {
    title: 'Занимаемая территория, га',
    dataIndex: 'territory',
    key: 'territory',
    render: renderContent,
  },
  {
    title: 'Нормативы размещения серы в открытом  виде, т/год*',
    dataIndex: 'placement_of_sulfur',
    key: 'placement_of_sulfur',
    render: renderContent,
  },
  {
    title: 'Метод вторичной переработки/ликвидации, т/год',
    dataIndex: 'recycling_method',
    key: 'recycling_method',
    render: renderContent,
  },
  {
    title: 'Примечание',
    dataIndex: 'note',
    key: 'note',
    render: renderContent,
  },
];

const columnSum = calcColumns(columns());

export default columns;

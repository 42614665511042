import { Button, Icon, message, Modal, Popconfirm, Tabs, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { deleteAllFromPekTable } from '../../api/PekAPI';
import { checkEditableOrder } from '../../utils/columnFilter';

const PekDeleteAllFromTable = ({ tableName, report }) => {
  const params = useParams();

  const { token, year, quarter, order } = useSelector(state => ({
    ...state.userStore,
    ...state.pekDateStore,
  }));

  const handleOk = async () => {
    await deleteAllFromPekTable(params.id, tableName, token, year, quarter)
      .then(() => {
        window.location.reload();
      })
      .catch(() => message.error('Произошла ошибка'));
  };

  const canDeleteAll = checkEditableOrder(
    tableName === 'pek_water' ? report : order
  );

  return (
    <Popconfirm
      title="Вы уверены ?"
      onConfirm={handleOk}
      okText="Да"
      cancelText="Нет"
    >
      {canDeleteAll && (
        <Button type="danger">
          <Icon type="delete" />
          Удалить все
        </Button>
      )}
    </Popconfirm>
  );
};

export default PekDeleteAllFromTable;

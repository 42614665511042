import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Select } from 'antd';
import { Typography, Table } from 'antd';
import { renderColumns } from './data/columns';
import inputs from './data/inputs';
// import columnList from './data/columns';
import expandedInputs from './data/expandedInputs';
import editableExpandedInputs from './data/editableExpandedInputs';
import SuperSelect from '../../../../../components/SuperSelect';
// import Table from '../../../../../modules/SuperTable';
import Editor from '../../../../../modules/Editor';
import expandedColsList from './data/expandedCols';
import {
  getKatoSelectOptions,
  getSourceTypeSelectOptions,
} from '../../../../../api/PekAPI';
import StandardTable from '../../../../../modules/StandardTable';
import PekDeleteAllFromTable from '../../../PekDeleteAllFromTable';
import moment from 'moment';

const { Option } = Select;
const PAGE_SIZE = 15;

const Pollutants = ({ substance, fetchSumSources, token, tableName }) => {
  const [katoSelectOptions, setKatoSelectOptions] = useState();
  const [sourceTypeSelectOptions, setSourceTypeSelectOptions] = useState();
  const [currentPage, setCurrentPage] = useState('1');
  const [totalPage, setTotalPage] = useState(0);

  const renderSelectOption = () => {
    return substance.map(({ code, name }) => (
      <Option
        key={code}
        value={`${code} - ${name}`}
      >{`${code} - ${name}`}</Option>
    ));
  };

  const transformDataSource = (data, setState) => {
    const newDataSource = [];
    data.forEach(el =>
      newDataSource.push({
        key: el.id,
        ...el,
      })
    );
    setState(newDataSource);
  };
  const columns = rounding => (token, refresh) => {
    // console.log(dataSource.filter(data => data.department_id === '6'));
    const columns = [
      {
        title: 'Действие',
        key: 'action',
        render: (text, record) => (
          <Editor
            addInputs={inputs}
            editInputs={inputs}
            text={text}
            tableName={tableName}
            editTableType={'gos_pek'}
            record={record}
            refresh={refresh}
          />
        ),
      },
      {
        title: ' № ',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: 'Мероприятие по применению НДТ, соблюдению нормативов',
        dataIndex: 'application_measure_ndt',
        key: 'application_measure_ndt',
      },
      {
        title: 'Объект / источник эмиссии',
        dataIndex: 'object_emissions',
        key: 'object_emissions',
      },
      {
        title: 'Код вещества',
        dataIndex: 'code_substances',
        key: 'code_substances',
      },
      {
        title: 'Наименование загрязняющих веществ',
        dataIndex: 'name_substances',
        key: 'name_substances',
      },
      {
        title: 'Показатель (нормативы эмиссий, технологические нормативы)',
        dataIndex: 'indicator_nominal',
        key: 'indicator_nominal',
      },
      {
        title: 'Фактическая величина на конец года',
        dataIndex: 'actual_volume_year',
        key: 'actual_volume_year',
      },
      {
        title: 'Срок выполнения',
        dataIndex: 'deadline',
        key: 'deadline',
        render: date => moment(date).format('YYYY-MM-DD'),
      },
      {
        title: 'примечание',
        dataIndex: 'note',
        key: 'note',
      },
    ];
    return columns;
  };

  // useEffect(() => {
  //   getKatoSelectOptions({ token }).then(res => {
  //     setKatoSelectOptions(
  //       res.data &&
  //         res.data.map(option => ({
  //           value: option.id,
  //           label: option.name,
  //         }))
  //     );
  //   });
  //
  //   getSourceTypeSelectOptions({ token }).then(res => {
  //     setSourceTypeSelectOptions(
  //       res.data &&
  //         res.data.map(option => ({
  //           value: option.id,
  //           label: option.name,
  //         }))
  //     );
  //   });
  //
  //   const inputIndex = expandedInputs.findIndex(
  //     ({ title }) => title === 'Код и наименование ЗВ'
  //   );
  //   const select = (
  //     <SuperSelect
  //       showSearch
  //       filterOption={(input, option) =>
  //         option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
  //       }
  //     >
  //       {renderSelectOption()}
  //     </SuperSelect>
  //   );
  //   expandedInputs[inputIndex].component = select;
  //   editableExpandedInputs[inputIndex].component = select;
  // }, []);
  //
  // const insertEditor = ({ target, fixed = false, width = 300, rounding }) => (
  //   token,
  //   refresh
  // ) => [
  //   ...target(rounding),
  //   {
  //     title: 'Область аккредитации испытательной лаборатории',
  //     key: 'action',
  //     width: width,
  //     fixed: fixed ? 'right' : false,
  //     render: (text, record) => {
  //       const { is_source, data_type } = record;
  //       const props = {
  //         text,
  //         refresh,
  //         record,
  //         editInputs: is_source ? inputs : editableExpandedInputs,
  //         addInputs: expandedInputs,
  //         tableName: is_source ? 'calculation_source' : 'calculation_data',
  //         dataType: 'fact_emissions',
  //         isFactEmissions:
  //           data_type === 'instrumental_result' && is_source ? true : false,
  //         tableType: 'emissions',
  //         editTableType: 'super',
  //       };
  //       return <Editor {...props} />;
  //     },
  //   },
  // ];
  // const katoSelectIndex = inputs.findIndex(
  //   ({ dataIndex }) => dataIndex === 'kato_id'
  // );
  // const sourceTypeSelectIndex = inputs.findIndex(
  //   ({ dataIndex }) => dataIndex === 'source_type_id'
  // );
  // const newInputs = inputs.map((item, index) => {
  //   return index === katoSelectIndex
  //     ? {
  //         ...item,
  //         component: (
  //           <Select>
  //             {katoSelectOptions &&
  //               katoSelectOptions.map(option => (
  //                 <Option key={option.value}>{option.label}</Option>
  //               ))}
  //           </Select>
  //         ),
  //       }
  //     : index === sourceTypeSelectIndex
  //     ? {
  //         ...item,
  //         component: (
  //           <Select>
  //             {sourceTypeSelectOptions &&
  //               sourceTypeSelectOptions.map(option => (
  //                 <Option key={option.value}>{option.label}</Option>
  //               ))}
  //           </Select>
  //         ),
  //       }
  //     : item;
  // });
  // const props = {
  //   tableName,
  //   modalTables: ['calculation_source', 'calculation_data'],
  //   // columns: rounding =>
  //   // insertEditor({ target: columnList, width: 270, rounding }),
  //   expandedCols: rounding =>
  //     insertEditor({
  //       target: expandedColsList,
  //       fixed: true,
  //       width: 120,
  //       rounding,
  //     }),
  //   inputs: newInputs,
  //   expandedInputs,
  //   dataType: 'fact_emissions',
  //   scrollMultiplier: 170,
  //   transformDataSource,
  //   fetchSumSources,
  //   company: true,
  //   tableType: 'emissions',
  // };

  return (
    <div>
      {/* <Typography.Title level={4}>
        Фактические выбросы загрязняющих веществ (сводная таблица) по
        мониторингу эмиссии атмосферного воздуха
      </Typography.Title> */}
      <StandardTable
        inputs={inputs}
        tableName={tableName}
        tableType={'gos_pek'}
        columns={rounding => columns(rounding)}
        transformDataSource={transformDataSource}
        dataSource={[]}
      />
      <Button.Group style={{ marginBottom: 20 }}>
        <PekDeleteAllFromTable tableName={tableName} />
      </Button.Group>
    </div>
  );
};

const mapStateToProps = ({
  substanceStore: { substance },
  companyStore: { promoAreaId },
  pekDateStore: { year, quarter },
  userStore: { token },
}) => ({
  substance,
  promoAreaId,
  year,
  quarter,
  token,
});

export default connect(mapStateToProps)(Pollutants);

import React from 'react';
import { DatePicker, Input, Select } from 'antd';
import { isNumberAndAboveZero } from '../../../../../../utils/validation';

export default [
  {
    title: 'Мероприятие по применению НДТ, соблюдению нормативов',
    dataIndex: 'application_measure_ndt',
    key: 'application_measure_ndt',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Объект / источник эмиссии',
    dataIndex: 'object_emissions',
    key: 'object_emissions',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Код вещества',
    dataIndex: 'code_substances',
    key: 'code_substances',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Наименование загрязняющих веществ',
    dataIndex: 'name_substances',
    key: 'name_substances',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Показатель (нормативы эмиссий, технологические нормативы)',
    dataIndex: 'indicator_nominal',
    key: 'indicator_nominal',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Фактическая величина на конец года',
    dataIndex: 'actual_volume_year',
    key: 'actual_volume_year',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Срок выполнения',
    dataIndex: 'deadline',
    key: 'deadline',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <DatePicker format="YYYY-MM-DD" />,
  },
  {
    title: 'примечание',
    dataIndex: 'note',
    key: 'note',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
];

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Select } from 'antd';
import inputs from './data/inputs';
import StandardTable from '../../../../../modules/StandardTable';
import Editor from '../../../../../modules/Editor';
import PekDeleteAllFromTable from '../../../PekDeleteAllFromTable';
import PekPromoFilesUpload from '../../../PekPromoFilesUpload';

const { Option } = Select;

const PAGE_SIZE = 15;

const InfoAtmos = ({ substance, token, tableName }) => {
  // const [katoSelectOptions, setKatoSelectOptions] = useState();
  // const [sourceTypeSelectOptions, setSourceTypeSelectOptions] = useState();
  // const [currentPage, setCurrentPage] = useState('1');
  // const [totalPage, setTotalPage] = useState(0);

  // const renderSelectOption = () => {
  //   return substance.map(({ code, name }) => (
  //     <Option
  //       key={code}
  //       value={`${code} - ${name}`}
  //     >{`${code} - ${name}`}</Option>
  //   ));
  // };
  //
  // useEffect(() => {
  //   getKatoSelectOptions({ token }).then(res => {
  //     setKatoSelectOptions(
  //       res.data &&
  //         res.data.map(option => ({
  //           value: option.id,
  //           label: option.name,
  //         }))
  //     );
  //   });
  //
  //   getSourceTypeSelectOptions({ token }).then(res => {
  //     setSourceTypeSelectOptions(
  //       res.data &&
  //         res.data.map(option => ({
  //           value: option.id,
  //           label: option.name,
  //         }))
  //     );
  //   });
  //
  //   const inputIndex = expandedInputs.findIndex(
  //     ({ title }) => title === 'Код и наименование ЗВ'
  //   );
  //
  //   const select = (
  //     <SuperSelect
  //       showSearch
  //       filterOption={(input, option) =>
  //         option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
  //       }
  //     >
  //       {renderSelectOption()}
  //     </SuperSelect>
  //   );
  //   expandedInputs[inputIndex].component = select;
  //   editableExpandedInputs[inputIndex].component = select;
  // }, []);
  //
  //

  //
  // const insertEditor = ({ target, fixed = false, rounding, width = 300 }) => (
  //   token,
  //   refresh
  // ) => [
  //   ...target(rounding),
  //   {
  //     title: 'Период действия программы производственного мониторинга',
  //     key: 'action',
  //     width: width,
  //     fixed: fixed ? 'right' : false,
  //     render: (text, record) => {
  //       const { is_source } = record;
  //       const props = {
  //         text,
  //         refresh,
  //         record,
  //         editInputs: is_source ? inputs : editableExpandedInputs,
  //         addInputs: expandedInputs,
  //         tableName: is_source ? 'calculation_source' : 'calculation_data',
  //         dataType: 'instrumental_result',
  //         tableType: 'emissions',
  //         editTableType: 'super',
  //       };
  //       return <Editor {...props} />;
  //     },
  //   },
  // ];
  //
  // const formItemLayout = {
  //   labelCol: {
  //     xs: { span: 24 },
  //     sm: { span: 12 },
  //   },
  //   wrapperCol: {
  //     xs: { span: 18 },
  //     sm: { span: 12 },
  //   },
  // };
  //
  // const katoSelectIndex = inputs.findIndex(
  //   ({ dataIndex }) => dataIndex === 'kato_id'
  // );
  // const sourceTypeSelectIndex = inputs.findIndex(
  //   ({ dataIndex }) => dataIndex === 'source_type_id'
  // );
  // const newInputs = inputs.map((item, index) => {
  //   return index === katoSelectIndex
  //     ? {
  //         ...item,
  //         component: (
  //           <Select>
  //             {katoSelectOptions &&
  //               katoSelectOptions.map(option => (
  //                 <Option key={option.value}>{option.label}</Option>
  //               ))}
  //           </Select>
  //         ),
  //       }
  //     : index === sourceTypeSelectIndex
  //     ? {
  //         ...item,
  //         component: (
  //           <Select>
  //             {sourceTypeSelectOptions &&
  //               sourceTypeSelectOptions.map(option => (
  //                 <Option key={option.value}>{option.label}</Option>
  //               ))}
  //           </Select>
  //         ),
  //       }
  //     : item;
  // });
  //
  // const props = {
  //   tableName,
  //   modalTables: [
  //     'instrumental_calculation_source',
  //     'instrumental_calculation_data',
  //   ],
  //   formItemLayout,
  //   inputs: newInputs,
  //   columns: rounding => insertEditor({ target: columnList, rounding }),
  //   expandedCols: rounding =>
  //     insertEditor({
  //       target: expandedColsList,
  //       fixed: true,
  //       width: 120,
  //       rounding,
  //     }),
  //   expandedInputs,
  //   scrollMultiplier: 190,
  //   transformDataSource,
  //   dataType: 'instrumental_result',
  //   company: true,
  //   tableType: 'emissions',
  // };
  const transformDataSource = (data, setState) => {
    const newDataSource = [];
    data.forEach(el =>
      newDataSource.push({
        key: el.id,
        ...el,
      })
    );
    setState(newDataSource);
  };
  const columns = rounding => (token, refresh) => {
    // console.log(dataSource.filter(data => data.department_id === '6'));
    const columns = [
      {
        title: 'Действие',
        key: 'action',
        render: (text, record) => (
          <Editor
            addInputs={inputs}
            editInputs={inputs}
            text={text}
            tableName={tableName}
            editTableType={'gos_pek'}
            record={record}
            refresh={refresh}
          />
        ),
      },
      { title: ' №', dataIndex: 'id', key: 'id',width: '4%' },
      {
        title: 'Наименование мероприятия',
        dataIndex: 'name',
        key: 'name',
        width: '11%'
      },
      {
        title: 'Объект / источник эмиссии',
        dataIndex: 'object_emissions',
        key: 'object_emissions',
        width: '12%'
      },
      {
        title: 'Код вещества',
        dataIndex: 'code_substances',
        key: 'code_substances',
        width: '11%'
      },
      {
        title: 'Наименование загрязняющих веществ',
        dataIndex: 'name_substances',
        key: 'name_substances',
        width: '11%'
        
      },
      {
        title: 'Показатель нормативов, согласно разрешения',
        dataIndex: 'indicator_standards',
        key: 'indicator_standards',
        width: '11%'
      },
      {
        title: 'Фактическая величина на конец отчетного периода',
        dataIndex: 'actual_value_period',
        key: 'actual_value_period',
        width: '11%'
      },
      {
        title:
          'Фактические расходы на мероприятие за отчетный период (тыс.тенге)',
        dataIndex: 'actual_costs_event',
        key: 'actual_costs_event',
        width: '11%'
      },
      {
        title: 'Проведенные работы по выполнению мероприятия',
        dataIndex: 'activities_carried_out_activities',
        key: 'activities_carried_out_activities',
        width: '11%'
      },
      {
        title: 'Экологический эффект от мероприятия, в применимых единицах',
        dataIndex: 'environmental_impact',
        key: 'environmental_impact',
        width: '11%'
      },
      {
        title: 'Примечание',
        dataIndex: 'note',
        key: 'note',
      },
    ];

    return columns;
  };

  return (
    <div>
      {/* <Typography.Title level={4}>
        Сведения об источниках загрязнения атмосферы
      </Typography.Title> */}
      {/* <Table {...props} /> */}
      <StandardTable
        inputs={inputs}
        tableName={tableName}
        tableType={'gos_pek'}
        columns={rounding => columns(rounding)}
        transformDataSource={transformDataSource}
        dataSource={[]}
      />
      <Button.Group style={{ marginBottom: 20 }}>
        <PekDeleteAllFromTable tableName={tableName} />
      </Button.Group>
      <div>
        <PekPromoFilesUpload
          columnName="environmental_protection_file"
          title="ПМ, (максимум 50мб)"
        />
      </div>
    </div>
  );
};

const mapStateToProps = ({
  substanceStore: { substance },
  userStore: { token },
}) => ({
  substance,
  token,
});

export default connect(mapStateToProps)(InfoAtmos);

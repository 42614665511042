import React, { useState } from 'react';
import { Input } from 'antd';
import { isNumberAndAboveZero } from '../../../../../../utils/validation';

export default [
  {
    title: 'Код отхода',
    dataIndex: 'waste_code',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    key: 'waste_code',
    component: <Input />,
  },
  {
    title: 'БИН организации, от которого получен отход',
    dataIndex: 'bin_organization',
    key: 'bin_organization',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
      {
        min: 12,
        max: 12,
        message: 'БИН должен состоять из 12 символов',
      },
    ],
    component: <Input />,
  },

  {
    title: 'Объем полученного отхода, тонн',
    dataIndex: 'waste_volume',
    key: 'waste_volume',
    footerTitle: 'Сумма, Объем полученного отхода',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Объем отхода, направленный на проведение операций с ними, тонн',
    dataIndex: 'waste_volume_to_operations',
    key: 'waste_volume_to_operations',
    footerTitle:
      'Сумма,Объем отхода, направленный на проведение операций с ними',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  // {
  //     title: 'Место накопления отхода',
  //     children: [
  //         {
  //             title: 'Широта',
  //             dataIndex: 'width',
  //             key: 'width',
  //             rules: [
  //                 {
  //                     required: true,
  //                     message: 'Данные обязательны для заполнения',
  //                     validator: isNumberAndAboveZero
  //                 },
  //             ],
  //             component: <Input/>,
  //         },
  //         {
  //             title: 'Долгота',
  //             dataIndex: 'longitude',
  //             key: 'longitude',
  //             rules: [
  //                 {
  //                     required: true,
  //                     message: 'Данные обязательны для заполнения',
  //                     validator: isNumberAndAboveZero
  //                 },
  //             ],
  //             component: <Input/>,
  //         },
  //     ],
  // },
  {
    title: 'Вид операции',
    dataIndex: 'operation_type',
    key: 'operation_type',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Переданный объем отхода/сырья после операции с ними, тонн',
    dataIndex: 'flash_waste_volume_after_operation',
    key: 'flash_waste_volume_after_operation',
    footerTitle: 'Сумма, Переданный объем отхода/сырья после операции с ними',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'БИН организации, которому передан отход/сырье',
    dataIndex: 'bin_organization_flash_raw',
    key: 'bin_organization_flash_raw',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
      {
        min: 12,
        max: 12,
        message: 'БИН должен состоять из 12 символов',
      },
    ],
    component: <Input />,
  },
  {
    title:
      'Вид образованного отхода после проведения операции с изначальным видом отхода',
    dataIndex: 'type_waste_after_operation',
    key: 'type_waste_after_operation',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title:
      'Код отхода, образованного после проведения операции с изначальным видом отхода',
    dataIndex: 'code_waste_after_operation',
    key: 'code_waste_after_operation',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title:
      'Объем образованного отхода после проведения операции с изначальным видом отхода, тонн',
    dataIndex: 'waste_volume_after_operations',
    key: 'waste_volume_after_operations',
    footerTitle:
      'Сумма, Объем образованного отхода после проведения операции с изначальным видом отхода',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Вид операции с образованным после проведения операции отхода',
    dataIndex: 'type_operation_after_waste_typed',
    key: 'type_operation_after_waste_typed',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title:
      'Объем отхода, направленный на проведение повторной операций с ними, тонна',
    dataIndex: 'waste_volume_repeated',
    key: 'waste_volume_repeated',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title:
      'БИН организации, которому передан оставшихся объемы отходов, в случае их передачи',
    dataIndex: 'bin_organization_flash_volume',
    key: 'bin_organization_flash_volume',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
      {
        min: 12,
        max: 12,
        message: 'БИН должен состоять из 12 символов',
      },
    ],
    component: <Input />,
  },
];

const initialState = {
    dataTable: {},
};
const tableDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_DATA_PEK_TABLE': {
            console.log("redux" + action.data)
            return {
                ...state,
                dataTable: action.data,
            };
        }
        default: {
            return state;
        }
    }
};
export default tableDataReducer;

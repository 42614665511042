import React from 'react';
import { Input } from 'antd';
import { isNumberAndAboveZero } from '../../../utils/validation';

export default [
  {
    title: 'Наименование промышленной площадки',
    dataIndex: 'name',
    key: 'name',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Наименование источников выделения загрязняющих веществ',
    dataIndex: 'name_of_sources',
    key: 'name_of_sources',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Код загрязняющего вещества',
    dataIndex: 'pollutant_code',
    key: 'pollutant_code',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Наименование веществ',
    dataIndex: 'name_of_substances',
    key: 'name_of_substances',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Нормативы выбросов загрязняющих веществ в атмосферу *',
    key: 'standards_for_emissions',
    children: [
      {
        title: 'грамм/сек',
        key: 'gram',
        dataIndex: 'gram',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
      {
        title: '(тонн/год)',
        key: 'tons',
        dataIndex: 'tons',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
    ],
  },
  {
    title: 'Примечание',
    dataIndex: 'note',
    key: 'note',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
];

import { Icon, Layout } from 'antd';
import React from 'react';
import Navigation from '../../Navigation';

const { Header, Sider } = Layout;
const MainWrapper = ({ children, collapsed, toggle }) => (
  <Layout style={{ height: '100vh' }}>
    <Sider
      width="25vw"
      theme="light"
      trigger={null}
      collapsible
      collapsed={collapsed}
      breakpoint="lg"
    >
      <Navigation />
    </Sider>

    <Layout>
      <Header style={{ background: '#fff', padding: 0 }}>
        <Icon
          className="trigger"
          type={collapsed ? 'menu-unfold' : 'menu-fold'}
          onClick={toggle}
        />
        {/* <div className="logo-wrapper">
          <img className="logo" src={require('../../../assets/img/koktem.png')} alt="Логотип Коктем2" />
        </div> */}
      </Header>
      <main
        style={{
          margin: '24px 16px',
          padding: 24,
          background: '#fff',
        }}
      >
        {children}
      </main>
    </Layout>
  </Layout>
);

export default MainWrapper;

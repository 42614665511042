import { Button, Icon } from 'antd';
import React from 'react';
import AddForm from '../components/AddForm';

const AddButton = ({
  inputs,
  tableName,
  record,
  refresh,
  modalShow,
  visible,
  modalClose,
  dataType,
  tableType,
  columnName
}) => {
  return (
    <>
      <Button onClick={modalShow} type="dashed">
        <Icon type={tableType === 'waste' ? 'copy' : 'plus'} />
      </Button>
      <AddForm
        tableType={tableType}
        record={record}
        tableName={tableName}
        visible={visible}
        inputs={inputs}
        modalClose={modalClose}
        refresh={refresh}
        dataType={dataType}
        columnName={columnName}
      />
    </>
  );
};

export default AddButton;

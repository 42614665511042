import { Button, Collapse, Form, InputNumber, message, Modal } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { setDischargeConstants } from '../../../api/SuperTableAPI';
import withInteractivity from '../../../hoc/withInteractivity';
import InformationOnTheAccumulation from './Tables/InformatioOnTheAccumulation';
import styles from '../../../assets/Main.module.css';
import OperationsCarriedOutAtTheEnterprise from './Tables/OperationsCarriedOutAtTheEnterprise';
import GasMonitoringWaste from './Tables/GasMonitoringWaste';
import InformationDisposalWaste from './Tables/InformationDisposalWaste';
import InformationOperationsProductionWaste from './Tables/InformationOperationsProductionWaste';

const { Panel } = Collapse;

class Discharges extends Component {
  state = {
    ...this.props.state,
    waterOutcomeCount: 0,
    wasteWaterVolume: 0,
    visible: false,
    active: '',
  };

  componentDidMount() {
    const { promoAreaId, year, quarter, token } = this.props;
    console.log('promoAreaId', promoAreaId, {
      token,
      promoAreaId,
      year,
      period: quarter,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { promoAreaId: companyId, year, quarter, token } = this.props;
    if (
      companyId !== prevProps.companyId ||
      year !== prevProps.year ||
      quarter !== prevProps.quarter
    ) {
    }
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = e => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = e => {
    this.setState({
      visible: false,
    });
  };

  onSubmit = (evt, values) => {
    evt.preventDefault();

    this.props.form.validateFields((err, values) => {
      const { waterOutcomeCount, wasteWaterVolume } = values;
      const { year, quarter, promoAreaId, token } = this.props;
      console.log('companyId', promoAreaId);
      setDischargeConstants({
        year,
        period: quarter,
        promoAreaId,
        token,
        waterOutcomeCount,
        wasteWaterVolume,
      }).then(({ data }) => {
        message.success('Данные отправлены успешно!');
        this.handleCancel();
      });
    });
  };

  //
  render() {
    console.log('state: ', this.state);
    const { waterAnalysis, infoToWastes } = this.props.state;
    const { showTableHandler } = this.props;
    const { getFieldDecorator } = this.props.form;
    const active = this.state;
    const tabTablesNames = [
      'Информация по накоплению отходов производства и потребления',
      'Операции, проведенные на предприятии, с отходами производства и потребления',
      'Информация по захоронению отходов производства и потребления',
      'Информация по операциям с отходами производства и потребления при получении их от сторонней организации',
      'Газовый мониторинг полигонов твердо бытовых отходов',
    ];
    console.log(active);

    return (
      <div>
        <Collapse bordered={false}>
          <Panel header={tabTablesNames[0]} key="1">
            <InformationOnTheAccumulation
              tableName={'information_accumulation'}
            />
          </Panel>

          <Panel header={tabTablesNames[1]} key="2">
            <OperationsCarriedOutAtTheEnterprise
              tableName={'operations_сarried_out_enterprise'}
            />
          </Panel>

          <Panel header={tabTablesNames[2]} key="3">
            <InformationDisposalWaste
              tableName={'information_disposal_waste'}
            />
          </Panel>

          <Panel header={tabTablesNames[3]} key="4">
            <InformationOperationsProductionWaste
              tableName={'information_operations_production_waste'}
            />
          </Panel>

          <Panel header={tabTablesNames[4]} key="5">
            <GasMonitoringWaste tableName={'gas_monitoring_waste'} />
          </Panel>
        </Collapse>
      </div>
    );
  }
}

const mapStateToProps = ({
  userStore: { token },
  companyStore: { companyId, promoAreaId },
  pekDateStore: { year, quarter },
}) => ({ token, companyId, year, quarter, promoAreaId });

export default compose(
  withInteractivity({
    waterAnalysis: false,
    factDischarges: false,
  }),
  Form.create(),
  connect(mapStateToProps)
)(Discharges);

import React from 'react';
import { Input, Select } from 'antd';
import { isNumberAndAboveZero } from '../../../../utils/validation';

const { Option } = Select;
const select = (
  <Select>
    <Option value={1}>Выбросы от стационарных источников:</Option>
    <Option value={2}>Сбросы ЗВ со сточными водами:</Option>
    <Option value={3}>Отходы:</Option>
  </Select>
);

export default [
  {
    title: 'Тип',
    dataIndex: 'emission_tax_type_id',
    key: 'emission_tax_type_id',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: select,
  },
  {
    title: 'Виды загрязняющих веществ ',
    dataIndex: 'type',
    key: 'type',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Ставка платы (МРП)',
    key: 'rate',
    children: [
      {
        title: 'за 1 тонну',
        key: 'rate_t',
        dataIndex: 'rate_t',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
      {
        title: 'за 1 кг',
        key: 'rate_kg',
        dataIndex: 'rate_kg',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
    ],
  },
  {
    title: 'Ставка платы, тенге',
    dataIndex: 'rate_tenge',
    key: 'rate_tenge',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Лимит, тонн ',
    dataIndex: 'limit_t',
    key: 'limit_t',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Выбросы, тонн',
    key: 'emissions',
    children: [
      {
        title: 'Факт, тонн',
        dataIndex: 'emissions_fact',
        key: 'emissions_fact',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
      {
        title: 'Норматив',
        dataIndex: 'emissions_standard',
        key: 'emissions_standard',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
      {
        title: 'Сверх норматив',
        dataIndex: 'emissions_excess',
        key: 'emissions_excess',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
    ],
  },
  {
    title: 'Остаток лимита, тонн',
    dataIndex: 'limit_balance',
    key: 'limit_balance',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Сумма к оплате, тенге',
    dataIndex: 'sum',
    children: [
      {
        title: 'Норматив',
        dataIndex: 'pay_standard',
        key: 'pay_standard',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
      {
        title: 'Сверх норматив',
        dataIndex: 'pay_excess',
        key: 'pay_excess',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
      {
        title: 'Всего',
        dataIndex: 'pay_total',
        key: 'pay_total',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
    ],
  },
];

import React from 'react';
import { Tabs } from 'antd';

import Air from './Air';
import WaterManagement from './WaterManagement';
import OS from './OS';

const { TabPane } = Tabs;

const Statistic = () => {
  return (
    <div>
      <Tabs>
        <TabPane tab="2-ТП Воздух" key="1">
          <Air />
        </TabPane>
        <TabPane tab="2-ТП водхоз" key="2">
          <WaterManagement />
        </TabPane>
        <TabPane tab="4-ОС" key="3">
          <OS />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Statistic;

import React from 'react';
import { Input } from 'antd';
import { isNumberAndAboveZero } from '../../../../../../utils/validation';
import isNumeric from 'validator/es/lib/isNumeric';
import roundUp from '../../../../../../utils/roundUp';

export default [
  {
    title: 'Код и наименование ЗВ',
    dataIndex: 'code',
    key: 'code',
    rules: [
      {
        required: true,
        message: 'Данное поле должно содержать 4 цифры',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Объем выбросов по разрешению на эмиссии в ОС, тн.',
    children: [
      {
        title: 'г/с',
        dataIndex: 'setup_standard_g_sec',
        key: 'setup_standard_g_sec',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
      {
        title: 'тонн/год',
        dataIndex: 'setup_standard_t_year',
        key: 'setup_standard_t_year',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,

      }
    ]
  },
  {
    title: 'Фактический объем выбросов ЗВ за квартал/с начала года, тн.',
    key: 'gross_emission',
    children: [
      {
        title: 'тонн/квартал',
        dataIndex: 'fact_result_t_year',
        key: 'fact_result_t_year',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
    ],
  },
  {
    title: 'Эффективность очистки, %',
    dataIndex: 'cleaning_efficiency',
    key: 'cleaning_efficiency',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: async (rule, value, callback) => {
          try {
            if (
              isNumeric(value, { no_symbols: false }) &&
              +value >= 0 &&
              +value <= 100
            ) {
              rule.message = 'Данные обязательны для заполнения';
              return true;
            }

            if (+value < 0 || +value > 100) {
              rule.message = 'Значение должно быть от 0 до 100 включительно!';
            }

            callback(rule.message);
          } catch (err) {
            callback(err);
          }
        },
      },
    ],
    component: <Input />,
  },
  {
    title: 'Причины снижения/увеличения',
    dataIndex: 'reasons',
    key: 'reasons',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
];

import { Button, Col, Icon } from 'antd';
import React, { useState } from 'react';
import AdminList from './AdminList/AdminList';
import AddAdminList from './AdminPopup/AddAdminList';

const Admin = () => {
  const [operation, setOperation] = useState(null);
  const [show, setShowTable] = useState(true);

  const closePopup = () => {
    setOperation(null);
    setShowTable(false);
    setTimeout(()=>setShowTable(true),100)
  };

  return (
    <div>
      <Col>
        <Button.Group>
          <Button
            style={{ marginBottom: 30 }}
            type="primary"
            onClick={() => setOperation('addAdminList')}
          >
            Добавить
            <Icon type="plus" />
          </Button>
        </Button.Group>
      </Col>
      {show && <AdminList />}
      {operation === 'addAdminList' && <AddAdminList onCancel={closePopup} />}
    </div>
  );
};

export default Admin;

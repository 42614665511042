import React from 'react';

import staticData from './data';
import renderUnnecessaryRow from '../../../../../utils/renderUnnecessaryRow';
import columnList from './data/columns';
import StandardTable from '../../../../../modules/StandardTable';
import inputs from './data/modalInputs';
import Editor from '../../../../../modules/Editor';

const tableName = 'statistic_water_consumption';

const columns = rounding => (token, refresh) => [
  {
    title: 'Действие',
    key: 'action',
    render: (text, record) => {
      console.log(record);
      const condition = record.code_string === 'А';
      const obj = {
        children: condition ? null : (
          <Editor
            addInputs={inputs}
            editInputs={inputs}
            text={text}
            tableName={tableName}
            record={record}
            refresh={refresh}
          />
        ),
        props: {},
      };
      // if () obj.children = 21;
      return obj;
    },
  },
  ...columnList,
];

const transformDataSource = (data, setState) => {
  const newDataSource = [renderUnnecessaryRow(columnList, staticData)];
  if (data.data) {
    data.data.forEach(el =>
      newDataSource.push({
        key: el.id,
        ...el,
      })
    );
    setState(newDataSource);
  } else {
    setState([]);
  }
};

const WaterConsumption = () => (
  <StandardTable
    tableName={tableName}
    inputs={inputs}
    tableType={'statistics'}
    columns={columns}
    transformDataSource={transformDataSource}
    scrollMultiplier={250}
  />
);

export default WaterConsumption;

import moment from 'moment';

const initialState = {
  // year: moment().format('YYYY'),
  year: 2021,
  quarter: moment()
    .subtract(3, 'months')
    .quarter(),
  order: null,
};

const pekDateReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case 'PEK_YEAR_CHANGED':
      return {
        ...state,
        year: payload,
      };
    case 'PEK_QUARTER_CHANGED':
      return {
        ...state,
        quarter: payload,
      };
    case 'PEK_ORDER_CHANGED':
      return {
        ...state,
        order: payload,
      };
    default:
      return state;
  }
};

export default pekDateReducer;

import React from 'react';
import { Typography } from 'antd';
import { connect } from 'react-redux';

import columnList from './data/columns';
import StandardTable from '../../../modules/StandardTable';
import inputs from './data/modalInputs';
import Editor from '../../../modules/Editor';

const { Title } = Typography;

const tableName = 'list_of_water_substances';

const transformDataSource = (data, setState) => {
  const newDataSource = [];
  data.forEach(el =>
    newDataSource.push({
      key: el.id,
      ...el,
    })
  );
  setState(newDataSource);
};

const WaterSubstanceList = ({ substance }) => {
  const columns = rounding => (token, refresh) => [
    {
      title: 'Действие',
      key: 'action',
      fixed: 'left',
      width: 130,
      render: (text, record) => (
        <Editor
          addInputs={inputs}
          editInputs={inputs}
          text={text}
          tableName={tableName}
          record={record}
          refresh={refresh}
        />
      ),
    },
    ...columnList(rounding),
  ];

  return (
    <div>
      <Title level={2}>Перечень водных веществ</Title>
      <StandardTable
        tableName={tableName}
        inputs={inputs}
        columns={rounding => columns(rounding)}
        transformDataSource={transformDataSource}
        scrollMultiplier={150}
        company={false}
      />
    </div>
  );
};

const mapStateToProps = ({ substanceStore: { substance } }) => ({
  substance,
});

export default connect(mapStateToProps)(WaterSubstanceList);

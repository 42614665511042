import React, { useState } from 'react';

import EditButtonContainer from '../containers/EditButton';

const EditButton = ({ inputs, tableName, record, refresh, dataType, editTableType }) => {
  const [visible, setVisible] = useState(false);

  const modalShow = () => setVisible(true);

  const modalClose = () => setVisible(false);

  const props = {
    inputs,
    tableName,
    record,
    refresh,
    modalShow,
    visible,
    modalClose,
    dataType,
    editTableType
  };

  return <EditButtonContainer {...props} />;
};

export default EditButton;

import React from 'react';
import { Input } from 'antd';
import { isNumberAndAboveZero } from '../../../utils/validation';

export default [
  {
    title: 'Источник сброса сточных вод',
    dataIndex: 'wastewater_discharge_source',
    key: 'wastewater_discharge_source',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Наименования (номер) выпусков',
    dataIndex: 'names_of_issues',
    key: 'names_of_issues',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Наименования веществ  (показателя)',
    dataIndex: 'names_of_substances',
    key: 'names_of_substances',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Расход сточных вод тыс. м3/год',
    dataIndex: 'wastewater_consumption',
    key: 'wastewater_consumption',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Допустимая концентрация на выпуске, мг/дм3',
    dataIndex: 'permissible_concentration',
    key: 'permissible_concentration',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Нормативы сбросов ЗВ тонн/год',
    dataIndex: 'standards_for_discharges',
    key: 'standards_for_discharges',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Примечание',
    dataIndex: 'note',
    key: 'note',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
];

import { BaseAPI } from './BaseAPI';

import axios from 'axios';

class ChartsAPI extends BaseAPI {
  getPieCarbonMonoxide = async (token, { promoAreaId }) => {
    const url = `${this.BASE_URL}${this.CHART_PATH}carbon_monoxide_tone_year?companyId=${promoAreaId}`;
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (error) {
      console.error('error: ', error);
      return error;
    }
  };

  getCarbonMonoxide = async (token, { promoAreaId }) => {
    const url = `${this.BASE_URL}${this.CHART_PATH}carbon_monoxide?companyId=${promoAreaId}`;
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (error) {
      console.error('error: ', error);
      return error;
    }
  };

  getNitrogenOxides = async (token, { promoAreaId }) => {
    console.log(promoAreaId);
    const url = `${this.BASE_URL}${this.CHART_PATH}nitrogen_oxides?companyId=${promoAreaId}`;
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (error) {
      console.error('error: ', error);
      return error;
    }
  };
}

const {
  getPieCarbonMonoxide,
  getCarbonMonoxide,
  getNitrogenOxides,
} = new ChartsAPI();

export { getPieCarbonMonoxide, getCarbonMonoxide, getNitrogenOxides };

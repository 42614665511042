export const selectCurrentPromo = promo => ({
  type: 'SET_CURRENT_PROMO',
  payload: promo,
});

export const selectGrvpzData = promo => ({
    type: 'SET_CODE_DATA',
    payload: promo,
  });
  

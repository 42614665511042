import { BaseAPI } from './BaseAPI';
import axios from 'axios';

export class RegistrationApi extends BaseAPI {
  register = async payload => {
    const {replacePassword, ...dataUser}=payload

    const url = `${this.BASE_URL}${this.USER_PATH}register`;
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    return axios.post(url, dataUser, config);
  };

  getDepartmentData = async () => {
    const url = `${this.BASE_URL}${this.DEPARTMENT_PATH}`;
    console.log('start');
    return axios.get(url);
  };
}

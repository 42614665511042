import React, { useState } from 'react';
import columnsList from './columns';
import Editor from '../../../modules/Editor';
import inputs from './modalInputs';
import StandardTable from '../../../modules/StandardTable';
import { connect } from 'react-redux';

const tableName = 'saved_waste';

const SavedWaste = ({ token }) => {
  const [data, setData] = useState([]);

  const columns = rounding => (token, refresh) => [
    ...columnsList,
    {
      title: 'Действия',
      key: 'actions',
      width: 120,
      render: (text, record) => (
        <Editor
          addInputs={inputs}
          editInputs={inputs}
          text={text}
          tableName={tableName}
          editTableType={'order_production_tables'}
          record={record}
          refresh={refresh}
        />
      ),
    },
  ];

  const transformDataSource = (data, setState) => {
    const newDataSource = [];
    data.forEach(el =>
      newDataSource.push({
        key: el.id,
        ...el,
      })
    );
    setData(newDataSource);
  };

  return (
    <div>
      <StandardTable
        inputs={inputs}
        tableName={tableName}
        tableType={'order_production_tables'}
        columns={rounding => columns(rounding)}
        transformDataSource={transformDataSource}
        dataSource={data}
      />
    </div>
  );
};

const mapStateToProps = ({ userStore: { token } }) => ({
  token,
});

export default connect(mapStateToProps)(SavedWaste);

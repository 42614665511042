import { BaseAPI } from './BaseAPI';
import axios from 'axios';

class PekAPI extends BaseAPI {
  getPekWaterList = ({ token, id, year }) =>
    axios({
      method: 'GET',
      url: `${this.BASE_URL}${this.PEK_WATER_PATH}/order-list/${id}?year=${year}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

  savePekWaterList = ({ token, values }) =>
    axios({
      method: 'POST',
      url: `${this.BASE_URL}${this.PEK_WATER_PATH}/order-list`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: values,
    });

  getWaterHistory = ({ token, userId, year }) =>
    axios({
      method: 'GET',
      url: `${this.BASE_URL}${this.PEK_WATER_PATH}/actions/history/${userId}?year=${year}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

  saveWaterHistory = ({ token, data }) =>
    axios({
      method: 'POST',
      url: `${this.BASE_URL}${this.PEK_WATER_PATH}/actions/history/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });

  sendWaterReport = async ({ data, token }) =>
    axios({
      method: 'POST',
      url: `${this.BASE_URL}${this.PEK_WATER_PATH}/send/report`,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
      data,
    });

  uploadPekWaterProgramFile = (data, token) =>
    axios({
      method: 'POST',
      url: `${this.BASE_URL}${this.PEK_WATER_PATH}/save/file`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
      data,
    });
}

const {
  getPekWaterList,
  savePekWaterList,
  getWaterHistory,
  saveWaterHistory,
  sendWaterReport,
  uploadPekWaterProgramFile,
} = new PekAPI();

export {
  getPekWaterList,
  savePekWaterList,
  getWaterHistory,
  saveWaterHistory,
  sendWaterReport,
  uploadPekWaterProgramFile,
};

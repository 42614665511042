import React, { Component } from 'react';

const withInteractivity = tableState => Wrapped => {
  return class extends Component {
    state = {
      ...tableState,
    };

    showTableHandler = name => {
      const tables = Object.keys(this.state).filter(table => table !== name);
      const newState = {};
      tables.forEach(table => {
        newState[table] = false;
      });

      this.setState(state => ({
        ...newState,
        [name]: !state[name],
      }));
    };

    render() {
      const { state } = this;
      return <Wrapped state={state} showTableHandler={this.showTableHandler} />;
    }
  };
};

export default withInteractivity;

import React from 'react';
import { Tabs } from 'antd';

import SubstanceList from './SubstanceList';
import WaterSubstanceList from './WaterSubctanceList';
import OrderProduction from '../ApplicationForm/OrderProduction';

const { TabPane } = Tabs;

const Substance = () => (
  <Tabs defaultActiveKey="1">
    <TabPane tab="Перечень веществ" key="1">
      <SubstanceList />
    </TabPane>
    <TabPane tab="Перечень водных веществ" key="2">
      <WaterSubstanceList />
    </TabPane>
    <Tabs.TabPane tab="Перечень отрасли" key="8">
      <OrderProduction />
    </Tabs.TabPane>
  </Tabs>
);

export default Substance;

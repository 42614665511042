import React from 'react';
import { Form, Icon, Input, Button, message } from 'antd';
import { connect } from 'react-redux';
import { Typography, Space } from 'antd';
import { Radio } from 'antd';
import { userSetAction } from '../../redux/actions/userActions';
import { Select } from 'antd';
const { Option } = Select;

class RegistrationStep extends React.Component {
  render() {
    const { Text } = Typography;
    const {
      data,
      handleChange,
      isLast,
      handleChangeStep,
      handleSubmit,
    } = this.props;
    const { getFieldDecorator, validateFields } = this.props.form;
      console.log(data)
    return (
      <div className="login-container">
        <Text>{data.text}</Text>
        <Form
          onSubmit={e => {
            e.preventDefault();
            validateFields((err, values) => {
              if (!err) {
                if (isLast) {
                  handleSubmit();
                } else {
                  handleChangeStep();
                }
              }
            });
          }}
        >
          {data.fields.map((el, index) => (
            <>
              {el.type === 'input' && (
                <Form.Item key={index} style={{ marginBottom: '10px' }}>
                  {getFieldDecorator(el.code, {
                    rules: [
                      { required: el.required, message: el.message },
                      el.additional || {},
                    ],
                  })(
                    <Input
                      prefix={
                        <Icon
                          type="user"
                          style={{ color: 'rgba(0,0,0,.25)' }}
                        />
                      }
                      name={el.name}
                      value={el.value}
                      onChange={event => handleChange(event, data.type, index)}
                      type={el.input_type ? el.input_type : null}
                      placeholder={el.placeholder}
                    />
                  )}
                </Form.Item>
              )}
              {el.type === 'radio' && (
                <Form.Item style={{ marginBottom: '10px' }}>
                  <Text
                    style={{ fontSize: '14px !important' }}
                    type="secondary"
                  >
                    {el.message}
                  </Text>
                  <Radio.Group
                    name={el.name}
                    onChange={event => handleChange(event, data.type, index)}
                    value={el.value}
                  >
                    {el.answers.map((e, index) => (
                      <Radio key={index} value={e.value}>
                        {e.text}
                      </Radio>
                    ))}
                  </Radio.Group>
                </Form.Item>
              )}
              {el.type === 'select' && (
                <Form.Item style={{ marginBottom: '10px' }}>
                  {el.code === 'department_id' ? (
                    <Select
                      showSearch
                      name={el.name}
                      placeholder={el.placeholder}
                      optionFilterProp="children"
                      value={el.value}
                      onChange={event =>
                        handleChange(
                          { target: { value: event } },
                          data.type,
                          index
                        )
                      }
                    >
                      {el.answers.map((el, index) => (
                        <Option key={index} value={el.id}>
                          {el.name}
                        </Option>
                      ))}
                    </Select>
                  ) : (
                    <Select
                      showSearch
                      name={el.name}
                      placeholder={el.placeholder}
                      optionFilterProp="children"
                      value={el.value}
                      onChange={event =>
                        handleChange(
                          { target: { value: event } },
                          data.type,
                          index
                        )
                      }
                    >
                      {el.answers.map((el, index) => (
                        <Option key={index} value={el}>
                          {el}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              )}
            </>
          ))}
          <Button
            // onClick={handleChangeStep}
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            {isLast ? 'Зарегистрироваться' : 'Продолжить'}
          </Button>
        </Form>
      </div>
    );
  }
}

const RegistrationStepForm = Form.create({ name: 'normal_login' })(
  RegistrationStep
);

export default connect(({ userStore }) => ({ userStore }), {
  userSetAction,
})(RegistrationStepForm);

import React from 'react';
import { Input } from 'antd';
import { isNumberAndAboveZero } from '../../../../../../utils/validation';

export default [
  {
    title: 'Код строки',
    dataIndex: 'code_string',
    key: 'code_string',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Наименование водного объекта',
    dataIndex: 'name',
    key: 'name',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Код источника',
    dataIndex: 'code_source',
    key: 'code_source',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Код передающего предприятия',
    dataIndex: 'code_company',
    key: 'code_company',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Код моря-реки',
    dataIndex: 'code_sea',
    key: 'code_sea',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Притоки',
    key: 'balance',
    children: [
      {
        title: '1',
        dataIndex: 'inflow_1',
        key: 'inflow_1',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
          },
        ],
        component: <Input />,
      },
      {
        title: '2',
        dataIndex: 'inflow_2',
        key: 'inflow_2',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
          },
        ],
        component: <Input />,
      },
      {
        title: '3',
        dataIndex: 'inflow_3',
        key: 'inflow_3',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
          },
        ],
        component: <Input />,
      },
      {
        title: '4',
        dataIndex: 'inflow_4',
        key: 'inflow_4',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
          },
        ],
        component: <Input />,
      },
      {
        title: '5',
        dataIndex: 'inflow_5',
        key: 'inflow_5',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
          },
        ],
        component: <Input />,
      },
    ],
  },
  {
    title: 'Код качества',
    dataIndex: 'code_quality',
    key: 'code_quality',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Расстояние от устья',
    dataIndex: 'distance',
    key: 'distance',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Забрано, получено за год',
    dataIndex: 'recived_year',
    key: 'recived_year',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'В том числе по месяцам',
    children: [
      {
        title: 'Январь',
        dataIndex: 'recived_jan',
        key: 'recived_jan',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
      {
        title: 'Февраль',
        dataIndex: 'recived_feb',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        key: 'recived_feb',
        component: <Input />,
      },
      {
        title: 'Март',
        dataIndex: 'recived_mar',
        key: 'recived_mar',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
      {
        title: 'Апрель',
        dataIndex: 'recived_apr',
        key: 'recived_apr',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
      {
        title: 'Май',
        dataIndex: 'recived_may',
        key: 'recived_may',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
      {
        title: 'Июнь',
        dataIndex: 'recived_june',
        key: 'recived_june',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
      {
        title: 'Июль',
        dataIndex: 'recived_july',
        key: 'recived_july',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
      {
        title: 'Август',
        dataIndex: 'recived_aug',
        key: 'recived_aug',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
      {
        title: 'Сентябрь',
        dataIndex: 'recived_sep',
        key: 'recived_sep',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
      {
        title: 'Октябрь',
        dataIndex: 'recived_oct',
        key: 'recived_oct',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
      {
        title: 'Ноябрь',
        dataIndex: 'recived_nov',
        key: 'recived_nov',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
      {
        title: 'Декабрь',
        dataIndex: 'recived_dec',
        key: 'recived_dec',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
    ],
  },
  {
    title: 'Использовано, передано',
    children: [
      {
        title: 'код вещества',
        dataIndex: 'used_transferred_code',
        key: 'used_transferred_code',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
      {
        title: 'количество',
        dataIndex: 'used_transferred_count',
        key: 'used_transferred_count',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
    ],
  },
  {
    title: 'Оборотное использование',
    dataIndex: 'reverse_use',
    key: 'reverse_use',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Повторное использование',
    dataIndex: 'repeat_use',
    key: 'repeat_use',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Передано после использования',
    dataIndex: 'transferred_use',
    key: 'transferred_use',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Потери при транспортировке',
    dataIndex: 'losses',
    key: 'losses',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Площадь орошения (гектар)',
    dataIndex: 'irrigination_area',
    key: 'irrigination_area',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
];

import React from 'react';
import { Form, Input, Tabs, Button } from 'antd';

import EnvironmentalMeasuresTable from './Tables/EnvironmentalMeasures';
import formItemLayout from '../../utils/formItemLayout';

const { TabPane } = Tabs;

const EnvironmentalMeasures = () => {
  return (
    <div>
      <Form {...formItemLayout}>
        <Form.Item label="Разрешение на эмиссии">
          <Input />
        </Form.Item>
      </Form>
      <Tabs>
        <TabPane key="1" tab="План мероприятий по охране окружающей среды">
          <EnvironmentalMeasuresTable />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default EnvironmentalMeasures;

import React from 'react';
import { DatePicker, Input, Select } from 'antd';
import moment from 'moment';
import { isNumberAndAboveZero } from '../../../../utils/validation';

export default [
  {
    title: 'Наименование производственного объекта',
    dataIndex: 'name_area',
    key: 'name_area',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Бизнес Идентификационный номер оператора объекта (БИН)',
    dataIndex: 'bin',
    key: 'bin',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
      {
        min: 12,
        max: 12,
        message: 'БИН должен состоять из 12 символов',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Широта',
    dataIndex: 'latitude',
    key: 'latitude',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Долгота',
    dataIndex: 'longitude',
    key: 'longitude',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
      {
        type: 'email',
        message: 'Неправильный формат email',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Номер телефона',
    dataIndex: 'phone_number',
    key: 'phone_number',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
];

import React from 'react';
import LocationIndustrialsSitesTable from './Tables/locationIndustrialsSites';

const LocationIndustrialsSites = () => {
  return (
    <div>
      <LocationIndustrialsSitesTable />
    </div>
  );
};

export default LocationIndustrialsSites;

import { Select, Typography } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { greenhouseCategory } from '../../../constants';
import Editor from '../../../modules/Editor';
import Table from '../../../modules/SuperTable';
import columnList from './data/columns';
import editableExpandedInputs from './data/editableExpandedInputs';
import expandedColsList from './data/expandedCols';
import expandedInputs from './data/expandedInputs';
import inputs from './data/inputs';

const { Option } = Select;

const SOURCE_NAME = greenhouseCategory.map(item => ({
  value: item.title,
  label: item.title,
}));

const Coefficient = ({
  substance,
  fetchSumSources,
  token,
  company,
  promoAreaId,
}) => {
  console.log('CMP', company);
  const renderSelectOption = () => {
    return substance.map(({ code, name }) => (
      <Option
        key={code}
        value={`${code} - ${name}`}
      >{`${code} - ${name}`}</Option>
    ));
  };

  const tableName = 'greenhouse_gas_source';

  const transformDataSource = data =>
    data.map(el => ({
      key: el.id,
      ...el,
      babies: el.greenhouse_gas_data.map((child, i) => ({
        ...child,
        key: child.id,
      })),
    }));

  const sourceNameIndex = inputs.findIndex(
    ({ dataIndex }) => dataIndex === 'source_name'
  );
  const newInputs = inputs.map((item, index) => {
    return index === sourceNameIndex
      ? {
          ...item,
          component: (
            <Select>
              {SOURCE_NAME.map(option => (
                <Option key={option.value}>{option.label}</Option>
              ))}
            </Select>
          ),
        }
      : item;
  });

  const insertEditor = ({
    target,
    fixed = false,
    width = 300,
    rounding,
    companyCardName,
  }) => (token, refresh) => [
    ...target(rounding, companyCardName),
    {
      title: 'Действие',
      key: 'action',
      width: width,
      fixed: fixed ? 'right' : false,
      render: (text, record) => {
        const { is_source } = record;
        const columnName = greenhouseCategory.find(
          item => item.title === record?.source_name
        )?.name;
        const props = {
          text,
          refresh,
          record,
          editInputs: is_source ? newInputs : editableExpandedInputs,
          addInputs: expandedInputs[columnName] || [],
          tableName: is_source
            ? 'greenhouse_gas_source'
            : 'greenhouse_gas_data',
          tableType: 'greenhouse',
          editTableType: 'super',
          columnName
        };
        return <Editor {...props} />;
      },
    },
  ];

  const props = {
    tableName,
    tableType: 'greenhouse',
    modalTables: ['greenhouse_gas_source', 'greenhouse_gas_data'],
    columns: rounding =>
      insertEditor({
        target: columnList,
        width: 270,
        rounding,
        companyCardName: company?.company_cards?.find(
          ({ id }) => id === promoAreaId
        )?.name,
      }),
    expandedCols: rounding =>
      insertEditor({
        target: expandedColsList,
        fixed: true,
        width: 120,
        rounding,
      }),
    inputs: newInputs,
    expandedInputs,
    scrollMultiplier: 170,
    transformDataSource,
    fetchSumSources,
    company: true,
  };

  return (
    <div>
      <Typography.Title level={4}>
        Фактический объем выбросов парниковых газов за отчетный год по
        стационарным источникам или мобильным источникам, приравненным к
        стационарным
      </Typography.Title>
      <Table {...props} />
    </div>
  );
};

const mapStateToProps = ({
  substanceStore: { substance },
  companyStore: { promoAreaId, company },
  pekDateStore: { year, quarter },
  userStore: { token },
}) => ({
  substance,
  promoAreaId,
  company,
  year,
  quarter,
  token,
});

export default connect(mapStateToProps)(Coefficient);

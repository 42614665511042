export const columnSorter = column => (first, second) => {
  const columnName = column.dataIndex;

  const firstColumn = first[columnName];
  const secondColumn = second[columnName];

  if (typeof firstColumn === 'string')
    return firstColumn.localeCompare(secondColumn);

  return firstColumn - secondColumn;
};

const sorterOfColumn = column => {
  let sorterChange;

  sorterChange = column?.children
    ? { children: addSorterToColumns(column?.children) }
    : { sorter: columnSorter(column) };

  return { ...column, ...sorterChange };
};

export const addSorterToColumns = (columns = []) => {
  return columns.map(sorterOfColumn);
};

import React from 'react';
import { Form, Input } from 'antd';

const InitForm = ({ getFieldDecorator, fields }) => {
  return (
    <>
      {fields.map((item) => {
        return (
          <Form.Item
            key={item.name}
            label={item.label}
          >
            {getFieldDecorator(item.name, {})(<Input />)}
          </Form.Item>
        );
      })}
    </>
  );
};

export default InitForm;

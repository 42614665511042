import axios from 'axios';
import { BaseAPI } from './BaseAPI';


class AtmosphereAPI extends BaseAPI {
  getResources = async ({ token, promoAreaId, period, year }) =>
    await axios({
      method: 'GET',
      url: `${this.BASE_URL}${this.ATMOSPHERE_PATH}?companyId=${promoAreaId}&period=${period}&year=${year}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(({ data }) => data)
      .catch(error => {
        console.error(error);
      });

  setSource = async ({ token, values, period, year, promoAreaId }) =>
    await axios({
      method: 'POST',
      url: `${this.BASE_URL}${this.ATMOSPHERE_PATH}source`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        ...values,
        period,
        year,
        company_card_id: promoAreaId,
      },
    })
      .then(data => data)
      .catch(error => console.error(error));

  setZV = async ({ token, period, year, itemId, values }) =>
    await axios({
      method: 'POST',
      url: `${this.BASE_URL}${this.ATMOSPHERE_PATH}data`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: {
        ...values,
        period,
        year,
        source_id: itemId,
      },
    })
      .catch(error => error.response);
}

const { getResources, setSource, setZV } = new AtmosphereAPI();

export { getResources, setSource, setZV };

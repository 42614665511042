import { getResources } from '../../api/StandardTableAPI';

const getSubstance = payload => {
  return {
    type: 'SUBSTANCE_LIST',
    payload,
  };
};

const getWaterSubstance = payload => {
  return {
    type: 'WATER_SUBSTANCE_LIST',
    payload,
  };
};

const fetchSubstances = token => dispatch => {
  //SUBSTANCE_LIST
  getResources(token, 'list_of_substances')
    .then(data => dispatch(getSubstance(data)))
    .catch(e => console.error(e));
  //WATER_SUBSTANCE_LIST
  getResources(token, 'list_of_water_substances')
    .then(data => dispatch(getWaterSubstance(data)))
    .catch(e => console.error(e));
};

export default fetchSubstances;

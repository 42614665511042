import axios from 'axios';
import { BaseAPI } from '../../../api/BaseAPI';


export class OrderProductionService{

    async getResource(url){
        return await axios.get(`${new BaseAPI().BASE_URL}${url}`);
    }

    async postResource(url,body){
        return await axios.post(`${new BaseAPI().BASE_URL}${url}`,body)
    }

    async getProductionTypes(){
        return this.getResource('order_production_types')
    }

    async postProductionTypes(body){
        return this.postResource('order_production_types',body)
    }


    async getProductionTypesDetail(){
        return this.getResource('order_production_types_detail');
    }
    async postProductionTypesDetail(body){
        return this.postResource('order_production_types_detail',body);
    }
}
import React from 'react';
import { Input, Select } from 'antd';
import { isNumberAndAboveZero } from '../../../../utils/validation';

const { Option } = Select;

const select = (
  <Select>
    <Option value={1}>1. Охрана воздушного бассейна</Option>
    <Option value={2}>
      2. Охрана и рациональное использование водных ресурсов
    </Option>
    <Option value={3}>
      3. Охрана от воздействия на прибрежные и водные экосистемы
    </Option>
    <Option value={4}>4. Охрана земельных ресурсов</Option>
    <Option value={5}>5. Охрана и рациональное использование недр</Option>
    <Option value={6}>6. Охрана флоры и фауны</Option>
    <Option value={7}>
      7. Обращение с отходами производства и потребления
    </Option>
    <Option value={8}>
      8. Радиационная, биологическая и химическая безопасность
    </Option>
    <Option value={9}>
      9. Внедрение систем управления и наилучших безопасных технологий
    </Option>
    <Option value={10}>
      10. Научно-исследовательские, изыскательские и другие разработки
    </Option>
    <Option value={11}>11. Экологическое просвещение и пропаганда</Option>
  </Select>
);

export default [
  {
    title: 'Тип',
    dataIndex: 'type',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: select,
  },
  {
    title: 'Наименование мероприятия',
    dataIndex: 'name',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Объем планируемых работ',
    dataIndex: 'volume_work',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    key: 'volume_work',
    component: <Input />,
  },
  {
    title: 'Общая стоимость (тыс.тенге)',
    dataIndex: 'total_price',
    key: 'total_price',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
        validator: isNumberAndAboveZero,
      },
    ],
    component: <Input />,
  },
  {
    title: 'Источник финансирования',
    dataIndex: 'finance_source',
    key: 'finance_source',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
  {
    title: 'Срок выполнения',
    children: [
      {
        title: 'начало',
        dataIndex: 'start_date',
        key: 'start_date',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
          },
        ],
        component: <Input />,
      },
      {
        title: 'конец',
        dataIndex: 'finish_date',
        key: 'finish_date',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
          },
        ],
        component: <Input />,
      },
    ],
  },
  {
    title: 'План финансирования (тыс.тенге)',
    children: [
      {
        title: '20_ год',
        dataIndex: 'year_1',
        key: 'year_1',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
      {
        title: '20_ год',
        dataIndex: 'year_2',
        key: 'year_2',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
      {
        title: '20_ год',
        dataIndex: 'year_3',
        key: 'year_3',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
      {
        title: '20_ год',
        dataIndex: 'year_4',
        key: 'year_4',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
      {
        title: '20_ год',
        dataIndex: 'year_5',
        key: 'year_5',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
      {
        title: '20_ год',
        dataIndex: 'year_6',
        key: 'year_6',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
      {
        title: '20_ год',
        dataIndex: 'year_7',
        key: 'year_7',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
      {
        title: '20_ год',
        dataIndex: 'year_8',
        key: 'year_8',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
      {
        title: '20_ год',
        dataIndex: 'year_9',
        key: 'year_9',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
      {
        title: '20_ год',
        dataIndex: 'year_10',
        key: 'year_10',
        rules: [
          {
            required: true,
            message: 'Данные обязательны для заполнения',
            validator: isNumberAndAboveZero,
          },
        ],
        component: <Input />,
      },
    ],
  },
  {
    title: 'Ожидаемый экологический эффект от мероприятия',
    dataIndex: 'expectations',
    key: 'expectations',
    rules: [
      {
        required: true,
        message: 'Данные обязательны для заполнения',
      },
    ],
    component: <Input />,
  },
];

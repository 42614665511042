import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Select, Typography, Table } from 'antd';

import { renderColumns } from './data/columns';
import inputs from './data/inputs';
import expandedInputs from './data/expandedInputs';
import SuperSelect from '../../../../../components/SuperSelect';
import Editor from '../../../../../modules/Editor';
import editableExpandedInputs from './data/editableExpandedInputs';
import expandedColsList from './data/expandedCols';
import {
  getKatoSelectOptions,
  getSourceTypeSelectOptions,
} from '../../../../../api/PekAPI';
import StandardTable from '../../../../../modules/StandardTable';
import PekUploadShablon from '../../../PekUploadShablon';
import PekDeleteAllFromTable from '../../../PekDeleteAllFromTable';
import PekPromoFilesUpload from '../../../PekPromoFilesUpload';

const { Option } = Select;
const PAGE_SIZE = 15;

const InfoMonitoring = ({ substance, token, tableName }) => {
  const [katoSelectOptions, setKatoSelectOptions] = useState();
  const [sourceTypeSelectOptions, setSourceTypeSelectOptions] = useState();
  const [currentPage, setCurrentPage] = useState('1');
  const [totalPage, setTotalPage] = useState(0);

  // const renderSelectOption = () => {
  //     return substance.map(({code, name}) => (
  //         <Option
  //             key={code}
  //             value={`${code} - ${name}`}
  //         >{`${code} - ${name}`}</Option>
  //     ));
  // };
  //
  // useEffect(() => {
  //     getKatoSelectOptions({token}).then(res => {
  //         setKatoSelectOptions(
  //             res.data &&
  //             res.data.map(option => ({
  //                 value: option.id,
  //                 label: option.name,
  //             }))
  //         );
  //     });
  //
  //     getSourceTypeSelectOptions({token}).then(res => {
  //         setSourceTypeSelectOptions(
  //             res.data &&
  //             res.data.map(option => ({
  //                 value: option.id,
  //                 label: option.name,
  //             }))
  //         );
  //     });
  //
  //     const inputIndex = expandedInputs.findIndex(
  //         ({title}) => title === 'Код и наименование ЗВ'
  //     );
  //
  //     const select = (
  //         <SuperSelect
  //             showSearch
  //             filterOption={(input, option) =>
  //                 option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
  //             }
  //         >
  //             {renderSelectOption()}
  //         </SuperSelect>
  //     );
  //     expandedInputs[inputIndex].component = select;
  //     editableExpandedInputs[inputIndex].component = select;
  // }, []);

  const columns = rounding => (token, refresh) => {
    const columns = [
      {
        title: 'Действие',
        key: 'action',
        render: (text, record) => (
          <Editor
            addInputs={inputs}
            editInputs={inputs}
            text={text}
            tableName={tableName}
            editTableType={'gos_pek'}
            record={record}
            refresh={refresh}
          />
        ),
      },
      {
        title: 'Точки отбора проб ',
        dataIndex: 'sampling_points',
        key: 'sampling_points',
      },
      {
        title: 'Местоположение, координаты (долгота и широта)',
        children: [
          {
            title: 'Широта',
            dataIndex: 'latitude',
            key: 'latitude',
          },
          {
            title: 'Долгота',
            dataIndex: 'longitude',
            key: 'longitude',
          },
        ],
      },
      {
        title: 'Код вещества',
        dataIndex: 'substance_code',
        key: 'substance_code',
      },
      {
        title: 'Наименование загрязняющих веществ',
        dataIndex: 'name_pollutants',
        key: 'name_pollutants',
      },
      {
        title: 'Предельно допустимая концентрация (максимально разовая, мг/м3)',
        dataIndex: 'maximum_allowable_concentration',
        key: 'maximum_allowable_concentration',
      },
      {
        title: 'Фактическая концентрация, мг/м3',
        dataIndex: 'actual_concentration',
        key: 'actual_concentration',
      },
      {
        title:
          'Наличие превышения предельно допустимых концентраций, кратность',
        dataIndex: 'existence_exceeding_concentration',
        key: 'existence_exceeding_concentration',
      },
      {
        title:
          'Мероприятия по устранению нарушений и улучшению экологической обстановки (с указанием сроков)',
        dataIndex: 'measures_improve_environmental_situation',
        key: 'measures_improve_environmental_situation',
      },
    ];
    return columns;
  };

  const transformDataSource = (data, setState) => {
    const newDataSource = [];
    data.forEach(el =>
      newDataSource.push({
        key: el.id,
        ...el,
      })
    );
    setState(newDataSource);
  };

  // const insertEditor = ({target, fixed = false, rounding, width = 300}) => (
  //     token,
  //     refresh
  // ) => [
  //     ...target(rounding),
  //     {
  //         title: 'Период действия программы производственного мониторинга',
  //         key: 'action',
  //         width: width,
  //         fixed: fixed ? 'right' : false,
  //         render: (text, record) => {
  //             const {is_source} = record;
  //             const props = {
  //                 text,
  //                 refresh,
  //                 record,
  //                 editInputs: is_source ? inputs : editableExpandedInputs,
  //                 addInputs: expandedInputs,
  //                 tableName: is_source ? 'calculation_source' : 'calculation_data',
  //                 dataType: 'instrumental_result',
  //                 tableType: 'emissions',
  //                 editTableType: 'super',
  //             };
  //             return <Editor {...props} />;
  //         },
  //     },
  // ];
  //
  // const formItemLayout = {
  //     labelCol: {
  //         xs: {span: 24},
  //         sm: {span: 12},
  //     },
  //     wrapperCol: {
  //         xs: {span: 18},
  //         sm: {span: 12},
  //     },
  // };
  //
  // const katoSelectIndex = inputs.findIndex(
  //     ({dataIndex}) => dataIndex === 'kato_id'
  // );
  // const sourceTypeSelectIndex = inputs.findIndex(
  //     ({dataIndex}) => dataIndex === 'source_type_id'
  // );
  // const newInputs = inputs.map((item, index) => {
  //     return index === katoSelectIndex
  //         ? {
  //             ...item,
  //             component: (
  //                 <Select>
  //                     {katoSelectOptions &&
  //                     katoSelectOptions.map(option => (
  //                         <Option key={option.value}>{option.label}</Option>
  //                     ))}
  //                 </Select>
  //             ),
  //         }
  //         : index === sourceTypeSelectIndex
  //             ? {
  //                 ...item,
  //                 component: (
  //                     <Select>
  //                         {sourceTypeSelectOptions &&
  //                         sourceTypeSelectOptions.map(option => (
  //                             <Option key={option.value}>{option.label}</Option>
  //                         ))}
  //                     </Select>
  //                 ),
  //             }
  //             : item;
  // });

  // const props = {
  //     tableName,
  //     modalTables: [
  //         'instrumental_calculation_source',
  //         'instrumental_calculation_data',
  //     ],
  //     formItemLayout,
  //     inputs: newInputs,
  //     // columns: rounding => insertEditor({ target: columnList, rounding }),
  //     expandedCols: rounding =>
  //         insertEditor({
  //             target: expandedColsList,
  //             fixed: true,
  //             width: 120,
  //             rounding,
  //         }),
  //     expandedInputs,
  //     scrollMultiplier: 190,
  //     transformDataSource,
  //     dataType: 'instrumental_result',
  //     company: true,
  //     tableType: 'emissions',
  // };

  return (
    <div>
      <StandardTable
        inputs={inputs}
        tableName={tableName}
        tableType={'gos_pek'}
        scrollMultiplier={170}
        columns={rounding => columns(rounding)}
        transformDataSource={transformDataSource}
        dataSource={[]}
      />
      <Button.Group style={{ marginBottom: 20 }}>
        <PekUploadShablon tableName={tableName} shablonName="ПЭК_Атм_6.xlsx" />
        <PekDeleteAllFromTable tableName={tableName} />
      </Button.Group>
      <div>
        <PekPromoFilesUpload
          columnName="atmosphere_monitoring_file"
          title="Протокол, (максимум 50мб)"
        />
      </div>
    </div>
  );
};

const mapStateToProps = ({
  substanceStore: { substance },
  userStore: { token },
}) => ({
  substance,
  token,
});

export default connect(mapStateToProps)(InfoMonitoring);

import { Button, message, Table, Typography } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  pekQuarterChange,
  pekYearChange,
} from '../../../../../redux/actions/pekDateActions';
import { pekTableGetData } from '../../../../../redux/actions/TableActions';
import tableDataReducer from '../../../../../redux/reducers/tableDataRedicer';
import inputs from './data/inputs';
import StandardTable from '../../../../../modules/StandardTable';
import Editor from '../../../../../modules/Editor';
import PekUploadShablon from '../../../PekUploadShablon';
import PekDeleteAllFromTable from '../../../PekDeleteAllFromTable';

const PAGE_SIZE = 15;

const InformationDisposalWaste = ({
  data,
  tableName,
  promoAreaId,
  quarter,
  year,
  setRefresh,
  token,
  props,
}) => {
  const { user } = useSelector(state => ({
    ...state.userStore,
  }));
  const [operation, setOperation] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  let query = ' ';
  const [search, setSearch] = useState('');
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState('1');
  const [departments, setDepartments] = useState([]);
  const [id, setId] = useState();

  const categoryStatusTypes = {
    'Ожидается определение категории': 'waiting',
    'Ожидается подпись': 'signing',
    'Отказано ': 'rejected',
    'Категория определена: I': 1,
    'Категория определена: II ': 2,
    'Категория определена: III': 3,
    'Категория определена: IV': 4,
  };
  const [selectedCategory, setSelectedCategory] = useState(
    user.username?.role_id === '5'
      ? 'Ожидается подпись'
      : 'Ожидается определение категории'
  );

  // useEffect(() => {
  //   const params = new URLSearchParams();
  //   if (search) {
  //     params.append('search', search);
  //   } else {
  //     params.delete('search');
  //   }
  //   if (currentPage) {
  //     params.append('page', currentPage);
  //   }
  //   history.push({ search: params.toString() });
  // }, [search, history, currentPage]);
  //
  // useEffect(() => {
  //   console.log("WWS",search, currentPage, selectedCategory);
  //   fetchUsers();
  // }, [search, currentPage, selectedCategory]);

  const onDeleteAsset = async id => {
    await axios.delete(
      `http://185.146.3.215:4000/api/data/delete/files?id=${id}`
    );
    console.log('Удаление');
    submit({ year, quarter, promoAreaId });
  };

  const onAcceptFile = async id => {
    await axios({
      method: 'POST',
      url: `http://185.146.3.215:4000/api/pek/excel/accept/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(() => {
        message.success('Успешно');
      })
      .catch(() => {
        message.error('Ошибка');
      });
    submit({ year, quarter, promoAreaId });
  };

  const closePopup = () => {
    setOperation(false);
    submit({ year, quarter, promoAreaId });
  };

  const submit = async () => {
    message.loading('Загружаем данные...');
    axios
      .get('http://185.146.3.215:4000/api/department', {
        Authorization: `Bearer ${token}`,
      })
      .then(res => {
        setDepartments(res.data);
      });
  };

  const fetchUsers = async () => {
    await axios({
      method: 'GET',
      url: `http://185.146.3.215:4000/api/user/category?pageSize=${PAGE_SIZE}&pageNumber=${currentPage}&search=${search}&category=${categoryStatusTypes[selectedCategory]}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(function(response) {
        message.success('Успешно загрузили');
        //handle success
        console.log(response.data);
        setDataSource(response.data.users);
        setTotalPage(response.data.total);
        console.log(response.data);
      })
      .catch(function(response) {
        //handle error
        console.log(response);
      });
  };

  useEffect(() => {
    submit();
    // axios.get(
    //     `http://localhost:4000/api/gos-pek/information_accumulation`
    // ).then(res => {
    //     message.success('Успешно загрузили');
    //     pekTableGetData(res.data);
    // })
  }, []);

  const categoryList = {
    1: 'I',
    2: 'II',
    3: 'III',
    4: 'IV',
  };
  const transformDataSource = (data, setState) => {
    const newDataSource = [];
    data.forEach(el =>
      newDataSource.push({
        key: el.id,
        ...el,
      })
    );
    setState(newDataSource);
  };

  const columns = rounding => (token, refresh) => {
    console.log(dataSource.filter(data => data.department_id === '6'));
    const columns = [
      {
        title: 'Действие',
        key: 'action',
        render: (text, record) => (
          <Editor
            addInputs={inputs}
            editInputs={inputs}
            text={text}
            tableName={tableName}
            editTableType={'gos_pek'}
            record={record}
            refresh={refresh}
          />
        ),
      },
      {
        title: 'Вид отхода',
        dataIndex: 'waste_type',
        key: 'waste_type',
      },
      {
        title: 'Код отхода',
        dataIndex: 'waste_code',
        key: 'waste_code',
      },
      {
        title: 'Образованный объем отходов на предприятии, тонн',
        dataIndex: 'volume_of_waste_enterprise',
        key: 'volume_of_waste_enterprise',
      },
      {
        title: 'Место захоронения отхода (координаты месторасположение)',
        children: [
          { title: 'Широта', dataIndex: 'latitude', key: 'latitude' },
          { title: 'Долгота', dataIndex: 'longitude', key: 'longitude' },
        ],
      },
      {
        title:
          'Захороненный объем отходов на данном месте захоронения на начало отчетного периода, тонн',
        dataIndex: 'buried_volume_of_waste',
        key: 'buried_volume_of_waste',
      },
      {
        title: 'Лимит захоронения отходов, тонн',
        dataIndex: 'limit_buried_waste',
        key: 'limit_buried_waste',
      },
      {
        title:
          'Фактический объем захороненных отходов за отчетный период, тонн',
        dataIndex: 'actual_volume_buried_waste',
        key: 'actual_volume_buried_waste',
      },
    ];
    return columns;
  };

  return (
    <div>
      <Typography.Text>
        Заполняется при захоронении собственных отходов производства и
        потребления, а также при захоронении на собственном полигоне отходов,
        оставшегося после проведения операции с изначальным видом отходов
      </Typography.Text>
      <StandardTable
        inputs={inputs}
        tableName={tableName}
        tableType={'gos_pek'}
        scrollMultiplier={150}
        columns={rounding => columns(rounding)}
        transformDataSource={transformDataSource}
        dataSource={[]}
      />

      <Button.Group style={{ marginBottom: 20 }}>
        <PekUploadShablon
          tableName={tableName}
          shablonName="ПЭК_Отходы_4.xlsx"
        />

        <PekDeleteAllFromTable tableName={tableName} />
      </Button.Group>
    </div>
  );
};

const mapStateToProps = ({
  userStore: { token },
  companyStore: { promoAreaId },
  pekDateStore: { quarter, year },
}) => ({
  promoAreaId,
  token,
  quarter,
  year,
  data: tableDataReducer.dataTable,
});
const mapDispatchToProps = {
  pekTableGetData,
  pekQuarterChange,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InformationDisposalWaste);

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Select } from 'antd';
import { Typography, Table } from 'antd';
import inputs from './data/inputs';
import expandedInputs from './data/expandedInputs';
import editableExpandedInputs from './data/editableExpandedInputs';
import SuperSelect from '../../../../../components/SuperSelect';
import Editor from '../../../../../modules/Editor';
import {
  getKatoSelectOptions,
  getSourceTypeSelectOptions,
} from '../../../../../api/PekAPI';
import StandardTable from '../../../../../modules/StandardTable';
import PekUploadShablon from '../../../PekUploadShablon';
import PekDeleteAllFromTable from '../../../PekDeleteAllFromTable';
import PekPromoFilesUpload from '../../../PekPromoFilesUpload';

const { Option } = Select;
const PAGE_SIZE = 15;

const PollutantsResults = ({
  substance,
  fetchSumSources,
  token,
  tableName,
}) => {
  const [katoSelectOptions, setKatoSelectOptions] = useState();
  const [sourceTypeSelectOptions, setSourceTypeSelectOptions] = useState();
  const [currentPage, setCurrentPage] = useState('1');
  const [totalPage, setTotalPage] = useState(0);

  // const renderSelectOption = () => {
  //     return substance.map(({code, name}) => (
  //         <Option
  //             key={code}
  //             value={`${code} - ${name}`}
  //         >{`${code} - ${name}`}</Option>
  //     ));
  // };
  const columns = rounding => (token, refresh) => {
    return [
      {
        title: 'Действие',
        key: 'action',
        render: (text, record) => (
          <Editor
            addInputs={inputs}
            editInputs={inputs}
            text={text}
            tableName={tableName}
            editTableType={'gos_pek'}
            record={record}
            refresh={refresh}
          />
        ),
      },
      {
        title: 'Площадка',
        children: [
          {
            title: 'Наименование',
            dataIndex: 'name',
            key: 'name',
          },
          {
            title: 'Местоположение, координаты (долгота и широта)',
            children: [
              {
                title: 'Широта',
                dataIndex: 'latitude',
                key: 'latitude',
              },
              {
                title: 'Долгота',
                dataIndex: 'longitude',
                key: 'longitude',
              },
            ],
          },
        ],
      },
      {
        title: 'Источник выброса',
        children: [
          {
            title: 'Наименование',
            dataIndex: 'ejection_source_name',
            key: 'ejection_source_name',
          },
          {
            title: 'Номер',
            dataIndex: 'ejection_source_number',
            key: 'ejection_source_number',
          },
        ],
      },
      {
        title: 'Код вещества',
        dataIndex: 'substance_code',
        key: 'substance_code',
      },
      {
        title: 'Наименование загрязняющих веществ',
        dataIndex: 'name_pollutants',
        key: 'name_pollutants',
      },
      {
        title: 'Установленный норматив по ПДВ, ОВОС',
        children: [
          {
            title: 'грамм в секунду',
            dataIndex: 'established_standard_gram_sek',
            key: 'established_standard_gram_sek',
          },
          {
            title: 'тонна в год',
            dataIndex: 'established_standard_ton_year',
            key: 'established_standard_ton_year',
          },
        ],
      },
      {
        title: 'Фактический результат',
        children: [
          {
            title: 'грамм в секунду',
            dataIndex: 'actual_result_gram_sek',
            key: 'actual_result_gram_sek',
          },
          {
            title: 'тонна в год',
            dataIndex: 'actual_result_ton_year',
            key: 'actual_result_ton_year',
          },
        ],
      },
      {
        title: 'Методика расчета',
        dataIndex: 'calculation_method',
        key: 'calculation_method',
      },
      {
        title: 'Вид потребляемого сырья/ материала (название)',
        dataIndex: 'type_waste',
        key: 'type_waste',
      },
      {
        title: 'Расход сырья/ материала, тонн',
        dataIndex: 'row_consumption_ton',
        key: 'row_consumption_ton',
      },
      {
        title: 'Время работы оборудования, часов',
        dataIndex: 'time_work',
        key: 'time_work',
      },
      {
        title: 'Превышение нормативов ПДВ',
        dataIndex: 'exceeding_standards',
        key: 'exceeding_standards',
      },
    ];
  };

  const transformDataSource = (data, setState) => {
    const newDataSource = [];
    data.forEach(el =>
      newDataSource.push({
        key: el.id,
        ...el,
      })
    );
    setState(newDataSource);
  };

  // useEffect(() => {
  //     getKatoSelectOptions({token}).then(res => {
  //         setKatoSelectOptions(
  //             res.data &&
  //             res.data.map(option => ({
  //                 value: option.id,
  //                 label: option.name,
  //             }))
  //         );
  //     });
  //
  //     getSourceTypeSelectOptions({token}).then(res => {
  //         setSourceTypeSelectOptions(
  //             res.data &&
  //             res.data.map(option => ({
  //                 value: option.id,
  //                 label: option.name,
  //             }))
  //         );
  //     });
  //
  //     const inputIndex = expandedInputs.findIndex(
  //         ({title}) => title === 'Код и наименование ЗВ'
  //     );
  //     const select = (
  //         <SuperSelect
  //             showSearch
  //             filterOption={(input, option) =>
  //                 option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
  //             }
  //         >
  //             {renderSelectOption()}
  //         </SuperSelect>
  //     );
  //     expandedInputs[inputIndex].component = select;
  //     editableExpandedInputs[inputIndex].component = select;
  // }, []);

  // const insertEditor = ({target, fixed = false, width = 300, rounding}) => (
  //     token,
  //     refresh
  // ) => [
  //     ...target(rounding),
  //     {
  //         title: 'Область аккредитации испытательной лаборатории',
  //         key: 'action',
  //         width: width,
  //         fixed: fixed ? 'right' : false,
  //         render: (text, record) => {
  //             const {is_source, data_type} = record;
  //             const props = {
  //                 text,
  //                 refresh,
  //                 record,
  //                 editInputs: is_source ? inputs : editableExpandedInputs,
  //                 addInputs: expandedInputs,
  //                 tableName: is_source ? 'calculation_source' : 'calculation_data',
  //                 dataType: 'fact_emissions',
  //                 isFactEmissions:
  //                     data_type === 'instrumental_result' && is_source ? true : false,
  //                 tableType: 'emissions',
  //                 editTableType: 'super',
  //             };
  //             return <Editor {...props} />;
  //         },
  //     },
  // ];
  // // const katoSelectIndex = inputs.findIndex(
  // //     ({dataIndex}) => dataIndex === 'kato_id'
  // // );
  // // const sourceTypeSelectIndex = inputs.findIndex(
  // //     ({dataIndex}) => dataIndex === 'source_type_id'
  // // );
  // // const newInputs = inputs.map((item, index) => {
  // //     return index === katoSelectIndex
  // //         ? {
  // //             ...item,
  // //             component: (
  // //                 <Select>
  // //                     {katoSelectOptions &&
  // //                     katoSelectOptions.map(option => (
  // //                         <Option key={option.value}>{option.label}</Option>
  // //                     ))}
  // //                 </Select>
  // //             ),
  // //         }
  // //         : index === sourceTypeSelectIndex
  // //             ? {
  // //                 ...item,
  // //                 component: (
  // //                     <Select>
  // //                         {sourceTypeSelectOptions &&
  // //                         sourceTypeSelectOptions.map(option => (
  // //                             <Option key={option.value}>{option.label}</Option>
  // //                         ))}
  // //                     </Select>
  // //                 ),
  // //             }
  // //             : item;
  // // });
  // // const props = {
  // //     tableName,
  // //     modalTables: ['calculation_source', 'calculation_data'],
  // //     // columns: rounding =>
  // //     //   insertEditor({ target: columnList, width: 270, rounding }),
  // //     expandedCols: rounding =>
  // //         insertEditor({
  // //             target: expandedColsList,
  // //             fixed: true,
  // //             width: 120,
  // //             rounding,
  // //         }),
  // //     inputs: newInputs,
  // //     expandedInputs,
  // //     dataType: 'fact_emissions',
  // //     scrollMultiplier: 170,
  // //     transformDataSource,
  // //     fetchSumSources,
  // //     company: true,
  // //     tableType: 'emissions',
  // // };

  return (
    <div>
      <StandardTable
        inputs={inputs}
        tableName={tableName}
        tableType={'gos_pek'}
        scrollMultiplier={200}
        columns={rounding => columns(rounding)}
        transformDataSource={transformDataSource}
        dataSource={[]}
      />
      <Button.Group style={{ marginBottom: 20 }}>
        <PekUploadShablon tableName={tableName} shablonName="ПЭК_Атм_5.xlsx" />
        <PekDeleteAllFromTable tableName={tableName} />
      </Button.Group>
      <div>
        <PekPromoFilesUpload
          columnName="atmosphere_result_calculation_file"
          title="Результат расчетов, (максимум 50мб)"
        />
      </div>
    </div>
  );
};

const mapStateToProps = ({
  substanceStore: { substance },
  companyStore: { promoAreaId },
  pekDateStore: { year, quarter },
  userStore: { token },
}) => ({
  substance,
  promoAreaId,
  year,
  quarter,
  token,
});

export default connect(mapStateToProps)(PollutantsResults);

import { Button, Form, Icon, Modal, Select, Typography, Upload } from 'antd';
import axios from 'axios';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

const { Title } = Typography;
const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const AddAdminList = ({ onCancel, isUpdate = false, id }) => {
  const [file, setFile] = useState();
  const [type, setType] = useState();
  const { year, quarter, promoAreaId, token } = useSelector(state => ({
    ...state.pekDateStore,
    ...state.companyStore,
    ...state.userStore,
  }));

  const onUpload = info => {
    if (info.file.status !== 'uploading') {
      console.log(info.file);
      setFile(info.file);
    }
  };

  async function onSubmit() {
    const data = new FormData();
    data.append('file', file.originFileObj);
    data.append('year', year);
    data.append('period', quarter);
    data.append('companyCardId', promoAreaId);
    data.append('type', type);

    await axios({
      method: isUpdate ? 'PUT' : 'POST',
      url: `http://185.146.3.215:4000/api/pek/excel${
        isUpdate ? `?id=${id}` : ''
      }`,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(function(response) {
        //handle success
        console.log(response);
      })
      .catch(function(response) {
        //handle error
        console.log(response);
      })
      .finally(onCancel);
  }

  return (
    <div>
      <Modal
        visible
        width={700}
        footer={[
          <Button
            form="add-adminlist-form"
            htmlType="submit"
            type="primary"
            disabled={!file || !type}
            onClick={onSubmit}
          >
            Добавить
          </Button>,
        ]}
        onCancel={onCancel}
      >
        <Title style={{ marginTop: 20, marginBottom: 50 }} level={4}>
          Добавление файлов
        </Title>
        <Form.Item label="Тип">
          <Select
            onChange={setType}
            style={{ width: '50%' }}
            placeholder="Выберите тип файла"
          >
            <Option value="emission">Выбросы</Option>
            <Option value="discharge">Cбросы</Option>
            <Option value="waste">Отходы</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Добавить файл">
          <Upload onChange={info => onUpload(info)} showUploadList={false}>
            <Button>
              <Icon type="upload" /> Добавить файл
            </Button>
          </Upload>
          {' ' + file ? file?.name : ''}
        </Form.Item>
      </Modal>
    </div>
  );
};

export default AddAdminList;

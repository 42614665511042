import roundUp from '../../../../utils/roundUp';

export default rounding => [
  {
    title: 'Код ЗВ',
    dataIndex: 'code',
    key: 'code',
    fixed: 'left',
    width: 150,
  },
  {
    title: 'Наименование вещества',
    dataIndex: 'name',
    key: 'name',
    fixed: 'left',
    width: 200,
  },
  {
    title: 'Класс опасности',
    dataIndex: 'danger_class',
    key: 'danger_class',
  },
  {
    title: 'ПДКм,р,',
    dataIndex: 'pdkm_r',
    key: 'pdkm_r',
    render: value => roundUp(value, rounding),
  },
  {
    title: 'ПДКс,с ,',
    dataIndex: 'pdkc_c',
    key: 'pdkc_c',
    render: value => roundUp(value, rounding),
  },
  {
    title: 'ОБУВ',
    dataIndex: 'obuv',
    key: 'obuv',
    render: value => roundUp(value, rounding),
  },
  {
    title: 'Агрегатное состояние',
    dataIndex: 'aggregation_state',
    key: 'aggregation_state',
  },
  {
    title: 'Код по 2-ТП',
    dataIndex: 'code_2tp',
    key: 'code_2tp',
  },
];

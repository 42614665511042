import { Button, Icon, message, Popconfirm, Table, Typography } from 'antd';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import AddAdminList from '../AdminPopup/AddAdminList';

const AdminList = ({ data, promoAreaId, quarter, year, setRefresh, token }) => {
  const [operation, setOperation] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [id, setId] = useState();

  const onDeleteAsset = async id => {
    await axios.delete(
      `http://185.146.3.215:4000/api/data/delete/files?id=${id}`
    );
    console.log('Удаление');
    submit({ year, quarter, promoAreaId });
  };

  const onAcceptFile = async id => {
    await axios({
      method: 'POST',
      url: `http://185.146.3.215:4000/api/pek/excel/accept/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(() => {
        message.success('Успешно');
      })
      .catch(() => {
        message.error('Ошибка');
      });
    submit({ year, quarter, promoAreaId });
  };

  const closePopup = () => {
    setOperation(false);
    submit({ year, quarter, promoAreaId });
  };

  const submit = async ({ year, quarter, promoAreaId }) => {
    if (!promoAreaId) return message.error('Не выбрано предприятие!');
    console.log(year, quarter, promoAreaId);
    await axios({
      method: 'GET',
      url: `http://185.146.3.215:4000/api/pek/excel?year=${year}&period=${quarter}&companyId=${promoAreaId}`,
    })
      .then(function(response) {
        //handle success
        console.log(response.data);
        setDataSource(response.data);
      })
      .catch(function(response) {
        //handle error
        console.log(response);
      });
  };

  useEffect(() => {
    submit({ year, quarter, promoAreaId });
  }, [promoAreaId, quarter, year]);

  const columns = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Наименование',
      dataIndex: 'name',
      key: 'name',
      width: 240,
      render: name => (
        <a onClick={() => window.open(`http://185.146.3.215:4000/${name}`)}>
          {name}
        </a>
      ),
    },
    {
      title: 'Тип',
      dataIndex: 'type',
      key: 'type',
      render: name => {
        const types = {
          waste: 'Отходы',
          discharge: 'Сбросы',
          emission: 'Выбросы',
        };
        return types[name];
      },
    },
    {
      title: 'Создано',
      dataIndex: 'created',
      key: 'created',
      render: date => moment(date).format('YYYY-MM-DD HH:mm'),
    },
    {
      title: 'Кем обновлен',
      dataIndex: 'updated_by',
      key: 'updated_by',
    },
    {
      title: 'Обновлен',
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: date => (date ? moment(date).format('YYYY-MM-DD HH:mm') : ''),
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Действие',
      dataIndex: '',
      render: record => (
        <Button.Group>
          <Popconfirm
            title="Соглосовать ?"
            onConfirm={() => onAcceptFile(record.id)}
            okText="Да"
            cancelText="Нет"
          >
            <Button type="primary">
              <Icon type="check" />
            </Button>
          </Popconfirm>
          <Button
            type="primary"
            onClick={() => {
              setOperation(true);
              setId(record.id);
            }}
          >
            <Icon type="edit" />
          </Button>

          <Popconfirm
            title="Вы уверены?"
            onConfirm={() => onDeleteAsset(record.id)}
            okText="Да"
            cancelText="Нет"
          >
            <Button type="danger">
              <Icon type="delete" />
            </Button>
          </Popconfirm>
        </Button.Group>
      ),
    },
  ];

  return (
    <div>
      <Typography.Title
        level={2}
        style={{
          fontSize: 14,
          textAlign: 'center',
          marginTop: 15,
          marginBottom: 15,
        }}
      >
        Файлы
      </Typography.Title>
      <Table
        pagination={{ pageSize: 25 }}
        dataSource={dataSource}
        columns={columns}
        scroll={{ x: 'calc(1500px + 50%)' }}
        bordered
      />
      {operation && (
        <AddAdminList isUpdate={true} onCancel={closePopup} id={id} />
      )}
    </div>
  );
};

const mapStateToProps = ({
  userStore: { token },
  companyStore: { promoAreaId },
  pekDateStore: { quarter, year },
}) => ({
  promoAreaId,
  token,
  quarter,
  year,
});

export default connect(mapStateToProps)(AdminList);

import {
  Button,
  Form,
  Icon,
  message,
  Popconfirm,
  Table,
  Typography,
} from 'antd';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import AddAdminList from '../AdminPopup/AddAdminList';
import { Select } from 'antd';

const AdminList = ({ data, promoAreaId, quarter, year, setRefresh, token }) => {
  const { user } = useSelector(state => ({
    ...state.userStore,
  }));
  const [operation, setOperation] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [id, setId] = useState();

  const categoryStatusTypes = {
    'Все ': 'Все',
    'Заполняется предприятием': null,
    'Ожидается определение категории': 'waiting',
    'Ожидается подпись': 'signing',
    'Отказано ': 'rejected',
    'Категория определена: I': 1,
    'Категория определена: II ': 2,
    'Категория определена: III': 3,
    'Категория определена: IV': 4,
  };
  const [selectedCategory, setSelectedCategory] = useState(
    user.username?.role_id === '5' ? 'Ожидается подпись' : 'Все'
  );

  const onDeleteAsset = async id => {
    await axios.delete(
      `http://185.146.3.215:4000/api/data/delete/files?id=${id}`
    );
    console.log('Удаление');
    submit({ year, quarter, promoAreaId });
  };

  const onAcceptFile = async id => {
    await axios({
      method: 'POST',
      url: `http://185.146.3.215:4000/api/pek/excel/accept/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(() => {
        message.success('Успешно');
      })
      .catch(() => {
        message.error('Ошибка');
      });
    submit({ year, quarter, promoAreaId });
  };

  const closePopup = () => {
    setOperation(false);
    submit({ year, quarter, promoAreaId });
  };

  const submit = async () => {
    message.loading('Загружаем данные...');
    axios
      .get('http://185.146.3.215:4000/api/data/select/departments')
      .then(res => {
        setDepartments(res.data);
      });
    await axios({
      method: 'GET',
      url: `http://185.146.3.215:4000/api/user`,
    })
      .then(function(response) {
        message.success('Успешно загрузили');
        //handle success
        console.log(response.data);
        setDataSource(response.data);
        console.log(response.data);
      })
      .catch(function(response) {
        //handle error
        console.log(response);
      });
  };

  useEffect(() => {
    submit();
  }, []);

  const categoryList = {
    1: 'I',
    2: 'II',
    3: 'III',
    4: 'IV',
  };

  const renderСolumns = () => {
    const columns = [
      {
        title: '№',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: 'Наименование',
        dataIndex: 'name',
        key: 'name',
        width: 240,
      },
      {
        title: 'БИН',
        dataIndex: 'bin',
        key: 'bin',
      },
      {
        title: 'E-mail',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Номер телефона',
        dataIndex: 'phone_num',
        key: 'phone_num',
      },
      {
        title: 'Адрес',
        dataIndex: 'city_kz',
        key: 'city_kz',
        render: (city, record) => (
          <div>
            {city} {record.street_name_ru}
          </div>
        ),
      },
      {
        title: 'ОКЭД',
        dataIndex: 'oked',
        key: 'oked',
      },
      {
        title: 'Руководитель',
        dataIndex: 'fio_leader',
        key: 'fio_leader',
      },
      {
        title: 'Телефон руководителя',
        dataIndex: 'phone_leader',
        key: 'phone_leader',
      },
      {
        title: 'Статус на определение категории',
        dataIndex: 'category_status',
        key: 'category_status',
        render: (category_status, record) => (
          <div>
            {category_status
              ? category_status === 'waiting'
                ? 'Ожидается определение категории'
                : category_status === 'rejected'
                ? 'Отказано'
                : category_status === 'signing'
                ? `Ожидается подпись (категория: ${
                    categoryList[record?.category]
                  })`
                : `Категория определена: ${categoryList[record?.category]}`
              : 'Заполняется предприятием'}
          </div>
        ),
      },
      {
        title: 'Дата подачи заявки',
        dataIndex: 'send_report_date',
        key: 'send_report_date',
        render: date => {
          console.log('WWS', date);
          if (!date) return '';
          return moment(date).format('YYYY-MM-DD');
        },
      },
    ];
    return columns;
  };

  return (
    <div>
      {/* <select
        value={selectedCategory}
        onChange={e => setSelectedCategory(e.target.value)}
        style={{ width: 270 }}
      >
        {categoryTypes.map((categoryType, index) => (
          <option key={index} value={categoryType}>
            {categoryType}
          </option>
        ))}
      </select> */}
      <Select
        value={selectedCategory}
        onSelect={setSelectedCategory}
        style={{ width: 270 }}
      >
        {Object.keys(categoryStatusTypes).map((categoryType, index) => (
          <Select.Option key={index} value={categoryType}>
            {categoryType}
          </Select.Option>
        ))}
      </Select>
      <Typography.Title
        level={2}
        style={{
          fontSize: 14,
          textAlign: 'center',
          marginTop: 15,
          marginBottom: 15,
        }}
      >
        Предприятия{' '}
      </Typography.Title>
      <Table
        pagination={{ pageSize: 25 }}
        dataSource={dataSource.filter(item =>
          selectedCategory === 'Все' ||
          categoryStatusTypes[selectedCategory] === 'Все'
            ? item.name !== null
            : typeof categoryStatusTypes[selectedCategory] === 'string'
            ? item.name !== null &&
              item.category_status === categoryStatusTypes[selectedCategory]
            : categoryStatusTypes[selectedCategory] === null
            ? item.name !== null && item.category_status === null
            : item.name !== null &&
              item.category_status === 'finished' &&
              item.category ===
                categoryStatusTypes[selectedCategory]?.toString()
        )}
        columns={renderСolumns()}
        scroll={{ x: 'calc(1500px + 50%)' }}
        bordered
      />
      {/* {operation && (
        <AddAdminList isUpdate={true} onCancel={closePopup} id={id} />
      )} */}
    </div>
  );
};

const mapStateToProps = ({
  userStore: { token },
  companyStore: { promoAreaId },
  pekDateStore: { quarter, year },
}) => ({
  promoAreaId,
  token,
  quarter,
  year,
});

export default connect(mapStateToProps)(AdminList);

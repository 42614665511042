import axios from 'axios';

import {BaseAPI} from './BaseAPI';

class WasteAPI extends BaseAPI {
    getResources = async ({token, name, promoAreaId, period, year}) => {
        console.log(
            `${this.BASE_URL}${this.WASTE_PATH}${
                name === 'report' ? '/report' : ''
            }?companyId=${promoAreaId}&period=${period}&year=${year}`
        );
        return await axios({
            method: 'GET',
            url: `${this.BASE_URL}${this.WASTE_PATH}${
                name === 'report' ? '/report' : ''
            }?companyId=${promoAreaId}&period=${period}&year=${year}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(({data}) => {
                return data;
            })
            .catch(error => {
                console.error(error.response);
            });
    };

    setSource = async ({token, values, period, year, promoAreaId}) =>
        await axios({
            method: 'POST',
            url: `${this.BASE_URL}${this.WASTE_PATH}/insert/waste_data`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            data: {
                ...values,
                period,
                year,
                company_card_id: promoAreaId,
            },
        })
            .then(({data}) => data)
            .catch(error => {
                console.error(error);
            });

    update = async ({token, values, period, year, promoAreaId, itemId}) =>
        await axios({
            method: 'PUT',
            url: `${this.BASE_URL}${this.WASTE_PATH}/update/waste_data?id=${itemId}`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            data: {
                ...values,
                period,
                year,
                company_card_id: promoAreaId,
            },
        })
            .then(res => console.log(res))
            .catch(error => {
                console.error(error.response);
            });
}

const {getResources, setSource, setZV, update, updateZV} = new WasteAPI();

export {getResources, setSource, setZV, update, updateZV};
